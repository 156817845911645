import React, { useState, useEffect, useContext } from "react";
import VideoIDContext from "./../videoContext.js";
import { authAxios } from "../config/axios.js";
function UploadStatus() {
  const [showLoader, setShowLoader] = useState(false);
  const [message, setMessage] = useState("Video is uploading...");
  const {
    videoID,
    isPopup,
    setIsUpload,
    isUpload,
    videoDetail,
    uploadStatus,
    setUploadStatus,
  } = useContext(VideoIDContext);

  useEffect(() => {
    const checkVideoUploadStatus = async () => {
      try {
        if (!videoID) return; // Exit if videoID is not available
        setShowLoader(isUpload);

        // Continuously check the video upload status
        const interval = setInterval(async () => {
          const res = await authAxios(
            "get",
            `/apisv1/authadmin/checkvideostatus?video=${videoID}&isPopup=${isPopup}`,
          );
          if (res.status === 200) {
            setUploadStatus(res.data.data);
            const uploadStatus = res.data.data.video_state;
            if (uploadStatus) {
              setShowLoader(false);
              setIsUpload(false);
              clearInterval(interval); // Stop checking the status when upload is completed
            } else {
              setMessage("video is uploading");
            }
          }
        }, 3000); // Check every 2 seconds

        // Cleanup the interval when the component is unmounted
        return () => {
          //   clearInterval(interval);
        };
      } catch (error) {
        console.error("Error checking video upload status:", error);
        setMessage("Error checking video upload status.");
      }
    };

    // Call the API to check video upload status
    checkVideoUploadStatus();
  }, [videoID, isPopup, isUpload]);

  return (
    <></>
    // showLoader && (
    //   <div
    //     style={{
    //       position: "fixed",
    //       bottom: "20px",
    //       right: "20px",
    //       backgroundColor: "#fff",
    //       padding: "10px",
    //       border: "1px solid #ccc",
    //       borderRadius: "5px",
    //       display: "flex",
    //       alignItems: "center",
    //       justifyContent: "space-between",
    //       zIndex: "9999",
    //     }}
    //   >
    //     <div
    //       style={{ display: "flex", alignItems: "center", fontSize: "16px" }}
    //     >
    //       <div>
    //         <svg className="loader-check" viewBox="25 25 50 50">
    //           <circle
    //             className="loader-path"
    //             cx={50}
    //             cy={50}
    //             r={20}
    //             fill="none"
    //             stroke="#7441f6"
    //             strokeWidth={2}
    //           />
    //         </svg>
    //       </div>
    //       <span style={{ marginLeft: "5px" }}>{message}</span>
    //     </div>
    //   </div>
    // )
  );
}

export default UploadStatus;

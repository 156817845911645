import React, { useState, useRef, useEffect, useContext } from "react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { authAxios } from "../config/axios";
import { useAuth } from "../AuthComp/Auth";
import Loader from "./Loader";
import VideoIDContext from "./../videoContext.js";
import { Pause, Play, WarningIcon } from "../assets/icons";
import { flushSync } from "react-dom";
import toast, { Toaster } from "react-hot-toast";
import CSLoader from "./CSLoader.js";

let ffmpeg;
let max = 0;
let isPopup = true;

function VideoEditor({
  videoInfo,
  tab,
  videoTrimmedUrl,
  setVideoTrimmedUrl,
  setTabIndex,
  videoFile,
  fetchMediaList,
  isEdit,
  // moveToNext,
  // setMoveToNext,
  handleClose,
}) {
  let auth = useAuth();
  let min;
  let check_call;
  let last_saved_video;
  if (tab == 1) {
    min = 4;
    max = 10;
    isPopup = false;
    last_saved_video = videoInfo.cover_video_src;
    check_call = videoInfo.is_cover_video_comp;
  } else {
    min = 9;
    max = 15;
    isPopup = true;
    last_saved_video = videoInfo.popup_video_src;
    check_call = videoInfo.is_popup_video_comp;
  }

  const assetsUrl = "https://assets.vidzy.in";
  let video_link = `${assetsUrl}/${auth.shopname}/original/${videoInfo.origin_video_file}`;
  const [isMuted, setIsMuted] = useState(false);
  const [duration, setDuration] = useState(null);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);
  const [durationValue, setDurationValue] = useState(min + 1);
  const [currentTime, setCurrentTime] = useState(0);
  const [showFullVideo, setShowFullVideo] = useState(false);

  const [last_saved_video_src, setLast_saved_video_src] =
    useState(last_saved_video);
  const [videoKey, setVideoKey] = useState(0);

  let playedVideo = useRef(0);
  let video_ref = useRef(null);
  const [counter, setcounter] = useState(0);
  // const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");
  const [videoFileValue, setVideoFileValue] = useState("");
  const [isVideoCompressed, setIsVideoCompressed] = useState(false);
  // const [videoTrimmedUrl, setVideoTrimmedUrl] = useState(last_saved_video);
  const [isPlay, setIsPlay] = useState(false);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [apiCallCount, setApiCallCount] = useState(0);
  const [error, setError] = useState("");
  /* video cut related function starts */
  const { setVideoID, setIsPopup, setIsUpload, setVideoDetail } =
    useContext(VideoIDContext);

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  useEffect(() => {
    if (videoInfo.video_id) {
      setVideoID(videoInfo.video_id);
      setIsPopup(isPopup);
      videoStatusCheck();
    }
  }, [apiCallCount]);
  useEffect(() => {
    if (!videoTrimmedUrl) {
      setShowFullVideo(true);
    } else {
      // setMoveToNext(true);
    }
  }, [videoTrimmedUrl]);
  useEffect(() => {
    setUploadLoader(videoInfo && videoInfo.video_id ? false : true);
  }, [videoInfo]);

  const videoStatusCheck = async () => {
    try {
      setUploadLoader(true);
      let res = await authAxios(
        "get",
        `/apisv1/authadmin/checkvideostatus?video=${videoInfo.video_id}&isPopup=${isPopup}`
      );
      if (res.status === 200) {
        let state = res.data.data.video_state;
        let new_video_src = res.data.data.origin_video_file;
        if (new_video_src) {
          setVideoTrimmedUrl(`${assetsUrl}/${auth.shopname}/${new_video_src}`);
          setVideoKey(videoKey + 1);
        }
        setUploadLoader(false);
      }
    } catch (error) {
      console.log(error);
      setUploadLoader(false);
    }
  };

  const handleTrim = async () => {
    if (videoTrimmedUrl) {
      setTabIndex(tab + 1);
      return;
    }
    const duration = end - start;
    if (duration > 15 || duration < 4) {
      toast("Video duration should be between 4 to 15 seconds.", {
        duration: 2000,
        position: "top-center",
        icon: <WarningIcon />,
        style: {
          maxWidth: "fit-content",
        },
      });
      // setError("Video duration should be between 4 and 15 seconds.");
      return;
    }
    setVideoTrimmedUrl("");
    // const url = URL.createObjectURL(videoFile);
    // setVideoTrimmedUrl(url);
    await uploadVideoCall(videoFile, start, end);
    // if(isEdit){
    //   await uploadVideoCall(videoFile, start, end);
    // }
    // if (videoFile) {
    //   await uploadVideoCall(videoFile, start, end);
    // } else {
    //   // Delay the execution and check again
    //   setTimeout(() => {
    //     handleTrim();
    //   }, 2000);
    // }
  };

  const convertToHHMMSS = (val) => {
    const secNum = parseInt(val, 10);
    let hours = Math.floor(secNum / 3600);
    let minutes = Math.floor((secNum - hours * 3600) / 60);
    let seconds = secNum - hours * 3600 - minutes * 60;
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    let time;
    // only mm:ss
    if (hours === "00") {
      time = minutes + ":" + seconds;
    } else {
      time = hours + ":" + minutes + ":" + seconds;
    }
    return time;
  };

  const loadVideoFromURL = async (url) => {
    try {
      const response = await fetch(url);
      const videoBlob = await response.blob();
      return videoBlob;
    } catch (error) {
      console.error("Error loading video from URL:", error);
      throw error;
    }
  };

  const onTrimComplete = async () => {
    let res = await authAxios(
      "get",
      `/apisv1/authadmin/checkvideostatus?video=${videoInfo.video_id}&isPopup=${isPopup}`
    );
    if (res.status === 200) {
      const { new_video_src } = res.data;
      if (new_video_src) {
        setVideoTrimmedUrl(`${assetsUrl}/${auth.shopname}/${new_video_src}`);
      }
    }
  };

  const uploadVideoCall = async (file, startTime, endTime) => {
    setUploadLoader(true);
    try {
      if (videoInfo && videoInfo.video_id) {
        setVideoDetail(videoInfo);
        setVideoID(videoInfo.video_id);
        setIsPopup(isPopup);
        setIsUpload(true);
      }
      // if (isEdit) {
      //   let response = await fetch(file);
      //   file = await response.blob();
      //   file = await loadVideoFromURL(video_link);
      // }
      // let form = new FormData();
      // form.append("cropped_video", file, "cropped.mp4");
      let res = await authAxios(
        "post",
        `/apisv1/authadmin/trimvideoupload?video=${videoInfo.video_id}&start=${startTime}&end=${endTime}&ispopup=${isPopup}&isEdit=${isEdit}`
        // form,
      );
      if (res.status === 200) {
        setTimeout(() => {
          setApiCallCount(apiCallCount + 1);
        }, 2000);

        let res = await authAxios(
          "get",
          `/apisv1/authadmin/checkvideostatus?video=${videoInfo.video_id}&isPopup=${isPopup}`
        );
        if (res.status === 200) {
          const { new_video_src } = res.data.data;
          if (new_video_src) {
            setVideoTrimmedUrl(
              `${assetsUrl}/${auth.shopname}/${new_video_src}`
            );
            setUploadLoader(false);
            setShowFullVideo(false);
            setTabIndex(tab + 1);
            // setMoveToNext(!moveToNext);
          }
        }

        // setPreviewImag(URL.createObjectURL(blob));
        // setTabIndex(tab + 1);
      } else {
        /* show error */
      }
      // setUploadLoader(false);
    } catch (error) {
      setUploadLoader(false);
      console.log(error);
    }
  };

  /* video cut related function ends */

  const handleLoadedVideo = (e) => {
    let Totle_duration = Math.round(e.target.duration);
    setDuration(Totle_duration);
    max = Totle_duration;
    setEnd(Totle_duration);
  };

  const handleTimeUpdate = (e) => {
    let timeline = Math.round(e.target.currentTime);
    setCurrentTime(timeline);
    if (timeline === end) {
      video_ref.current.pause();
      video_ref.current.currentTime = start;
      video_ref.current.play();
    }
  };
  const handleSelectRange = (e) => {
    const startTime = parseFloat(e[0]);
    const endTime = parseFloat(e[1]);

    if (!isNaN(startTime) && !isNaN(endTime)) {
      setStart(startTime);
      setEnd(endTime);
      video_ref.current.currentTime = startTime;
      setError("");
    }
  };

  const handleOnInput = (e, user) => {
    setVideoTrimmedUrl("");
    let startThmb = e[0];
    let endThmb = e[1];
    if (startThmb !== endThmb) {
      if (endThmb - startThmb <= durationValue) {
        video_ref.current.currentTime = start;
        setStart(startThmb);
        setEnd(endThmb);
      }
    }
  };
  const handleSelectedVideo = () => {
    video_ref.current.currentTime = start;
    video_ref.current.play();
  };
  const handleCustomInput = (e) => {
    let value = e.target.value;
    video_ref.current.currentTime = value;
    if (Number(value) + durationValue > duration) {
      // setEnd(duration);
    } else {
      setStart(value);
      setEnd(Number(value) + durationValue);
    }
  };

  const handleDuration = (e) => {
    max = duration;
    let value = Number(e.target.value);
    let final_start = Number(start);
    let final_end = Number(end);
    if (value <= max && value >= min) {
      if (final_start + value > duration) {
        let startvalue = final_start - (final_start + value - duration);
        setStart(startvalue);
        setEnd(startvalue + value);
        setDurationValue(value > duration ? duration : value);
      } else {
        // setEnd(final_start + value)
        setDurationValue(value > duration ? duration : value);
      }
    }
  };

  const handleVideoState = () => {
    let final = !isPlay;
    if (final === true) {
      video_ref.current.play();
    } else {
      video_ref.current.pause();
    }
    setIsPlay(final);
  };
  return (
    <div className="">
      <Toaster />
      <div className="video-box">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "center",
            marginLeft: "auto",
          }}
        >
          {/* <p className="border-header" style={{ fontWeight: "700" }}>
            {tab === 1 ? "Cover Video" : "Popup Video"}
          </p> */}
          <div>
            {isEdit && (
              <button
                className={`${showFullVideo ? "modal-text-btn-disable" : "modal-text-btn"}`}
                onClick={() => {
                  setShowFullVideo(true);
                  // setMoveToNext(false);
                }}
                style={{ fontSize: "16px" }}
              >
                Replace
              </button>
            )}

            {/* <button
              className="modal-text-btn"
              style={{
                color: `${moveToNext ? "grey" : ""}`,
                fontSize: "16px",
              }}
              onClick={() => handleTrim()}
            >
              Trim & Save
            </button> */}
          </div>
        </div>
        {showFullVideo && (
          <div className="left-video">
            <div className="video_editor">
              <video
                // controls
                style={{ height: "260px", width: "260px" }}
                ref={video_ref}
                className="video"
                onLoadedMetadata={(e) => handleLoadedVideo(e)}
                id="video"
                preload="metadata"
                poster=""
                onTimeUpdate={(e) => handleTimeUpdate(e)}
              >
                {isEdit ? (
                  <source
                    src={video_link && video_link}
                    type="video/mp4"
                  ></source>
                ) : (
                  <source
                    src={videoFile && URL.createObjectURL(videoFile)}
                    type="video/mp4"
                  ></source>
                )}
              </video>
              {uploadLoader && (
                <div className="VD-vid_load_main">
                  <CSLoader />
                  {/* Video is trimming. */}
                </div>
              )}

              <div style={{ maxWidth: "400px", margin: "auto" }}>
                <div style={{ display: "flex" }}>
                  <div onClick={() => handleVideoState()}>
                    {!isPlay ? <Play /> : <Pause />}
                  </div>
                  <div title="Minimum requirements"></div>
                  <div
                    style={{
                      marginTop: 10,
                      marginBottom: 20,
                      marginLeft: 10,
                      width: "100%",
                    }}
                  >
                    <RangeSlider
                      id="range-slider-blue"
                      step={1}
                      value={[start, end]}
                      min={0}
                      max={duration}
                      onInput={handleSelectRange}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    width: "100%",

                    justifyContent: "space-between",
                    fontSize: "14px",
                    marginBottom: "10px",
                  }}
                >
                  <div>
                    {convertToHHMMSS(start)}s - {convertToHHMMSS(end)}s
                  </div>
                  <div> {convertToHHMMSS(currentTime - start)}</div>
                </div>
                {!!error && (
                  <div
                    style={{ color: "red", fontSize: "14px", marginBottom: 15 }}
                  >
                    {error}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {!showFullVideo && (
          <div className="right-video">
            <div
              className="VD-Cover-Video"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginBottom: "2px",
              }}
            >
              {videoTrimmedUrl !== null ? (
                <>
                  <div className="video-container">
                    <video
                      key={videoKey}
                      loop
                      muted={isMuted}
                      autoPlay
                      controls
                      style={{ height: "322px", width: "180px" }}
                    >
                      <source src={videoTrimmedUrl} type="video/mp4" />
                    </video>
                  </div>
                </>
              ) : (
                <div className="noimagefound">No Video found</div>
              )}
            </div>
          </div>
        )}
      </div>

      <div
        className="border-header"
        style={{
          margin: "0",
          marginBottom: "12px",
          paddingBottom: "0px",
          fontSize: "14px",
          marginInline: "auto",
          width: "fit-content",
        }}
      >
        Video duration should be between 4-15 seconds
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "12px",
          paddingTop: "12px",
          borderTop: " 1px solid #EAEAEA",
        }}
      >
        <button
          className="cancel-btn"
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </button>
        <button className="success-btn" onClick={handleTrim}>
          Save & Next
        </button>
      </div>
    </div>
  );
}

export default React.memo(VideoEditor);

import React, { useState } from "react";
import { authAxios } from "../../config/axios";
import Loader from "../Loader";
import CommonSuccess from "../CommonSuccess";
import CommonDelete from "../CommonDelete";
function Billing(props) {
  const [loading, setLoading] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const cancelPlan = async (planId) => {
    try {
      setLoading(true);
      await authAxios("post", `/apisv1/billing/chargecancel`, {
        id: planId,
      });

      setLoading(false);
    } catch (error) {
      console.log("Error fetching data:", error);
      setLoading(false);
    }
  };

  const convertToTitleCase = (value) => {
    return value?.charAt(0).toUpperCase() + value?.slice(1).toLowerCase();
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="Billing">
          <div className="Polaris-Page__Content">
            <div className="Polaris-Grid">
              <div className="Polaris-Grid-Cell Polaris-Grid-Cell--cell_6ColumnXs Polaris-Grid-Cell--cell_3ColumnSm Polaris-Grid-Cell--cell_3ColumnMd Polaris-Grid-Cell--cell_6ColumnLg Polaris-Grid-Cell--cell_6ColumnXl">
                <div className="billing-card">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "0.5px solid #858D9D",
                      paddingBottom: "20px",
                    }}
                  >
                    <p className="billing-title">
                      {convertToTitleCase(props.data.user.plan_name)} Plan
                    </p>
                    <p className="billing-value">
                      ${props.data.user.plan_price}
                    </p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "0.5px solid #858D9D",
                      paddingBottom: "20px",
                    }}
                  >
                    <p className="billing-title">Usage Charges</p>
                    <p className="billing-value">
                      Additional views at $9/1,000 views
                    </p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="billing-title">Bill Amount</p>
                    <p className="billing-value">
                      ${props.data.user.plan_price} (+usage charges, if any)
                    </p>
                  </div>
                </div>
              </div>
              <div className="Polaris-Grid-Cell Polaris-Grid-Cell--cell_6ColumnXs Polaris-Grid-Cell--cell_3ColumnSm Polaris-Grid-Cell--cell_3ColumnMd Polaris-Grid-Cell--cell_6ColumnLg Polaris-Grid-Cell--cell_6ColumnXl">
                <div className="billing-card">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "0.5px solid #858D9D",
                      paddingBottom: "20px",
                    }}
                  >
                    <p className="billing-title">
                      Billing for {props.data.billing.date_range}
                    </p>
                    <p className="billing-value">Usage charges created (USD)</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "0.5px solid #858D9D",
                      paddingBottom: "20px",
                    }}
                  >
                    <p className="billing-title">
                      {props.data.billing.extraUsageFee.toFixed(2)}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="billing-title">
                      ({props.data.billing.extraUsageFee.toFixed(2)}% of
                      revenue)
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "28px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  className="Polaris-LegacyCard__Section"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <p className="upgrade-title">Upgrade or modify plan</p>
                  <p className="upgrade-description">
                    To upgrade or downgrade choose any plan listed below
                  </p>
                </div>
                <div style={{ display: "flex", gap: "20px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p className="upgrade-title">Cancel Plan</p>
                    <p className="upgrade-description">
                      Cancel your plan and switch to Starter (Free)
                    </p>
                  </div>
                  <div>
                    <button
                      onClick={() => setShowCancelModal(!showCancelModal)}
                      // onClick={() => cancelPlan(props.data.user.plan_id)}
                      type="button"
                      className="cancel-btn"
                    >
                      Cancel Plan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showCancelModal && (
            <CommonDelete
              headlText="Cancel Plan"
              desc="Are you sure you want to cancel the starter plan?"
              cancelText="No"
              confirmText="Yes"
              setIsShowDeletePopup={setShowCancelModal}
              handleDelete={() => {
                setShowCancelModal(!showCancelModal);
                setShowSuccessModal(!showSuccessModal);
                cancelPlan(props.data.user.plan_id);
              }}
            />
          )}
          {showSuccessModal && (
            <CommonSuccess
              headlText="Plan Cancel"
              desc="Starter Plan is cancelled successfully"
              setIsShowDeletePopup={setShowSuccessModal}
              handleDelete={() => setShowSuccessModal(!showSuccessModal)}
            />
          )}
        </div>
      )}
    </>
  );
}

export default Billing;

import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider, ProtectedRoutes } from "./AuthComp/Auth";
import Analytics from "./pages/Analytics.js";
import Dashboard from "./pages/Dashboard.js";
import config from "./config/config";
import Billing from "./pages/Billing";
import Media from "./pages/Media";
import Playlist from "./pages/Playlist";
import Pages from "./pages/Pages";
import Customise from "./pages/Customise";
import Settings from "./pages/Settings";
import HelpAndSupport from "./pages/HelpAndSupport.js";
import { VideoIDProvider } from "./videoContext.js";
import UploadStatus from "./component/checkUploadStatus";
import PrivacyPolicy from "./pages/Privacy-policy";
import { Amplify } from "aws-amplify";

Amplify.configure({
  Auth: {
    Cognito: {
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      allowGuestAccess: true,
      region: process.env.REACT_APP_REGION,
    },
  },
  Storage: {
    S3: {
      bucket: process.env.REACT_APP_S3_BUCKET,
      region: process.env.REACT_APP_REGION,
    },
  },
});

function App() {
  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    let token = params.get("shopcode");
    if (token) {
      localStorage.setItem(config.LOCAL_STORAGE.SECURITY_TOKEN, token);
      window.location.replace(window.location.origin);
    }
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <AuthProvider>
          <VideoIDProvider>
            <Routes>
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/:shop/dashboard"
                element={
                  <ProtectedRoutes>
                    <Dashboard />
                  </ProtectedRoutes>
                }
              />
              <Route
                path="/:shop/analytics"
                element={
                  <ProtectedRoutes>
                    <Analytics />
                  </ProtectedRoutes>
                }
              />
              <Route
                path="/:shop/media"
                element={
                  <ProtectedRoutes>
                    <Media />
                  </ProtectedRoutes>
                }
              />
              <Route
                path="/:shop/playlist"
                element={
                  <ProtectedRoutes>
                    <Playlist />
                  </ProtectedRoutes>
                }
              />
              <Route
                path="/:shop/pages"
                element={
                  <ProtectedRoutes>
                    <Pages />
                  </ProtectedRoutes>
                }
              />
              <Route
                path="/:shop/customise"
                element={
                  <ProtectedRoutes>
                    <Customise />
                  </ProtectedRoutes>
                }
              />
              <Route
                path="/:shop/settings"
                element={
                  <ProtectedRoutes>
                    <Settings />
                  </ProtectedRoutes>
                }
              />
              <Route
                path="/:shop/help-and-support"
                element={
                  <ProtectedRoutes>
                    <HelpAndSupport />
                  </ProtectedRoutes>
                }
              />
              <Route path="/:shop/billing" element={<Billing />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              {/* <Route path="/404" element={<NotFound/>} />            */}
            </Routes>
            <UploadStatus></UploadStatus>
          </VideoIDProvider>
        </AuthProvider>
      </BrowserRouter>
      {/* <VideoPackage/> */}
      {/* {static_data.length && storyLoader(static_data)} */}
    </div>
  );
}

export default App;

import React from "react";

function InputCheckBox({ label, events }) {
  return (
    <div>
      <label
        className="Polaris-Choice"
        style={{ padding: "var(--p-space-2) 0", alignItems: "center" }}
        htmlFor={events.name}
      >
        <span className="Polaris-Choice__Control">
          <span className="Polaris-Checkbox">
            <input
              name={events.name}
              id={events.name}
              type="checkbox"
              defaultChecked
              className="Polaris-Checkbox__Input"
              aria-invalid="false"
              role="checkbox"
              aria-checked="false"
              //   value={value}
              onChange={(e) => events.onChange(e)}
              checked={events.checked}
              //   defaultValue
            />
            <span className="Polaris-Checkbox__Backdrop"></span>
            <span className="Polaris-Checkbox__Icon">
              <span className="Polaris-Icon">
                <span className="Polaris-Text--root Polaris-Text--visuallyHidden"></span>
                <svg
                  viewBox="0 0 20 20"
                  className="Polaris-Icon__Svg"
                  focusable="false"
                  aria-hidden="true"
                >
                  <path d="M14.723 6.237a.94.94 0 0 1 .053 1.277l-5.366 6.193a.834.834 0 0 1-.611.293.83.83 0 0 1-.622-.264l-2.927-3.097a.94.94 0 0 1 0-1.278.82.82 0 0 1 1.207 0l2.297 2.43 4.763-5.498a.821.821 0 0 1 1.206-.056Z"></path>
                </svg>
              </span>
            </span>
          </span>
        </span>
        <h1 className="Polaris-Text--root Polaris-Text--headingMd">{label}</h1>
      </label>
    </div>
  );
}

export default InputCheckBox;

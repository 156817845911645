export const dashboardMock = {
  welcome: {
    pages: 0,
    videos: 0,
  },
  analytics: {
    click: 0,
    clickConversionRate: 0,
    impression: 0,
    impressionConversionRate: 0,
    revenue: 0,
  },
  orders: {
    totalOrders: 0,
    conversionRate: 0,
    averageOrderValue: 0,
    previousConversationRate: 0,
  },
  engagement: {
    totalWatchCountPerDay: 0,
    totalWatchCount: 0,
    totalWatchCountInHours: 0,
  },
  topPlaylist: [],
  topVideos: [],
};

export const dashboardChartMock = {
  impressionChart: [
    { date: "2023-04-25", impression: 100, addToCart: 20 },
    { date: "2023-04-26", impression: 150, addToCart: 15 },
    { date: "2023-04-27", impression: 80, addToCart: 10 },
    { date: "2023-05-26", impression: 70, addToCart: 15 },
    { date: "2023-05-29", impression: 13, addToCart: 15 },
    { date: "2023-05-30", impression: 99, addToCart: 15 },
  ],
  revenueChart: [{ date: "2023-05-25", revenue: 100 }],
};

import React, { useState, useEffect } from "react";
import { authAxios } from "../../config/axios";
import Table from "./table";
import Loader from "../Loader";
import { dashboardMock } from "../../assets/mocks/dashboard.mock";

function TopPlaylist(props) {
  const [data, setData] = useState(dashboardMock.topPlaylist);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let query = `date_start=${props.startDate}&date_end=${props.endDate}`;
        let response = await authAxios(
          "get",
          `/apisv1/authadmin/get-top-playlist?${query}`
        );
        if (response.data.data) setData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [props.startDate, props.endDate]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div
          className="transitionEffect "
          style={{
            background: "#fff",
            borderRadius: "12px",
            boxShadow: "0px 4px 30px 0px rgba(46, 45, 116, 0.05)",
            padding: "24px",
          }}
        >
          <div className="" style={{ borderRadius: "unset" }}>
            <div className="Polaris-IndexTable">
              <div className="Polaris-IndexTable__IndexTableWrapper">
                <div
                  className="Polaris-IndexTable__StickyTable"
                  role="presentation"
                >
                  <div>
                    <div></div>
                    <div>
                      <div className="Polaris-IndexTable__StickyTableHeader">
                        <div className="Polaris-IndexTable__StickyTableHeadings">
                          <div
                            className="Polaris-IndexTable__TableHeading Polaris-IndexTable__StickyTableHeading--second"
                            data-index-table-sticky-heading="true"
                            style={{ minWidth: "67px" }}
                          >
                            Order
                          </div>
                          <div
                            className="Polaris-IndexTable__TableHeading"
                            data-index-table-sticky-heading="true"
                            style={{ minWidth: "160px" }}
                          >
                            Date
                          </div>
                          <div
                            className="Polaris-IndexTable__TableHeading"
                            data-index-table-sticky-heading="true"
                            style={{ minWidth: "162px" }}
                          >
                            Customer
                          </div>
                          <div
                            className="Polaris-IndexTable__TableHeading"
                            data-index-table-sticky-heading="true"
                            style={{ minWidth: "98px" }}
                          >
                            Total
                          </div>
                          <div
                            className="Polaris-IndexTable__TableHeading"
                            data-index-table-sticky-heading="true"
                            style={{ minWidth: "146px" }}
                          >
                            Payment status
                          </div>
                          <div
                            className="Polaris-IndexTable__TableHeading"
                            data-index-table-sticky-heading="true"
                            style={{ minWidth: "145px" }}
                          >
                            Fulfillment status
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Table data={data}></Table>
              </div>
              <div></div>
            </div>
            {/* <div className="Polaris-IndexTable__ScrollBarContainer Polaris-IndexTable--scrollBarContainerHidden">
              <div
                className="Polaris-IndexTable__ScrollBar"
                style={{ "-pcIndexTableScrollBarContentWidth": "828px" }}
              >
                <div className="Polaris-IndexTable__ScrollBarContent"></div>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
}

export default TopPlaylist;

import React, { useState, useEffect } from "react";
import { authAxios } from "../config/axios";
import AddPageComp from "./AddPageComp";
import Loader from "./Loader";

function MainPagesComp({ page_type, playlist, showToast }) {
  const [pages, setPages] = useState([]);
  const [current, setCurrent] = useState([]);
  const [pagination, setpagination] = useState(null);
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(true);
  const [isNext, setIsNext] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  let count = 5;
  useEffect(() => {
    fetchPages();
  }, []);

  const fetchPages = async () => {
    try {
      if (page_type === "product") {
        if (isNext) {
          setLoader(isNext == true ? true : false);
          let response = await authAxios(
            "get",
            `/apisv1/authadmin/getshopifyproduct?count=${count}&search=${search}&cursor=${
              isNext == true ? null : isNext
            }`
          );
          if (response.status === 200) {
            let res_data = response.data.data;
            setIsNext(res_data.pagination.endCursor);
            if (res_data.pagination.hasNextPage == false) {
              setIsNext(false);
            }
            let final_data = res_data.products.map((item) => ({
              id: item.node.id.split("/")[4],
              title: item.node.title,
            }));
            setPages([...pages, ...final_data]);
            setCurrent([...current, final_data[0].id]);
            setLoader(false);
            if (res_data.pagination.hasNextPage === true) {
              setpagination(res_data.pagination);
              //  setLoaderButton({isShow:true,isLoading:false})
            } else {
              //  setLoaderButton({isShow:false,isLoading:false})
            }
          }
        }
      } else {
        setLoader(true);
        let response = await authAxios(
          "get",
          `/apisv1/authadmin/getpages?type=${page_type}`
        );
        if (response.status === 200) {
          setPages(response.data.data);
          setCurrent([...current, response.data.data[0].id]);
          setLoader(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSearch = (e) => {
    let search_str = e.target.value;
    setSearch(search_str);
    fetchPages();
  };

  const handleCollapse = (id) => {
    let current_open = current;
    if (current_open.includes(id)) {
      current_open.splice(
        current_open.findIndex((item) => item == id),
        1
      );
    } else {
      current_open.push(id);
    }
    setCurrent([...current_open]);
  };
  const handleLoadmore = () => {
    fetchPages();
  };

  return (
    <div role="tabpanel" className="tab-panel">
      {loader ? (
        <Loader />
      ) : pages.length ? (
        pages.map((item, index) => (
          <>
            <div
              key={item.id}
              className={`acrodian-box ${
                current.includes(item.id) ? "VD-active" : ""
              }`}
            >
              <div
                className="acrodian-header"
                onClick={() => handleCollapse(item.id)}
              >
                <div className="acrodian-title">{item.title}</div>
                <div>
                  <svg
                    className={`chevron ${
                      current.includes(item.id) ? "rotated" : ""
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M6 9L12 15L18 9"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              {current.includes(item.id) && (
                <AddPageComp
                  page_type={page_type}
                  id={item.id}
                  playlist={playlist}
                  showToast={showToast}
                />
              )}
            </div>
          </>
        ))
      ) : (
        "No Pages Found"
      )}
      {page_type == "product" && (
        <div style={{ textAlign: "center", marginTop: 10, marginBottom: 10 }}>
          <button
            className="Polaris-Button Polaris-Button--secondary"
            onClick={() => handleLoadmore()}
          >
            {loader === true ? (
              <></>
            ) : (
              // <span class="Polaris-Button__Spinner">
              //   <span class={"Polaris-Spinner Polaris-Spinner--sizeSmall"}>
              //     <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              //       <path d="M7.229 1.173a9.25 9.25 0 1011.655 11.412 1.25 1.25 0 10-2.4-.698 6.75 6.75 0 11-8.506-8.329 1.25 1.25 0 10-.75-2.385z"></path>
              //     </svg>
              //   </span>
              // </span>
              <span class="view-more">View More</span>
            )}
          </button>
        </div>
      )}
    </div>
  );
}

export default MainPagesComp;

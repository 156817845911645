import React, { useState } from "react";
import sample_video from "../../assets/img/vid.mp4";
import sample_image from "../../assets/img/img.png";
import InputCheckBox from "../../component/InputCheckBox";
import InputColorSelector from "../../component/InputColorSelector";
import InputRange from "../../component/InputRange";
import { authAxios } from "../../config/axios";

const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

function CardTab({ allSettings, setAllSettings, showToast }) {
  let { card_set } = allSettings;
  const [formData, setformData] = useState(card_set);
  const [fieldErrors, setFieldErrors] = useState({});
  const [isBtnDis, setIsBtnDis] = useState(true);

  const handleChange = (e, colour = null) => {
    setIsBtnDis(false);
    if (e.target.type === "checkbox") {
      setformData({ ...formData, [e.target.name]: e.target.checked });
    } else {
      setformData({ ...formData, [e.target.name]: e.target.value });
    }
    if (colour) {
      let validation_checked = hexColorRegex.test(e.target.value);
      if (e.target.value === "") {
        setFieldErrors({
          ...fieldErrors,
          [e.target.name]: "Please enter colour",
        });
      } else if (!validation_checked) {
        setFieldErrors({
          ...fieldErrors,
          [e.target.name]: "You have not entered proper hex value",
        });
      } else {
        setformData({ ...formData, [e.target.name]: e.target.value });
        if (fieldErrors[e.target.name]) {
          let f_fieldErrors = fieldErrors;
          delete f_fieldErrors[e.target.name];
          setFieldErrors(f_fieldErrors);
        }
      }
    }
  };

  const handleSave = async () => {
    showToast("Saved Successfully");
    try {
      if (!Object.values(fieldErrors).length) {
        if (JSON.stringify(formData) !== JSON.stringify(card_set)) {
          let res = await authAxios("post", "/apisv1/authadmin/customise", {
            card_set: formData,
          });
          if (res.status === 200) {
            setAllSettings({ ...allSettings, card_set: formData });
          }
        }
        setIsBtnDis(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <div className="Polaris-flex" style={{ gap: "24px" }}>
        <div
          className="left-card"
          style={{
            // borderRight: "var(--p-border-divider)",
            maxWidth: "316px",
            width: "100%",
          }}
        >
          <div
            className=""
            style={{
              borderTop: 0,
              padding: "var(--p-space-4)",
              borderRadius: "var(--p-border-radius-00)",
            }}
          >
            <h1 className="story-circle-header">Card Customise</h1>
          </div>
          {/* For Checkbox */}
          <div
            className="Polaris-LegacyCard__Section"
            style={{
              borderTop: 0,
              padding: "0 var(--p-space-4) var(--p-space-4)",
              borderBottom: "0.0625rem solid #eaeaea",
            }}
          >
            <div className="checkbox-input">
               <InputCheckBox
                label="Center Align Cards"
                key={"Center Align Cards"}
                events={{
                  onChange: (e) => handleChange(e),
                  checked: formData.center_cards,
                  name: "center_cards",
                }}
              />
              <InputCheckBox
                label="Product image"
                key={"Product image"}
                events={{
                  onChange: (e) => handleChange(e),
                  checked: formData.card_prod_img,
                  name: "card_prod_img",
                }}
              />
              <InputCheckBox
                label="Product Title"
                key={"Product Title"}
                events={{
                  onChange: (e) => handleChange(e),
                  checked: formData.card_prod_title,
                  name: "card_prod_title",
                }}
              />

              <InputCheckBox
                label="Review (Shopify review)"
                key={"Review (Shopify review)"}
                events={{
                  onChange: (e) => handleChange(e),
                  checked: formData.card_review,
                  name: "card_review",
                }}
              />
            </div>
          </div>
          {/* Product Text color And Size */}
          <div
            className=""
            style={{
              padding: "var(--p-space-4)",
              borderBottom: "0.0625rem solid #eaeaea",
            }}
          >
            <h1 className="Polaris-Text--root Polaris-Text--headingMd Polaris-Text--bold">
              Product Title
            </h1>
            <div>
              <p className="border-header" style={{ marginTop: "12px" }}>
                Text Color
              </p>
              <InputColorSelector
                label=""
                events={{
                  onChange: (e) => handleChange(e, true),
                  value: formData.card_product_text_clr,
                  name: "card_product_text_clr",
                }}
                error={fieldErrors["card_product_text_clr"]}
              />
            </div>
            <div>
              <p className="border-header" style={{ marginTop: "12px" }}>
                Font Size
              </p>
              <InputRange
                label=""
                events={{
                  onChange: (e) => handleChange(e),
                  value: formData.card_product_font_size,
                  name: "card_product_font_size",
                  min: 8,
                  max: 26,
                }}
              />
            </div>
          </div>
          {/* End Product Text color And Size */}
          {/* Discount Bages */}
          <div
            className="Polaris-LegacyCard__Section"
            style={{
              padding: "var(--p-space-4)",
              // borderBottom: "0.0625rem solid #eaeaea",
            }}
          >
            <h1 className="Polaris-Text--root Polaris-Text--headingMd Polaris-Text--bold">
              Discount Bages
            </h1>

            <InputCheckBox
              label="Discount Bages"
              key={"Discount Bages"}
              events={{
                onChange: (e) => handleChange(e),
                checked: formData.card_discount_badge,
                name: "card_discount_badge",
              }}
            />
            <div>
              <p className="border-header">Text Color</p>
              <InputColorSelector
                key="card_badge_text_clr"
                label=""
                events={{
                  onChange: (e) => handleChange(e, true),
                  value: formData.card_badge_text_clr,
                  name: "card_badge_text_clr",
                }}
                error={fieldErrors["card_badge_text_clr"]}
              />
            </div>
            <div>
              <p className="border-header" style={{ marginTop: "12px" }}>
                Background Color
              </p>
              <InputColorSelector
                key="card_badge_bg_clr"
                label=""
                events={{
                  onChange: (e) => handleChange(e, true),
                  value: formData.card_badge_bg_clr,
                  name: "card_badge_bg_clr",
                }}
                error={fieldErrors["card_badge_bg_clr"]}
              />
            </div>
          </div>
          {/* End Discount Bages */}
          {/* Price */}
          <div
            className="Polaris-LegacyCard__Section"
            style={{
              padding: "var(--p-space-4)",
              // borderBottom: "0.5px solid #858d9d",
            }}
          >
            <h1 className="Polaris-Text--root Polaris-Text--headingMd Polaris-Text--bold">
              Price
            </h1>
            <div>
              <p className="border-header">Base Price</p>
              <InputColorSelector
                key="card_base_price_clr"
                label=""
                events={{
                  onChange: (e) => handleChange(e, true),
                  value: formData.card_base_price_clr,
                  name: "card_base_price_clr",
                }}
                error={fieldErrors["card_base_price_clr"]}
              />
            </div>
            <div>
              <p className="border-header" style={{ marginTop: "12px" }}>
                Compare Price
              </p>
              <InputColorSelector
                key="card_compare_price_clr"
                label=""
                events={{
                  onChange: (e) => handleChange(e, true),
                  value: formData.card_compare_price_clr,
                  name: "card_compare_price_clr",
                }}
                error={fieldErrors["card_compare_price_clr"]}
              />
            </div>
          </div>
          {/* End Price */}
          {/* Save Button */}
          <div className="Polaris-PageActions " style={{ padding: "0" }}>
            <div className="Polaris-LegacyStack Polaris-LegacyStack--spacingTight Polaris-LegacyStack--distributionTrailing">
              <div className="Polaris-LegacyStack__Item">
                <button
                  className="Polaris-Button Polaris-Button--primary"
                  type="button"
                  // disabled={isBtnDis}
                  onClick={() => handleSave()}
                >
                  <span className="Polaris-Button__Content">
                    <span className="Polaris-Button__Text">Save Changes</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
          {/* End Save Button */}
        </div>
        {/* Right Side Video Section */}
        <div
          className="right-card"
          style={{
            width: "100%",
            position: "sticky",
            height: "fit-content",
            top: "60px",
          }}
        >
          <div className="right-card-title">Card View</div>
          <div
            className="Polaris-LegacyCard__Section"
            style={{ height: "100%" }}
          >
            <div className="Polaris-video-main">
              <div className="Polaris-video-inner">
                <video
                  style={{
                    objectFit: "cover",
                    pointerEvents: "none",
                    width: "100%",
                  }}
                  controls
                  loop
                  muted
                  autoPlay
                >
                  <source src={sample_video} type="video/mp4" />
                </video>
                <div className="Video-cart-content">
                  {formData.card_prod_img && (
                    <div className="Product_thamnail">
                      <span
                        className="Polaris-Thumbnail Polaris-Thumbnail--sizeMedium"
                        style={{ width: "50px" }}
                      >
                        <img alt="Black choker necklace" src={sample_image} />
                      </span>
                    </div>
                  )}
                  {formData.card_prod_title && (
                    <h6
                      className="Polaris-Text--center Polaris-Text--headingSm"
                      style={{
                        fontSize: `${formData.card_product_font_size}px`,
                        padding: "5px 5px",
                        borderBottom: "var(--p-border-divider)",
                        color: formData.card_product_text_clr,
                      }}
                    >
                      Wave Style Call
                    </h6>
                  )}
                  {/* review  */}
                  {formData.card_review && (
                    <div
                      className="polaris-review"
                      style={{ paddingTop: "var(--p-space-2)" }}
                    >
                      <svg
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.2 18a.8.8 0 01-.792-.914l.743-5.203-2.917-2.917a.8.8 0 01.434-1.355l4.398-.733 2.218-4.435a.8.8 0 011.435.008l2.123 4.361 4.498.801a.8.8 0 01.425 1.353l-2.917 2.917.744 5.203a.8.8 0 01-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 015.2 18z"
                          fill="#ffcc00"
                        />
                      </svg>
                      <svg
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.2 18a.8.8 0 01-.792-.914l.743-5.203-2.917-2.917a.8.8 0 01.434-1.355l4.398-.733 2.218-4.435a.8.8 0 011.435.008l2.123 4.361 4.498.801a.8.8 0 01.425 1.353l-2.917 2.917.744 5.203a.8.8 0 01-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 015.2 18z"
                          fill="#ffcc00"
                        />
                      </svg>
                      <svg
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.2 18a.8.8 0 01-.792-.914l.743-5.203-2.917-2.917a.8.8 0 01.434-1.355l4.398-.733 2.218-4.435a.8.8 0 011.435.008l2.123 4.361 4.498.801a.8.8 0 01.425 1.353l-2.917 2.917.744 5.203a.8.8 0 01-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 015.2 18z"
                          fill="#ffcc00"
                        />
                      </svg>
                      <svg
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.2 18a.8.8 0 01-.792-.914l.743-5.203-2.917-2.917a.8.8 0 01.434-1.355l4.398-.733 2.218-4.435a.8.8 0 011.435.008l2.123 4.361 4.498.801a.8.8 0 01.425 1.353l-2.917 2.917.744 5.203a.8.8 0 01-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 015.2 18z"
                          fill="#ffcc00"
                        />
                      </svg>
                      <svg
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.2 18a.8.8 0 01-.792-.914l.743-5.203-2.917-2.917a.8.8 0 01.434-1.355l4.398-.733 2.218-4.435a.8.8 0 011.435.008l2.123 4.361 4.498.801a.8.8 0 01.425 1.353l-2.917 2.917.744 5.203a.8.8 0 01-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 015.2 18z"
                          fill="#ffcc00"
                        />
                      </svg>
                      <p className="Polaris-Text--root Polaris-Text--bodySm">
                        &nbsp; 5 reviews
                      </p>
                    </div>
                  )}
                  {/* Discount Price */}
                  <div className="polaris-Price-main">
                    {formData.card_discount_badge && (
                      <div className="polaris-discount">
                        <p
                          style={{
                            color: formData.card_badge_text_clr,
                            backgroundColor: formData.card_badge_bg_clr,
                          }}
                        >
                          77% off
                        </p>
                      </div>
                    )}
                    <div className="polaris-Price">
                      <p
                        style={{ color: formData.card_base_price_clr }}
                        className="Polaris-Text--semibold"
                      >
                        ₹1,799
                      </p>
                    </div>
                    <div className="polaris-CPrice">
                      <del
                        style={{ color: formData.card_compare_price_clr }}
                        className="Polaris-Text--subdued"
                      >
                        ₹7990
                      </del>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Right Side Video Section */}
      </div>
    </div>
  );
}

export default CardTab;

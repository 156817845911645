import React, { useState, useEffect, useRef } from "react";
import "react-range-slider-input/dist/style.css";
import "react-image-crop/dist/ReactCrop.css";

import { authAxios } from "../config/axios";
import { useAuth } from "../AuthComp/Auth";
import toast, { Toaster } from "react-hot-toast";
import { WarningIcon } from "../assets/icons";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";

import { uploadData } from "@aws-amplify/storage";

function ImageCroper({
  videoInfo,
  tab,
  isEdit,
  previewImag,
  setPreviewImag,
  setTabIndex,
  handleClose,
}) {
  let auth = useAuth();
  const assetsUrl = `${process.env.REACT_APP_ASSETS_BASE_URL}/${auth.shopname}`;
  let isStory;
  let prev_img;
  let aspect_rt;
  let video_link =
    videoInfo.status == 0
      ? `http://localhost/vidzy/videos/${videoInfo.origin_video_file}`
      : videoInfo.origin_video;
  if (tab == 2) {
    aspect_rt = 9 / 16;
    isStory = false;
  } else {
    aspect_rt = 1;
    isStory = true;
  }

  const [file, setFile] = useState(null);
  const [uploadLoader, setUploadLoader] = useState(false);

  const [showUpload, setShowUpload] = useState(false);

  const [aspect, setAspect] = useState(aspect_rt);

  const blobUrlRef = useRef(null);

  const handleFileUpload = async (file) => {
    if (!file) {
      throw new Error("Failed to get the cropped image file");
    }
    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current);
    }
    blobUrlRef.current = URL.createObjectURL(file);

    const { type } = file;
    let ext;
    if (type === "image/jpeg") {
      ext = "jpeg";
    } else if (type === "image/png") {
      ext = "png";
    } else {
      ext = "jpg";
    }
    try {
      setUploadLoader(true);
      const currentDate = Math.floor(Date.now() / 1000);
      const res = await uploadData({
        path: `${auth.shopname}/assets/${currentDate}.${ext}`,
        data: file,
        options: {
          contentType: file.type,
        },
      }).result;
      // const bucketParams = {
      //   Bucket: S3_BUCKET,
      //   Key: `${auth.shopname}/assets/${currentDate}.${ext}`,
      //   Body: file,
      // };
      // const res = await s3Client.send(new PutObjectCommand(bucketParams));

      let data = await authAxios(
        "post",
        `/apisv1/authadmin/coverupload?video=${videoInfo.video_id}&isstory=${isStory}&asset=assets/${currentDate}&ext=${ext}`
      );
      console.log("data123", data);
      setUploadLoader(false);
      setShowUpload(false);
      setPreviewImag(URL.createObjectURL(file));
    } catch (error) {
      setUploadLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (!previewImag) {
      setShowUpload(true);
    }
  }, [previewImag]);

  const handleSave = async () => {
    if (!previewImag) {
      toast("Please upload image !", {
        duration: 2000,
        position: "top-center",
        icon: <WarningIcon />,
      });
      return;
    }
    if (previewImag) {
      setTabIndex(tab + 1);
    }
  };

  return (
    <div className="">
      <Toaster />
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div>
            {isEdit && (
              <button
                className={`${showUpload ? "modal-text-btn-disable" : "modal-text-btn"}`}
                onClick={() => {
                  setShowUpload(true);
                }}
                style={{ fontSize: "16px" }}
              >
                Replace
              </button>
            )}
          </div>
        </div>
        {showUpload && (
          <div className="left">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
              }}
            >
              {file && (
                <>
                  <img
                    width="100%"
                    height="350px"
                    style={{ objectFit: "contain" }}
                    src={file}
                    alt="captured frame"
                  />
                </>
              )}
            </div>
            {!file && (
              <div className="Croper-bottom" style={{ width: "100%" }}>
                <div className="croper-upload" style={{ width: "100%" }}>
                  <div
                    className="Polaris-Labelled--hidden"
                    style={{ width: "100%" }}
                  >
                    <ImgCrop
                      rotationSlider
                      aspect={aspect}
                      cropShape={tab === 2 ? "rect" : "round"}
                    >
                      <Upload
                        beforeUpload={(file) => {
                          console.log("clickedddddd");
                          handleFileUpload(file);
                          return false;
                        }}
                        showUploadList={false}
                      >
                        <div className="upload-container">
                          <p className="cancel-btn">Add image</p>
                        </div>
                      </Upload>
                    </ImgCrop>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {!showUpload && (
          <div className="right">
            <div
              className="VD-Cover-Video"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginBottom: "2px",
              }}
            >
              {previewImag !== null ? (
                <img
                  src={
                    previewImag.includes("blob") ||
                    previewImag.includes("shopify")
                      ? previewImag
                      : `${assetsUrl}/${previewImag}`
                  }
                  alt="captured frame"
                  width="100%"
                  height="320px"
                  style={{ objectFit: "contain", marginBottom: "10px" }}
                />
              ) : (
                <div className="noimagefound">No Image found</div>
              )}
            </div>
          </div>
        )}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "12px",
          paddingTop: "12px",
          borderTop: " 1px solid #EAEAEA",
        }}
      >
        <button
          className="cancel-btn"
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </button>
        <button className="success-btn" onClick={handleSave}>
          Save & Next
        </button>
      </div>
    </div>
  );
}

export default ImageCroper;

import React, { useState, useEffect } from "react";
import { authAxios } from "../../config/axios";
import Loader from "../Loader";
import {
  dashboardMock,
  dashboardChartMock,
} from "../../assets/mocks/dashboard.mock";
import ChartComponent from "./commonChart";
import ImpressionChart from "./impressionChart";
import RevenueChart from "./revenueChart";
import impressions from "../../assets/img/impressions.svg";
import addToCart from "../../assets/img/addToCart.svg";

function Analytics(props) {
  const [data, setData] = useState(dashboardMock.analytics);
  const [loading, setLoading] = useState(true);
  console.log("data", data);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let query = `date_start=${props.startDate}&date_end=${props.endDate}`;
        let response = await authAxios(
          "get",
          `/apisv1/authadmin/impressions-analytics?${query}`
        );
        if (response.data.data) {
          setData(response.data.data);
        }
        setLoading(false);
      } catch (error) {
        console.log("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [props.startDate, props.endDate]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="Polaris-Page--divider transitionEffect">
          <div className="Polaris-Grid">
            <div className="Polaris-Grid-Cell Polaris-Grid-Cell--cell_6ColumnXs Polaris-Grid-Cell--cell_3ColumnSm Polaris-Grid-Cell--cell_3ColumnMd Polaris-Grid-Cell--cell_6ColumnLg Polaris-Grid-Cell--cell_6ColumnXl">
              <div
                className="Polaris-LegacyCard"
                style={{ position: "relative" }}
              >
                <div className="Polaris-LegacyCard__Section">
                  <div className="VD-imprestion">
                    <div className="VD-logic">
                      <div className="VD-name-logic">
                        {" "}
                        <img src={impressions} alt="impressions" />
                        <div className="VD-flex">
                          <span>Impressions</span>
                          <div className="VD-logic-number">
                            <div className="VD-top-number">
                              {data.impression}
                            </div>
                            {data.impressionConversionRate > 99 ? (
                              <div
                                className="VD-top-number-arrow"
                                style={{ color: "#0A850A" }}
                              >
                                {data.impressionConversionRate.toFixed(2)}%
                                <span>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M4.27322 10.6667H11.7265C11.8584 10.6661 11.9871 10.6265 12.0964 10.5528C12.2058 10.4791 12.2908 10.3746 12.3407 10.2526C12.3907 10.1306 12.4033 9.99648 12.3771 9.86727C12.3508 9.73806 12.2868 9.61954 12.1932 9.52669L8.47322 5.8067C8.41124 5.74421 8.33751 5.69461 8.25627 5.66077C8.17503 5.62692 8.08789 5.6095 7.99988 5.6095C7.91187 5.6095 7.82474 5.62692 7.7435 5.66077C7.66226 5.69461 7.58852 5.74421 7.52655 5.8067L3.80655 9.52669C3.71294 9.61954 3.64895 9.73806 3.6227 9.86727C3.59644 9.99648 3.60909 10.1306 3.65904 10.2526C3.70899 10.3746 3.794 10.4791 3.90332 10.5528C4.01264 10.6265 4.14137 10.6661 4.27322 10.6667Z"
                                      fill="#1A9882"
                                    />
                                  </svg>
                                </span>
                              </div>
                            ) : (
                              <div
                                className="VD-top-number-arrow"
                                style={{ color: "#FF3F3F" }}
                              >
                                <span>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g id="fi-sr-caret-down">
                                      <path
                                        id="Vector"
                                        d="M4.27602 6H11.724C11.8559 6.00003 11.9847 6.03914 12.0943 6.1124C12.2039 6.18565 12.2894 6.28976 12.3398 6.41156C12.3903 6.53336 12.4035 6.66738 12.3777 6.79669C12.352 6.92599 12.2886 7.04476 12.1954 7.138L8.47135 10.862C8.34634 10.987 8.1768 11.0572 8.00002 11.0572C7.82325 11.0572 7.65371 10.987 7.52869 10.862L3.80469 7.138C3.71148 7.04476 3.64801 6.92599 3.6223 6.79669C3.59659 6.66738 3.60979 6.53336 3.66024 6.41156C3.71068 6.28976 3.79611 6.18565 3.90572 6.1124C4.01532 6.03914 4.14419 6.00003 4.27602 6Z"
                                        fill="#EB3D4D"
                                      />
                                    </g>
                                  </svg>
                                </span>
                                {data.impressionConversionRate.toFixed(2)}%
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="VD-logic">
                      <div className="VD-name-logic">
                        <img src={addToCart} alt="impressions" />
                        <div className="VD-flex">
                          <span>Add To Cart</span>
                          <div className="VD-logic-number">
                            <div className="VD-top-number">{data.click}</div>
                            {data.clickConversionRate > 100 ? (
                              <div
                                className="VD-top-number-arrow"
                                style={{ color: "#0A850A" }}
                              >
                                {data.clickConversionRate}%
                                <span>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M4.27322 10.6667H11.7265C11.8584 10.6661 11.9871 10.6265 12.0964 10.5528C12.2058 10.4791 12.2908 10.3746 12.3407 10.2526C12.3907 10.1306 12.4033 9.99648 12.3771 9.86727C12.3508 9.73806 12.2868 9.61954 12.1932 9.52669L8.47322 5.8067C8.41124 5.74421 8.33751 5.69461 8.25627 5.66077C8.17503 5.62692 8.08789 5.6095 7.99988 5.6095C7.91187 5.6095 7.82474 5.62692 7.7435 5.66077C7.66226 5.69461 7.58852 5.74421 7.52655 5.8067L3.80655 9.52669C3.71294 9.61954 3.64895 9.73806 3.6227 9.86727C3.59644 9.99648 3.60909 10.1306 3.65904 10.2526C3.70899 10.3746 3.794 10.4791 3.90332 10.5528C4.01264 10.6265 4.14137 10.6661 4.27322 10.6667Z"
                                      fill="#1A9882"
                                    />
                                  </svg>
                                </span>
                              </div>
                            ) : (
                              <div
                                className="VD-top-number-arrow"
                                style={{ color: "#FF3F3F" }}
                              >
                                {data.clickConversionRate}%
                                <span>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g id="fi-sr-caret-down">
                                      <path
                                        id="Vector"
                                        d="M4.27602 6H11.724C11.8559 6.00003 11.9847 6.03914 12.0943 6.1124C12.2039 6.18565 12.2894 6.28976 12.3398 6.41156C12.3903 6.53336 12.4035 6.66738 12.3777 6.79669C12.352 6.92599 12.2886 7.04476 12.1954 7.138L8.47135 10.862C8.34634 10.987 8.1768 11.0572 8.00002 11.0572C7.82325 11.0572 7.65371 10.987 7.52869 10.862L3.80469 7.138C3.71148 7.04476 3.64801 6.92599 3.6223 6.79669C3.59659 6.66738 3.60979 6.53336 3.66024 6.41156C3.71068 6.28976 3.79611 6.18565 3.90572 6.1124C4.01532 6.03914 4.14419 6.00003 4.27602 6Z"
                                        fill="#EB3D4D"
                                      />
                                    </g>
                                  </svg>
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Chart this place */}
                <ImpressionChart data={data.impressionChart} />
                {data.impression === 0 && (
                  <div className="no-data">
                    <span className="border-header">No Data Available</span>
                  </div>
                )}
              </div>
            </div>
            <div className="Polaris-Grid-Cell Polaris-Grid-Cell--cell_6ColumnXs Polaris-Grid-Cell--cell_3ColumnSm Polaris-Grid-Cell--cell_3ColumnMd Polaris-Grid-Cell--cell_6ColumnLg Polaris-Grid-Cell--cell_6ColumnXl">
              <div
                className="Polaris-LegacyCard"
                style={{ position: "relative" }}
              >
                <div className="Polaris-LegacyCard__Section">
                  <div className="VD-imprestion">
                    <div className="VD-logic" style={{}}>
                      <div className="VD-name-logic">
                        <span>
                          <svg
                            width="36"
                            height="36"
                            viewBox="0 0 36 36"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="36"
                              height="36"
                              rx="8"
                              fill="#F4ECFB"
                            />
                            <g clipPath="url(#clip0_14_10540)">
                              <path
                                d="M18 10.6667V25.3334"
                                stroke="#883DCF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M21.3333 13.3333H16.3333C15.7145 13.3333 15.121 13.5791 14.6834 14.0167C14.2458 14.4543 14 15.0478 14 15.6666C14 16.2855 14.2458 16.879 14.6834 17.3166C15.121 17.7541 15.7145 18 16.3333 18H19.6667C20.2855 18 20.879 18.2458 21.3166 18.6834C21.7542 19.121 22 19.7145 22 20.3333C22 20.9522 21.7542 21.5456 21.3166 21.9832C20.879 22.4208 20.2855 22.6666 19.6667 22.6666H14"
                                stroke="#883DCF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_14_10540">
                                <rect
                                  width="16"
                                  height="16"
                                  fill="white"
                                  transform="translate(10 10)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <div className="VD-flex">
                          <span>Revenue</span>
                          <div className="VD-logic-number">
                            <div className="VD-top-number">${data.revenue}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Chart this place */}
                <RevenueChart data={data.revenueChart} />
                {data.revenue === 0 && (
                  <div className="no-data">
                    <span className="border-header">No Data Available</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Analytics;

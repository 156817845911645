import React, { useState } from "react";
import sample_video from "../../assets/img/vid.mp4";
import sample_image from "../../assets/img/img.png";
import sample_inner_video from "../../assets/img/inner video.mp4";
import InputCheckBox from "../../component/InputCheckBox";
import InputColorSelector from "../../component/InputColorSelector";
import InputRange from "../../component/InputRange";
import InputText from "../../component/InputText";
import { authAxios } from "../../config/axios";

const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
function PopupMobile({
  setIsMobileView,
  allSettings,
  setAllSettings,
  showToast,
}) {
  let { popup_mobile } = allSettings;
  const [isPopupBottom, setIsPopupBottom] = useState(true);
  const [isBtnDis, setIsBtnDis] = useState(true);
  const [formData, setformData] = useState(popup_mobile);
  const [fieldErrors, setFieldErrors] = useState({});
  const handleChange = (e, colour = null) => {
    setIsBtnDis(false);
    if (e.target.type === "checkbox") {
      setformData({ ...formData, [e.target.name]: e.target.checked });
    } else {
      setformData({ ...formData, [e.target.name]: e.target.value });
    }
    if (colour) {
      let validation_checked = hexColorRegex.test(e.target.value);
      if (e.target.value === "") {
        setFieldErrors({
          ...fieldErrors,
          [e.target.name]: "Please enter colour",
        });
      } else if (!validation_checked) {
        setFieldErrors({
          ...fieldErrors,
          [e.target.name]: "You have not entered proper hex value",
        });
      } else {
        setformData({ ...formData, [e.target.name]: e.target.value });
        if (fieldErrors[e.target.name]) {
          let f_fieldErrors = fieldErrors;
          delete f_fieldErrors[e.target.name];
          setFieldErrors(f_fieldErrors);
        }
      }
    }
  };
  const handleSave = async () => {
    showToast("Saved Successfully");
    try {
      if (!Object.values(fieldErrors).length) {
        if (JSON.stringify(formData) !== JSON.stringify(popup_mobile)) {
          let res = await authAxios("post", "/apisv1/authadmin/customise", {
            popup_mobile: formData,
          });
          if (res.status === 200) {
            setAllSettings({ ...allSettings, popup_mobile: formData });
          }
        }
        setIsBtnDis(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleText = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.value === "") {
      setFieldErrors({
        ...fieldErrors,
        [e.target.name]: "Please enter text",
      });
    } else {
      setformData({ ...formData, [e.target.name]: e.target.value });
      if (fieldErrors[e.target.name]) {
        let f_fieldErrors = fieldErrors;
        delete f_fieldErrors[e.target.name];
        setFieldErrors(f_fieldErrors);
      }
    }
  };
  return (
    <div className="Polaris-flex" style={{ gap: "24px" }}>
      <div
        className="left-card"
        style={{
          // borderRight: "var(--p-border-divider)",
          maxWidth: "330px",
          width: "100%",
        }}
      >
        <div
          className=""
          style={{
            borderTop: 0,
            padding: "var(--p-space-4)",
            borderRadius: "var(--p-border-radius-00)",
          }}
        >
          <h1 className="story-circle-header">Mobile Popup Customize</h1>
        </div>
        <div
          className="Polaris-LegacyCard__Section"
          style={{ borderTop: 0, padding: "var(--p-space-4)" }}
        >
          <div className="tab-list" data-buttongroup-segmented="true">
            <button
              onClick={() => setIsPopupBottom(true)}
              className="tab-list-button"
              type="button"
              aria-selected={`${isPopupBottom ? true : false}`}
            >
              Popup Bottom
            </button>

            <button
              onClick={() => setIsPopupBottom(false)}
              className="tab-list-button "
              type="button"
              aria-selected={`${!isPopupBottom ? true : false}`}
            >
              Popup Detail
            </button>
          </div>
        </div>
        {isPopupBottom ? (
          <>
            {/* For Checkbox */}
            <div
              className="Polaris-LegacyCard__Section"
              style={{
                borderTop: 0,
                padding: "0 var(--p-space-4) var(--p-space-4)",
              }}
            >
              <InputCheckBox
                label="Review (Shopify review)"
                key={"pm_botm_review"}
                events={{
                  onChange: (e) => handleChange(e),
                  checked: formData.pm_botm_review,
                  name: "pm_botm_review",
                }}
              />
            </div>
            {/* Card bg */}
            <div
              className="Polaris-LegacyCard__Section"
              style={{ padding: "var(--p-space-4)" }}
            >
              <h1 className="Polaris-Text--root Polaris-Text--headingMd Polaris-Text--bold">
                Card
              </h1>
              <div>
                <p className="border-header">Box Background Color</p>
                <InputColorSelector
                  label=""
                  events={{
                    onChange: (e) => handleChange(e, true),
                    value: formData.pm_botm_box_bg_clr,
                    name: "pm_botm_box_bg_clr",
                  }}
                  error={fieldErrors["pm_botm_box_bg_clr"]}
                />
              </div>
            </div>
            {/* Product Text color And Size */}
            <div
              className="Polaris-LegacyCard__Section"
              style={{ padding: "var(--p-space-4)" }}
            >
              <h1 className="Polaris-Text--root Polaris-Text--headingMd Polaris-Text--bold">
                Product
              </h1>
              <InputCheckBox
                label="Product image"
                key={"pm_botm_prod_img"}
                events={{
                  onChange: (e) => handleChange(e),
                  checked: formData.pm_botm_prod_img,
                  name: "pm_botm_prod_img",
                }}
              />
              <div>
                <p className="border-header">Title Color</p>
                <InputColorSelector
                  label=""
                  events={{
                    onChange: (e) => handleChange(e, true),
                    value: formData.pm_botm_title_clr,
                    name: "pm_botm_title_clr",
                  }}
                  error={fieldErrors["pm_botm_title_clr"]}
                />
              </div>
              <div>
                <p className="border-header" style={{ marginTop: "12px" }}>
                  Font Size
                </p>
                <InputRange
                  label=""
                  key="pm_botm_font_size"
                  events={{
                    onChange: (e) => handleChange(e),
                    value: formData.pm_botm_font_size,
                    name: "pm_botm_font_size",
                    min: 8,
                    max: 26,
                  }}
                />
              </div>
            </div>
            {/* End Product Text color And Size */}
            {/* Discount Bages */}
            <div
              className="Polaris-LegacyCard__Section"
              style={{ padding: "var(--p-space-4)" }}
            >
              <h1 className="Polaris-Text--root Polaris-Text--headingMd Polaris-Text--bold">
                Discount Badges
              </h1>
              <div>
                <p className="border-header">Text Color</p>
                <InputColorSelector
                  label=""
                  events={{
                    onChange: (e) => handleChange(e, true),
                    value: formData.pm_botm_badge_text_clr,
                    name: "pm_botm_badge_text_clr",
                  }}
                  error={fieldErrors["pm_botm_badge_text_clr"]}
                />
              </div>
              <div>
                <p className="border-header" style={{ marginTop: "12px" }}>
                  Background Color
                </p>
                <InputColorSelector
                  label=""
                  events={{
                    onChange: (e) => handleChange(e, true),
                    value: formData.pm_botm_badge_bg_clr,
                    name: "pm_botm_badge_bg_clr",
                  }}
                  error={fieldErrors["pm_botm_badge_bg_clr"]}
                />
              </div>
            </div>
            {/* End Discount Bages */}
            {/* Price */}
            <div
              className="Polaris-LegacyCard__Section"
              style={{ padding: "var(--p-space-4)" }}
            >
              <h1 className="Polaris-Text--root Polaris-Text--headingMd Polaris-Text--bold">
                Price
              </h1>
              <div>
                <p className="border-header">Base Price</p>
                <InputColorSelector
                  label=""
                  events={{
                    onChange: (e) => handleChange(e, true),
                    value: formData.pm_botm_base_price_clr,
                    name: "pm_botm_base_price_clr",
                  }}
                  error={fieldErrors["pm_botm_base_price_clr"]}
                />
              </div>
              <div>
                <p className="border-header" style={{ marginTop: "12px" }}>
                  Compare Price
                </p>

                <InputColorSelector
                  label=""
                  events={{
                    onChange: (e) => handleChange(e, true),
                    value: formData.pm_botm_compare_price_clr,
                    name: "pm_botm_compare_price_clr",
                  }}
                  error={fieldErrors["pm_botm_compare_price_clr"]}
                />
              </div>
            </div>
            {/* End Price */}
            {/* CTA Button  */}
            <div
              className="Polaris-LegacyCard__Section"
              style={{ padding: "var(--p-space-4)" }}
            >
              <h1 className="Polaris-Text--root Polaris-Text--headingMd Polaris-Text--bold">
                CTA Button
              </h1>
              <div>
                <p className="border-header">Button Text Color</p>
                <InputColorSelector
                  label=""
                  events={{
                    onChange: (e) => handleChange(e, true),
                    value: formData.pm_botm_cta_btn_text_clr,
                    name: "pm_botm_cta_btn_text_clr",
                  }}
                  error={fieldErrors["pm_botm_cta_btn_text_clr"]}
                />
              </div>
              <div>
                <p className="border-header" style={{ marginTop: "12px" }}>
                  Button Background Color
                </p>
                <InputColorSelector
                  label=""
                  events={{
                    onChange: (e) => handleChange(e, true),
                    value: formData.pm_botm_cta_btn_bg_clr,
                    name: "pm_botm_cta_btn_bg_clr",
                  }}
                  error={fieldErrors["pm_botm_cta_btn_bg_clr"]}
                />
              </div>
              <div>
                <p className="border-header" style={{ marginTop: "12px" }}>
                  Text
                </p>
                <InputText
                  key="pm_botm_cta_btn_text"
                  label=""
                  events={{
                    onChange: (e) => handleText(e, true),
                    value: formData.pm_botm_cta_btn_text,
                    name: "pm_botm_cta_btn_text",
                  }}
                  error={fieldErrors["pm_botm_cta_btn_text"]}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              {/* For Checkbox */}
              <div
                className="Polaris-LegacyCard__Section"
                style={{
                  borderTop: 0,
                  padding: "0 var(--p-space-4) var(--p-space-4)",
                }}
              >
                <InputCheckBox
                  label="Review (Shopify review)"
                  key={"pm_det_review"}
                  events={{
                    onChange: (e) => handleChange(e),
                    checked: formData.pm_det_review,
                    name: "pm_det_review",
                  }}
                />
              </div>
              {/* Product Text color And Size */}
              <div
                className="Polaris-LegacyCard__Section"
                style={{ padding: "var(--p-space-4)" }}
              >
                <InputCheckBox
                  label="Product Description"
                  key={"pm_det_prod_desc"}
                  events={{
                    onChange: (e) => handleChange(e),
                    checked: formData.pm_det_prod_desc,
                    name: "pm_det_prod_desc",
                  }}
                />
                <p className="border-header">Text</p>
                <div
                  className="Polaris-Connected"
                  style={{ alignItems: "center", marginTop: "10px" }}
                >
                  <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                    <div className="Polaris-TextField Polaris-TextField--hasValue">
                      <input
                        autoComplete="off"
                        className="Polaris-TextField__Input"
                        type="text"
                        name="pm_det_desc_text"
                        placeholder="Description"
                        onChange={(e) => handleChange(e)}
                        value={formData.pm_det_desc_text}
                      />
                      <div className="Polaris-TextField__Backdrop" />
                    </div>
                  </div>
                </div>
              </div>
              {/* End Product Text color And Size */}
              {/* Card bg */}
              {/* <div
                className="Polaris-LegacyCard__Section"
                style={{ padding: "var(--p-space-4)" }}
              >
                <h1 className="Polaris-Text--root Polaris-Text--headingMd Polaris-Text--bold">
                  Card
                </h1>
                <InputColorSelector
                  label="Box background color"
                  events={{
                    onChange: (e) => handleChange(e, true),
                    value: formData.pm_det_box_bg_clr,
                    name: "pm_det_box_bg_clr"
                  }}
                  error={fieldErrors["pm_det_box_bg_clr"]}
                />
              </div> */}
              {/* Product Text color And Size */}
              <div
                className="Polaris-LegacyCard__Section"
                style={{ padding: "var(--p-space-4)" }}
              >
                <h1 className="Polaris-Text--root Polaris-Text--headingMd Polaris-Text--bold">
                  Product
                </h1>
                <InputCheckBox
                  label="Product image"
                  key={"pm_det_prod_img"}
                  events={{
                    onChange: (e) => handleChange(e),
                    checked: formData.pm_det_prod_img,
                    name: "pm_det_prod_img",
                  }}
                />
                <div>
                  <p className="border-header">Title Color</p>
                  <InputColorSelector
                    label=""
                    events={{
                      onChange: (e) => handleChange(e, true),
                      value: formData.pm_det_title_clr,
                      name: "pm_det_title_clr",
                    }}
                    error={fieldErrors["pm_det_title_clr"]}
                  />
                </div>
                <div>
                  <p className="border-header" style={{ marginTop: "12px" }}>
                    Font Size
                  </p>
                  <InputRange
                    label=""
                    key="pm_det_font_size"
                    events={{
                      onChange: (e) => handleChange(e),
                      value: formData.pm_det_font_size,
                      name: "pm_det_font_size",
                      min: 8,
                      max: 26,
                    }}
                  />
                </div>
              </div>
              {/* End Product Text color And Size */}
              {/* Price */}
              <div
                className="Polaris-LegacyCard__Section"
                style={{ padding: "var(--p-space-4)" }}
              >
                <h1 className="Polaris-Text--root Polaris-Text--headingMd Polaris-Text--bold">
                  Price
                </h1>
                <div>
                  <p className="border-header">Base Price</p>
                  <InputColorSelector
                    label=""
                    events={{
                      onChange: (e) => handleChange(e, true),
                      value: formData.pm_det_baseprice_clr,
                      name: "pm_det_baseprice_clr",
                    }}
                    error={fieldErrors["pm_det_baseprice_clr"]}
                  />
                </div>
                <div>
                  <p className="border-header" style={{ marginTop: "12px" }}>
                    Compare Price
                  </p>
                  <InputColorSelector
                    label=""
                    events={{
                      onChange: (e) => handleChange(e, true),
                      value: formData.pm_det_compareprice_clr,
                      name: "pm_det_compareprice_clr",
                    }}
                    error={fieldErrors["pm_det_compareprice_clr"]}
                  />
                </div>
              </div>
              {/* End Price */}
              {/* CTA Button  */}
              <div
                className="Polaris-LegacyCard__Section"
                style={{ padding: "var(--p-space-4)" }}
              >
                <h1 className="Polaris-Text--root Polaris-Text--headingMd Polaris-Text--bold">
                  CTA Button
                </h1>
                <div>
                  <p className="border-header">Button Text Color</p>
                  <InputColorSelector
                    label=""
                    events={{
                      onChange: (e) => handleChange(e, true),
                      value: formData.pm_det_cta_text_clr,
                      name: "pm_det_cta_text_clr",
                    }}
                    error={fieldErrors["pm_det_cta_text_clr"]}
                  />
                </div>
                <div>
                  <p className="border-header" style={{ marginTop: "12px" }}>
                    Button Background Color
                  </p>
                  <InputColorSelector
                    label=""
                    events={{
                      onChange: (e) => handleChange(e, true),
                      value: formData.pm_det_cta_bg_clr,
                      name: "pm_det_cta_bg_clr",
                    }}
                    error={fieldErrors["pm_det_cta_bg_clr"]}
                  />
                </div>
                <div>
                  <p className="border-header" style={{ marginTop: "12px" }}>
                    Text
                  </p>
                  <InputText
                    key="pm_det_cta_text"
                    label=""
                    events={{
                      onChange: (e) => handleText(e, true),
                      value: formData.pm_det_cta_text,
                      name: "pm_det_cta_text",
                    }}
                    error={fieldErrors["pm_det_cta_text"]}
                  />
                </div>
              </div>
              {/* End CTA Button  */}
            </div>
          </>
        )}
        {/* End CTA Button  */}
        {/* Save Button */}
        <div className="Polaris-PageActions" style={{ padding: "0" }}>
          <div className="Polaris-LegacyStack Polaris-LegacyStack--spacingTight Polaris-LegacyStack--distributionTrailing">
            <div className="Polaris-LegacyStack__Item">
              <button
                className="Polaris-Button Polaris-Button--primary"
                type="button"
                // disabled={isBtnDis}
                onClick={() => handleSave()}
              >
                <span className="Polaris-Button__Content">
                  <span className="Polaris-Button__Text">Save Changes</span>
                </span>
              </button>
            </div>
          </div>
        </div>
        {/* End Save Button */}
      </div>
      {/* Right Side Video Section */}
      <div
        className="right-card"
        style={{
          width: "100%",
          position: "sticky",
          top: "60px",
          height: "fit-content",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 30px",
          }}
        >
          <div className="right-card-title" style={{ margin: "0" }}>
            Popup View
          </div>
          <div className="device-btn" data-buttongroup-segmented="true">
            <div className="">
              <button
                onClick={() => setIsMobileView(false)}
                className="desktop-btn"
                type="button"
                aria-pressed="true"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 17H4C3.46957 17 2.96086 16.7893 2.58579 16.4142C2.21071 16.0391 2 15.5304 2 15V5C2 4.46957 2.21071 3.96086 2.58579 3.58579C2.96086 3.21071 3.46957 3 4 3H20C20.5304 3 21.0391 3.21071 21.4142 3.58579C21.7893 3.96086 22 4.46957 22 5V15C22 15.5304 21.7893 16.0391 21.4142 16.4142C21.0391 16.7893 20.5304 17 20 17H19"
                    stroke="#777777"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 15L17 21H7L12 15Z"
                    stroke="#777777"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div className="">
              <button
                onClick={() => setIsMobileView(true)}
                className="mobile-btn-active"
                type="button"
                aria-pressed="false"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M17 2H7C5.89543 2 5 2.89543 5 4V20C5 21.1046 5.89543 22 7 22H17C18.1046 22 19 21.1046 19 20V4C19 2.89543 18.1046 2 17 2Z"
                    stroke="#883DCF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 18H12.01"
                    stroke="#883DCF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="VD-mobile-story" style={{ padding: "20px 0" }}>
          {isPopupBottom ? (
            <div className="VD-mobile-story-inner">
              <button className="VD-story-close-btn">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.0002 10.586L4.70718 3.29297L3.29297 4.70718L10.586 12.0002L3.29297 19.2933L4.70718 20.7075L12.0002 13.4145L19.2933 20.7075L20.7075 19.2933L13.4145 12.0002L20.7075 4.70723L19.2933 3.29302L12.0002 10.586Z"
                    fill="white"
                  />
                </svg>
              </button>
              <video
                style={{
                  objectFit: "cover",
                  pointerEvents: "none",
                  width: "100%",
                  height: "100%",
                }}
                controls
                loop
                muted
                autoPlay
              >
                <source src={sample_inner_video} type="video/mp4" />
              </video>
              <div className="VD-mobile-story-content">
                <div className="VD-audio-control">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M5.889 16H2a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h3.889l5.294-4.332a.5.5 0 0 1 .817.387v15.89a.5.5 0 0 1-.817.387L5.89 16zm13.517 4.134l-1.416-1.416A8.978 8.978 0 0 0 21 12a8.982 8.982 0 0 0-3.304-6.968l1.42-1.42A10.976 10.976 0 0 1 23 12c0 3.223-1.386 6.122-3.594 8.134zm-3.543-3.543l-1.422-1.422A3.993 3.993 0 0 0 16 12c0-1.43-.75-2.685-1.88-3.392l1.439-1.439A5.991 5.991 0 0 1 18 12c0 1.842-.83 3.49-2.137 4.591z"></path>
                  </svg>
                </div>
                <div
                  className="VD-mobile-card"
                  style={{ backgroundColor: formData.pm_botm_box_bg_clr }}
                >
                  {formData.pm_botm_prod_img && (
                    <div className="VD-pro-img">
                      <img src={sample_image} alt="" />
                    </div>
                  )}
                  <div className="VD-pro-content">
                    <div
                      style={{
                        fontSize: `${formData.pm_botm_font_size}px`,
                        color: formData.pm_botm_title_clr,
                      }}
                      className="VD-pro-title"
                    >
                      Men's Plant Pacers
                    </div>
                    {formData.pm_botm_review && (
                      <div className="VD-pro-review">
                        <div className="VD-star">
                          <svg
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.2 18a.8.8 0 01-.792-.914l.743-5.203-2.917-2.917a.8.8 0 01.434-1.355l4.398-.733 2.218-4.435a.8.8 0 011.435.008l2.123 4.361 4.498.801a.8.8 0 01.425 1.353l-2.917 2.917.744 5.203a.8.8 0 01-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 015.2 18z"
                              fill="#ffcc00"
                            />
                          </svg>
                          <svg
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.2 18a.8.8 0 01-.792-.914l.743-5.203-2.917-2.917a.8.8 0 01.434-1.355l4.398-.733 2.218-4.435a.8.8 0 011.435.008l2.123 4.361 4.498.801a.8.8 0 01.425 1.353l-2.917 2.917.744 5.203a.8.8 0 01-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 015.2 18z"
                              fill="#ffcc00"
                            />
                          </svg>
                          <svg
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.2 18a.8.8 0 01-.792-.914l.743-5.203-2.917-2.917a.8.8 0 01.434-1.355l4.398-.733 2.218-4.435a.8.8 0 011.435.008l2.123 4.361 4.498.801a.8.8 0 01.425 1.353l-2.917 2.917.744 5.203a.8.8 0 01-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 015.2 18z"
                              fill="#ffcc00"
                            />
                          </svg>
                          <svg
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.2 18a.8.8 0 01-.792-.914l.743-5.203-2.917-2.917a.8.8 0 01.434-1.355l4.398-.733 2.218-4.435a.8.8 0 011.435.008l2.123 4.361 4.498.801a.8.8 0 01.425 1.353l-2.917 2.917.744 5.203a.8.8 0 01-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 015.2 18z"
                              fill="#ffcc00"
                            />
                          </svg>
                          <svg
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.2 18a.8.8 0 01-.792-.914l.743-5.203-2.917-2.917a.8.8 0 01.434-1.355l4.398-.733 2.218-4.435a.8.8 0 011.435.008l2.123 4.361 4.498.801a.8.8 0 01.425 1.353l-2.917 2.917.744 5.203a.8.8 0 01-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 015.2 18z"
                              fill="#ffcc00"
                            />
                          </svg>
                          <span className="VD-review-count"> 5 reviews </span>
                        </div>
                      </div>
                    )}
                    <div className="VD-pro-price">
                      <span
                        className="VD-price"
                        style={{ color: formData.pm_botm_base_price_clr }}
                      >
                        Rs. 10,996.97
                      </span>
                      <span
                        className="VD-camp-price"
                        style={{ color: formData.pm_botm_compare_price_clr }}
                      >
                        Rs. 12,000.00
                      </span>
                      <span
                        style={{
                          backgroundColor: formData.pm_botm_badge_bg_clr,
                          color: formData.pm_botm_badge_text_clr,
                        }}
                        className="VD-badge-discount"
                      >
                        8% OFF
                      </span>
                    </div>
                  </div>
                  <button
                    style={{ backgroundColor: formData.pm_botm_cta_btn_bg_clr }}
                  >
                    <span style={{ color: formData.pm_botm_cta_btn_text_clr }}>
                      {formData.pm_botm_cta_btn_text}
                    </span>
                    <svg
                      viewBox="0 0 24 24"
                      width={24}
                      height={24}
                      stroke="currentColor"
                      strokeWidth={2}
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="css-i6dzq1"
                    >
                      <polyline points="9 18 15 12 9 6" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="VD-mobile-story-inner">
              <video
                style={{
                  objectFit: "cover",
                  pointerEvents: "none",
                  width: "100%",
                  height: "100%",
                }}
                controls
                loop
                muted
                autoPlay
              >
                <source src="file/inner video.mp4" type="video/mp4" />
              </video>
              <div className="VD-mobile-story-content">
                <div className="VD-audio-control">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M5.889 16H2a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h3.889l5.294-4.332a.5.5 0 0 1 .817.387v15.89a.5.5 0 0 1-.817.387L5.89 16zm13.517 4.134l-1.416-1.416A8.978 8.978 0 0 0 21 12a8.982 8.982 0 0 0-3.304-6.968l1.42-1.42A10.976 10.976 0 0 1 23 12c0 3.223-1.386 6.122-3.594 8.134zm-3.543-3.543l-1.422-1.422A3.993 3.993 0 0 0 16 12c0-1.43-.75-2.685-1.88-3.392l1.439-1.439A5.991 5.991 0 0 1 18 12c0 1.842-.83 3.49-2.137 4.591z"></path>
                  </svg>
                </div>
                <div className="VD-mobile-card">
                  <div className="VD-pro-img">
                    <img src={sample_image} alt="" />
                  </div>
                  <div className="VD-pro-content">
                    <div className="VD-pro-title">Men's Plant Pacers</div>
                    <div className="VD-pro-review">
                      <div className="VD-star">
                        <svg
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.2 18a.8.8 0 01-.792-.914l.743-5.203-2.917-2.917a.8.8 0 01.434-1.355l4.398-.733 2.218-4.435a.8.8 0 011.435.008l2.123 4.361 4.498.801a.8.8 0 01.425 1.353l-2.917 2.917.744 5.203a.8.8 0 01-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 015.2 18z"
                            fill="#ffcc00"
                          />
                        </svg>
                        <svg
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.2 18a.8.8 0 01-.792-.914l.743-5.203-2.917-2.917a.8.8 0 01.434-1.355l4.398-.733 2.218-4.435a.8.8 0 011.435.008l2.123 4.361 4.498.801a.8.8 0 01.425 1.353l-2.917 2.917.744 5.203a.8.8 0 01-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 015.2 18z"
                            fill="#ffcc00"
                          />
                        </svg>
                        <svg
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.2 18a.8.8 0 01-.792-.914l.743-5.203-2.917-2.917a.8.8 0 01.434-1.355l4.398-.733 2.218-4.435a.8.8 0 011.435.008l2.123 4.361 4.498.801a.8.8 0 01.425 1.353l-2.917 2.917.744 5.203a.8.8 0 01-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 015.2 18z"
                            fill="#ffcc00"
                          />
                        </svg>
                        <svg
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.2 18a.8.8 0 01-.792-.914l.743-5.203-2.917-2.917a.8.8 0 01.434-1.355l4.398-.733 2.218-4.435a.8.8 0 011.435.008l2.123 4.361 4.498.801a.8.8 0 01.425 1.353l-2.917 2.917.744 5.203a.8.8 0 01-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 015.2 18z"
                            fill="#ffcc00"
                          />
                        </svg>
                        <svg
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.2 18a.8.8 0 01-.792-.914l.743-5.203-2.917-2.917a.8.8 0 01.434-1.355l4.398-.733 2.218-4.435a.8.8 0 011.435.008l2.123 4.361 4.498.801a.8.8 0 01.425 1.353l-2.917 2.917.744 5.203a.8.8 0 01-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 015.2 18z"
                            fill="#ffcc00"
                          />
                        </svg>
                        <span className="VD-review-count"> 5 reviews </span>
                      </div>
                    </div>
                    <div className="VD-pro-price">
                      <span className="VD-price">Rs. 10,996.97</span>
                      <span className="VD-camp-price">Rs. 12,000.00</span>
                      <span className="VD-badge-discount">8% OFF</span>
                    </div>
                  </div>
                  <button>
                    <span>Shop Now</span>
                    <svg
                      viewBox="0 0 24 24"
                      width={24}
                      height={24}
                      stroke="currentColor"
                      strokeWidth={2}
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="css-i6dzq1"
                    >
                      <polyline points="9 18 15 12 9 6" />
                    </svg>
                  </button>
                </div>
              </div>
              {/* Click to Swipt Open Cart  */}
              <div className="VD-pop-open">
                <div className="VD-pop-down-aero">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_1"
                    x={0}
                    y={0}
                    version="1.1"
                    viewBox="0 0 29 29"
                    xmlSpace="preserve"
                  >
                    <path
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit={10}
                      strokeWidth={2}
                      d="m20.5 11.5-6 6-6-6"
                    />
                  </svg>
                </div>
                <div className="VD-pop-open-inner">
                  <div>
                    {formData.pm_det_prod_img && (
                      <div className="VD-pop-img">
                        <img src={sample_image} alt="" />
                      </div>
                    )}
                    <div className="VD-product-details">
                      <div
                        className="VD-product-title"
                        style={{
                          fontSize: `${formData.pm_det_font_size}px`,
                          color: formData.pm_det_title_clr,
                        }}
                      >
                        Men's Plant Pacers
                      </div>
                      {formData.pm_det_review && (
                        <div className="VD-pro-review">
                          <div className="VD-star">
                            <svg
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.2 18a.8.8 0 01-.792-.914l.743-5.203-2.917-2.917a.8.8 0 01.434-1.355l4.398-.733 2.218-4.435a.8.8 0 011.435.008l2.123 4.361 4.498.801a.8.8 0 01.425 1.353l-2.917 2.917.744 5.203a.8.8 0 01-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 015.2 18z"
                                fill="#ffcc00"
                              />
                            </svg>
                            <svg
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.2 18a.8.8 0 01-.792-.914l.743-5.203-2.917-2.917a.8.8 0 01.434-1.355l4.398-.733 2.218-4.435a.8.8 0 011.435.008l2.123 4.361 4.498.801a.8.8 0 01.425 1.353l-2.917 2.917.744 5.203a.8.8 0 01-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 015.2 18z"
                                fill="#ffcc00"
                              />
                            </svg>
                            <svg
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.2 18a.8.8 0 01-.792-.914l.743-5.203-2.917-2.917a.8.8 0 01.434-1.355l4.398-.733 2.218-4.435a.8.8 0 011.435.008l2.123 4.361 4.498.801a.8.8 0 01.425 1.353l-2.917 2.917.744 5.203a.8.8 0 01-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 015.2 18z"
                                fill="#ffcc00"
                              />
                            </svg>
                            <svg
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.2 18a.8.8 0 01-.792-.914l.743-5.203-2.917-2.917a.8.8 0 01.434-1.355l4.398-.733 2.218-4.435a.8.8 0 011.435.008l2.123 4.361 4.498.801a.8.8 0 01.425 1.353l-2.917 2.917.744 5.203a.8.8 0 01-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 015.2 18z"
                                fill="#ffcc00"
                              />
                            </svg>
                            <svg
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.2 18a.8.8 0 01-.792-.914l.743-5.203-2.917-2.917a.8.8 0 01.434-1.355l4.398-.733 2.218-4.435a.8.8 0 011.435.008l2.123 4.361 4.498.801a.8.8 0 01.425 1.353l-2.917 2.917.744 5.203a.8.8 0 01-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 015.2 18z"
                                fill="#ffcc00"
                              />
                            </svg>
                            <span className="VD-review-count"> 5 reviews </span>
                          </div>
                        </div>
                      )}
                      <div className="VD-pro-price">
                        <span
                          className="VD-price"
                          style={{ color: formData.pm_det_baseprice_clr }}
                        >
                          Rs. 10,996.97
                        </span>
                        <span
                          className="VD-camp-price"
                          style={{ color: formData.pm_det_compareprice_clr }}
                        >
                          Rs. 12,000.00
                        </span>
                      </div>
                      <div className="VD-pro-variant">
                        <label htmlFor>Variant selection</label>
                        <select name id>
                          <option value>1</option>
                          <option value>2</option>
                          <option value>3</option>
                        </select>
                      </div>
                    </div>
                    {formData.pm_det_prod_desc && (
                      <div className="VD-pro-disc">
                        <span>Description</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 13.82 24.38"
                        >
                          <path
                            fill="#000"
                            d="M11.32,28.72a1.64,1.64,0,0,0,2.3,0L24.18,18.15A1.6,1.6,0,0,0,24.66,17a1.63,1.63,0,0,0-.48-1.16L13.62,5.28a1.63,1.63,0,0,0-2.3,2.3L20.73,17l-9.41,9.41A1.63,1.63,0,0,0,11.32,28.72Z"
                            transform="translate(-10.84 -4.82)"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                  <div className="VD-product-btn">
                    <button
                      style={{ backgroundColor: formData.pm_det_cta_bg_clr }}
                    >
                      <span style={{ color: formData.pm_det_cta_text_clr }}>
                        {formData.pm_det_cta_text}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* End Right Side Video Section */}
    </div>
  );
}

export default PopupMobile;

export const SettingMock = {
  user: {
    plan_name: "",
    plan_price: "",
  },
  billing: {
    roi: "",
    extraUsageFee: 0,
    date_range: "",
  },
};

import React from "react";
import Chart from "./commonChart";

const RevenueChart = (props) => {
  const data = props.data;

  const config = {
    title: {
      text: "Revenue",
    },
    xAxis: {
      categories: data.map((entry) => entry.date),
    },
    yAxis: {
      title: {
        text: "Revenue",
      },
    },
    series: [
      {
        name: "Revenue",
        data: data.map((entry) => entry.revenue),
      },
    ],
  };

  return <Chart data={data} config={config} />;
};

export default RevenueChart;

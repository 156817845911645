import React, { useState, useEffect } from "react";
import { authAxios } from "../../config/axios";
import Loader from "../Loader";
import { dashboardMock } from "../../assets/mocks/dashboard.mock";
import addToCartPurp from "../../assets/img/addToCartPurple.svg";
import cnvRate from "../../assets/img/cnvRate.svg";
import avgOrderValue from "../../assets/img/avgOrderValue.svg";
function Orders(props) {
  const [data, setData] = useState(dashboardMock.orders);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let query = `date_start=${props.startDate}&date_end=${props.endDate}`;
        let response = await authAxios(
          "get",
          `/apisv1/authadmin/order-analytics?${query}`
        );

        if (response.data.data) setData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [props.startDate, props.endDate]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="Polaris-Page__Content transitionEffect">
          <div className="Polaris-Layout">
            <div className="Polaris-Layout__Section Polaris-Layout__Section--oneThird">
              <div className="Polaris-LegacyCard">
                <div className="Polaris-LegacyCard__Header">
                  <div className="Polaris-LegacyStack Polaris-LegacyStack--alignmentBaseline">
                    <div className="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill">
                      <div>
                        <h2
                          className="Polaris-Text--root Polaris-Text--headingMd"
                          style={{
                            color: "#777980",
                            marginBottom: "8px",
                            fontWeight: "500",
                          }}
                        >
                          Total Orders
                        </h2>
                        <div className="Polaris-LegacyCard__Section">
                          <span
                            className="Polaris-Text--root Polaris-Text--subdued"
                            style={{
                              fontWeight: "600",
                              fontSize: "24px",
                              color: "#000",
                            }}
                          >
                            {data.totalOrders}
                          </span>
                        </div>
                      </div>
                      <div>
                        <img src={addToCartPurp} alt="orders" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Polaris-Layout__Section Polaris-Layout__Section--oneThird">
              <div className="Polaris-LegacyCard">
                <div className="Polaris-LegacyCard__Header">
                  <div className="Polaris-LegacyStack Polaris-LegacyStack--alignmentBaseline">
                    <div className="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill">
                      <div>
                        <h2
                          className="Polaris-Text--root Polaris-Text--headingMd"
                          style={{
                            color: "#777980",
                            marginBottom: "8px",
                            fontWeight: "500",
                          }}
                        >
                          Conversion Rate
                        </h2>
                        <div className="Polaris-LegacyCard__Section">
                          <div className="VD-logic-number">
                            <div className="VD-top-number">
                              {data.conversionRate.toFixed(2)}%
                            </div>
                            {data.previousConversationRate > 99 ? (
                              <div
                                className="VD-top-number-arrow"
                                style={{ color: "#0A850A" }}
                              >
                                {data.previousConversationRate}%
                                <span>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M4.27322 10.6667H11.7265C11.8584 10.6661 11.9871 10.6265 12.0964 10.5528C12.2058 10.4791 12.2908 10.3746 12.3407 10.2526C12.3907 10.1306 12.4033 9.99648 12.3771 9.86727C12.3508 9.73806 12.2868 9.61954 12.1932 9.52669L8.47322 5.8067C8.41124 5.74421 8.33751 5.69461 8.25627 5.66077C8.17503 5.62692 8.08789 5.6095 7.99988 5.6095C7.91187 5.6095 7.82474 5.62692 7.7435 5.66077C7.66226 5.69461 7.58852 5.74421 7.52655 5.8067L3.80655 9.52669C3.71294 9.61954 3.64895 9.73806 3.6227 9.86727C3.59644 9.99648 3.60909 10.1306 3.65904 10.2526C3.70899 10.3746 3.794 10.4791 3.90332 10.5528C4.01264 10.6265 4.14137 10.6661 4.27322 10.6667Z"
                                      fill="#1A9882"
                                    />
                                  </svg>
                                </span>
                              </div>
                            ) : (
                              <div
                                className="VD-top-number-arrow"
                                style={{ color: "#FF3F3F" }}
                              >
                                {data.previousConversationRate}%
                                <span>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g id="fi-sr-caret-down">
                                      <path
                                        id="Vector"
                                        d="M4.27602 6H11.724C11.8559 6.00003 11.9847 6.03914 12.0943 6.1124C12.2039 6.18565 12.2894 6.28976 12.3398 6.41156C12.3903 6.53336 12.4035 6.66738 12.3777 6.79669C12.352 6.92599 12.2886 7.04476 12.1954 7.138L8.47135 10.862C8.34634 10.987 8.1768 11.0572 8.00002 11.0572C7.82325 11.0572 7.65371 10.987 7.52869 10.862L3.80469 7.138C3.71148 7.04476 3.64801 6.92599 3.6223 6.79669C3.59659 6.66738 3.60979 6.53336 3.66024 6.41156C3.71068 6.28976 3.79611 6.18565 3.90572 6.1124C4.01532 6.03914 4.14419 6.00003 4.27602 6Z"
                                        fill="#EB3D4D"
                                      />
                                    </g>
                                  </svg>
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <img src={cnvRate} alt="orders" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Polaris-Layout__Section Polaris-Layout__Section--oneThird">
              <div className="Polaris-LegacyCard">
                <div className="Polaris-LegacyCard__Header">
                  <div className="Polaris-LegacyStack Polaris-LegacyStack--alignmentBaseline">
                    <div className="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill">
                      <div>
                        <h2
                          className="Polaris-Text--root Polaris-Text--headingMd"
                          style={{
                            color: "#777980",
                            marginBottom: "8px",
                            fontWeight: "500",
                          }}
                        >
                          Average Order Value
                        </h2>
                        <div className="Polaris-LegacyCard__Section">
                          <span
                            className="Polaris-Text--root Polaris-Text--subdued"
                            style={{
                              fontWeight: "600",
                              fontSize: "24px",
                              color: "#000",
                            }}
                          >
                            {data.averageOrderValue}
                          </span>
                        </div>
                      </div>
                      <div>
                        <img src={avgOrderValue} alt="orders" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Orders;

import * as React from "react";
import { AppProvider, Card, IndexTable } from "@shopify/polaris";

function PlaylistTable({ headings, tableData }) {
  const rowMarkup = tableData.map(
    ({ title, numOfVideo, action, _id: id }, index) => (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell>{title}</IndexTable.Cell>
        <IndexTable.Cell>{numOfVideo}</IndexTable.Cell>
        <IndexTable.Cell className="action-header">{action}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  return (
    <AppProvider i18n={{}}>
      <div className="Polaris-Text--root" style={{ fontSize: "14px" }}>
        <Card>
          <IndexTable
            selectable={false}
            itemCount={tableData.length}
            headings={headings}
          >
            {rowMarkup}
          </IndexTable>
        </Card>
      </div>
    </AppProvider>
  );
}

export default PlaylistTable;

import React, { useState, useEffect } from "react";
import Welcome from "../component/dashboard/welcome";
import SectionTitle from "../component/dashboard/sectionTitle";
import Engagement from "../component/dashboard/engagement";
import TopPlaylist from "../component/dashboard/topPlaylist";
import TopVideos from "../component/dashboard/topVideos";
import Analytics from "../component/dashboard/analytics";
import DateRangeFilter from "../component/DateRangePicker";
import Orders from "../component/dashboard/orders";
import moment from "moment";
import calendar from "../assets/img/fi-sr-calendar.svg";
import { dashboardMock } from "../assets/mocks/dashboard.mock";
import { NoAnalytics } from "../assets/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../AuthComp/Auth";
import { authAxios } from "../config/axios";
function AnalyticsPage() {
  const navigate = useNavigate();
  let auth = useAuth();
  let { shop } = useParams();
  const defaultStartDate = new Date().getTime() - 7 * 24 * 60 * 60 * 1000;
  const [startDate, setStartDate] = useState(new Date(defaultStartDate));
  const [endDate, setEndDate] = useState(new Date());
  const [showDate, setShowDate] = useState(false);
  const [data, setData] = useState(dashboardMock.analytics);
  const [isVideo, setIsVideo] = useState(false);
  // const [isPickerOpen, setIsPickerOpen] = useState(false);

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVideo = async () => {
      let response = await authAxios("get", "/apisv1/authadmin/getvideos");
      if (response.data.data.length) {
        setIsVideo(true);
      }
    };
    fetchVideo();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let query = `date_start=${moment(startDate).format("YYYY-MM-DD")}&date_end=${moment(endDate).format("YYYY-MM-DD")}`;
        let response = await authAxios(
          "get",
          `/apisv1/authadmin/impressions-analytics?${query}`
        );
        console.log("res", response);
        if (response.data.data) {
          setData(response.data.data);
        }
        setLoading(false);
      } catch (error) {
        console.log("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  const onChange = (ranges) => {
    if (
      moment(ranges.startDate).format("MM-DD-YYYY") !==
      moment(ranges.endDate).format("MM-DD-YYYY")
    ) {
      setIsPickerOpen(false);
    } else if (ranges.startDate === "" && ranges.endDate === "") {
      setIsPickerOpen(false);
    }
    setShowDate(true);
  };

  // const handleDateRangeChange = (selection) => {
  //   console.log(selection, "Selection");
  //   setStartDate(selection.startDate);
  //   setEndDate(selection.endDate);
  //   setIsPickerOpen(false);
  // };

  const handleTogglePicker = () => {
    setIsPickerOpen(!isPickerOpen);
  };

  const buttonLabel = "Select Date";

  const setDefaultDates = () => {
    const today = new Date();
    const lastSevenDays = new Date();
    lastSevenDays.setTime(today.getTime() - 7 * 24 * 60 * 60 * 1000); // Subtract 7 days in milliseconds

    setStartDate(lastSevenDays);
    setEndDate(today);
  };

  useEffect(() => {
    setDefaultDates();
  }, []);

  return (
    <>
      <main
        className="Polaris-Frame__Main"
        id="AppFrameMain"
        data-has-global-ribbon="false"
      >
        <div className="Polaris-Frame__Content transitionEffect">
          {!isVideo ? (
            <div className="no-analytics transitionEffect">
              <NoAnalytics />
              <p className="border-header">
                Upload video to see analytics.
                <span
                  style={{
                    color: "#883DCF",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(`/${shop}/media`)}
                >
                  Upload video
                </span>
              </p>
            </div>
          ) : (
            <div className="Polaris-Page Polaris-Page--fullWidth">
              <div
                className="analytic-main-row analytic-main"
                style={{ marginTop: "20px" }}
              >
                <h2 className="Polaris-Page-subTitle" style={{ margin: "0" }}>
                  Analytics
                </h2>
                <div className="analytic-main-row ">
                  <button
                    className="Polaris-Button Polaris-Button--unfilled select-date"
                    // className={`Polaris-Button Polaris-Button--third ${showDate && "Polaris-Button--active"}`}
                    onClick={handleTogglePicker}
                  >
                    <img src={calendar} alt="calendar icon" />
                    {!showDate && (
                      <>
                        <span>{buttonLabel}</span>
                      </>
                    )}
                    {showDate && startDate && endDate && (
                      <div className="show-date">
                        <span>
                          {moment(startDate).format("Do MMMM YYYY")} -{" "}
                          {moment(endDate).format("Do MMMM YYYY")}
                        </span>
                      </div>
                    )}
                  </button>
                </div>
                <div className="daterange-picker">
                  <DateRangeFilter
                    buttonLabel={buttonLabel}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={onChange}
                    open={isPickerOpen}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                  />
                </div>
              </div>

              <Analytics
                startDate={moment(startDate).format("YYYY-MM-DD")}
                endDate={moment(endDate).format("YYYY-MM-DD")}
              ></Analytics>
              <Orders
                startDate={moment(startDate).format("YYYY-MM-DD")}
                endDate={moment(endDate).format("YYYY-MM-DD")}
              ></Orders>

              <h2 className="Polaris-Page-subTitle section-padding">
                Engagement
              </h2>
              <Engagement
                startDate={moment(startDate).format("YYYY-MM-DD")}
                endDate={moment(endDate).format("YYYY-MM-DD")}
              ></Engagement>

              <h2
                className="Polaris-Page-subTitle section-padding"
                style={{ marginBottom: "20px" }}
              >
                Top Playlist
              </h2>
              <TopPlaylist
                startDate={moment(startDate).format("YYYY-MM-DD")}
                endDate={moment(endDate).format("YYYY-MM-DD")}
              ></TopPlaylist>

              <h2
                className="Polaris-Page-subTitle section-padding"
                style={{ marginBottom: "20px" }}
              >
                Top Videos
              </h2>
              <TopVideos
                startDate={moment(startDate).format("YYYY-MM-DD")}
                endDate={moment(endDate).format("YYYY-MM-DD")}
              ></TopVideos>
            </div>
          )}
        </div>
      </main>
    </>
  );
}

export default AnalyticsPage;

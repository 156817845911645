import React, { useState } from "react";
import preview from "../assets/img/preview.svg";
import { Step1, Step2, Step3, Step4, Step5 } from "../assets/icons";
import TutorialVideo from "../component/TutorialVideoModal";

function HelpAndSupport() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <main
      className="Polaris-Frame__Main"
      id="AppFrameMain"
      data-has-global-ribbon="false"
    >
      <div className="help-and-support transitionEffect">
        <div className="header">
          <h1>Help & Support</h1>
          <button
            className="watch-btn"
            onClick={() => setIsModalOpen(!isModalOpen)}
          >
            <img src={preview} alt="help and support" />
            <span>Watch Tutorial</span>
          </button>
        </div>
        <div className="cards-container">
          <div className="step-card">
            <div className="image-div">
              <Step1 />
              <p>Step 1</p>
            </div>
            <p className="step-desc">
              Go to the "Video Collection" tab, then upload your video step by
              step to get started. You can upload multiple videos.
            </p>
          </div>
          <div className="step-card">
            <div className="image-div">
              <Step2 />
              <p>Step 2</p>
            </div>
            <p className="step-desc">
              Go to the "Playlist" tab and create a new playlist for your
              videos. Playlists allow you to organize multiple videos together,
              making it easier to display them on your website.
            </p>
          </div>
          <div className="step-card">
            <div className="image-div">
              <Step3 />
              <p>Step 3</p>
            </div>
            <p className="step-desc">
              Go to the "Pages" tab where you can add your playlist to different
              pages like Story Playlist and Card Playlist, depending on how you
              want your videos to be displayed.
            </p>
          </div>
        </div>
        <div className="cards-container">
          <div className="step-card">
            <div className="image-div">
              <Step4 />
              <p>Step 4</p>
            </div>
            <p className="step-desc">
              You can customize your playlist. Adjust your Card Playlist, Popup
              Video, and Story Playlist to fit your website's style and
              preferences.
            </p>
          </div>
          <div className="step-card">
            <div className="image-div">
              <Step5 />
              <p>Step 5</p>
            </div>
            <p className="step-desc">
              Finally, enable Vidzy for your website. Once it's active, you'll
              be able to access video analytics and track the performance of
              your content.
            </p>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <TutorialVideo
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </main>
  );
}

export default HelpAndSupport;

import React, { Fragment } from "react";
import { useParams } from "react-router-dom";

function PrivacyPolicy() {
  let { shop } = useParams();
  
  return (
    <Fragment>
      <div className="container">
        <h1>Privacy Policy</h1>
        <p><strong>Effective Date:</strong> 16 July 2024</p>
        <p>Vidzy ("we," "our," or "us") respects the value of your privacy and is committed to safeguarding the personal information you share with us. This Privacy Policy explains how we collect, use, and protect your personal information when you use our Shopify public app. By using our app, you consent to the data practices described in this policy.</p>
        
        <h2>Information We Collect</h2>
        
        <h3>Personal Information</h3>
        <p>When you use our app, we collect the following personal information:</p>
        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Phone number</li>
        </ul>
        <p>We do not collect any other personal information beyond what is stated above.</p>
        
        <h3>Usage Information</h3>
        <p>We may collect non-personal information about how you interact with our app, such as the pages visited, the features used, and the actions taken within the app. This information helps us understand user behavior and improve our services.</p>
        
        <h2>How We Use Your Information</h2>
        
        <h3>Personal Information</h3>
        <p>We use personal information to:</p>
        <ul>
          <li>Provide and improve our services</li>
          <li>Communicate with you</li>
          <li>Respond to your inquiries and support requests</li>
        </ul>
        
        <h3>Usage Information</h3>
        <p>We use non-personal usage information to:</p>
        <ul>
          <li>Analyze trends and user behavior</li>
          <li>Improve the functionality and user experience of our app</li>
          <li>Monitor and enhance the performance of our services</li>
        </ul>
        
        <h2>How We Protect Your Information</h2>
        <p>We implement a variety of security measures to ensure the safety of your personal information. These measures include:</p>
        <ul>
          <li>Encryption of sensitive information</li>
          <li>Regular security audits and updates</li>
          <li>Restricted access to personal information, limited to authorized personnel only</li>
        </ul>
        <p>Despite our best efforts, no method of transmission over the internet or electronic storage is completely secure. Therefore, we cannot guarantee absolute security of your information.</p>
        
        <h2>Cookies and Tracking Technologies</h2>
        <p>Our app does not use cookies or other tracking technologies to collect personal information. However, we may use cookies to enhance the user experience and collect non-personal usage data.</p>
        
        <h2>Third-Party Disclosure</h2>
        <p>We do not sell, trade, or otherwise transfer your personal information to third parties. We may share your information with trusted third-party service providers who assist us in operating our app, conducting our business, or serving our users, as long as those parties agree to keep this information confidential.</p>
        
        <h2>Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.</p>
        
        <h2>Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
        <p>samarth.shinde@devxconsultancy.com</p>
        
        <p>By using our app, you agree to the terms of this Privacy Policy. If you do not agree with the terms, please do not use our app.</p>
      </div>
      <footer>
        &copy; 2024 Vidzy All Rights Reserved
      </footer>
    </Fragment>
  );
}

export default PrivacyPolicy;

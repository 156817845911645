import React, { useState, useEffect, useMemo, useContext } from "react";

import MediaPopup from "../component/MediaPopup";
import { authAxios } from "../config/axios";
import Loader from "../component/Loader";
import { convertToHHMMSS } from "../commonFunc";
import { FileUploader } from "react-drag-drop-files";
import { Upload } from "../assets/icons";
import Table from "../component/videoTable";
import VideoIDContext from "../videoContext";
import dummyImage from "./../assets/img/dummy-image-square.jpg";
import CommonDelete from "../component/CommonDelete";
import CommonSuccess from "../component/CommonSuccess";
import { useAuth } from "../AuthComp/Auth";
import VideoTabContainer from "../component/videoCollection/VideoTabContainer";
import toast, { Toaster } from "react-hot-toast";
import { upload } from "@testing-library/user-event/dist/upload";

import { uploadData } from "@aws-amplify/storage";

const video = document.createElement("video");

let ffmpeg;

function Media() {
  const auth = useAuth();
  const assetsUrl = `${process.env.REACT_APP_ASSETS_BASE_URL}/${auth.shopname}`;
  const [tab, setTab] = useState("All");
  const [file, setFile] = useState(null);
  const [error, setError] = useState({ file_error: "" });
  const [isMediaPopupOpen, setIsMediaPopupOpen] = useState(false);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [videoId, setVideoId] = useState(null);
  const [listItem, setListItem] = useState([]);
  const [title, setTitle] = useState("");
  const [videoInfo, setVideoInfo] = useState({});
  const [videoFile, setVideoFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isVideoUploadOrTrimmed, setIsVideoUploadOrTrimmed] = useState(false);
  const validFileType = ["video/mp4", "video/mov", "video/mkv"];
  const { uploadStatus } = useContext(VideoIDContext);
  const [hasFetchedMedia, setHasFetchedMedia] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [videoToDelete, setVideoToDelete] = useState({ id: null, title: "" });
  const [deleteSuccessPopUp, setDeleteSuccessPopUp] = useState(false);
  const [updateCollection, setUpdateCollection] = useState(false);
  const [isNoVideo, setIsNoVideo] = useState(false);

  useEffect(() => {
    fetchMediaList();
  }, []);

  useEffect(() => {
    uploadLoader ? auth.setLoader(true) : auth.setLoader(false);
  }, [uploadLoader]);

  useEffect(() => {
    listItem.length ? setIsNoVideo(false) : setIsNoVideo(true);
  }, [listItem]);

  useEffect(() => {
    if (!hasFetchedMedia) {
      fetchMediaList();
      setHasFetchedMedia(true);
    }
  }, [uploadStatus]);

  const fetchMediaList = async () => {
    // fetch collection api
    try {
      console.log();
      setIsLoading(true);
      let response = await authAxios("get", "/apisv1/authadmin/getvideos");
      if (response.status === 200) {
        let responseData = response.data.data;
        if (uploadStatus) {
          for (const obj of responseData) {
            if (obj._id == uploadStatus._id) {
              // let badgeText = uploadStatus.isPopup ? "popup-" : "cover-";
              // obj.progress = badgeText + uploadStatus.progress;
              obj.progress =
                uploadStatus.progress == "Trimming" ||
                uploadStatus.progress == "Compressing"
                  ? "Processing"
                  : "Done";
            }
          }
        }
        setListItem(responseData);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showToast = (message) => {
    toast(message, {
      duration: 2000,
      position: "top-center",
      type: "success",
    });
  };
  const readFileDataAsBase64 = (e) => {
    const file = e;

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event);
      };

      reader.onerror = (err) => {
        reject(err);
      };

      reader.readAsDataURL(file);
    });
  };
  const handleVideo = async (e) => {
    setIsEdit(false);
    let file = e;

    setVideoFile(file);
    if (validFileType.includes(file.type)) {
      console.log("FILE", file.type);
      setFile(file);
      var video = document.createElement("video");
      video.preload = "metadata";
      // wait for duration to change from NaN to the actual duration
      try {
        video.onloadedmetadata = async function () {
          console.log("video info before upload", video);
          window.URL.revokeObjectURL(video.src);
          let vid_duration = video.duration;
          if (vid_duration > 4) {
            setUploadLoader(true);

            console.log("CHECKSUM", auth);
            const currentDate = Math.floor(Date.now() / 1000);

            const res = await uploadData({
              path: `${auth.shopname}/original/${currentDate}-${file.name}`,
              data: file,
              options: {
                // contentType: file.type,
                contentType: "video/mp4",
                useAccelerateEndpoint: true,
              },
            }).result;
            // const bucketParams = {
            //   Bucket: S3_BUCKET,
            //   Key: `${auth.shopname}/original/${currentDate}-${file.name}`,
            //   Body: file,
            // };
            // const res = await s3Client.send(new PutObjectCommand(bucketParams));
            console.log("s3 res :>> ", res);

            const form = new FormData();
            const body = {
              fileName: `${currentDate}-${file.name}`,
              originalName: file.name,
            };
            // Make the API call in the background
            await authAxios("post", "/apisv1/authadmin/upload", body)
              .then((res) => {
                console.log("UPLOAD API RES", res.data);
                fetchMediaList();
                setVideoInfo(res.data.data);
                setUploadLoader(false);
                setIsMediaPopupOpen(true);
                setTitle(file.name);
                setFile(null);
                // Handle the response if needed
              })
              .catch((error) => {
                setUploadLoader(false);
                console.log(error);
              });
          } else {
            setError({
              file_error: "Video duration should be greater then 4 seconds",
            });
            setTimeout(() => {
              setError({ file_error: "" });
            }, 5000);
          }
        };
        video.src = URL.createObjectURL(file);
        // e.target.value = null;
      } catch (error) {
        setUploadLoader(false);
        console.log(error);
      }
    } else {
      setError({ file_error: "Please upload valid video (.mp4) file" });
      setTimeout(() => {
        setError({ file_error: "" });
      }, 5000);
    }
  };

  const handleClose = async () => {
    try {
      if (videoInfo.video_title !== title && videoInfo.video_title !== "") {
        let res = await authAxios("post", "/apisv1/authadmin/updatetitle", {
          id: videoInfo.video_id,
          title: videoInfo.video_title,
        });
      }
      setIsMediaPopupOpen(false);
      setVideoInfo({});
      fetchMediaList();
    } catch (error) {
      console.log(error);
    }
  };

  const cancelUpload = async () => {
    try {
      setIsMediaPopupOpen(false);
      setVideoInfo({});
      fetchMediaList();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteVideo = (_id, video_title) => {
    setDeletePopUp(true);
    setVideoToDelete({ id: _id, title: video_title });
  };

  const handleEditVideo = async (video_id, flag) => {
    try {
      setIsEdit(true);
      if (flag == "edit") {
        let res = await authAxios(
          "get",
          `/apisv1/authadmin/getvideos?video=${video_id}`
        );
        if (res.status === 200) {
          setVideoInfo(res.data.data);
          setIsMediaPopupOpen(true);
        }
      } else {
        let res = await authAxios(
          "get",
          `/apisv1/authadmin/delete?video=${video_id}`
        );
        if (res.status === 200) {
          setDeletePopUp(false);
          setDeleteSuccessPopUp(true);
          fetchMediaList();
        }
      }
    } catch (error) {
      setUploadLoader(false);
      console.log(error);
    }
  };
  const videoTableData = useMemo(
    () =>
      listItem.map(({ _id, video_title, card_image, products, progress }) => {
        return {
          id: _id,
          video: (
            <div>
              {card_image ? (
                <img
                  className="card-preview"
                  src={
                    card_image.includes("shopify")
                      ? card_image
                      : `${assetsUrl}/${card_image}`
                  }
                  alt="captured frame"
                />
              ) : (
                <div>No Thumbnail</div>
              )}
            </div>
          ),
          title: (
            <div className="video-title">
              <div className="title">{video_title}</div>
              {progress && <span className="badge">{progress}</span>}
            </div>
          ),
          // products: (
          //   <div>
          //     {products?.map((p) =>
          //       p.images ? (
          //         <img
          //           className="card-preview"
          //           src={p.images}
          //           alt="captured frame"
          //         />
          //       ) : (
          //         <img
          //           className="card-preview"
          //           src={dummyImage}
          //           alt="captured frame"
          //         />
          //       ),
          //     )}
          //   </div>
          // ),
          action: (
            <div class="Polaris-ButtonGroup">
              <div class="Polaris-ButtonGroup__Item">
                <button
                  onClick={() => handleEditVideo(_id, "edit")}
                  class="Polaris-Button"
                  type="button"
                >
                  <span className="Polaris-Button__Content">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.333 1.28422C10.5081 1.10912 10.716 0.970229 10.9447 0.875468C11.1735 0.780706 11.4187 0.731934 11.6663 0.731934C11.914 0.731934 12.1592 0.780706 12.3879 0.875468C12.6167 0.970229 12.8246 1.10912 12.9997 1.28422C13.1748 1.45931 13.3137 1.66718 13.4084 1.89596C13.5032 2.12473 13.552 2.36993 13.552 2.61755C13.552 2.86517 13.5032 3.11037 13.4084 3.33915C13.3137 3.56792 13.1748 3.77579 12.9997 3.95089L3.99967 12.9509L0.333008 13.9509L1.33301 10.2842L10.333 1.28422Z"
                        fill="#A3A9B6"
                      />
                    </svg>
                  </span>
                </button>
              </div>
              <div class="Polaris-ButtonGroup__Item">
                <button
                  // class="Polaris-Button Polaris-Button--destructive"
                  type="button"
                  style={{ border: "none", background: "none" }}
                  onClick={() => handleDeleteVideo(_id, video_title)}
                >
                  <span className="Polaris-Button__Content">
                    <svg
                      width="14"
                      height="17"
                      viewBox="0 0 14 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="Group">
                        <path
                          id="Vector"
                          d="M12.9997 2.95084H10.933C10.614 1.3999 9.24973 0.28618 7.66632 0.28418H6.33298C4.74957 0.28618 3.38526 1.3999 3.06632 2.95084H0.999664C0.631477 2.95084 0.333008 3.2493 0.333008 3.61749C0.333008 3.98568 0.631477 4.28418 0.999664 4.28418H1.66632V12.9508C1.66854 14.7909 3.15963 16.282 4.99966 16.2842H8.99966C10.8397 16.282 12.3308 14.7909 12.333 12.9508V4.28418H12.9997C13.3679 4.28418 13.6663 3.98571 13.6663 3.61752C13.6663 3.24934 13.3679 2.95084 12.9997 2.95084ZM6.33301 11.6175C6.33301 11.9857 6.03454 12.2842 5.66635 12.2842C5.29813 12.2842 4.99966 11.9857 4.99966 11.6175V7.61752C4.99966 7.24934 5.29813 6.95087 5.66632 6.95087C6.03451 6.95087 6.33298 7.24934 6.33298 7.61752V11.6175H6.33301ZM8.99966 11.6175C8.99966 11.9857 8.7012 12.2842 8.33301 12.2842C7.96482 12.2842 7.66635 11.9857 7.66635 11.6175V7.61752C7.66635 7.24934 7.96482 6.95087 8.33301 6.95087C8.7012 6.95087 8.99966 7.24934 8.99966 7.61752V11.6175ZM4.44701 2.95084C4.73057 2.15237 5.4857 1.61852 6.33301 1.61749H7.66635C8.51367 1.61852 9.26879 2.15237 9.55235 2.95084H4.44701Z"
                          fill="#A3A9B6"
                        />
                      </g>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          ),
        };
      }),
    [listItem]
  );
  return (
    <>
      <main
        className="Polaris-Frame__Main"
        id="AppFrameMain"
        data-has-global-ribbon="false"
        style={{ position: "relative" }}
      >
        <Toaster />
        <div
          className="Polaris-Frame__Content transitionEffect video-media"
          style={{ minHeight: "calc(100vh - 56px)", overflowY: "auto" }}
        >
          {uploadLoader && <Loader backdrop={true} />}
          <div
            className="Polaris-Page Polaris-Page--fullWidth"
            style={{ overflow: "hidden" }}
          >
            <div
              className="Polaris-Box"
              style={{
                "--pc-box-padding-block-end-xs": "var(--p-space-4)",
                "--pc-box-padding-block-end-md": "var(--p-space-5)",
                "--pc-box-padding-block-start-xs": "var(--p-space-4)",
                "--pc-box-padding-block-start-md": "var(--p-space-5)",
                "--pc-box-padding-inline-start-xs": "var(--p-space-4)",
                "--pc-box-padding-inline-start-sm": "var(--p-space-0)",
                "--pc-box-padding-inline-end-xs": "var(--p-space-4)",
                "--pc-box-padding-inline-end-sm": "var(--p-space-0)",

                position: "relative",
              }}
            ></div>
            <div>
              <div className="Polaris-Labelled--hidden">
                {isNoVideo && (
                  <div className="dnd-container">
                    <h4 className="dnd-title">Video</h4>
                    <FileUploader
                      dropMessageStyle={{ backgroundColor: "grey", zIndex: 99 }}
                      hoverTitle=" "
                      multiple={false}
                      handleChange={handleVideo}
                      name="file"
                      types={["mp4"]}
                      className="bg-white"
                    >
                      <label htmlFor="file">
                        <div
                          className="Polaris-DropZone Polaris-DropZone--hasOutline"
                          aria-disabled="false"
                        >
                          <span className="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--visuallyHidden"></span>

                          <div className="Polaris-DropZone__Container">
                            <div className="Polaris-DropZone-FileUpload Polaris-DropZone-FileUpload--large">
                              <div
                                className="Polaris-AlphaStack"
                                style={{
                                  "--pc-stack-inline-align": "center",
                                  "--pc-stack-order": "column",
                                  "--pc-stack-gap-xs": "var(--p-space-2)",
                                  gap: "16px",
                                }}
                              >
                                <Upload />
                                <p
                                  className="border-header"
                                  // style={{ fontSize: 16, marginTop: 10 }}
                                >
                                  Drag and drop image here, or click add image
                                </p>

                                <button
                                  className="Polaris-Button Polaris-Button--unfilled"
                                  // style={{ fontSize: 16, marginTop: 10 }}
                                  type="button"
                                >
                                  <span className="Polaris-Button__Text_Neutral">
                                    Upload Video
                                  </span>
                                </button>
                                {error.file_error != "" && (
                                  <div style={{ color: "red" }}>
                                    {error.file_error}
                                  </div>
                                )}
                              </div>
                              {/* {uploadLoader && <Loader backdrop={true} />} */}
                            </div>
                          </div>
                        </div>
                      </label>
                    </FileUploader>
                  </div>
                )}
                {!isNoVideo && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="tabs">
                        <div
                          role="tablist"
                          aria-label="Programming Languages"
                          className="tab-list"
                        >
                          <button
                            className="tab-list-button"
                            role="tab"
                            onClick={() => setTab("All")}
                            aria-selected={`${tab === "All" ? true : false}`}
                          >
                            All
                          </button>
                          <button
                            className="tab-list-button"
                            role="tab"
                            onClick={() => setTab("Saved")}
                            aria-selected={`${tab === "Saved" ? true : false}`}
                          >
                            Saved
                          </button>
                          <button
                            className="tab-list-button"
                            role="tab"
                            onClick={() => setTab("Draft")}
                            aria-selected={`${tab === "Draft" ? true : false}`}
                          >
                            Draft
                          </button>
                        </div>
                      </div>
                      <div
                        className="add-btn-div"
                        style={{
                          justifyContent: `${uploadLoader ? "center" : "flex-end"}`,
                        }}
                      >
                        <FileUploader
                          dropMessageStyle={{
                            backgroundColor: "grey",
                            zIndex: 99,
                          }}
                          hoverTitle=" "
                          multiple={false}
                          handleChange={handleVideo}
                          name="file"
                          types={["mp4"]}
                          className="bg-white"
                        >
                          <button className="add-btn">
                            {/* {uploadLoader ? (
                              <Loader backdrop={true} />
                            ) : ( */}
                            <div className="add-btn-text">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5"
                                  stroke="#F8F8FC"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M14.1666 6.66667L9.99992 2.5L5.83325 6.66667"
                                  stroke="#F8F8FC"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10 2.5V12.5"
                                  stroke="#F8F8FC"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <span
                                style={{ marginLeft: "5px", fontSize: "14px" }}
                              >
                                Add Video
                              </span>
                            </div>
                            {/* )} */}
                          </button>
                        </FileUploader>
                      </div>
                    </div>
                    <div>
                      <VideoTabContainer
                        selectedTab={tab}
                        videoData={listItem}
                        deletePopUp={deletePopUp}
                        setDeletePopUp={setDeletePopUp}
                        handleDeleteVideo={handleDeleteVideo}
                        handleEditVideo={handleEditVideo}
                      />
                    </div>
                  </div>
                )}
              </div>
              {/* {isLoading ? (
                <Loader />
              ) : (
                listItem.length > 0 && (
                  <div style={{ marginTop: "20px" }}>
                    <Table
                      headings={[
                        { title: "Video Collection" },
                        { title: "" },
                        { title: "Actions" },
                      ]}
                      tableData={videoTableData}
                    />
                  </div>
                )
              )} */}
            </div>
          </div>
        </div>
      </main>
      {isMediaPopupOpen && (
        <MediaPopup
          videoInfo={videoInfo}
          setVideoInfo={setVideoInfo}
          videoFile={videoFile}
          setTitle={setTitle}
          isEdit={isEdit}
          handleClose={() => handleClose()}
          cancelUpload={() => cancelUpload()}
          fetchMediaLis={() => fetchMediaList()}
        />
      )}
      {deletePopUp && (
        <CommonDelete
          desc={`Do you want to delete "${videoToDelete.title}" ?`}
          setIsShowDeletePopup={setDeletePopUp}
          headlText={"Delete Video"}
          handleDelete={() => handleEditVideo(videoToDelete.id, "del")}
        />
      )}
      {deleteSuccessPopUp && (
        <CommonSuccess
          desc={`"${videoToDelete.title}" deleted successfully.`}
          setIsShowDeletePopup={setDeleteSuccessPopUp}
          headlText={"Delete Video"}
          handleDelete={() => setDeleteSuccessPopUp(false)}
        />
      )}
    </>
  );
}

export default Media;

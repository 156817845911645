import React from "react";
import { useAuth } from "../../AuthComp/Auth";

const assetsUrl = "https://assets.vidzy.in";

const VideoCard = ({ tag, videoData, handleDeleteVideo, handleEditVideo }) => {
  let auth = useAuth();
  return (
    <div className="card-container" key={videoData._id}>
      <div className="video-img">
        <video
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            borderRadius: "4px 4px 0 0",
          }}
        >
          <source
            src={`${assetsUrl}/${auth.shopname}/original/${videoData.origin_video_file}`}
            type="video/mp4"
          />
        </video>
        <div
          className={` ${videoData.steps_completed === 5 ? "video-tag-saved " : "video-tag-draft"}`}
          style={{ position: "absolute", top: "6px", right: "6px" }}
        >
          {videoData.steps_completed === 5 ? "Saved" : "Draft"}
        </div>
      </div>
      <div className="video-details">
        <span className="video-title">{videoData.video_title}</span>

        <div className="video-options">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => handleEditVideo(videoData._id, "edit")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M11.3334 1.99955C11.5085 1.82445 11.7163 1.68556 11.9451 1.5908C12.1739 1.49604 12.4191 1.44727 12.6667 1.44727C12.9143 1.44727 13.1595 1.49604 13.3883 1.5908C13.6171 1.68556 13.8249 1.82445 14 1.99955C14.1751 2.17465 14.314 2.38252 14.4088 2.61129C14.5036 2.84006 14.5523 3.08526 14.5523 3.33288C14.5523 3.58051 14.5036 3.8257 14.4088 4.05448C14.314 4.28325 14.1751 4.49112 14 4.66622L5.00004 13.6662L1.33337 14.6662L2.33337 10.9996L11.3334 1.99955Z"
                fill="#A3A9B6"
              />
            </svg>
          </div>
          <div
            onClick={() =>
              handleDeleteVideo(videoData._id, videoData.video_title)
            }
            style={{ cursor: "pointer" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="16"
              viewBox="0 0 14 16"
              fill="none"
            >
              <path
                d="M13 2.66666H10.9334C10.6144 1.11572 9.25009 0.002 7.66669 0H6.33334C4.74994 0.002 3.38562 1.11572 3.06669 2.66666H1.00003C0.631843 2.66666 0.333374 2.96513 0.333374 3.33331C0.333374 3.7015 0.631843 4 1.00003 4H1.66669V12.6667C1.66891 14.5067 3.16 15.9978 5.00003 16H9.00003C10.8401 15.9978 12.3312 14.5067 12.3334 12.6667V4H13C13.3682 4 13.6667 3.70153 13.6667 3.33334C13.6667 2.96516 13.3682 2.66666 13 2.66666ZM6.33337 11.3333C6.33337 11.7015 6.0349 12 5.66672 12C5.2985 12 5.00003 11.7015 5.00003 11.3333V7.33334C5.00003 6.96516 5.2985 6.66669 5.66669 6.66669C6.03487 6.66669 6.33334 6.96516 6.33334 7.33334V11.3333H6.33337ZM9.00003 11.3333C9.00003 11.7015 8.70156 12 8.33337 12C7.96519 12 7.66672 11.7015 7.66672 11.3333V7.33334C7.66672 6.96516 7.96519 6.66669 8.33337 6.66669C8.70156 6.66669 9.00003 6.96516 9.00003 7.33334V11.3333ZM4.44737 2.66666C4.73094 1.86819 5.48606 1.33434 6.33337 1.33331H7.66672C8.51403 1.33434 9.26916 1.86819 9.55272 2.66666H4.44737Z"
                fill="#A3A9B6"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCard;

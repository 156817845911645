import React, { useState, useEffect } from "react";
import { authAxios } from "../../config/axios";
import Loader from "../Loader";
import { dashboardMock } from "../../assets/mocks/dashboard.mock";
import watchCount from "../../assets/img/watchCount.svg";
import avgWatchTime from "../../assets/img/watchCount.svg";
import totalWatchHours from "../../assets/img/watchCount.svg";

function Engagement(props) {
  const [data, setData] = useState(dashboardMock.engagement);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let query = `date_start=${props.startDate}&date_end=${props.endDate}`;
        let response = await authAxios(
          "get",
          `/apisv1/authadmin/watch-count-analytics?${query}`
        );

        if (response.data.data) setData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [props.startDate, props.endDate]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="transitionEffect">
          <div className="Polaris-Layout">
            <div className="Polaris-Layout__Section Polaris-Layout__Section--oneThird">
              <div
                className="Polaris-LegacyCard"
                style={{ position: "relative" }}
              >
                <div className="Polaris-LegacyCard__Header">
                  <div className="Polaris-LegacyStack Polaris-LegacyStack--alignmentBaseline">
                    <div className="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill">
                      <div>
                        <h2
                          className="Polaris-Text--root Polaris-Text--headingMd"
                          style={{
                            color: "#777980",
                            marginBottom: "8px",
                            fontWeight: "500",
                          }}
                        >
                          Watch Count
                        </h2>
                        <div className="Polaris-LegacyCard__Section">
                          <span
                            className="Polaris-Text--root Polaris-Text--subdued"
                            style={{
                              fontWeight: "600",
                              fontSize: "24px",
                              color: "#000",
                            }}
                          >
                            {Math.floor(data.totalWatchCount)}
                          </span>
                        </div>
                      </div>
                      <div>
                        <img src={watchCount} alt="total watch" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Polaris-Layout__Section Polaris-Layout__Section--oneThird">
              <div
                className="Polaris-LegacyCard"
                style={{ position: "relative" }}
              >
                <div className="Polaris-LegacyCard__Header">
                  <div className="Polaris-LegacyStack Polaris-LegacyStack--alignmentBaseline">
                    <div className="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill">
                      <div>
                        <h2
                          className="Polaris-Text--root Polaris-Text--headingMd"
                          style={{
                            color: "#777980",
                            marginBottom: "8px",
                            fontWeight: "500",
                          }}
                        >
                          Average Watch Time
                        </h2>
                        <div className="Polaris-LegacyCard__Section">
                          <span
                            className="Polaris-Text--root Polaris-Text--subdued"
                            style={{
                              fontWeight: "600",
                              fontSize: "24px",
                              color: "#000",
                            }}
                          >
                            {data.totalWatchCountPerDay.toFixed(2)}
                          </span>
                        </div>
                      </div>
                      <div>
                        <img src={avgWatchTime} alt="total watch" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Polaris-Layout__Section Polaris-Layout__Section--oneThird">
              <div
                className="Polaris-LegacyCard"
                style={{ position: "relative" }}
              >
                <div className="Polaris-LegacyCard__Header">
                  <div className="Polaris-LegacyStack Polaris-LegacyStack--alignmentBaseline">
                    <div className="Polaris-LegacyStack__Item Polaris-LegacyStack__Item--fill">
                      <div>
                        <h2
                          className="Polaris-Text--root Polaris-Text--headingMd"
                          style={{
                            color: "#777980",
                            marginBottom: "8px",
                            fontWeight: "500",
                          }}
                        >
                          Total Watch
                        </h2>
                        <div className="Polaris-LegacyCard__Section">
                          <span
                            className="Polaris-Text--root Polaris-Text--subdued"
                            style={{
                              fontWeight: "600",
                              fontSize: "24px",
                              color: "#000",
                            }}
                          >
                            {Math.floor(data.totalWatchCountInHours * 24)} Hours
                          </span>
                        </div>
                      </div>
                      <div>
                        <img src={totalWatchHours} alt="total watch" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Engagement;

import React, { useState, useEffect } from "react";
import MediaProductListing from "./MediaProductListing";
import { authAxios } from "../config/axios";
import Loader from "./Loader";
import CSLoader from "./CSLoader";
import toast, { Toaster } from "react-hot-toast";
import { WarningIcon } from "../assets/icons";
let timer_id = null;
function ProductComp({
  video_id,
  handleClose,
  // moveToNext,
  // setMoveToNext
}) {
  const [productSelectorPopup, setProductSelectorPopup] = useState(false);
  const [products, setProducts] = useState([]);
  const [selected_prod, setSelected_prod] = useState([]);
  const [shopifyProducts, setShopifyProducts] = useState([]);
  const [pagination, setpagination] = useState(null);
  const [search, setSearch] = useState("");
  const [loaderButton, setLoaderButton] = useState({
    isShow: false,
    isLoading: false,
  });

  const [isSelectedProductLoad, setIsSelectedProductLoad] = useState(true);
  const [isShopifyProductLoad, setIsShopifyProductLoad] = useState(true);
  useEffect(() => {
    fetchSelectedProduct();
    fetchProduct();
  }, []);

  // useEffect(() => {
  //   !!selected_prod.length ? setMoveToNext(true) : setMoveToNext(false);
  // }, [selected_prod]);

  let count = 5;
  const fetchSelectedProduct = async () => {
    // fetch collection api
    // setMoveToNext(false);
    try {
      setIsSelectedProductLoad(true);
      let response = await authAxios(
        "get",
        `/apisv1/authadmin/getselectedproduct?video=${video_id}`
      );
      if (response.status === 200) {
        setProducts(response.data.data.products);
        setSelected_prod(response.data.data.selected_products);
        setIsSelectedProductLoad(false);
        if (response.data.data.products.length >= 1) {
          // setMoveToNext(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProduct = async (str = "") => {
    try {
      setLoaderButton({ ...loaderButton, isLoading: true });
      let response = await authAxios(
        "get",
        `/apisv1/authadmin/getshopifyproduct?count=${count}&search=${str}&cursor=${
          pagination !== null ? pagination.endCursor : null
        }`
      );
      if (response.status === 200) {
        let res_data = response.data.data;
        if (!search != "") {
          setShopifyProducts([...shopifyProducts, ...res_data.products]);
          if (res_data.pagination.hasNextPage === true) {
            setpagination(res_data.pagination);
            setLoaderButton({ isShow: true, isLoading: false });
          } else {
            setLoaderButton({ isShow: false, isLoading: false });
          }
        } else {
          setShopifyProducts(res_data.products ? res_data.products : []);
          setLoaderButton({ isShow: false, isLoading: false });
        }
        setIsShopifyProductLoad(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSearch = async (e) => {
    let search_str = e.target.value;
    setSearch(search_str);
    setpagination(null);
    clearTimeout(timer_id);
    // if(search_str !== ""){
    timer_id = setTimeout(async () => {
      await fetchProduct(e.target.value);
    }, 700);
    // }
  };

  const handleViewMore = () => {
    fetchProduct();
  };
  const handleSave = async (product, flag) => {
    // setMoveToNext(false);

    let split_id = product.node.id.split("/");
    split_id = Number(split_id[4]);
    let selected_product = selected_prod;
    let fianl_prod = products;
    if (flag === "add") {
      if (selected_prod.length === 1) {
        toast("Only one product can be added.", {
          duration: 2000,
          position: "top-center",
          icon: <WarningIcon />,
        });
        return;
      }
      selected_product = [...selected_prod, split_id];
      fianl_prod = [...fianl_prod, product];
    } else {
      selected_product.splice(
        selected_prod.findIndex((item) => item == split_id),
        1
      );
      fianl_prod.splice(
        fianl_prod.findIndex((item) => item.node.id.includes(split_id)),
        1
      );
    }
    try {
      let prepare = { products: selected_product };
      let response = await authAxios(
        "post",
        `/apisv1/authadmin/manageshopifyproduct?video=${video_id}`,
        prepare
      );
      if (response.status === 200) {
        setSelected_prod(selected_product);
        if (selected_product.length >= 1) {
          // setMoveToNext(true);
        }
        setProducts([...fianl_prod]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleProduct = async (product_id, flag) => {
    // fetch collection api
    let split_id = product_id.split("/");
    split_id = Number(split_id[4]);
    if (flag == "add") {
      setSelected_prod([...selected_prod, split_id]);
    } else {
      let selected_prod = selected_prod;
      selected_prod.splice(
        selected_prod.findIndex((item) => item == split_id),
        1
      );
      setSelected_prod([...selected_prod]);
    }
    handleSave();
  };

  const handleSaveAndExit = () => {
    if (!selected_prod.length) {
      toast("Please select one product", {
        duration: 2000,
        position: "top-center",
        icon: <WarningIcon />,
      });
      return;
    } else {
      handleClose();
    }
  };
  return (
    <>
      <Toaster />

      <div className="">
        {/* <p
          className="border-header"
          style={{ fontWeight: "700", margin: "0", padding: "0 0 12px" }}
        >
          Product
        </p> */}
        <div className="Polaris-Connected">
          <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
            <div className="Polaris-TextField">
              <div
                className="Polaris-TextField__Prefix"
                id="PolarisComboboxTextField1-Prefix"
                style={{ marginRight: "0.3rem" }}
              >
                <span className=" ">
                  <span className="Polaris-Text--root Polaris-Text--visuallyHidden"></span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                  >
                    <path
                      d="M7.83333 12.6667C10.7789 12.6667 13.1667 10.2789 13.1667 7.33333C13.1667 4.38781 10.7789 2 7.83333 2C4.88781 2 2.5 4.38781 2.5 7.33333C2.5 10.2789 4.88781 12.6667 7.83333 12.6667Z"
                      stroke="#858D9D"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.4996 13.9996L11.5996 11.0996"
                      stroke="#858D9D"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </div>
              <input
                onChange={(e) => handleSearch(e)}
                id="PolarisComboboxTextField1"
                role="combobox"
                placeholder="Search"
                autoComplete="off"
                className="Polaris-TextField__Input"
                type="text"
                aria-labelledby="PolarisComboboxTextField1Label PolarisComboboxTextField1-Prefix"
                aria-invalid="false"
                aria-autocomplete="list"
                aria-expanded="false"
                defaultValue=""
                tabIndex={0}
                aria-controls="Polarispopover1"
                aria-owns="Polarispopover1"
                style={{ fontSize: "16px" }}
              />
              <div className="Polaris-TextField__Backdrop"></div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="New-video-flx">
          {!isSelectedProductLoad ? (
            products.length ? (
              products.map(
                (item) => (
                  <div
                    key={item.node.id}
                    className="video-list-box-close"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <div className="video-name" style={{ fontSize: "12px" }}>
                      {item.node.title}
                    </div>
                    <div
                      onClick={() => handleSave(item, "remove")}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                      >
                        <path
                          d="M11.0009 3.50087C10.8915 3.39151 10.7431 3.33008 10.5885 3.33008C10.4338 3.33008 10.2854 3.39151 10.176 3.50087L7.50087 6.17604L4.8257 3.50087C4.71631 3.39151 4.56797 3.33008 4.41329 3.33008C4.25861 3.33008 4.11026 3.39151 4.00087 3.50087C3.89151 3.61026 3.83008 3.75861 3.83008 3.91329C3.83008 4.06797 3.89151 4.21631 4.00087 4.3257L6.67604 7.00087L4.00087 9.67604C3.89151 9.78543 3.83008 9.93377 3.83008 10.0885C3.83008 10.2431 3.89151 10.3915 4.00087 10.5009C4.11026 10.6102 4.25861 10.6717 4.41329 10.6717C4.56797 10.6717 4.71631 10.6102 4.8257 10.5009L7.50087 7.8257L10.176 10.5009C10.2854 10.6102 10.4338 10.6717 10.5885 10.6717C10.7431 10.6717 10.8915 10.6102 11.0009 10.5009C11.1102 10.3915 11.1717 10.2431 11.1717 10.0885C11.1717 9.93377 11.1102 9.78543 11.0009 9.67604L8.3257 7.00087L11.0009 4.3257C11.1102 4.21631 11.1717 4.06797 11.1717 3.91329C11.1717 3.75861 11.1102 3.61026 11.0009 3.50087Z"
                          fill="#883DCF"
                        />
                      </svg>
                    </div>
                  </div>
                )
                // )
              )
            ) : (
              <span className="border-header">No products selected</span>
            )
          ) : (
            <CSLoader />
          )}
        </div>
      </div>
      <div
        className="Polaris-Modal__Body Polaris-Scrollable Polaris-Scrollable--vertical Polaris-Scrollable--horizontal"
        data-polaris-scrollable="true"
      >
        <div className="Polaris-Modal-Section">
          <section
            className="Polaris-Box"
            style={{
              "--pc-box-padding-block-end-xs": "var(--p-space-5)",
              "--pc-box-padding-block-start-xs": "var(--p-space-5)",
              " --pc-box-padding-inline-start-xs": "var(--p-space-5)",
              padding: "0px",
            }}
          >
            <div
              className="Tab-media-all-product-1"
              style={{ height: "31vh", marginBottom: "30px" }}
            >
              {!isShopifyProductLoad ? (
                shopifyProducts.length ? (
                  <>
                    {" "}
                    {shopifyProducts.map((item, index) => (
                      <div key={item.node.id} className="video-list-box">
                        <div
                          className="video-name"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "12px",
                          }}
                        >
                          <span
                            className="Polaris-Thumbnail Polaris-Thumbnail--sizeMedium"
                            style={{ marginRight: 10 }}
                          >
                            {item.node.featuredImage &&
                              item.node.featuredImage.url && (
                                <img
                                  crossorigin="anonymous"
                                  alt="Black choker necklace"
                                  src={item.node.featuredImage.url}
                                />
                              )}
                          </span>
                          {item.node.title}
                        </div>
                        {!selected_prod.includes(
                          Number(item.node.id.split("/")[4])
                        ) ? (
                          <div className="">
                            <div className="">
                              <button
                                className="success-btn"
                                type="button"
                                onClick={() => handleSave(item, "add")}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="video-button">
                            <div className="Polaris-ButtonGroup">
                              <div className="Polaris-ButtonGroup__Item">
                                <button
                                  className="cancel-btn"
                                  type="button"
                                  onClick={() => handleSave(item, "remove")}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                    {loaderButton.isShow && (
                      <div
                        style={{
                          width: "100%",
                          margin: "15px 0",
                          textAlign: "center",
                        }}
                      >
                        <button
                          class="Polaris-Button  "
                          aria-disabled="true"
                          type="button"
                          aria-busy="true"
                          tabindex="-1"
                          disabled={loaderButton.isLoading}
                          onClick={() => handleViewMore()}
                        >
                          <span class="Polaris-Button__Content">
                            {loaderButton.isLoading === true ? (
                              <span class="Polaris-Button__Spinner">
                                <span
                                  class={
                                    "Polaris-Spinner Polaris-Spinner--sizeSmall"
                                  }
                                >
                                  <svg
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M7.229 1.173a9.25 9.25 0 1011.655 11.412 1.25 1.25 0 10-2.4-.698 6.75 6.75 0 11-8.506-8.329 1.25 1.25 0 10-.75-2.385z"></path>
                                  </svg>
                                </span>
                              </span>
                            ) : (
                              <span class="Polaris-Button__Text">
                                View More Products
                              </span>
                            )}
                          </span>
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  "No product found in your store"
                )
              ) : (
                <CSLoader />
              )}
            </div>
          </section>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "12px",
          paddingTop: "12px",
          borderTop: " 1px solid #EAEAEA",
        }}
      >
        <button
          className="cancel-btn"
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </button>
        <button className="success-btn" onClick={handleSaveAndExit}>
          Save & Exit
        </button>
      </div>
      {/* <div style={{ textAlign: "end", marginBottom: 20 }}>
        <button
          className="modal-text-btn"
          style={{}}
          onClick={() => handleClose()}
        >
          Save & Exit
        </button>
      </div> */}
      {/* Popup Product list*/}

      {/* end Popup product list*/}
    </>
  );
}

export default ProductComp;

import React, { useContext, useEffect, useState } from "react";
import VideoCard from "./VideoCard";
import { authAxios } from "../../config/axios";
import VideoIDContext from "../../videoContext";
import CommonDelete from "../CommonDelete";
import CommonSuccess from "../CommonSuccess";
import MediaPopup from "../MediaPopup";
import { NoDraftVideos, NoSavedVideos } from "../../assets/icons";

const VideoTabContainer = ({
  selectedTab,
  videoData,
  deletePopUp,
  setDeletePopUp,
  handleDeleteVideo,
  handleEditVideo,
  isNoVideo,
  setIsNoVideo,
}) => {
  // const [deletePopUp, setDeletePopUp] = useState(false);
  const [videoToDelete, setVideoToDelete] = useState({ id: null, title: "" });
  const [deleteSuccessPopUp, setDeleteSuccessPopUp] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isMediaPopupOpen, setIsMediaPopupOpen] = useState(false);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { uploadStatus } = useContext(VideoIDContext);
  const [title, setTitle] = useState("");
  const [listItem, setListItem] = useState([]);
  const [videoInfo, setVideoInfo] = useState({});
  const [videoList, setVideoList] = useState([]);
  const [videoFile, setVideoFile] = useState(null);
  const [filteredCards, setFilteredCards] = useState([]);
  useEffect(() => {
    const sortedList = [...videoData].sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
    setVideoList(sortedList);
  }, [videoData]);
  const cancelUpload = async () => {
    try {
      setIsMediaPopupOpen(false);
      setVideoInfo({});
      fetchMediaList();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const filteredCards = videoList.filter((card) => {
      const tag = card.steps_completed === 5 ? "Saved" : "Draft";
      if (selectedTab === "All") return true;
      return tag === selectedTab;
    });
    setFilteredCards([...filteredCards]);
  }, [videoList, selectedTab]);

  useEffect(() => {
    const sortedList = [...listItem].sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
    setVideoList(sortedList);
  }, [listItem]);
  // const handleDeleteVideo = (_id, video_title) => {
  //   setDeletePopUp(true);
  //   setVideoToDelete({ id: _id, title: video_title });
  // };

  useEffect(() => {
    fetchMediaList();
  }, []);
  const fetchMediaList = async () => {
    // fetch collection api
    try {
      setIsLoading(true);
      let response = await authAxios("get", "/apisv1/authadmin/getvideos");
      if (response.status === 200) {
        let responseData = response.data.data;
        if (uploadStatus) {
          for (const obj of responseData) {
            if (obj._id == uploadStatus._id) {
              // let badgeText = uploadStatus.isPopup ? "popup-" : "cover-";
              // obj.progress = badgeText + uploadStatus.progress;
              obj.progress =
                uploadStatus.progress == "Trimming" ||
                uploadStatus.progress == "Compressing"
                  ? "Processing"
                  : "Done";
            }
          }
        }
        setListItem(responseData);

        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const handleEditVideo = async (video_id, flag) => {
  //   try {
  //     setIsEdit(true);
  //     if (flag === "edit") {
  //       let res = await authAxios(
  //         "get",
  //         `/apisv1/authadmin/getvideos?video=${video_id}`
  //       );
  //       if (res.status === 200) {
  //         setVideoInfo(res.data.data);
  //         setIsMediaPopupOpen(true);
  //       }
  //     } else {
  //       let res = await authAxios(
  //         "get",
  //         `/apisv1/authadmin/delete?video=${video_id}`
  //       );
  //       if (res.status === 200) {
  //         setDeletePopUp(false);
  //         setDeleteSuccessPopUp(true);
  //         fetchMediaList();
  //       }
  //     }
  //   } catch (error) {
  //     setUploadLoader(false);
  //     console.log(error);
  //   }
  // };
  const handleClose = async () => {
    try {
      if (videoInfo.video_title !== title && videoInfo.video_title !== "") {
        let res = await authAxios("post", "/apisv1/authadmin/updatetitle", {
          id: videoInfo.video_id,
          title: videoInfo.video_title,
        });
      }
      setIsMediaPopupOpen(false);
      setVideoInfo({});
      fetchMediaList();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="video-tab-container">
        {filteredCards.length ? (
          filteredCards.map((card) => (
            <VideoCard
              key={card.id}
              videoData={card}
              handleDeleteVideo={handleDeleteVideo}
              handleEditVideo={handleEditVideo}
            />
          ))
        ) : (
          <div style={{ width: "100%", display: "flex" }}>
            {selectedTab === "Saved" && (
              <div
                style={{
                  width: " 100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <NoSavedVideos />
                  <p className="border-header">
                    Complete your draft videos or Add more videos
                  </p>
                </div>
              </div>
            )}
            {selectedTab === "Draft" && (
              <div
                style={{
                  width: " 100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    alignItems: "center",
                    justifyContent: " center",
                  }}
                >
                  <NoDraftVideos />
                  <p className="border-header">
                    Well done! You completed all your videos. Add more video
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {isMediaPopupOpen && (
        <MediaPopup
          videoInfo={videoInfo}
          setVideoInfo={setVideoInfo}
          videoFile={videoFile}
          setTitle={setTitle}
          isEdit={isEdit}
          handleClose={() => handleClose()}
          cancelUpload={() => cancelUpload()}
          fetchMediaLis={() => fetchMediaList()}
        />
      )}
      {/* {deletePopUp && (
        <CommonDelete
          desc={`Do you want to delete "${videoToDelete.title}" ?`}
          setIsShowDeletePopup={setDeletePopUp}
          headlText={"Delete Video"}
          handleDelete={() => handleEditVideo(videoToDelete.id, "del")}
        />
      )}
      {deleteSuccessPopUp && (
        <CommonSuccess
          desc={`"${videoToDelete.title}" deleted successfully.`}
          setIsShowDeletePopup={setDeleteSuccessPopUp}
          headlText={"Delete Video"}
          handleDelete={() => setDeleteSuccessPopUp(false)}
        />
      )} */}
    </div>
  );
};

export default VideoTabContainer;

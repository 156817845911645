import React from "react";

function InputText({ label, events, error }) {
  return (
    <>
      <p className="Polaris-Text--bodyLg" style={{ marginTop: "10px" }}>
        {label}
      </p>
      <div
        className="Polaris-Connected"
        style={{ alignItems: "center", marginTop: "10px" }}
      >
        <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
          <div className="Polaris-TextField Polaris-TextField--hasValue">
            <input
              autoComplete="off"
              className="Polaris-TextField__Input"
              type="text"
              //   defaultValue="Add to cart"
              name={events.name}
              onChange={(e) => events.onChange(e)}
              value={events.value}
            />
            <div className="Polaris-TextField__Backdrop" />
          </div>
        </div>
      </div>
      {error && <div className="VD-error">{error}</div>}
    </>
  );
}

export default InputText;

import React, { useEffect, useState } from "react";
import MediaProductListing from "./MediaProductListing";
import VideoEditor from "./VideoEditor";
import ProductComp from "./ProductComp";
import ImageCroper from "./ImageCroper";
import { useAuth } from "../AuthComp/Auth";
import crossButton from "../assets/img/cross-close-svgrepo-com.svg";
import { Step, Stepper } from "react-form-stepper";
import toast, { Toaster } from "react-hot-toast";
import { tab } from "@testing-library/user-event/dist/tab";

const assetsUrl = "https://assets.vidzy.in";

function MediaPopup({
  handleClose,
  cancelUpload,
  videoInfo,
  setVideoInfo,
  videoFile,
  isEdit,
  fetchMediaList,
}) {
  let auth = useAuth();
  console.log("VIDEO INFO", videoInfo);
  const [tabIndex, setTabIndex] = useState(videoInfo?.steps_completed + 1 || 1);
  const [error, setError] = useState("");
  const [moveToNext, setMoveToNext] = useState(false);
  const [popUpVideoSrc, setPopUpVideoSrc] = useState(
    videoInfo.popup_video_src
      ? `${assetsUrl}/${auth.shopname}/${videoInfo.popup_video_src}`
      : null
  );
  const [cover_video_src, setCover_video_src] = useState(
    videoInfo.cover_video_src
      ? `${assetsUrl}/${auth.shopname}/${videoInfo.cover_video_src}`
      : null
  );
  const [cardImage, setCardImage] = useState(
    videoInfo.card_image ? videoInfo.card_image : null
  );
  const [storyImage, setStoryImage] = useState(
    videoInfo.story_image ? videoInfo.story_image : null
  );
  useEffect(() => {
    if (isEdit) {
      videoInfo.steps_completed < 5
        ? setTabIndex(videoInfo.steps_completed + 1)
        : setTabIndex(5);
    }
  }, [videoInfo]);
  const steps = [
    { label: "Cover Video" },
    { label: "Cover Image" },
    { label: "Popup Video" },
    { label: "Cover Story" },
    { label: "Product" },
  ];

  const showToast = (message) => {
    toast(message, {
      duration: 2000,
      position: "top-center",
      icon: "⚠️",
    });
  };

  const handleSaveAndNext = () => {
    if (moveToNext) {
      if (tabIndex < 5) {
        setTabIndex(tabIndex + 1);
      } else if (!videoInfo.video_title) {
        showToast("Please Add title of the video!");
        return;
      } else {
        handleClose();
      }

      setMoveToNext(false);
    } else {
      const messages = {
        1: "Perform Trim & Save!",
        2: "Perform Crop & Save!",
        3: "Perform Trim & Save!",
        4: "Perform Crop & Save!",
        5: "Please add a product!",
      };

      if (messages[tabIndex]) {
        showToast(messages[tabIndex]);
      }
    }
  };
  return (
    <>
      <Toaster />
      <div data-portal-id="modal-Polarisportal1">
        <div>
          <div
            className="Polaris-Modal-Dialog__Container"
            data-polaris-layer="true"
            data-polaris-overlay="true"
          >
            <div>
              <div
                role="dialog"
                aria-modal="true"
                aria-label="Polarismodal-header1"
                aria-labelledby="Polarismodal-header1"
                tabIndex={-1}
                className="Polaris-Modal-Dialog"
              >
                <div
                  className="Polaris-Modal-Dialog__Modal"
                  style={{
                    // minHeight: "650px",
                    padding: "24px",
                    maxHeight: "unset",
                  }}
                >
                  <div className="Polaris-Box">
                    <div className="Polaris-Columns">
                      <div className="d-flex">
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: 600,
                            paddingBottom: "10px",
                          }}
                          className=" "
                          id="Polarismodal-header1"
                        >
                          {isEdit ? "Edit Video" : "Add Video"}
                        </p>
                        {/* <span
                          style={{
                            fontSize: "18px",
                            fontWeight: 600,
                            paddingBottom: "10px",
                            cursor: "pointer",
                          }}
                          onClick={handleClose}
                          className=" "
                          id="Polarismodal-header1"
                        >
                          <img
                            src={crossButton}
                            alt="cross"
                            width="16px"
                            height="16px"
                          />
                        </span> */}
                      </div>
                    </div>
                  </div>
                  <div
                    className="Polaris-Modal__Body Polaris-Scrollable Polaris-Scrollable--vertical Polaris-Scrollable--horizontal"
                    data-polaris-scrollable="true"
                  >
                    <div className="Polaris-Modal-Section">
                      <section className="Polaris-Box">
                        <div className="">
                          <div className="">
                            <div
                              className="Polaris-Label"
                              style={{ marginBottom: "3px" }}
                            >
                              <label
                                id="PolarisTextField1Label"
                                htmlFor="PolarisTextField1"
                                className="border-header"
                              >
                                Video Title
                              </label>
                            </div>
                          </div>
                          <div className="Polaris-Connected">
                            <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                              <div className="Polaris-TextField Polaris-TextField--hasValue">
                                <input
                                  value={videoInfo.video_title}
                                  onChange={(e) =>
                                    setVideoInfo({
                                      ...videoInfo,
                                      video_title: e.target.value,
                                    })
                                  }
                                  id="PolarisTextField1"
                                  autoComplete="off"
                                  className="Polaris-TextField__Input"
                                  type="text"
                                  aria-labelledby="PolarisTextField1Label"
                                  aria-invalid="false"
                                  defaultValue="Jaded Pixel"
                                  maxLength={30}
                                  style={{ fontSize: "12px" }}
                                />
                                <div className="Polaris-TextField__Backdrop"></div>
                              </div>
                            </div>
                          </div>
                          {error !== "" && (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              {error}
                            </div>
                          )}
                        </div>
                        <div className="stepper-box">
                          <Stepper
                            activeStep={tabIndex - 1}
                            styleConfig={{
                              activeBgColor: "#9B51E0",
                              activeTextColor: "#ffffff",
                              completedBgColor: "#883DCF",
                              completedTextColor: "#ffffff",
                              inactiveBgColor: "#E0E0E7",
                              inactiveTextColor: "#898F8F",
                            }}
                            connectorStateColors={true}
                            connectorStyleConfig={{
                              activeColor: "#9B51E0",
                              completedColor: "#883DCF",
                            }}
                          >
                            {steps.map((step, index) => (
                              <Step
                                key={index}
                                label={step.label}
                                onClick={() => {
                                  setTabIndex(index + 1);
                                }}
                              />
                            ))}
                          </Stepper>
                        </div>

                        <div className="tabs">
                          <div role="tabpanel">
                            {tabIndex === 1 && (
                              <VideoEditor
                                videoTrimmedUrl={cover_video_src}
                                setVideoTrimmedUrl={setCover_video_src}
                                tab={tabIndex}
                                setTabIndex={setTabIndex}
                                videoInfo={videoInfo}
                                videoFile={videoFile}
                                setVideoInfo={setVideoInfo}
                                isEdit={isEdit}
                                // moveToNext={moveToNext}
                                // setMoveToNext={setMoveToNext}
                                handleClose={handleClose}
                              />
                            )}
                            {tabIndex === 2 && (
                              <ImageCroper
                                previewImag={cardImage}
                                setPreviewImag={setCardImage}
                                tab={tabIndex}
                                setTabIndex={setTabIndex}
                                videoInfo={videoInfo}
                                isEdit={isEdit}
                                // moveToNext={moveToNext}
                                // setMoveToNext={setMoveToNext}
                                handleClose={handleClose}
                              />
                            )}
                            {tabIndex === 3 && (
                              <VideoEditor
                                videoTrimmedUrl={popUpVideoSrc}
                                setVideoTrimmedUrl={setPopUpVideoSrc}
                                setTabIndex={setTabIndex}
                                tab={tabIndex}
                                videoInfo={videoInfo}
                                videoFile={videoFile}
                                setVideoInfo={setVideoInfo}
                                isEdit={isEdit}
                                // moveToNext={moveToNext}
                                // setMoveToNext={setMoveToNext}
                                handleClose={handleClose}
                              />
                            )}
                            {tabIndex === 4 && (
                              <ImageCroper
                                previewImag={storyImage}
                                setPreviewImag={setStoryImage}
                                tab={tabIndex}
                                setTabIndex={setTabIndex}
                                videoInfo={videoInfo}
                                isEdit={isEdit}
                                // moveToNext={moveToNext}
                                // setMoveToNext={setMoveToNext}
                                handleClose={handleClose}
                              />
                            )}
                            {tabIndex === 5 && (
                              <ProductComp
                                video_id={videoInfo.video_id}
                                handleClose={handleClose}
                                isEdit={isEdit}
                                // tab={tabIndex}
                                // setTabIndex={setTabIndex}
                                // moveToNext={moveToNext}
                                // setMoveToNext={setMoveToNext}
                              />
                            )}
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "12px",
                      paddingTop: "12px",
                      borderTop: " 1px solid #EAEAEA",
                    }}
                  >
                    <button
                      className="cancel-btn"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </button>
                    <button className="success-btn" onClick={handleSaveAndNext}>
                      Save & Next
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Polaris-Backdrop" />
      </div>
    </>
  );
}

export default MediaPopup;

import React from "react";

function Loader({backdrop = false, style}) {
  return (
    <div className="profile-main-loader" >
      <div className="loader">
        <svg className="circular-loader" viewBox="25 25 50 50" style={style}>
          <circle
            className="loader-path"
            cx={50}
            cy={50}
            r={20}
            fill="none"
            stroke="#7441f6"
            strokeWidth={2}
          />
        </svg>
    
     {backdrop && <div className="Polaris-Backdrop"  style={{backgroundColor: "rgba(0, 0, 0, 0.4)"}}/>}
      </div>
    </div>
  );
}

export default Loader;

import React from "react";

function CSLoader({backdrop = false}) {
  return (
    <div className="profile-main-loader" >
      <div className="loader">
        <svg className="circular-loader-1" viewBox="25 25 50 50">
          <circle
            className="loader-path"
            cx={50}
            cy={50}
            r={20}
            fill="none"
            stroke="#883dcf"
            strokeWidth={2}
          />
        </svg>
    
     {backdrop && <div className="Polaris-Backdrop"  style={{backgroundColor: "rgba(0, 0, 0, 0.4)"}}/>}
      </div>
    </div>
  );
}

export default CSLoader;

import React from "react";
import { CrossSVG } from "../assets/icons";

function TutorialVideo({ isModalOpen, setIsModalOpen }) {
  return (
    <div data-portal-id="modal-Polarisportal1">
      <div
        className="Polaris-Modal-Dialog__Container"
        data-polaris-layer="true"
        data-polaris-overlay="true"
      >
        <div
          role="dialog"
          aria-modal="true"
          aria-label="Polarismodal-header1"
          aria-labelledby="Polarismodal-header1"
          tabIndex={-1}
          className="Polaris-Modal-Dialog"
        >
          <div
            className="Polaris-Modal-Dialog__Modal"
            style={{
              maxWidth: "680px",
              padding: "24px",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              borderRadius: "24px",
            }}
          >
            <div className="modal-header">
              <h1>Tutorial </h1>
              <button onClick={() => setIsModalOpen(!isModalOpen)}>
                <CrossSVG />
              </button>
            </div>
            <div className="tutorial-video">
              <video
                src="/fullTutorial.mp4"
                width="100%"
                height="100%"
                controls
              />
            </div>
          </div>
        </div>
      </div>

      <div className="Polaris-Backdrop" />
    </div>
  );
}

export default TutorialVideo;

import React from "react";

function Table(props) {
  const { data } = props;
  return (
    <>
      <div className="Polaris-IndexTable-ScrollContainer">
        <table className="Polaris-IndexTable__Table Polaris-IndexTable__Table--sticky">
          <thead>
            <tr>
              <th
                className="Polaris-IndexTable__TableHeading"
                data-index-table-heading="true"
                style={{ left: "54px" }}
              >
                <span
                  className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--bold"
                  style={{ color: "#000", fontSize: "14px" }}
                >
                  Name
                </span>
              </th>
              <th
                className="Polaris-IndexTable__TableHeading"
                data-index-table-heading="true"
              >
                <span
                  className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--bold"
                  style={{ color: "#000", fontSize: "14px" }}
                >
                  Impression
                </span>
              </th>
              <th
                className="Polaris-IndexTable__TableHeading"
                data-index-table-heading="true"
              >
                <span
                  className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--bold"
                  style={{ color: "#000", fontSize: "14px" }}
                >
                  Revenue
                </span>
              </th>
              <th
                className="Polaris-IndexTable__TableHeading"
                data-index-table-heading="true"
              >
                <span
                  className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--bold"
                  style={{ color: "#000", fontSize: "14px" }}
                >
                  Add to cart
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((item, index) => (
                <tr key={index} className="Polaris-IndexTable__TableRow">
                  <td
                    className="Polaris-IndexTable__TableCell"
                    style={{ fontSize: "14px" }}
                  >
                    {item.name}
                  </td>
                  <td
                    className="Polaris-IndexTable__TableCell"
                    style={{ fontSize: "14px" }}
                  >
                    {item.impression}
                  </td>
                  <td
                    className="Polaris-IndexTable__TableCell"
                    style={{ fontSize: "14px" }}
                  >
                    ${item.revenue}
                  </td>
                  <td
                    className="Polaris-IndexTable__TableCell"
                    style={{ fontSize: "14px" }}
                  >
                    {item.click}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="4"
                  className="Polaris-IndexTable__TableCell"
                  style={{ fontSize: "14px", textAlign: "center" }}
                >
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Table;

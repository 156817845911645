import React, { useState, useEffect } from "react";
import Select from "react-select";
import { authAxios } from "../config/axios";
import { useNavigate, useParams } from "react-router-dom";

function AddPageComp({ id, page_type, playlist, showToast }) {
  const navigate = useNavigate();
  let { shop } = useParams();
  let playlist_options = playlist.length
    ? playlist.map((item) => ({ value: item._id, label: item.playlist_name }))
    : [];
  // const [cardOptions, setCardOptions] = useState(playlist_options);
  // const [storiesOptions, setStoriesOptions] = useState(playlist_options)
  const [selectedStoryPlaylist, setSelectedStoryPlaylist] = useState([]);
  const [selectedCardPlaylist, setSelectedCardPlaylist] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [deleteData, setDeleteData] = useState({});
  const [error, setError] = useState("");
  useEffect(() => {
    fetchPlaylists();
  }, []);
  const fetchPlaylists = async () => {
    try {
      let response = await authAxios(
        "get",
        `/apisv1/authadmin/getsavedplaylist?pagetype=${page_type}&id=${id}`
      );
      if (response.status === 200) {
        let cards = response.data.data.cards.map((item) => ({
          value: item._id,
          label: item.playlist_name,
        }));
        let stories = response.data.data.stories.map((item) => ({
          value: item._id,
          label: item.playlist_name,
        }));
        if (cards.length || stories.length) setIsDisabled(false);
        setSelectedCardPlaylist(cards);
        setSelectedStoryPlaylist(stories);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#F9F9FC",
      borderRadius: "8px",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? data.color
            : isFocused
              ? "#F4ECFB"
              : undefined,

        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : "#F4ECFB"
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "#F4ECFB",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#883DCF",
      fontSize: "12px",
      fontWeight: "600",
      lineHeight: "18px",
      letterSpacing: "0.06px",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "#883DCF",
      ":hover": {
        backgroundColor: data.color,
        color: "#883DCF",
        cursor: "pointer",
      },
    }),
  };

  const savePlaylist = async (playlist, type) => {
    const valueArray = playlist.map((item) => item.value);
    let response = await authAxios(
      "post",
      `/apisv1/authadmin/update_page?pagetype=${page_type}&list_type=${type}`,
      {
        page_id: id,
        playlist_ids: valueArray,
      }
    );
    if (response.status === 200) {
      return true;
    }
  };

  const handleSave = async () => {
    if (isDisabled) return;
    const res = await Promise.all([
      savePlaylist(selectedCardPlaylist, "card"),
      savePlaylist(selectedStoryPlaylist, "story"),
    ]);

    if (res.every((result) => result === true)) {
      showToast("Saved successfully");
    }
  };
  const customNoOptionsMessage = ({ inputValue }) => {
    return inputValue ? (
      "No results found"
    ) : (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          alignItems: "center",
          justifyContent: "center",
          padding: "12px",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="101"
          height="92"
          viewBox="0 0 101 92"
          fill="none"
        >
          <g clip-path="url(#clip0_375_19547)">
            <path
              d="M65.1677 21.011H64.6819V7.70256C64.6819 6.69105 64.4827 5.68944 64.0956 4.75492C63.7085 3.82041 63.1411 2.97128 62.4259 2.25603C61.7106 1.54078 60.8615 0.973416 59.927 0.586325C58.9925 0.199235 57.9909 6.38182e-07 56.9794 0H28.7836C27.7721 -2.39308e-06 26.7705 0.199228 25.836 0.586316C24.9015 0.973404 24.0523 1.54077 23.3371 2.25602C22.6218 2.97126 22.0545 3.82038 21.6674 4.7549C21.2803 5.68942 21.0811 6.69103 21.0811 7.70254V80.7137C21.0811 82.7565 21.8926 84.7157 23.3371 86.1602C24.7816 87.6047 26.7408 88.4162 28.7836 88.4162H56.9793C59.0222 88.4162 60.9813 87.6047 62.4258 86.1602C63.8704 84.7157 64.6819 82.7565 64.6819 80.7137V30.4842H65.1677V21.011Z"
              fill="#E6E6E6"
            />
            <path
              d="M57.2902 2.00391H53.6098C53.779 2.41887 53.8436 2.86909 53.7979 3.3149C53.7522 3.76071 53.5975 4.18843 53.3475 4.56039C53.0976 4.93234 52.7599 5.23712 52.3644 5.44786C51.9689 5.65861 51.5277 5.76885 51.0795 5.76888H34.9265C34.4784 5.76885 34.0371 5.65861 33.6416 5.44786C33.2461 5.23712 32.9085 4.93235 32.6585 4.56039C32.4086 4.18843 32.2539 3.76071 32.2082 3.31489C32.1624 2.86908 32.227 2.41887 32.3963 2.00391H28.9587C27.4332 2.00391 25.9701 2.60994 24.8913 3.68868C23.8126 4.76742 23.2065 6.23051 23.2065 7.75608V80.6601C23.2065 81.4155 23.3553 82.1634 23.6444 82.8613C23.9335 83.5592 24.3572 84.1933 24.8913 84.7275C25.4255 85.2616 26.0596 85.6853 26.7575 85.9744C27.4553 86.2635 28.2033 86.4122 28.9587 86.4122H57.2902C58.0456 86.4122 58.7936 86.2635 59.4915 85.9744C60.1894 85.6853 60.8235 85.2616 61.3576 84.7275C61.8918 84.1933 62.3155 83.5592 62.6046 82.8613C62.8936 82.1634 63.0424 81.4155 63.0424 80.6601V7.75609C63.0424 7.0007 62.8936 6.25271 62.6046 5.55482C62.3155 4.85694 61.8918 4.22282 61.3576 3.68868C60.8235 3.15454 60.1894 2.73084 59.4915 2.44177C58.7936 2.15269 58.0456 2.00391 57.2902 2.00391Z"
              fill="white"
            />
            <path
              d="M37.6906 91.3421H37.3262L38.0551 42.6255H38.4196L37.6906 91.3421Z"
              fill="#3F3D56"
            />
            <path
              d="M47.653 91.3421H47.2885L46.5596 42.6255H46.924L47.653 91.3421Z"
              fill="#3F3D56"
            />
            <path
              d="M46.6809 43.5977H38.1768V43.9621H46.6809V43.5977Z"
              fill="#3F3D56"
            />
            <path
              d="M46.6809 49.915H38.1768V50.2795H46.6809V49.915Z"
              fill="#3F3D56"
            />
            <path
              d="M46.9238 56.2324H38.0552V56.5969H46.9238V56.2324Z"
              fill="#3F3D56"
            />
            <path
              d="M47.0453 62.5498H38.0552V62.9143H47.0453V62.5498Z"
              fill="#3F3D56"
            />
            <path
              d="M47.1667 68.8672H37.9336V69.2317H47.1667V68.8672Z"
              fill="#3F3D56"
            />
            <path
              d="M47.1666 75.1846H37.812V75.549H47.1666V75.1846Z"
              fill="#3F3D56"
            />
            <path
              d="M47.2885 81.502H37.6909V81.8664H47.2885V81.502Z"
              fill="#3F3D56"
            />
            <path
              d="M47.4098 87.8193H37.4478V88.1838H47.4098V87.8193Z"
              fill="#3F3D56"
            />
            <path
              d="M42.0239 66.3603C44.8428 66.3603 47.1279 64.0752 47.1279 61.2563C47.1279 58.4375 44.8428 56.1523 42.0239 56.1523C39.2051 56.1523 36.9199 58.4375 36.9199 61.2563C36.9199 64.0752 39.2051 66.3603 42.0239 66.3603Z"
              fill="#2F2E41"
            />
            <path
              d="M45.0263 64.7843L43.5005 65.0605L43.9954 67.7942L45.5211 67.518L45.0263 64.7843Z"
              fill="#2F2E41"
            />
            <path
              d="M41.9745 65.3366L40.4487 65.6128L40.9436 68.3465L42.4694 68.0703L41.9745 65.3366Z"
              fill="#2F2E41"
            />
            <path
              d="M41.7535 68.9211C42.2981 68.4599 42.5993 67.9204 42.4264 67.7162C42.2534 67.512 41.6717 67.7203 41.1272 68.1815C40.5826 68.6427 40.2813 69.1822 40.4543 69.3864C40.6272 69.5906 41.2089 69.3823 41.7535 68.9211Z"
              fill="#2F2E41"
            />
            <path
              d="M44.7418 68.38C45.2863 67.9188 45.5876 67.3794 45.4147 67.1752C45.2417 66.971 44.66 67.1793 44.1154 67.6405C43.5709 68.1017 43.2696 68.6411 43.4426 68.8454C43.6155 69.0496 44.1972 68.8412 44.7418 68.38Z"
              fill="#2F2E41"
            />
            <path
              d="M41.6668 61.7525C42.6302 61.7525 43.4112 60.9715 43.4112 60.0081C43.4112 59.0447 42.6302 58.2637 41.6668 58.2637C40.7034 58.2637 39.9224 59.0447 39.9224 60.0081C39.9224 60.9715 40.7034 61.7525 41.6668 61.7525Z"
              fill="white"
            />
            <path
              d="M40.9496 59.9642C41.2708 59.9642 41.5311 59.7039 41.5311 59.3827C41.5311 59.0616 41.2708 58.8013 40.9496 58.8013C40.6285 58.8013 40.3682 59.0616 40.3682 59.3827C40.3682 59.7039 40.6285 59.9642 40.9496 59.9642Z"
              fill="#3F3D56"
            />
            <path
              d="M46.0504 55.7038C46.1271 53.8142 44.4986 52.2137 42.4129 52.129C40.3273 52.0443 38.5743 53.5074 38.4975 55.397C38.4208 57.2865 39.8706 57.7159 41.9562 57.8006C44.0419 57.8853 45.9736 57.5933 46.0504 55.7038Z"
              fill="#883DCF"
            />
            <path
              d="M39.7985 60.8966C39.8973 60.4653 38.8638 59.8604 37.49 59.5455C36.1162 59.2306 34.9224 59.3249 34.8235 59.7562C34.7247 60.1875 35.7582 60.7924 37.132 61.1073C38.5058 61.4222 39.6996 61.3278 39.7985 60.8966Z"
              fill="#2F2E41"
            />
            <path
              d="M49.8175 62.5626C49.9164 62.1313 48.8828 61.5264 47.509 61.2115C46.1352 60.8966 44.9414 60.9909 44.8426 61.4222C44.7437 61.8535 45.7773 62.4584 47.1511 62.7733C48.5249 63.0882 49.7187 62.9938 49.8175 62.5626Z"
              fill="#2F2E41"
            />
            <path
              d="M43.4255 63.2675C43.453 63.4178 43.4505 63.572 43.4184 63.7214C43.3862 63.8708 43.3249 64.0123 43.238 64.138C43.1511 64.2637 43.0403 64.371 42.9119 64.4538C42.7836 64.5366 42.6401 64.5934 42.4898 64.6207C42.3395 64.6481 42.1852 64.6456 42.0359 64.6133C41.8865 64.581 41.745 64.5197 41.6194 64.4327C41.4938 64.3457 41.3866 64.2348 41.3038 64.1063C41.2211 63.9779 41.1645 63.8344 41.1372 63.6841L41.1368 63.6818C41.0224 63.0498 41.511 62.8262 42.143 62.7118C42.775 62.5973 43.3111 62.6355 43.4255 63.2675Z"
              fill="white"
            />
            <path
              d="M59.6227 31.0085L26.9519 33.9366C26.7915 33.9508 26.632 33.9008 26.5084 33.7975C26.3848 33.6943 26.3072 33.5462 26.2927 33.3858L25.5663 25.2816C25.5521 25.1212 25.6022 24.9617 25.7054 24.8381C25.8087 24.7145 25.9567 24.6369 26.1171 24.6223L58.788 21.6943C58.9484 21.6801 59.1079 21.7301 59.2315 21.8333C59.355 21.9366 59.4326 22.0847 59.4472 22.2451L60.1735 30.3493C60.1877 30.5097 60.1377 30.6692 60.0344 30.7928C59.9312 30.9164 59.7831 30.994 59.6227 31.0085ZM26.1388 24.8643C26.0426 24.8731 25.9537 24.9196 25.8918 24.9938C25.8298 25.0679 25.7998 25.1636 25.8083 25.2599L26.5347 33.3641C26.5434 33.4604 26.5899 33.5492 26.6641 33.6112C26.7383 33.6731 26.834 33.7031 26.9302 33.6946L59.601 30.7665C59.6973 30.7578 59.7861 30.7112 59.8481 30.6371C59.91 30.5629 59.94 30.4672 59.9315 30.371L59.2052 22.2667C59.1965 22.1705 59.1499 22.0817 59.0758 22.0197C59.0016 21.9578 58.9059 21.9277 58.8096 21.9363L26.1388 24.8643Z"
              fill="#E6E6E6"
            />
            <path
              d="M30.7695 31.4511C32.1785 31.4511 33.3208 30.3089 33.3208 28.8999C33.3208 27.4909 32.1785 26.3486 30.7695 26.3486C29.3605 26.3486 28.2183 27.4909 28.2183 28.8999C28.2183 30.3089 29.3605 31.4511 30.7695 31.4511Z"
              fill="#3F3D56"
            />
            <path
              d="M36.4864 26.6797C36.4308 26.6847 36.3767 26.7006 36.3272 26.7265C36.2777 26.7524 36.2338 26.7878 36.198 26.8306C36.1622 26.8735 36.1352 26.923 36.1185 26.9763C36.1019 27.0295 36.0958 27.0856 36.1008 27.1412C36.1058 27.1968 36.1217 27.2509 36.1476 27.3004C36.1735 27.3499 36.2088 27.3938 36.2517 27.4296C36.2946 27.4654 36.344 27.4924 36.3973 27.5091C36.4506 27.5257 36.5067 27.5318 36.5623 27.5268L56.5278 25.7374C56.5834 25.7324 56.6375 25.7165 56.6869 25.6906C56.7364 25.6647 56.7803 25.6294 56.8161 25.5865C56.8519 25.5437 56.8789 25.4942 56.8956 25.4409C56.9123 25.3876 56.9183 25.3315 56.9133 25.2759C56.9083 25.2203 56.8924 25.1662 56.8666 25.1167C56.8407 25.0673 56.8053 25.0234 56.7624 24.9876C56.7196 24.9518 56.6701 24.9248 56.6168 24.9081C56.5635 24.8914 56.5075 24.8854 56.4518 24.8904L36.4864 26.6797Z"
              fill="#E6E6E6"
            />
            <path
              d="M36.7141 29.2212C36.6583 29.226 36.6041 29.2417 36.5544 29.2675C36.5047 29.2934 36.4606 29.3287 36.4246 29.3716C36.3887 29.4145 36.3615 29.4641 36.3447 29.5175C36.328 29.5709 36.3219 29.6271 36.3269 29.6828C36.3319 29.7386 36.3478 29.7928 36.3739 29.8424C36.3999 29.892 36.4354 29.9359 36.4784 29.9717C36.5215 30.0075 36.5711 30.0345 36.6246 30.051C36.6781 30.0676 36.7343 30.0734 36.79 30.0682L56.7555 28.2789C56.8677 28.2687 56.9713 28.2144 57.0436 28.1278C57.1158 28.0413 57.1507 27.9297 57.1406 27.8174C57.1305 27.7052 57.0763 27.6015 56.9899 27.5292C56.9035 27.4569 56.7918 27.4219 56.6796 27.4318L36.7141 29.2212Z"
              fill="#E6E6E6"
            />
            <path
              d="M47.6983 32.9118C48.2141 31.5622 48.2888 30.3369 47.8652 30.1749C47.4415 30.013 46.6798 30.9758 46.164 32.3254C45.6482 33.675 45.5734 34.9003 45.9971 35.0623C46.4208 35.2242 47.1824 34.2614 47.6983 32.9118Z"
              fill="#2F2E41"
            />
            <path
              d="M47.2596 37.4081C47.7225 34.5558 45.7854 31.8683 42.9331 31.4054C40.0808 30.9425 37.3933 32.8796 36.9304 35.7319C36.4675 38.5842 38.4046 41.2717 41.2569 41.7346C44.1092 42.1975 46.7967 40.2604 47.2596 37.4081Z"
              fill="#2F2E41"
            />
            <path
              d="M41.3 40.6758H39.7104V43.5237H41.3V40.6758Z"
              fill="#2F2E41"
            />
            <path
              d="M44.4792 40.6758H42.8896V43.5237H44.4792V40.6758Z"
              fill="#2F2E41"
            />
            <path
              d="M41.035 44.0535C41.7666 44.0535 42.3596 43.8311 42.3596 43.5568C42.3596 43.2825 41.7666 43.0601 41.035 43.0601C40.3035 43.0601 39.7104 43.2825 39.7104 43.5568C39.7104 43.8311 40.3035 44.0535 41.035 44.0535Z"
              fill="#2F2E41"
            />
            <path
              d="M44.2142 43.9876C44.9458 43.9876 45.5388 43.7652 45.5388 43.4909C45.5388 43.2165 44.9458 42.9941 44.2142 42.9941C43.4827 42.9941 42.8896 43.2165 42.8896 43.4909C42.8896 43.7652 43.4827 43.9876 44.2142 43.9876Z"
              fill="#2F2E41"
            />
            <path
              d="M37.0197 31.7041C36.5973 29.812 37.9477 27.9002 40.0361 27.4339C42.1245 26.9676 44.1599 28.1234 44.5824 30.0154C45.0049 31.9074 43.6208 32.6052 41.5325 33.0715C39.4441 33.5378 37.4422 33.5961 37.0197 31.7041Z"
              fill="#883DCF"
            />
            <path
              d="M38.5652 35.2633C38.7595 34.8535 37.8589 34.0193 36.5534 33.4002C35.248 32.781 34.0321 32.6113 33.8378 33.0211C33.6434 33.431 34.5441 34.2651 35.8495 34.8842C37.155 35.5034 38.3708 35.6731 38.5652 35.2633Z"
              fill="#2F2E41"
            />
            <path
              d="M10.5715 89.6206C13.4612 89.6206 15.8037 87.278 15.8037 84.3884C15.8037 81.4988 13.4612 79.1562 10.5715 79.1562C7.68187 79.1562 5.33936 81.4988 5.33936 84.3884C5.33936 87.278 7.68187 89.6206 10.5715 89.6206Z"
              fill="#2F2E41"
            />
            <path
              d="M9.77653 88.4946H8.18701V91.3425H9.77653V88.4946Z"
              fill="#2F2E41"
            />
            <path
              d="M12.9557 88.4946H11.3662V91.3425H12.9557V88.4946Z"
              fill="#2F2E41"
            />
            <path
              d="M9.51161 91.8724C10.2432 91.8724 10.8362 91.65 10.8362 91.3756C10.8362 91.1013 10.2432 90.8789 9.51161 90.8789C8.78005 90.8789 8.18701 91.1013 8.18701 91.3756C8.18701 91.65 8.78005 91.8724 9.51161 91.8724Z"
              fill="#2F2E41"
            />
            <path
              d="M12.6908 91.806C13.4224 91.806 14.0154 91.5836 14.0154 91.3092C14.0154 91.0349 13.4224 90.8125 12.6908 90.8125C11.9593 90.8125 11.3662 91.0349 11.3662 91.3092C11.3662 91.5836 11.9593 91.806 12.6908 91.806Z"
              fill="#2F2E41"
            />
            <path
              d="M10.7037 84.8518C11.6913 84.8518 12.4919 84.0512 12.4919 83.0636C12.4919 82.076 11.6913 81.2754 10.7037 81.2754C9.71613 81.2754 8.91553 82.076 8.91553 83.0636C8.91553 84.0512 9.71613 84.8518 10.7037 84.8518Z"
              fill="white"
            />
            <path
              d="M10.704 83.6599C11.0332 83.6599 11.3001 83.393 11.3001 83.0638C11.3001 82.7346 11.0332 82.4678 10.704 82.4678C10.3748 82.4678 10.1079 82.7346 10.1079 83.0638C10.1079 83.393 10.3748 83.6599 10.704 83.6599Z"
              fill="#3F3D56"
            />
            <path
              d="M5.4963 79.5224C5.07382 77.6304 6.4243 75.7186 8.51268 75.2523C10.6011 74.786 12.6365 75.9417 13.059 77.8338C13.4814 79.7258 12.0974 80.4236 10.009 80.8899C7.92065 81.3562 5.91877 81.4145 5.4963 79.5224Z"
              fill="#E6E6E6"
            />
            <path
              d="M14.3863 65.6965C14.9021 64.3469 14.9768 63.1215 14.5531 62.9596C14.1295 62.7977 13.3678 63.7604 12.852 65.11C12.3361 66.4596 12.2614 67.685 12.6851 67.8469C13.1088 68.0089 13.8704 67.0461 14.3863 65.6965Z"
              fill="#2F2E41"
            />
            <path
              d="M13.9481 70.1923C14.4109 67.3399 12.4739 64.6524 9.62156 64.1896C6.76923 63.7267 4.08173 65.6637 3.61886 68.5161C3.15599 71.3684 5.09303 74.0559 7.94536 74.5188C10.7977 74.9816 13.4852 73.0446 13.9481 70.1923Z"
              fill="#2F2E41"
            />
            <path
              d="M7.98844 73.4604H6.39893V76.3083H7.98844V73.4604Z"
              fill="#2F2E41"
            />
            <path
              d="M11.1676 73.4604H9.57812V76.3083H11.1676V73.4604Z"
              fill="#2F2E41"
            />
            <path
              d="M7.72353 76.8382C8.45508 76.8382 9.04812 76.6158 9.04812 76.3415C9.04812 76.0671 8.45508 75.8447 7.72353 75.8447C6.99197 75.8447 6.39893 76.0671 6.39893 76.3415C6.39893 76.6158 6.99197 76.8382 7.72353 76.8382Z"
              fill="#2F2E41"
            />
            <path
              d="M10.9027 76.7718C11.6343 76.7718 12.2273 76.5494 12.2273 76.275C12.2273 76.0007 11.6343 75.7783 10.9027 75.7783C10.1712 75.7783 9.57812 76.0007 9.57812 76.275C9.57812 76.5494 10.1712 76.7718 10.9027 76.7718Z"
              fill="#2F2E41"
            />
            <path
              d="M8.91565 69.8176C9.90325 69.8176 10.7039 69.017 10.7039 68.0294C10.7039 67.0418 9.90325 66.2412 8.91565 66.2412C7.92805 66.2412 7.12744 67.0418 7.12744 68.0294C7.12744 69.017 7.92805 69.8176 8.91565 69.8176Z"
              fill="white"
            />
            <path
              d="M8.91589 68.6257C9.2451 68.6257 9.51196 68.3589 9.51196 68.0297C9.51196 67.7005 9.2451 67.4336 8.91589 67.4336C8.58669 67.4336 8.31982 67.7005 8.31982 68.0297C8.31982 68.3589 8.58669 68.6257 8.91589 68.6257Z"
              fill="#3F3D56"
            />
            <path
              d="M3.70772 64.4882C3.28525 62.5962 4.63573 60.6844 6.7241 60.2181C8.81247 59.7518 10.8479 60.9075 11.2704 62.7996C11.6928 64.6916 10.3088 65.3894 8.22044 65.8557C6.13207 66.322 4.13019 66.3803 3.70772 64.4882Z"
              fill="#883DCF"
            />
            <path
              d="M5.25365 68.0475C5.44802 67.6376 4.54732 66.8035 3.24189 66.1844C1.93645 65.5652 0.720623 65.3955 0.526253 65.8053C0.331884 66.2151 1.23258 67.0493 2.53801 67.6684C3.84345 68.2876 5.05928 68.4573 5.25365 68.0475Z"
              fill="#2F2E41"
            />
            <path
              d="M6.21338 71.2418C6.21338 71.7539 7.53192 72.7651 8.99503 72.7651C10.4581 72.7651 11.83 71.3234 11.83 70.8113C11.83 70.2993 10.4581 70.9107 8.99503 70.9107C7.53193 70.9107 6.21338 70.7298 6.21338 71.2418Z"
              fill="white"
            />
            <path
              d="M31.2788 69.4768C31.7947 68.1272 31.8694 66.9018 31.4457 66.7399C31.022 66.5779 30.2604 67.5407 29.7446 68.8903C29.2287 70.2399 29.154 71.4653 29.5777 71.6272C30.0014 71.7891 30.763 70.8263 31.2788 69.4768Z"
              fill="#2F2E41"
            />
            <path
              d="M31.9339 75.0663C32.3968 72.214 30.4597 69.5265 27.6074 69.0636C24.7551 68.6007 22.0676 70.5378 21.6047 73.3901C21.1418 76.2424 23.0789 78.9299 25.9312 79.3928C28.7835 79.8557 31.471 77.9186 31.9339 75.0663Z"
              fill="#2F2E41"
            />
            <path
              d="M24.3848 78.3345H25.9742L25.6632 81.7449L24.3848 81.1823V78.3345Z"
              fill="#2F2E41"
            />
            <path
              d="M25.7289 82.747C25.9706 82.6172 25.886 81.9895 25.54 81.345C25.1939 80.7005 24.7174 80.2832 24.4757 80.413C24.234 80.5427 24.3186 81.1704 24.6647 81.815C25.0107 82.4595 25.4872 82.8768 25.7289 82.747Z"
              fill="#2F2E41"
            />
            <path
              d="M27.8501 78.3433H29.4396L29.1286 81.7538L27.8501 81.1912V78.3433Z"
              fill="#2F2E41"
            />
            <path
              d="M29.1938 82.7558C29.4355 82.626 29.3509 81.9983 29.0048 81.3538C28.6587 80.7093 28.1823 80.292 27.9406 80.4218C27.6989 80.5515 27.7835 81.1792 28.1295 81.8238C28.4756 82.4683 28.9521 82.8856 29.1938 82.7558Z"
              fill="#2F2E41"
            />
            <path
              d="M26.9015 74.6917C27.8891 74.6917 28.6897 73.891 28.6897 72.9034C28.6897 71.9158 27.8891 71.1152 26.9015 71.1152C25.9139 71.1152 25.1133 71.9158 25.1133 72.9034C25.1133 73.891 25.9139 74.6917 26.9015 74.6917Z"
              fill="white"
            />
            <path
              d="M26.9017 73.4998C27.2309 73.4998 27.4978 73.2329 27.4978 72.9037C27.4978 72.5745 27.2309 72.3076 26.9017 72.3076C26.5725 72.3076 26.3057 72.5745 26.3057 72.9037C26.3057 73.2329 26.5725 73.4998 26.9017 73.4998Z"
              fill="#3F3D56"
            />
            <path
              d="M21.6941 69.3623C21.2716 67.4702 22.6221 65.5584 24.7104 65.0921C26.7988 64.6258 28.8342 65.7816 29.2567 67.6736C29.6792 69.5656 28.2951 70.2634 26.2068 70.7297C24.1184 71.1961 22.1165 71.2543 21.6941 69.3623Z"
              fill="#F2F2F2"
            />
            <path
              d="M22.795 72.6899C23.1599 72.4204 22.7598 71.2599 21.9014 70.0977C21.043 68.9355 20.0513 68.2118 19.6865 68.4813C19.3216 68.7508 19.7218 69.9114 20.5802 71.0735C21.4386 72.2357 22.4302 72.9594 22.795 72.6899Z"
              fill="#2F2E41"
            />
            <path
              d="M29.3219 76.9259C29.3219 76.5038 28.2352 75.6704 27.0294 75.6704C25.8235 75.6704 24.6929 76.8586 24.6929 77.2807C24.6929 77.7027 25.8235 77.1988 27.0294 77.1988C28.2352 77.1988 29.3219 77.3479 29.3219 76.9259Z"
              fill="white"
            />
            <path
              d="M23.8412 69.8478C22.3253 69.8484 20.8243 69.5503 19.4237 68.9706C18.0232 68.3908 16.7506 67.5407 15.6788 66.4688C14.6069 65.397 13.7568 64.1245 13.177 62.7239C12.5973 61.3234 12.2992 59.8223 12.2998 58.3065C12.2998 58.2823 12.2999 58.2569 12.3012 58.2328C12.3366 51.9123 17.5135 46.7651 23.8412 46.7651C26.4448 46.7655 28.9718 47.6461 31.0118 49.2638C33.0518 50.8815 34.485 53.1414 35.0787 55.6764C35.6723 58.2114 35.3915 60.8727 34.2818 63.228C33.1722 65.5833 31.2989 67.4943 28.9661 68.6506C27.3742 69.4428 25.6193 69.8527 23.8412 69.8478ZM23.8412 47.0081C20.8582 47.0147 17.9984 48.1982 15.883 50.3013C13.7677 52.4045 12.5678 55.2575 12.544 58.2403C12.5427 58.2662 12.5428 58.2869 12.5428 58.3065C12.542 61.1717 13.6299 63.9302 15.5862 66.0237C17.5424 68.1171 20.221 69.3892 23.0798 69.5822C25.9385 69.7753 28.7638 68.8751 30.9839 67.0638C33.2039 65.2524 34.6528 62.6652 35.0374 59.8259C35.2546 58.2284 35.1273 56.6029 34.6641 55.0586C34.2009 53.5144 33.4124 52.0872 32.3518 50.8729C31.2912 49.6587 29.983 48.6856 28.515 48.0189C27.047 47.3523 25.4534 47.0077 23.8412 47.0081Z"
              fill="#3F3D56"
            />
            <path
              d="M29.0676 58.8653L21.1682 54.3045C21.1312 54.2832 21.0893 54.272 21.0467 54.272C21.004 54.272 20.9621 54.2832 20.9252 54.3045C20.8883 54.3259 20.8576 54.3565 20.8363 54.3935C20.8149 54.4304 20.8037 54.4723 20.8037 54.5149V63.6364C20.8037 63.6791 20.8149 63.721 20.8363 63.7579C20.8576 63.7949 20.8883 63.8255 20.9252 63.8469C20.9621 63.8682 21.004 63.8794 21.0467 63.8794C21.0893 63.8794 21.1312 63.8682 21.1682 63.8469L29.0676 59.2861C29.1045 59.2648 29.1352 59.2341 29.1565 59.1972C29.1779 59.1602 29.1891 59.1183 29.1891 59.0757C29.1891 59.033 29.1779 58.9911 29.1565 58.9542C29.1352 58.9173 29.1045 58.8866 29.0676 58.8653L21.1682 54.3045C21.1312 54.2832 21.0893 54.272 21.0467 54.272C21.004 54.272 20.9621 54.2832 20.9252 54.3045C20.8883 54.3259 20.8576 54.3565 20.8363 54.3935C20.8149 54.4304 20.8037 54.4723 20.8037 54.5149V63.6364C20.8037 63.6791 20.8149 63.721 20.8363 63.7579C20.8576 63.7949 20.8883 63.8255 20.9252 63.8469C20.9621 63.8682 21.004 63.8794 21.0467 63.8794C21.0893 63.8794 21.1312 63.8682 21.1682 63.8469L29.0676 59.2861C29.1045 59.2648 29.1352 59.2341 29.1565 59.1972C29.1779 59.1602 29.1891 59.1183 29.1891 59.0757C29.1891 59.033 29.1779 58.9911 29.1565 58.9542C29.1352 58.9173 29.1045 58.8866 29.0676 58.8653Z"
              fill="#883DCF"
            />
            <path
              d="M28.0932 91.0992C31.6492 91.0992 34.532 90.9904 34.532 90.8563C34.532 90.7221 31.6492 90.6133 28.0932 90.6133C24.5371 90.6133 21.6543 90.7221 21.6543 90.8563C21.6543 90.9904 24.5371 91.0992 28.0932 91.0992Z"
              fill="#E6E6E6"
            />
            <path
              d="M94.0609 88.7911C97.617 88.7911 100.5 88.6823 100.5 88.5482C100.5 88.414 97.617 88.3052 94.0609 88.3052C90.5048 88.3052 87.6221 88.414 87.6221 88.5482C87.6221 88.6823 90.5048 88.7911 94.0609 88.7911Z"
              fill="#E6E6E6"
            />
            <path
              d="M94.0303 88.3437L65.1568 72.7783C65.0152 72.7017 64.9097 72.572 64.8635 72.4178C64.8173 72.2635 64.8341 72.0972 64.9104 71.9553L68.7715 64.7931C68.8481 64.6514 68.9777 64.5459 69.132 64.4997C69.2863 64.4535 69.4526 64.4704 69.5944 64.5466L98.4679 80.112C98.6096 80.1886 98.7151 80.3182 98.7613 80.4725C98.8075 80.6268 98.7906 80.7931 98.7143 80.935L94.8532 88.0972C94.7766 88.2389 94.647 88.3444 94.4927 88.3906C94.3384 88.4368 94.1721 88.4199 94.0303 88.3437ZM69.4791 64.7605C69.394 64.7147 69.2942 64.7046 69.2017 64.7323C69.1091 64.76 69.0313 64.8234 68.9854 64.9084L65.1243 72.0706C65.0785 72.1557 65.0684 72.2555 65.0961 72.3481C65.1238 72.4406 65.1871 72.5184 65.2721 72.5644L94.1456 88.1298C94.2307 88.1756 94.3305 88.1857 94.423 88.158C94.5156 88.1302 94.5934 88.0669 94.6393 87.9819L98.5004 80.8197C98.5462 80.7345 98.5563 80.6348 98.5286 80.5422C98.5009 80.4496 98.4376 80.3718 98.3526 80.3259L69.4791 64.7605Z"
              fill="#E6E6E6"
            />
            <path
              d="M71.1186 73.2314C72.5276 73.2314 73.6699 72.0892 73.6699 70.6802C73.6699 69.2711 72.5276 68.1289 71.1186 68.1289C69.7096 68.1289 68.5674 69.2711 68.5674 70.6802C68.5674 72.0892 69.7096 73.2314 71.1186 73.2314Z"
              fill="#883DCF"
            />
            <path
              d="M77.112 71.9787C77.0628 71.9522 77.009 71.9356 76.9534 71.9299C76.8978 71.9243 76.8417 71.9296 76.7882 71.9456C76.7347 71.9616 76.6848 71.988 76.6415 72.0233C76.5982 72.0585 76.5623 72.102 76.5358 72.1512C76.5093 72.2003 76.4927 72.2542 76.4871 72.3098C76.4814 72.3654 76.4867 72.4215 76.5028 72.475C76.5188 72.5285 76.5452 72.5783 76.5805 72.6216C76.6158 72.6649 76.6593 72.7008 76.7085 72.7273L94.3533 82.2395C94.4025 82.266 94.4564 82.2826 94.5119 82.2883C94.5675 82.294 94.6236 82.2887 94.6771 82.2726C94.7306 82.2566 94.7805 82.2302 94.8238 82.195C94.8671 82.1597 94.903 82.1162 94.9295 82.0671C94.956 82.0179 94.9726 81.964 94.9782 81.9084C94.9839 81.8529 94.9786 81.7967 94.9625 81.7432C94.9465 81.6897 94.9201 81.6399 94.8848 81.5966C94.8495 81.5533 94.806 81.5174 94.7569 81.4909L77.112 71.9787Z"
              fill="#E6E6E6"
            />
            <path
              d="M75.9014 74.2248C75.8021 74.1712 75.6857 74.1594 75.5776 74.1917C75.4696 74.2241 75.3789 74.298 75.3253 74.3973C75.2718 74.4965 75.2599 74.613 75.2923 74.721C75.3246 74.8291 75.3986 74.9198 75.4978 74.9733L93.1427 84.4855C93.1918 84.512 93.2457 84.5285 93.3013 84.5342C93.3568 84.5399 93.413 84.5346 93.4664 84.5185C93.5199 84.5025 93.5698 84.4761 93.613 84.4409C93.6563 84.4056 93.6923 84.3621 93.7188 84.313C93.7453 84.2638 93.7618 84.2099 93.7675 84.1544C93.7731 84.0988 93.7678 84.0427 93.7518 83.9892C93.7358 83.9357 93.7094 83.8859 93.6741 83.8426C93.6388 83.7993 93.5954 83.7634 93.5462 83.7369L75.9014 74.2248Z"
              fill="#E6E6E6"
            />
          </g>
          <defs>
            <clipPath id="clip0_375_19547">
              <rect
                width="100"
                height="91.8723"
                fill="white"
                transform="translate(0.5)"
              />
            </clipPath>
          </defs>
        </svg>
        <span>
          Create your video playlists first.
          <span
            onClick={() => navigate(`/${shop}/playlist`)}
            style={{ color: "#883DCF", textDecoration: "underline" }}
          >
            {" "}
            Go to Playlist
          </span>
        </span>
      </div>
    );
  };

  return (
    <div
      key={id}
      style={{ padding: "0 24px 24px" }}
      className="transitionEffect"
    >
      <div className="page-box">
        {/* STORY */}
        <div className="story-box">
          <div className="VD-story-main">
            <div className="VD-name-story">Story</div>
            <div className="VD-story-add">
              <div className="search-bar">
                <Select
                  onChange={(e) => {
                    setSelectedStoryPlaylist(e);
                    setIsDisabled(false);
                  }}
                  isClearable={true}
                  options={playlist_options}
                  noOptionsMessage={customNoOptionsMessage}
                  isSearchable={true}
                  value={selectedStoryPlaylist}
                  placeholder={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        fontSize: "12px",
                      }}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
                          stroke="#858D9D"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M14.0001 14.0001L11.1001 11.1001"
                          stroke="#858D9D"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <span>Search & Select</span>
                    </div>
                  }
                  name="playlist"
                  isMulti
                  styles={{
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      display: "none",
                    }),
                    indicatorSeparator: (provided) => ({
                      ...provided,
                      display: "none",
                    }),
                    ...colourStyles,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* CARD */}
        <div>
          <div className="VD-story-main">
            <div className="VD-name-story">Card</div>
            <div className="VD-story-add">
              <div className="search-bar">
                <Select
                  onChange={(e) => {
                    setSelectedCardPlaylist(e);
                    setIsDisabled(false);
                  }}
                  isClearable={true}
                  options={playlist_options}
                  noOptionsMessage={customNoOptionsMessage}
                  isSearchable={true}
                  placeholder={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        fontSize: "12px",
                      }}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
                          stroke="#858D9D"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M14.0001 14.0001L11.1001 11.1001"
                          stroke="#858D9D"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <span>Search & Select</span>
                    </div>
                  }
                  value={selectedCardPlaylist}
                  name="playlist"
                  isMulti
                  styles={{
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      display: "none",
                    }),
                    indicatorSeparator: (provided) => ({
                      ...provided,
                      display: "none",
                    }),
                    ...colourStyles,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pages-save">
          <button
            className="success-btn"
            style={{
              background: `${isDisabled ? "grey" : ""}`,
              fontSize: "14px",
            }}
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddPageComp;

import React, { useState, useEffect } from "react";
import MainPagesComp from "../component/MainPagesComp";
import { authAxios } from "../config/axios";
import toast, { Toaster } from "react-hot-toast";

function Pages() {
  const [tab, setTab] = useState("page");
  const [playlist, setPlaylist] = useState([]);
  useEffect(() => {
    fetchPlaylist();
  }, []);

  const fetchPlaylist = async () => {
    // fetch collection api
    try {
      let response = await authAxios("get", "/apisv1/authadmin/getplaylist");
      if (response.status === 200) {
        setPlaylist(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const showToast = (message) => {
    toast(message, {
      duration: 2000,
      position: "top-center",
      type: "success",
    });
  };
  return (
    <main
      className="Polaris-Frame__Main"
      id="AppFrameMain"
      data-has-global-ribbon="false"
    >
      <Toaster />
      <div
        className="Polaris-Frame__Content transitionEffect"
        style={{ minHeight: "calc(100vh - 56px)", overflowY: "auto" }}
      >
        <div className="Polaris-Page Polaris-Page--fullWidth">
          <div className="Polaris-Page--divider">
            <div>
              <div className="Polaris-CalloutCard__Container">
                <div
                  className="Polaris-LegacyCard__Section"
                  style={{ padding: 0 }}
                >
                  <div className="wrapper">
                    <div className="tabs">
                      <div
                        role="tablist"
                        aria-label="Programming Languages"
                        className="tab-list"
                      >
                        <button
                          className="tab-list-button"
                          role="tab"
                          onClick={() => setTab("page")}
                          aria-selected={`${tab === "page" ? true : false}`}
                        >
                          Other page
                        </button>
                        <button
                          className="tab-list-button"
                          role="tab"
                          onClick={() => setTab("collection")}
                          aria-selected={`${
                            tab === "collection" ? true : false
                          }`}
                        >
                          Collection page
                        </button>
                        <button
                          className="tab-list-button"
                          role="tab"
                          onClick={() => setTab("product")}
                          aria-selected={`${tab === "product" ? true : false}`}
                        >
                          Product page
                        </button>
                      </div>
                      <div role="tabpanel">
                        {tab === "page" && (
                          <MainPagesComp
                            playlist={playlist}
                            page_type={tab}
                            showToast={showToast}
                          />
                        )}
                      </div>
                      <div role="tabpanel">
                        {tab === "collection" && (
                          <MainPagesComp
                            playlist={playlist}
                            page_type={tab}
                            showToast={showToast}
                          />
                        )}
                      </div>
                      <div role="tabpanel">
                        {tab === "product" && (
                          <MainPagesComp
                            playlist={playlist}
                            page_type={tab}
                            showToast={showToast}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Pages;

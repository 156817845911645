import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../assets/img/Logo.svg";
import video from "../assets/img/video.svg";
import upload from "../assets/img/upload.svg";
import settings from "../assets/img/settings.svg";
import help from "../assets/img/help.svg";
import analytics from "../assets/img/analytics.svg";
import dashboard from "../assets/img/grey-dashboard.svg";
import pages from "../assets/img/pages.svg";
import playlist from "../assets/img/playlist.svg";
import customize from "../assets/img/customization.svg";
import { useAuth } from "../AuthComp/Auth";
function NavbarWithTitle(props) {
  let auth = useAuth();
  const navigate = useNavigate();
  let currentUrl = window.location.pathname;
  let { shop } = useParams();

  const handleNav = (nav) => {
    navigate(`/${shop}/${nav}`);
  };

  function extractShopName(domain) {
    const parts = domain.split(".");
    return parts[0];
  }

  const redirectToAdminCustomise = () => {
    let deepLink = `https://admin.shopify.com/store/${extractShopName(
      shop
    )}/themes/current/editor?context=apps`;
    window.open(deepLink, "_blank");
  };

  const redirectToDemoVideo = () => {
    let deepLink = `https://youtu.be/rKrHKQocqHE?si=Hn6jWapiQJkXP4zB`;
    window.open(deepLink, "_blank");
  };

  return (
    <div style={{ height: 500 }}>
      <div
        className="Polaris-Frame Polaris-Frame--hasNav Polaris-Frame--hasTopBar"
        data-polaris-layer="true"
        data-has-navigation="true"
      >
        <div className="Polaris-Frame__Skip">
          <a href="#AppFrameMain">Skip to content</a>
        </div>
        <div
          className="Polaris-Frame__TopBar"
          data-polaris-layer="true"
          data-polaris-top-bar="true"
          id="AppFrameTopBar"
          style={{ zIndex: `${auth.loader ? "unset" : ""}` }}
        >
          <div className="Polaris-TopBar">
            <button
              type="button"
              className="Polaris-TopBar__NavigationIcon"
              aria-label="Toggle menu"
            >
              <div className="Polaris-TopBar__IconWrapper">
                <span className="Polaris-Icon">
                  <span className="Polaris-Text--root Polaris-Text--visuallyHidden"></span>
                  <svg
                    viewBox="0 0 20 20"
                    className="Polaris-Icon__Svg"
                    focusable="false"
                    aria-hidden="true"
                  >
                    <path d="M19 11h-18a1 1 0 0 1 0-2h18a1 1 0 1 1 0 2zm0-7h-18a1 1 0 0 1 0-2h18a1 1 0 1 1 0 2zm0 14h-18a1 1 0 0 1 0-2h18a1 1 0 0 1 0 2z"></path>
                  </svg>
                </span>
              </div>
            </button>
            <div
              className="Polaris-TopBar__LogoContainer Polaris-TopBar__LogoDisplayControl"
              style={{ borderBottom: "1px solid #eaeaea" }}
            >
              <a
                className="Polaris-TopBar__LogoLink"
                // style={{ width: 124 }}
                // href="#"
                data-polaris-unstyled="true"
              >
                <img
                  style={{ width: "133px", height: "33px" }}
                  alt="Vidzy"
                  src={logo}
                  className="Polaris-TopBar__Logo"
                  // style={{ width: 124 }}
                />
              </a>
            </div>
            <div className="Polaris-TopBar__Contents">
              <div className="Polaris-TopBar__SecondaryMenu">
                <button
                  className="Polaris-Button Polaris-Button--unfilled"
                  type="button"
                  style={{ padding: "0 10px 0 8px", margin: "0 10px 0 0" }}
                  onClick={() => redirectToAdminCustomise()}
                >
                  <span className="Polaris-Button__Content">
                    <span
                      className="Polaris-Button__Text_Neutral"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {/* <svg
                        viewBox="0 0 20 20"
                        width={20}
                        height={20}
                        class="Icon_Icon__Dm3QW"
                        style={{ marginRight: 10 }}
                        fill="none"
                      >
                        <path
                          fill="#7441F6"
                          d="M4.63 8.81a5.5 5.5 0 0 1 6.56-4.18.75.75 0 0 0 .325-1.464 7 7 0 1 0 5.32 8.35.75.75 0 0 0-1.465-.325 5.5 5.5 0 1 1-10.74-2.38Z"
                        ></path>
                        <path
                          fill="#7441F6"
                          d="M16.03 6.78a.75.75 0 0 0-1.06-1.06l-4.97 4.97-2.22-2.22a.75.75 0 0 0-1.06 1.06l2.75 2.75a.75.75 0 0 0 1.06 0l5.5-5.5Z"
                        ></path>
                      </svg> */}
                      Enable Vidzy
                    </span>
                  </span>
                </button>
                {/* <button
                  className="Polaris-Button Polaris-Button_background"
                  type="button"
                  style={{ padding: "0 10px 0 8px" }}
                  onClick={() => redirectToDemoVideo()}
                >
                  <span className="Polaris-Button__Content">
                    <span
                      className="Polaris-Button__Text"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        style={{ marginRight: 10 }}
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M16 10.0004C16 10.3524 15.816 10.6774 15.515 10.8574L5.515 16.8574C5.356 16.9524 5.178 17.0004 5 17.0004C4.83 17.0004 4.66 16.9574 4.507 16.8704C4.194 16.6924 4 16.3604 4 16.0004V4.0004C4 3.6404 4.194 3.3084 4.507 3.1304C4.82 2.9514 5.206 2.9574 5.515 3.1434L15.515 9.1434C15.816 9.3234 16 9.6484 16 10.0004Z"
                          fill="#7441F6"
                        />
                      </svg>{" "}
                      Tutorial Video
                    </span>
                  </span>
                </button> */}
              </div>

              {/* <div className="Polaris-TopBar__SecondaryMenu">
            <div>
              <div className="Polaris-TopBar-Menu__ActivatorWrapper">
                <button
                  className="Polaris-Button Polaris-Button--primary"
                  type="button"
                  style={{ marginRight: 10 }}
                >
                  <span className="Polaris-Button__Content">
                    <span className="Polaris-Button__Text">Upgrade</span>
                  </span>
                </button>
              </div>
            </div>
          </div> */}
              {/* <div className="Polaris-TopBar__SecondaryMenu">
            <div>
              <div className="Polaris-TopBar-Menu__ActivatorWrapper">
                <button
                  type="button"
                  className="Polaris-TopBar-Menu__Activator"
                  tabIndex={0}
                  aria-controls="Polarispopover1"
                  aria-owns="Polarispopover1"
                  aria-expanded="false"
                >
                  <span>
                    <span className="Polaris-Icon">
                      <span className="Polaris-Text--root Polaris-Text--visuallyHidden"></span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        className="Polaris-Icon__Svg"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path
                          d="M10 0a1 1 0 011 1v2.032l-.001.021-.002.03A6.002 6.002 0 0116 9c0 3.093.625 4.312 1.599 6.21l.034.068c.17.33-.07.722-.442.722H2.809a.496.496 0 01-.442-.722l.034-.068C3.375 13.312 4 12.093 4 9a6.002 6.002 0 015.003-5.918l-.002-.04A.835.835 0 019 3V1a1 1 0 011-1zM12 18a2 2 0 01-4 0h4z"
                        
                        />
                      </svg>
                    </span>
                    <span className="Polaris-Text--root Polaris-Text--visuallyHidden">
                      Secondary menu
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </div> */}
              {/* <div>
            <div className="Polaris-TopBar-Menu__ActivatorWrapper">
              <button
                type="button"
                className="Polaris-TopBar-Menu__Activator"
                tabIndex={0}
                aria-controls="Polarispopover1"
                aria-owns="Polarispopover1"
                aria-expanded="false"
              >
                <div className="Polaris-MessageIndicator__MessageIndicatorWrapper">
                  <span
                    aria-label="Avatar with initials D"
                    role="img"
                    className="Polaris-Avatar Polaris-Avatar--sizeSmall Polaris-Avatar--shapeRound Polaris-Avatar--styleFour"
                  >
                    <span className="Polaris-Avatar__Initials">
                      <svg className="Polaris-Avatar__Svg" viewBox="0 0 40 40">
                        <text
                          className="Polaris-Avatar__Text"
                          x="50%"
                          y="50%"
                          dy="0.35em"
                          fill="currentColor"
                          textAnchor="middle"
                        >
                          D
                        </text>
                      </svg>
                    </span>
                  </span>
                </div>
                <span className="Polaris-TopBar-UserMenu__Details">
                  <p className="Polaris-Text--root Polaris-Text--medium Polaris-Text--block Polaris-Text--start Polaris-Text--truncate">
                    Dharma
                  </p>
                </span>
              </button>
            </div>
          </div> */}
            </div>
          </div>
        </div>
        <div>
          <div
            aria-label="Navigation"
            className="Polaris-Frame__Navigation"
            id="AppFrameNav"
            style={{ zIndex: `${auth.loader ? "unset" : ""}` }}
          >
            <nav className="Polaris-Navigation">
              <div
                className="Polaris-Navigation__PrimaryNavigation Polaris-Scrollable Polaris-Scrollable--vertical Polaris-Scrollable--horizontal"
                data-polaris-scrollable="true"
                style={{
                  overflowX: "hidden",
                  justifyContent: "space-between",
                  padding: "0",
                }}
              >
                <ul className="Polaris-Navigation__Section">
                  <li
                    className="Polaris-Navigation__ListItem"
                    onClick={() => handleNav("dashboard")}
                  >
                    <div className="Polaris-Navigation__ItemWrapper">
                      <div
                        className={`Polaris-Navigation__ItemInnerWrapper ${
                          currentUrl.includes("dashboard") == true
                            ? "Polaris-Navigation__ItemInnerWrapper--selected"
                            : ""
                        }`}
                      >
                        <a
                          className={`Polaris-Navigation__Item ${
                            currentUrl.includes("dashboard") == true
                              ? "Polaris-Navigation__Item--selected Polaris-Navigation--subNavigationActive"
                              : ""
                          }`}
                          tabIndex={0}
                          href="#"
                          data-polaris-unstyled="true"
                        >
                          <div className="Polaris-Navigation__Icon">
                            <img src={dashboard} alt="dashboard" />
                          </div>
                          <span className="Polaris-Navigation__Text">
                            Dashboard
                          </span>
                        </a>
                      </div>
                    </div>
                  </li>
                  <li
                    className="Polaris-Navigation__ListItem"
                    onClick={() => handleNav("media")}
                  >
                    <div className="Polaris-Navigation__ItemWrapper">
                      <div
                        className={`Polaris-Navigation__ItemInnerWrapper ${
                          currentUrl.includes("media")
                            ? "Polaris-Navigation__ItemInnerWrapper--selected"
                            : ""
                        }`}
                      >
                        <a
                          className={`Polaris-Navigation__Item ${
                            currentUrl.includes("media")
                              ? "Polaris-Navigation__Item--selected Polaris-Navigation--subNavigationActive"
                              : ""
                          }`}
                          tabIndex={0}
                          // href="#"
                          data-polaris-unstyled="true"
                        >
                          <div className="Polaris-Navigation__Icon">
                            <img src={video} alt="dashboard" />
                          </div>
                          <span className="Polaris-Navigation__Text">
                            Video Collection
                          </span>
                        </a>
                      </div>
                    </div>
                  </li>
                  <li
                    className="Polaris-Navigation__ListItem"
                    onClick={() => handleNav("playlist")}
                  >
                    <div className="Polaris-Navigation__ItemWrapper">
                      <div
                        className={`Polaris-Navigation__ItemInnerWrapper ${
                          currentUrl.includes("playlist") == true &&
                          "Polaris-Navigation__ItemInnerWrapper--selected"
                        }`}
                      >
                        <a
                          className={`Polaris-Navigation__Item ${
                            currentUrl.includes("playlist") == true
                              ? "Polaris-Navigation__Item--selected Polaris-Navigation--subNavigationActive"
                              : ""
                          }`}
                          tabIndex={0}
                          // href="#"
                          data-polaris-unstyled="true"
                        >
                          <div className="Polaris-Navigation__Icon">
                            <img src={playlist} alt="dashboard" />
                          </div>
                          <span className="Polaris-Navigation__Text">
                            Playlist
                          </span>
                        </a>
                      </div>
                    </div>
                  </li>
                  <li
                    className="Polaris-Navigation__ListItem"
                    onClick={() => handleNav("pages")}
                  >
                    <div className="Polaris-Navigation__ItemWrapper">
                      <div
                        className={`Polaris-Navigation__ItemInnerWrapper ${
                          currentUrl.includes("pages") == true
                            ? "Polaris-Navigation__ItemInnerWrapper--selected"
                            : ""
                        }`}
                      >
                        <a
                          className={`Polaris-Navigation__Item ${
                            currentUrl.includes("pages") == true
                              ? "Polaris-Navigation__Item--selected Polaris-Navigation--subNavigationActive"
                              : ""
                          }`}
                          tabIndex={0}
                          // href="#"
                          data-polaris-unstyled="true"
                        >
                          <div className="Polaris-Navigation__Icon">
                            <img src={pages} alt="pages" />
                          </div>
                          <span className="Polaris-Navigation__Text">
                            Pages
                          </span>
                        </a>
                      </div>
                    </div>
                  </li>
                  <li
                    className="Polaris-Navigation__ListItem"
                    onClick={() => handleNav("customise")}
                  >
                    <div className="Polaris-Navigation__ItemWrapper">
                      <div
                        className={`Polaris-Navigation__ItemInnerWrapper ${
                          currentUrl.includes("customise") == true
                            ? "Polaris-Navigation__ItemInnerWrapper--selected"
                            : ""
                        }`}
                      >
                        <a
                          className={`Polaris-Navigation__Item ${
                            currentUrl.includes("customise") == true
                              ? "Polaris-Navigation__Item--selected Polaris-Navigation--subNavigationActive"
                              : ""
                          }`}
                          tabIndex={0}
                          // href="#"
                          data-polaris-unstyled="true"
                        >
                          <div className="Polaris-Navigation__Icon">
                            <img src={customize} alt="dashboard" />
                          </div>
                          <span className="Polaris-Navigation__Text">
                            Customise
                          </span>
                        </a>
                      </div>
                    </div>
                  </li>
                  <li
                    className="Polaris-Navigation__ListItem"
                    onClick={() => handleNav("analytics")}
                  >
                    <div className="Polaris-Navigation__ItemWrapper">
                      <div
                        className={`Polaris-Navigation__ItemInnerWrapper ${
                          currentUrl.includes("analytics") == true
                            ? "Polaris-Navigation__ItemInnerWrapper--selected"
                            : ""
                        }`}
                      >
                        <a
                          className={`Polaris-Navigation__Item ${
                            currentUrl.includes("analytics") == true
                              ? "Polaris-Navigation__Item--selected Polaris-Navigation--subNavigationActive"
                              : ""
                          }`}
                          tabIndex={0}
                          // href="#"
                          data-polaris-unstyled="true"
                        >
                          <div className="Polaris-Navigation__Icon">
                            <img src={analytics} alt="dashboard" />
                          </div>
                          <span className="Polaris-Navigation__Text">
                            Analytics
                          </span>
                        </a>
                      </div>
                    </div>
                  </li>
                  <li
                    className="Polaris-Navigation__ListItem"
                    onClick={() => handleNav("settings")}
                  >
                    <div className="Polaris-Navigation__ItemWrapper">
                      <div
                        className={`Polaris-Navigation__ItemInnerWrapper ${
                          currentUrl.includes("settings") == true
                            ? "Polaris-Navigation__ItemInnerWrapper--selected"
                            : ""
                        }`}
                      >
                        <a
                          className={`Polaris-Navigation__Item ${
                            currentUrl.includes("settings") == true
                              ? "Polaris-Navigation__Item--selected Polaris-Navigation--subNavigationActive"
                              : ""
                          }`}
                          tabIndex={0}
                          // href="#"
                          data-polaris-unstyled="true"
                        >
                          <div className="Polaris-Navigation__Icon">
                            <img src={settings} alt="settings" />
                          </div>
                          <span className="Polaris-Navigation__Text">
                            Settings
                          </span>
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
                <li
                  className="Polaris-Navigation__ListItem"
                  onClick={() => handleNav("help-and-support")}
                >
                  <div className="Polaris-Navigation__ItemWrapper">
                    <div
                      className={`Polaris-Navigation__ItemInnerWrapper ${
                        currentUrl.includes("help-and-support") == true
                          ? "Polaris-Navigation__ItemInnerWrapper--selected"
                          : ""
                      }`}
                    >
                      <a
                        className={`Polaris-Navigation__Item ${
                          currentUrl.includes("help-and-support") == true
                            ? "Polaris-Navigation__Item--selected Polaris-Navigation--subNavigationActive"
                            : ""
                        }`}
                        tabIndex={0}
                        // href="#"
                        data-polaris-unstyled="true"
                      >
                        <div className="Polaris-Navigation__Icon">
                          <img src={help} alt="help and support" />
                        </div>
                        <span className="Polaris-Navigation__Text">
                          Help & Support
                        </span>
                      </a>
                    </div>
                  </div>
                </li>
              </div>
            </nav>
            <button
              type="button"
              className="Polaris-Frame__NavigationDismiss"
              aria-hidden="true"
              aria-label="Close navigation"
              tabIndex={-1}
            >
              <span className="Polaris-Icon">
                <span className="Polaris-Text--root Polaris-Text--visuallyHidden"></span>
                <svg
                  viewBox="0 0 20 20"
                  className="Polaris-Icon__Svg"
                  focusable="false"
                  aria-hidden="true"
                >
                  <path d="m11.414 10 6.293-6.293a1 1 0 1 0-1.414-1.414l-6.293 6.293-6.293-6.293a1 1 0 0 0-1.414 1.414l6.293 6.293-6.293 6.293a1 1 0 1 0 1.414 1.414l6.293-6.293 6.293 6.293a.998.998 0 0 0 1.707-.707.999.999 0 0 0-.293-.707l-6.293-6.293z"></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div className="Polaris-Frame__ContextualSaveBar Polaris-Frame-CSSAnimation--startFade"></div>
        {/* this is window  */}
        {props.children}
      </div>
    </div>
  );
}

export default NavbarWithTitle;

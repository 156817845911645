import React from "react";

function Plan(props) {
  const { name, price, view, features, selectPlan, className, userData } =
    props;

  const isActive = userData.plan_name === name;

  const splitPrice = price.split("/");
  const currencyAmount = splitPrice[0].trim();
  const period = splitPrice[1]?.trim() || "";

  const convertToTitleCase = (value) => {
    return value?.charAt(0).toUpperCase() + value?.slice(1).toLowerCase();
  };
  return (
    <div className={`plan ${isActive ? "plan-active" : ""} ${className}`}>
      {className === "popular" && (
        <div className="recommended">
          <span>Recommended</span>
        </div>
      )}
      <div
        className={`price ${className === "popular" ? "popular-price" : ""}`}
      >
        {currencyAmount}
        <span>/{period}</span>
      </div>
      <h2 className={`${className === "popular" ? "popular-title" : ""}`}>
        {" "}
        {convertToTitleCase(name)}
      </h2>
      <div
        className={`pric-view ${className === "popular" ? "popular-pric-view" : ""}`}
      >
        {view}
      </div>
      <div className="features">
        {features.map((feature, index) => (
          <div
            key={index}
            className={`li ${className === "popular" ? "popular-li" : ""}`}
          >
            {className === "popular" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.9497 6.7935C13.3403 6.40297 13.3403 5.76981 12.9497 5.37928C12.5592 4.98876 11.9261 4.98876 11.5355 5.37928L6.58579 10.329L4.46447 8.20771C4.07394 7.81719 3.44078 7.81719 3.05025 8.20771C2.65973 8.59824 2.65973 9.2314 3.05025 9.62192L5.87868 12.4504C6.26921 12.8409 6.90237 12.8409 7.29289 12.4504L12.9497 6.7935Z"
                  fill="#883DCF"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z"
                  fill="#883DCF"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.9497 6.7935C13.3403 6.40297 13.3403 5.76981 12.9497 5.37928C12.5592 4.98876 11.9261 4.98876 11.5355 5.37928L6.58579 10.329L4.46447 8.20771C4.07394 7.81719 3.44078 7.81719 3.05025 8.20771C2.65973 8.59824 2.65973 9.2314 3.05025 9.62192L5.87868 12.4504C6.26921 12.8409 6.90237 12.8409 7.29289 12.4504L12.9497 6.7935Z"
                  fill="white"
                />
              </svg>
            )}
            <div>{feature}</div>
          </div>
        ))}
      </div>
      {isActive ? (
        <div className="active-plan">Active Plan</div>
      ) : (
        <button
          onClick={() => selectPlan(name)}
          className={`${className === "popular" ? "popular-btn" : ""}`}
        >
          Upgrade Plan
        </button>
      )}
      {/* <button onClick={() => selectPlan(name)}>Select Plan</button> */}
    </div>
  );
}

export default Plan;

import React, { useState } from "react";
import sample_img_one from "../../assets/img/1.png";
import sample_img_two from "../../assets/img/2.png";
import { useFormik } from "formik";
import * as yup from "yup";
import InputCheckBox from "../../component/InputCheckBox";
import InputRange from "../../component/InputRange";
import InputColorSelector from "../../component/InputColorSelector";
import { authAxios } from "../../config/axios";

const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

function StoryCircleTab({ allSettings, setAllSettings, showToast }) {
  let { story_cust_set } = allSettings;
  const [formData, setformData] = useState(story_cust_set);
  const [fieldErrors, setFieldErrors] = useState({});
  const [isBtnDis, setIsBtnDis] = useState(true);

  const handleChange = (e, colour = null) => {
    setIsBtnDis(false);
    if (e.target.type === "checkbox") {
      setformData({ ...formData, [e.target.name]: e.target.checked });
    } else {
      setformData({ ...formData, [e.target.name]: e.target.value });
    }
    if (colour) {
      let validation_checked = hexColorRegex.test(e.target.value);
      if (e.target.value === "") {
        setFieldErrors({
          ...fieldErrors,
          [e.target.name]: "Please enter colour",
        });
      } else if (!validation_checked) {
        setFieldErrors({
          ...fieldErrors,
          [e.target.name]: "You have not entered proper hex value",
        });
      } else {
        setformData({ ...formData, [e.target.name]: e.target.value });
        if (fieldErrors[e.target.name]) {
          let f_fieldErrors = fieldErrors;
          delete f_fieldErrors[e.target.name];
          setFieldErrors(f_fieldErrors);
        }
      }
    }
  };

  const handleSave = async () => {
    showToast("Saved Successfully");
    try {
      if (!Object.values(fieldErrors).length) {
        if (JSON.stringify(formData) !== JSON.stringify(story_cust_set)) {
          let res = await authAxios("post", "/apisv1/authadmin/customise", {
            story_cust_set: formData,
          });
          if (res.status === 200) {
            setAllSettings({ ...allSettings, story_cust_set: formData });
          }
        }
        setIsBtnDis(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="Polaris-flex" style={{ gap: "24px" }}>
      <div
        className="left-card"
        style={{
          // borderRight: "var(--p-border-divider)",
          maxWidth: "316px",
          minWidth: "316px",
          width: "100%",
        }}
      >
        <div
          className=""
          style={{
            borderTop: 0,
            padding: "var(--p-space-4)",
            borderRadius: "var(--p-border-radius-00)",
          }}
        >
          <h1 className="story-circle-header">Story circle customize</h1>
        </div>
        {/* For Checkbox */}
        <div
          className="Polaris-LegacyCard__Section"
          style={{
            borderTop: 0,
            // padding: "0 var(--p-space-4) var(--p-space-4)",
            // borderBottom: "0.5px solid #858d9d",
          }}
        >
          <div
            className="checkbox-input"
            style={{
              padding: "0 1rem 1rem",
              borderBottom: "0.0625rem solid #eaeaea",
            }}
          >
              <InputCheckBox
              label="Center Align Story"
              key={"Center Align Story"}
              events={{
                onChange: (e) => handleChange(e),
                checked: formData.center_story,
                name: "center_story",
              }}
            />
            <InputCheckBox
              label="Border"
              key={"Border"}
              events={{
                onChange: (e) => handleChange(e),
                checked: formData.sc_border,
                name: "sc_border",
              }}
            />
            <InputCheckBox
              label="Border Dot"
              key={"Border_Dot"}
              events={{
                onChange: (e) => handleChange(e),
                checked: formData.sc_border_dot,
                name: "sc_border_dot",
              }}
            />
            <div className="">
              <InputCheckBox
                label="Story text"
                key={"Story_text"}
                events={{
                  onChange: (e) => handleChange(e),
                  checked: formData.sc_text,
                  name: "sc_text",
                }}
              />
            </div>
          </div>
          <div style={{ padding: "0 1rem" }}>
            <p className="border-header" style={{ marginTop: "12px" }}>
              Border Color
            </p>
            <InputColorSelector
              label=""
              events={{
                onChange: (e) => handleChange(e, true),
                value: formData.sc_border_clr,
                name: "sc_border_clr",
              }}
              error={fieldErrors["sc_border_clr"]}
            />
          </div>
          <div style={{ padding: "0 1rem 1rem" }}>
            <p className="border-header" style={{ marginTop: "12px" }}>
              Border Size
            </p>
            <InputRange
              label=""
              events={{
                onChange: (e) => handleChange(e),
                value: formData.sc_border_size,
                name: "sc_border_size",
                min: 0,
                max: 3,
              }}
            />
          </div>
        </div>
        {/* Save Button */}
        <div className="Polaris-PageActions " style={{ padding: "0" }}>
          <div className="Polaris-LegacyStack Polaris-LegacyStack--spacingTight Polaris-LegacyStack--distributionTrailing">
            <div className="Polaris-LegacyStack__Item">
              <button
                className="Polaris-Button Polaris-Button--primary"
                type="button"
                // disabled={isBtnDis}
                onClick={() => handleSave()}
              >
                <span className="Polaris-Button__Content">
                  <span className="Polaris-Button__Text">Save Changes</span>
                </span>
              </button>
            </div>
          </div>
        </div>
        {/* End Save Button */}
      </div>
      {/* Right Side Video Section */}
      <div
        className="right-card"
        style={{
          width: "100%",
          position: "sticky",
          top: "60px",
          height: "fit-content",
        }}
      >
        <div className="right-card-title">Story View</div>
        <div style={{ padding: "40px 20px" }}>
          <div className="VD-home-slide-inner">
            {Array(8)
              .fill(0)
              .map((item, index) => (
                <div className="VD-home-box">
                  <div
                    className="VD-home-box-img"
                    style={
                      formData.sc_border
                        ? {
                            border: `${formData.sc_border_size}px ${
                              formData.sc_border_dot ? "dotted" : "solid"
                            } ${formData.sc_border_clr}`,
                          }
                        : null
                    }
                  >
                    <img
                      src={index % 2 == 0 ? sample_img_one : sample_img_two}
                      alt=""
                    />
                  </div>
                  {formData.sc_text && (
                    <div className="VD-home-box-title">Little Feet</div>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
      {/* End Right Side Video Section */}
    </div>
  );
}

export default StoryCircleTab;

import React, { createContext, useState } from "react";

const VideoIDContext = createContext();

export const VideoIDProvider = ({ children }) => {
  const [videoID, setVideoID] = useState(null);
  const [isPopup, setIsPopup] = useState(true);
  const [isUpload, setIsUpload] = useState(false);
  const [videoDetail, setVideoDetail] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);

  return (
    <VideoIDContext.Provider
      value={{
        videoID,
        setVideoID,
        isPopup,
        setIsPopup,
        isUpload,
        setIsUpload,
        videoDetail,
        setVideoDetail,
        uploadStatus,
        setUploadStatus,
      }}
    >
      {children}
    </VideoIDContext.Provider>
  );
};

export default VideoIDContext;

import React, { useState } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { color } from "highcharts";

const DateRangeFilter = ({ onChange, open, setOpen, ...props }) => {
  const [state, setState] = useState([
    {
      startDate: props.startDate,
      endDate: props.endDate,
      key: "selection",
      color: "#883DCF",
      showDateDisplay: true
    },
  ]);

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    onChange(selection);
    setState([selection]);
    props.setStartDate(selection.startDate);
    props.setEndDate(selection.endDate);
  };

  return (
    <>
      {open && (
        <DateRangePicker
          onChange={handleOnChange}
          showSelectionPreview={true}
          months={1}
          // editableDateInputs={true}
          dateDisplayFormat="MM-dd-yyyy"
          maxDate={new Date()}
          ranges={state}
          direction="vertical"
          // rangeColors={["#F4ECFB"]}
          // color="#000"
        />
      )}
    </>
  );
};

export default DateRangeFilter;

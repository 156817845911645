import React, { useState } from "react";
import sample_video from "../../assets/img/vid.mp4";
import sample_image from "../../assets/img/img.png";
import sample_inner_video from "../../assets/img/inner video.mp4";
import InputCheckBox from "../../component/InputCheckBox";
import InputColorSelector from "../../component/InputColorSelector";
import InputRange from "../../component/InputRange";
import InputText from "../../component/InputText";
import { authAxios } from "../../config/axios";

const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
function PopupDesktop({
  setIsMobileView,
  allSettings,
  setAllSettings,
  showToast,
}) {
  let { popup_desktop } = allSettings;
  const [formData, setformData] = useState(popup_desktop);
  const [fieldErrors, setFieldErrors] = useState({});
  const [isBtnDis, setIsBtnDis] = useState(true);

  const handleChange = (e, colour = null) => {
    setIsBtnDis(false);
    if (e.target.type === "checkbox") {
      setformData({ ...formData, [e.target.name]: e.target.checked });
    } else {
      setformData({ ...formData, [e.target.name]: e.target.value });
    }
    if (colour) {
      let validation_checked = hexColorRegex.test(e.target.value);
      if (e.target.value === "") {
        setFieldErrors({
          ...fieldErrors,
          [e.target.name]: "Please enter colour",
        });
      } else if (!validation_checked) {
        setFieldErrors({
          ...fieldErrors,
          [e.target.name]: "You have not entered proper hex value",
        });
      } else {
        setformData({ ...formData, [e.target.name]: e.target.value });
        if (fieldErrors[e.target.name]) {
          let f_fieldErrors = fieldErrors;
          delete f_fieldErrors[e.target.name];
          setFieldErrors(f_fieldErrors);
        }
      }
    }
  };

  const handleText = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.value === "") {
      setFieldErrors({
        ...fieldErrors,
        [e.target.name]: "Please enter text",
      });
    } else {
      setformData({ ...formData, [e.target.name]: e.target.value });
      if (fieldErrors[e.target.name]) {
        let f_fieldErrors = fieldErrors;
        delete f_fieldErrors[e.target.name];
        setFieldErrors(f_fieldErrors);
      }
    }
  };
  const handleSave = async () => {
    showToast("Saved Successfully");
    try {
      if (!Object.values(fieldErrors).length) {
        if (JSON.stringify(formData) !== JSON.stringify(popup_desktop)) {
          let res = await authAxios("post", "/apisv1/authadmin/customise", {
            popup_desktop: formData,
          });
          if (res.status === 200) {
            setAllSettings({ ...allSettings, popup_desktop: formData });
          }
        }
        setIsBtnDis(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="Polaris-flex" style={{ gap: "24px" }}>
      <div
        className="left-card"
        style={{
          // borderRight: "var(--p-border-divider)",
          maxWidth: "316px",
          width: "100%",
        }}
      >
        <div
          className=""
          style={{
            borderTop: 0,
            padding: "var(--p-space-4)",
            borderRadius: "var(--p-border-radius-00)",
          }}
        >
          <h1 className="story-circle-header">Desktop Popup Customize</h1>
        </div>
        {/* For Checkbox */}
        <div
          className="Polaris-LegacyCard__Section"
          style={{
            borderTop: 0,
            padding: "0 var(--p-space-4) var(--p-space-4)",
          }}
        >
          <InputCheckBox
            label="Product image"
            key={"pd_prod_img"}
            events={{
              onChange: (e) => handleChange(e),
              checked: formData.pd_prod_img,
              name: "pd_prod_img",
            }}
          />
          <InputCheckBox
            label="Product Description"
            key={"pd_prod_desc"}
            events={{
              onChange: (e) => handleChange(e),
              checked: formData.pd_prod_desc,
              name: "pd_prod_desc",
            }}
          />
          <InputCheckBox
            label="Quantity"
            key={"pd_qty"}
            events={{
              onChange: (e) => handleChange(e),
              checked: formData.pd_qty,
              name: "pd_qty",
            }}
          />
          <InputCheckBox
            label="Discount Bages"
            key={"pd_discount_badge"}
            events={{
              onChange: (e) => handleChange(e),
              checked: formData.pd_discount_badge,
              name: "pd_discount_badge",
            }}
          />
          <InputCheckBox
            label="Review (Shopify review)"
            key={"pd_review"}
            events={{
              onChange: (e) => handleChange(e),
              checked: formData.pd_card_review,
              name: "pd_card_review",
            }}
          />
        </div>
        {/* Product Text color And Size */}
        <div
          className="Polaris-LegacyCard__Section"
          style={{ padding: "var(--p-space-4)" }}
        >
          <h1 className="Polaris-Text--root Polaris-Text--headingMd Polaris-Text--bold">
            Product Title
          </h1>
          <div>
            <p className="border-header">Color</p>
            <InputColorSelector
              label=""
              events={{
                onChange: (e) => handleChange(e, true),
                value: formData.pd_title_clr,
                name: "pd_title_clr",
              }}
              error={fieldErrors["pd_title_clr"]}
            />
          </div>
          <div>
            <p className="border-header" style={{ marginTop: "12px" }}>
              Font Size
            </p>
            <InputRange
              label=""
              key="pd_font_size"
              events={{
                onChange: (e) => handleChange(e),
                value: formData.pd_font_size,
                name: "pd_font_size",
                min: 8,
                max: 26,
              }}
            />
          </div>
        </div>
        {/* End Product Text color And Size */}
        {/* Discount Bages */}
        <div
          className="Polaris-LegacyCard__Section"
          style={{ padding: "var(--p-space-4)" }}
        >
          <h1 className="Polaris-Text--root Polaris-Text--headingMd Polaris-Text--bold">
            Discount Badges
          </h1>
          <div>
            <p className="border-header">Text Color</p>
            <InputColorSelector
              label=""
              events={{
                onChange: (e) => handleChange(e, true),
                value: formData.pd_badge_text_clr,
                name: "pd_badge_text_clr",
              }}
              error={fieldErrors["pd_badge_text_clr"]}
            />
          </div>
          <div>
            <p className="border-header" style={{ marginTop: "12px" }}>
              Background Color
            </p>
            <InputColorSelector
              label=""
              events={{
                onChange: (e) => handleChange(e, true),
                value: formData.pd_badge_bg_clr,
                name: "pd_badge_bg_clr",
              }}
              error={fieldErrors["pd_badge_bg_clr"]}
            />
          </div>
        </div>
        {/* End Discount Bages */}
        {/* Price */}
        <div
          className="Polaris-LegacyCard__Section"
          style={{ padding: "var(--p-space-4)" }}
        >
          <h1 className="Polaris-Text--root Polaris-Text--headingMd Polaris-Text--bold">
            Price
          </h1>
          <div>
            <p className="border-header">Base Price</p>
            <InputColorSelector
              label=""
              events={{
                onChange: (e) => handleChange(e, true),
                value: formData.pd_base_price_clr,
                name: "pd_base_price_clr",
              }}
              error={fieldErrors["pd_base_price_clr"]}
            />
          </div>
          <div>
            <p className="border-header" style={{ marginTop: "12px" }}>
              Compare Price
            </p>
            <InputColorSelector
              label=""
              events={{
                onChange: (e) => handleChange(e, true),
                value: formData.pd_compare_price_clr,
                name: "pd_compare_price_clr",
              }}
              error={fieldErrors["pd_compare_price_clr"]}
            />
          </div>
        </div>
        {/* End Price */}
        {/* CTA Button  */}
        <div
          className="Polaris-LegacyCard__Section"
          style={{ padding: "var(--p-space-4)" }}
        >
          <h1 className="Polaris-Text--root Polaris-Text--headingMd Polaris-Text--bold">
            CTA Button
          </h1>
          <div>
            <p className="border-header">Button Text Color</p>
            <InputColorSelector
              label=""
              events={{
                onChange: (e) => handleChange(e, true),
                value: formData.pd_cta_btn_clr,
                name: "pd_cta_btn_clr",
              }}
              error={fieldErrors["pd_cta_btn_clr"]}
            />
          </div>
          <div>
            <p className="border-header" style={{ marginTop: "12px" }}>
              Button Background Color
            </p>
            <InputColorSelector
              label=""
              events={{
                onChange: (e) => handleChange(e, true),
                value: formData.pd_cta_btn_bg_clr,
                name: "pd_cta_btn_bg_clr",
              }}
              error={fieldErrors["pd_cta_btn_bg_clr"]}
            />
          </div>
          <div>
            <p className="border-header" style={{ marginTop: "12px" }}>
              Text
            </p>
            <InputText
              key="pd_cta_btn_text"
              label=""
              events={{
                onChange: (e) => handleText(e, true),
                value: formData.pd_cta_btn_text,
                name: "pd_cta_btn_text",
              }}
              error={fieldErrors["pd_cta_btn_text"]}
            />
          </div>
        </div>
        {/* End CTA Button  */}
        {/* Save Button */}
        <div className="Polaris-PageActions" style={{ padding: "0" }}>
          <div className="Polaris-LegacyStack Polaris-LegacyStack--spacingTight Polaris-LegacyStack--distributionTrailing">
            <div className="Polaris-LegacyStack__Item">
              <button
                className="Polaris-Button Polaris-Button--primary"
                type="button"
                // disabled={isBtnDis}
                onClick={() => handleSave()}
              >
                <span className="Polaris-Button__Content">
                  <span className="Polaris-Button__Text">Save Changes</span>
                </span>
              </button>
            </div>
          </div>
        </div>
        {/* End Save Button */}
      </div>
      {/* Right Side Video Section */}
      <div
        className="right-card"
        style={{
          width: "100%",
          position: "sticky",
          top: "60px",
          height: "fit-content",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 30px",
          }}
        >
          <div className="right-card-title" style={{ margin: "0" }}>
            Popup View
          </div>
          <div className="device-btn" data-buttongroup-segmented="true">
            <div className="">
              <button
                onClick={() => setIsMobileView(false)}
                className="mobile-btn-active"
                type="button"
                aria-pressed="true"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M5 17H4C3.46957 17 2.96086 16.7893 2.58579 16.4142C2.21071 16.0391 2 15.5304 2 15V5C2 4.46957 2.21071 3.96086 2.58579 3.58579C2.96086 3.21071 3.46957 3 4 3H20C20.5304 3 21.0391 3.21071 21.4142 3.58579C21.7893 3.96086 22 4.46957 22 5V15C22 15.5304 21.7893 16.0391 21.4142 16.4142C21.0391 16.7893 20.5304 17 20 17H19"
                    stroke="#883DCF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 15L17 21H7L12 15Z"
                    stroke="#883DCF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div className="">
              <button
                onClick={() => setIsMobileView(true)}
                className="desktop-btn"
                type="button"
                aria-pressed="false"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M17 2H7C5.89543 2 5 2.89543 5 4V20C5 21.1046 5.89543 22 7 22H17C18.1046 22 19 21.1046 19 20V4C19 2.89543 18.1046 2 17 2Z"
                    stroke="#667085"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 18H12.01"
                    stroke="#667085"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="VD-product-card" style={{ padding: "20px 0" }}>
          <div className="VD-product-card-inner">
            <div className="VD-product-card-video">
              <video
                style={{
                  objectFit: "cover",
                  pointerEvents: "none",
                  width: "100%",
                  height: "100%",
                }}
                controls
                loop
                muted
                autoPlay
              >
                <source src={sample_video} type="video/mp4" />
              </video>
              <button className="VD-product-video-mute">
                <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.548 2.892a1 1 0 11.904-1.784C17.755 2.782 19.83 6.158 19.83 10c0 3.832-2.08 7.212-5.376 8.891a1 1 0 11-.908-1.782c2.628-1.338 4.284-4.03 4.284-7.109 0-3.087-1.651-5.774-4.282-7.108zm-1.214 4.154a1 1 0 111.332-1.492C14.95 6.7 15.673 8.268 15.673 10c0 1.733-.723 3.3-2.007 4.446a1 1 0 11-1.332-1.492c.865-.771 1.339-1.8 1.339-2.954 0-1.154-.474-2.182-1.339-2.954zM1.499 14.001A1.5 1.5 0 010 12.5V7.5A1.5 1.5 0 011.5 6h2.15l4.725-3.78A1 1 0 0110 3v14a1 1 0 01-1.625.781l-4.726-3.78H1.5z"
                    fill="#fff"
                  />
                </svg>
              </button>
            </div>
            <div className="VD-product-card-content">
              <div className="VD-product-content-inner">
                <div>
                  <div className="product-content-name">
                    {formData.pd_prod_img && (
                      <div className="tamnail_img">
                        <span className="Polaris-Thumbnail Polaris-Thumbnail--sizeMedium">
                          <img alt="Black choker necklace" src={sample_image} />
                        </span>
                      </div>
                    )}
                    <div className="tamnail_name">
                      <h2
                        style={{
                          fontSize: `${formData.pd_font_size}px`,
                          color: formData.pd_title_clr,
                        }}
                        className="Polaris-Text--root Polaris-Text--headingMd pop-pro-title"
                      >
                        boAt Wave Flex Connect
                      </h2>
                      <p
                        className="Polaris-Text--bodySm"
                        style={{ fontSize: "13px" }}
                      >
                        <span
                          style={{
                            textDecoration: "line-through",
                            color: formData.pd_compare_price_clr,
                          }}
                        >
                          ₹ 7,990
                        </span>
                        <span
                          className="Polaris-Text--bold"
                          style={{ color: formData.pd_base_price_clr }}
                        >
                          ₹ 1,799
                        </span>
                      </p>
                      {formData.pd_discount_badge && (
                        <div class="VD-polaris-discount">
                          <p
                            style={{
                              color: formData.pd_badge_text_clr,
                              backgroundColor: formData.pd_badge_bg_clr,
                            }}
                          >
                            50 % off
                          </p>
                        </div>
                      )}
                      {/* review  */}
                      {formData.pd_card_review && (
                        <div
                          className="polaris-review"
                          style={{
                            paddingTop: "var(--p-space-2)",
                            justifyContent: "flex-start",
                          }}
                        >
                          <svg
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.2 18a.8.8 0 01-.792-.914l.743-5.203-2.917-2.917a.8.8 0 01.434-1.355l4.398-.733 2.218-4.435a.8.8 0 011.435.008l2.123 4.361 4.498.801a.8.8 0 01.425 1.353l-2.917 2.917.744 5.203a.8.8 0 01-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 015.2 18z"
                              fill="#ffcc00"
                            />
                          </svg>
                          <svg
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.2 18a.8.8 0 01-.792-.914l.743-5.203-2.917-2.917a.8.8 0 01.434-1.355l4.398-.733 2.218-4.435a.8.8 0 011.435.008l2.123 4.361 4.498.801a.8.8 0 01.425 1.353l-2.917 2.917.744 5.203a.8.8 0 01-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 015.2 18z"
                              fill="#ffcc00"
                            />
                          </svg>
                          <svg
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.2 18a.8.8 0 01-.792-.914l.743-5.203-2.917-2.917a.8.8 0 01.434-1.355l4.398-.733 2.218-4.435a.8.8 0 011.435.008l2.123 4.361 4.498.801a.8.8 0 01.425 1.353l-2.917 2.917.744 5.203a.8.8 0 01-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 015.2 18z"
                              fill="#ffcc00"
                            />
                          </svg>
                          <svg
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.2 18a.8.8 0 01-.792-.914l.743-5.203-2.917-2.917a.8.8 0 01.434-1.355l4.398-.733 2.218-4.435a.8.8 0 011.435.008l2.123 4.361 4.498.801a.8.8 0 01.425 1.353l-2.917 2.917.744 5.203a.8.8 0 01-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 015.2 18z"
                              fill="#ffcc00"
                            />
                          </svg>
                          <svg
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.2 18a.8.8 0 01-.792-.914l.743-5.203-2.917-2.917a.8.8 0 01.434-1.355l4.398-.733 2.218-4.435a.8.8 0 011.435.008l2.123 4.361 4.498.801a.8.8 0 01.425 1.353l-2.917 2.917.744 5.203a.8.8 0 01-1.154.828l-4.382-2.22-4.502 2.223A.792.792 0 015.2 18z"
                              fill="#ffcc00"
                            />
                          </svg>
                          <p className="Polaris-Text--root Polaris-Text--bodySm">
                            &nbsp; 5 reviews
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="product-content-varient">
                    <p
                      className="Polaris-Text--bodySm"
                      style={{ fontSize: "14px" }}
                    >
                      <span className="Polaris-Text--bold">
                        Selected Color:{" "}
                      </span>
                      <span style={{ fontSize: "13px", color: "#000" }}>
                        {" "}
                        ACTIVE BLACK
                      </span>
                    </p>
                    <div className="variend-img">
                      <div className="variend-img-main variend-img-main-select">
                        <img src={sample_image} alt="" />
                      </div>
                      <div className="variend-img-main">
                        <img src={sample_image} alt="" />
                      </div>
                      <div className="variend-img-main">
                        <img src={sample_image} alt="" />
                      </div>
                      <div className="variend-img-main">
                        <img src={sample_image} alt="" />
                      </div>
                    </div>
                  </div>
                  {formData.pd_prod_desc && (
                    <div className="product-content-discription">
                      <div className="discription-inner">
                        <p>
                          <strong>Description</strong>
                        </p>
                        <p>
                          <strong>
                            boAt Wave Flex Connect - 1.83" (4.64 cm) Large
                            Display BT Calling Smartwatch
                          </strong>
                        </p>
                      </div>
                    </div>
                  )}
                  {formData.pd_qty && (
                    <div className="quantity-main">
                      <p style={{ fontSize: "12px", fontWeight: 600 }}>
                        CHOOSE QUANTITY
                      </p>
                      {/* <p class="">CHOOSE QUANTITY</p> */}
                      <div className="quantity-inner">
                        <button className="Polaris-Button">
                          <svg
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.1667 9H5.83333C5.3725 9 5 9.448 5 10C5 10.552 5.3725 11 5.83333 11H14.1667C14.6275 11 15 10.552 15 10C15 9.448 14.6275 9 14.1667 9"
                              fill="#5C5F62"
                            />
                          </svg>
                        </button>
                        <div
                          className="Polaris-Connected"
                          style={{ maxWidth: "50px" }}
                        >
                          <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                            <div className="Polaris-TextField Polaris-TextField--hasValue">
                              <input
                                autoComplete="off"
                                className="Polaris-Text--center Polaris-TextField__Input"
                                type="text"
                                aria-labelledby="PolarisTextField1Label"
                                aria-invalid="false"
                                defaultValue={1}
                              />
                              <div className="Polaris-TextField__Backdrop"></div>
                            </div>
                          </div>
                        </div>
                        <button className="Polaris-Button">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path
                              d="M10 4a1 1 0 0 0-1 1v4H5a1 1 0 1 0 0 2h4v4a1 1 0 1 0 2 0v-4h4a1 1 0 1 0 0-2h-4V5a1 1 0 0 0-1-1Z"
                              fill="#5C5F62"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="Cart-btn">
                  <button
                    className="Polaris-Button cart-text"
                    style={{
                      color: formData.pd_cta_btn_clr,
                      backgroundColor: formData.pd_cta_btn_bg_clr,
                    }}
                  >
                    {formData.pd_cta_btn_text}
                  </button>
                  <div className="customise-icon">
                    <span class="Polaris-Icon">
                      <span class="Polaris-Text--root Polaris-Text--visuallyHidden"></span>
                      <svg viewBox="0 0 20 20" class="Icon_Icon__Dm3QW">
                        <path
                          fill-rule="evenodd"
                          d="M1 1c0-.552.45-1 1.004-1h1.505c.831 0 1.505.672 1.505 1.5v.56l12.574.908c.877.055 1.52.843 1.397 1.71l-.866 6.034a1.504 1.504 0 0 1-1.489 1.288h-11.616v2h10.043a3.005 3.005 0 0 1 3.011 3c0 1.657-1.348 3-3.01 3a3.005 3.005 0 0 1-2.84-4h-5.368a3.005 3.005 0 0 1-2.84 4 3.005 3.005 0 0 1-3.01-3c0-1.306.838-2.418 2.007-2.83v-12.17h-1.003a1.002 1.002 0 0 1-1.004-1zm4.014 3.064v5.936h11.18l.727-5.07-11.907-.866zm9.04 12.936c0-.552.449-1 1.003-1 .554 0 1.004.448 1.004 1s-.45 1-1.004 1a1.002 1.002 0 0 1-1.003-1zm-11.047 0c0-.552.45-1 1.004-1s1.003.448 1.003 1-.449 1-1.003 1a1.002 1.002 0 0 1-1.004-1z"
                        ></path>
                      </svg>
                    </span>
                    <div class="counter">1</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Right Side Video Section */}
    </div>
  );
}

export default PopupDesktop;

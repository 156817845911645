import React, { useState, useEffect } from "react";
import { authAxios } from "../config/axios";

function MediaProductListing({
  handleCloseProductListingPopup,
  video_id,
  selected_prod,
}) {
  const [shopifyProducts, setShopifyProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(selected_prod);
  const [pagination, setpagination] = useState(null);
  const [search, setSearch] = useState("");
  const [loaderButton, setLoaderButton] = useState({
    isShow: false,
    isLoading: false,
  });
  let count = 5;
  useEffect(() => {
    fetchProduct();
  }, []);

  const fetchProduct = async () => {
    // fetch collection api
    try {
      setLoaderButton({ ...loaderButton, isLoading: true });
      let response = await authAxios(
        "get",
        `/apisv1/authadmin/getshopifyproduct?count=${count}&search=${search}&cursor=${
          pagination !== null ? pagination.endCursor : null
        }`,
      );
      if (response.status === 200) {
        let res_data = response.data.data;
        setShopifyProducts([...shopifyProducts, ...res_data.products]);
        if (res_data.pagination.hasNextPage === true) {
          setpagination(res_data.pagination);
          setLoaderButton({ isShow: true, isLoading: false });
        } else {
          setLoaderButton({ isShow: false, isLoading: false });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSearch = (e) => {
    let search_str = e.target.value;
    setSearch(search_str);
    fetchProduct();
  };

  const handleViewMore = () => {
    fetchProduct();
  };
  const handleSave = async () => {
    // fetch collection api
    if (selectedProduct.length) {
      try {
        let prepare = { products: selectedProduct };
        let response = await authAxios(
          "post",
          `/apisv1/authadmin/manageshopifyproduct?video=${video_id}`,
          prepare,
        );
        if (response.status === 200) {
          handleCloseProductListingPopup();
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      handleCloseProductListingPopup();
    }
  };

  const handleProduct = async (product_id, flag) => {
    // fetch collection api
    let split_id = product_id.split("/");
    split_id = Number(split_id[4]);
    if (flag == "add") {
      setSelectedProduct([...selectedProduct, split_id]);
    } else {
      let selected_prod = selectedProduct;
      selected_prod.splice(
        selectedProduct.findIndex((item) => item == split_id),
        1,
      );
      setSelectedProduct([...selected_prod]);
    }
  };
  return (
    <>
      {/* Popup Product list*/}
      <div data-portal-id="modal-Polarisportal1">
        <div>
          <div
            className="Polaris-Modal-Dialog__Container"
            data-polaris-layer="true"
            data-polaris-overlay="true"
          >
            <div>
              <div
                role="dialog"
                aria-modal="true"
                aria-label="Polarismodal-header1"
                aria-labelledby="Polarismodal-header1"
                tabIndex={-1}
                className="Polaris-Modal-Dialog"
              >
                <div
                  className="Polaris-Modal-Dialog__Modal"
                  style={{ maxWidth: 800 }}
                >
                  <div
                    className="Polaris-Box"
                    style={{
                      "--pc-box-border-block-end": "var(--p-border-divider)",
                      "--pc-box-padding-block-end-xs": "var(--p-space-4)",
                      "--pc-box-padding-block-start-xs": "var(--p-space-4)",
                      "--pc-box-padding-inline-start-xs": "var(--p-space-5)",
                      "--pc-box-padding-inline-end-xs": "var(--p-space-5)",
                    }}
                  >
                    <div
                      className="Polaris-Columns"
                      style={{
                        "--pc-columns-grid-template-columns-xs": "1fr auto",
                        "--pc-columns-gap-xs": "var(--p-space-4)",
                      }}
                    >
                      <div
                        className="Polaris-Inline"
                        style={{
                          "--pc-inline-block-align": "center",
                          "--pc-inline-wrap": "wrap",
                          "--pc-inline-gap-xs": "var(--p-space-4)",
                        }}
                      >
                        <h2
                          className="Polaris-Text--root Polaris-Text--headingLg Polaris-Text--break"
                          id="Polarismodal-header1"
                        >
                          Product list
                        </h2>
                      </div>
                      <button
                        onClick={handleCloseProductListingPopup}
                        className="Polaris-Modal-CloseButton"
                        aria-label="Close"
                      >
                        <span className="Polaris-Icon Polaris-Icon--colorBase Polaris-Icon--applyColor">
                          <span className="Polaris-Text--root Polaris-Text--visuallyHidden" />
                          <svg
                            viewBox="0 0 20 20"
                            className="Polaris-Icon__Svg"
                            focusable="false"
                            aria-hidden="true"
                          >
                            <path d="m11.414 10 6.293-6.293a1 1 0 1 0-1.414-1.414l-6.293 6.293-6.293-6.293a1 1 0 0 0-1.414 1.414l6.293 6.293-6.293 6.293a1 1 0 1 0 1.414 1.414l6.293-6.293 6.293 6.293a.998.998 0 0 0 1.707-.707.999.999 0 0 0-.293-.707l-6.293-6.293z" />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                  <button onClick={() => handleSave()}>Save</button>
                  <div
                    className="Polaris-Modal__Body Polaris-Scrollable Polaris-Scrollable--vertical Polaris-Scrollable--horizontal"
                    data-polaris-scrollable="true"
                  >
                    <div className="Polaris-Modal-Section">
                      <section
                        className="Polaris-Box"
                        style={{
                          "--pc-box-padding-block-end-xs": "var(--p-space-5)",
                          "--pc-box-padding-block-start-xs": "var(--p-space-5)",
                          " --pc-box-padding-inline-start-xs":
                            "var(--p-space-5)",
                          "--pc-box-padding-inline-end-xs": "var(--p-space-5)",
                          "padding-bottom": "0px",
                        }}
                      >
                        <div className="">
                          <div className="Polaris-Connected">
                            <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                              <div className="Polaris-TextField">
                                <div
                                  className="Polaris-TextField__Prefix"
                                  id="PolarisComboboxTextField1-Prefix"
                                >
                                  <span className="Polaris-Icon Polaris-Icon--colorBase Polaris-Icon--applyColor">
                                    <span className="Polaris-Text--root Polaris-Text--visuallyHidden"></span>
                                    <svg
                                      viewBox="0 0 20 20"
                                      className="Polaris-Icon__Svg"
                                      focusable="false"
                                      aria-hidden="true"
                                    >
                                      <path d="M8 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm9.707 4.293-4.82-4.82a5.968 5.968 0 0 0 1.113-3.473 6 6 0 0 0-12 0 6 6 0 0 0 6 6 5.968 5.968 0 0 0 3.473-1.113l4.82 4.82a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414z"></path>
                                    </svg>
                                  </span>
                                </div>
                                <input
                                  onChange={(e) => handleSearch(e)}
                                  id="PolarisComboboxTextField1"
                                  role="combobox"
                                  placeholder="Search"
                                  autoComplete="off"
                                  className="Polaris-TextField__Input"
                                  type="text"
                                  aria-labelledby="PolarisComboboxTextField1Label PolarisComboboxTextField1-Prefix"
                                  aria-invalid="false"
                                  aria-autocomplete="list"
                                  aria-expanded="false"
                                  defaultValue=""
                                  tabIndex={0}
                                  aria-controls="Polarispopover1"
                                  aria-owns="Polarispopover1"
                                />
                                <div className="Polaris-TextField__Backdrop"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="Tab-media-all-product">
                          {shopifyProducts.length
                            ? shopifyProducts.map((item, index) => (
                                <div
                                  key={item.node.id}
                                  className="video-list-box"
                                >
                                  <div
                                    className="video-name"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span
                                      className="Polaris-Thumbnail Polaris-Thumbnail--sizeMedium"
                                      style={{ marginRight: 10 }}
                                    >
                                      <img
                                        crossorigin="anonymous"
                                        alt="Black choker necklace"
                                        src={item.node.featuredImage.url}
                                      />
                                    </span>
                                    {item.node.title}
                                  </div>
                                  {!selectedProduct.includes(
                                    Number(item.node.id.split("/")[4]),
                                  ) ? (
                                    <div className="video-button">
                                      <div className="Polaris-ButtonGroup">
                                        <div className="Polaris-ButtonGroup__Item">
                                          <button
                                            className="Polaris-Button Polaris-Button--primary"
                                            type="button"
                                            onClick={() =>
                                              handleProduct(item.node.id, "add")
                                            }
                                          >
                                            <span className="Polaris-Button__Content">
                                              <span className="Polaris-Button__Text">
                                                ADD
                                              </span>
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="video-button">
                                      <div className="Polaris-ButtonGroup">
                                        <div className="Polaris-ButtonGroup__Item">
                                          <button
                                            className="Polaris-Button Polaris-Button--primary"
                                            type="button"
                                            onClick={() =>
                                              handleProduct(
                                                item.node.id,
                                                "remove",
                                              )
                                            }
                                          >
                                            <span className="Polaris-Button__Content">
                                              <span className="Polaris-Button__Text">
                                                Remove
                                              </span>
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ))
                            : "No product found in your store"}
                          {loaderButton.isShow && (
                            <div
                              style={{
                                width: "100%",
                                margin: "15px 0",
                                textAlign: "center",
                              }}
                            >
                              <button
                                class="Polaris-Button  "
                                aria-disabled="true"
                                type="button"
                                aria-busy="true"
                                tabindex="-1"
                                disabled={loaderButton.isLoading}
                                onClick={() => handleViewMore()}
                              >
                                <span class="Polaris-Button__Content">
                                  {loaderButton.isLoading === true ? (
                                    <span class="Polaris-Button__Spinner">
                                      <span
                                        class={
                                          "Polaris-Spinner Polaris-Spinner--sizeSmall"
                                        }
                                      >
                                        <svg
                                          viewBox="0 0 20 20"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M7.229 1.173a9.25 9.25 0 1011.655 11.412 1.25 1.25 0 10-2.4-.698 6.75 6.75 0 11-8.506-8.329 1.25 1.25 0 10-.75-2.385z"></path>
                                        </svg>
                                      </span>
                                    </span>
                                  ) : (
                                    <span className="Polaris-Button__Text">
                                      View More Products
                                    </span>
                                  )}
                                </span>
                              </button>
                            </div>
                          )}
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Polaris-Backdrop" />
      </div>
      {/* end Popup product list*/}
    </>
  );
}

export default MediaProductListing;

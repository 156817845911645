import React, { useState } from "react";
import { CrossSVG } from "../../assets/icons";
import { authAxios } from "../../config/axios";
import { useNavigate, useParams } from "react-router-dom";

const steps = [
  {
    id: 1,
    name: "Step 1",
    description: `Go to the "Video Collection" tab, then upload your video to get started. You can upload multiple videos.`,
    videoSrc: "/step1.mp4",
  },
  {
    id: 2,
    name: "Step 2",
    description: `Now, go to the "Playlist" tab and create a new playlist for your videos. Playlists allow you to organize multiple videos together, making it easier to display them on your website.`,
    videoSrc: "/step2.mp4",
  },
  {
    id: 3,
    name: "Step 3",
    description: `Next, go to the "Pages" tab where you can add your playlist to different pages of your website. You can choose between two types of playlists: Story Playlist or Card Playlist, depending on how you want your videos to be displayed.`,
    videoSrc: "/step3.mp4",
  },
  {
    id: 4,
    name: "Step 4",
    description: `You can customize your playlist. Adjust your Card Playlist, Popup Video, and Story Playlist to fit your website's style and preferences.`,
    videoSrc: "/step4.mp4",
  },
  {
    id: 5,
    name: "Step 5",
    description: `Finally, enable Vidzy for your website. Once it's active, you'll be able to access video analytics and track the performance of your content.`,
    videoSrc: "/step5.mp4",
  },
];
function ToolKitModal({ isModalOpen, setIsModalOpen }) {
  let { shop } = useParams();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(steps[0]);

  const handleBack = async () => {
    setCurrentStep(steps[currentStep.id - 2]);
  };
  console.log("current step", currentStep);
  const handleNext = async () => {
    try {
      if (currentStep.id < steps.length) {
        setCurrentStep(steps[currentStep.id]);
      }
      if (currentStep.id === steps.length) {
        await handleUpdateToolkit();
        setIsModalOpen(false);
        navigate(`/${shop}/media`);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const handleCross = async () => {
    try {
      await handleUpdateToolkit();
      setIsModalOpen(false);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleUpdateToolkit = async () => {
    try {
      let response = await authAxios(
        "post",
        `/apisv1/authadmin/update-toolkit`,
        {
          showToolkit: !isModalOpen,
        }
      );
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <div data-portal-id="modal-Polarisportal1">
      <div
        className="Polaris-Modal-Dialog__Container"
        data-polaris-layer="true"
        data-polaris-overlay="true"
      >
        <div
          role="dialog"
          aria-modal="true"
          aria-label="Polarismodal-header1"
          aria-labelledby="Polarismodal-header1"
          tabIndex={-1}
          className="Polaris-Modal-Dialog"
        >
          <div
            className="Polaris-Modal-Dialog__Modal"
            style={{
              maxWidth: "680px",
              padding: "24px",
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              borderRadius: "24px",
            }}
          >
            <div className="modal-header-toolkit">
              <h1>Let's Get You Started! </h1>
              <button onClick={handleCross}>
                <CrossSVG />
              </button>
            </div>
            <div className="modal-sub-header-toolkit">
              <h3>{currentStep.name}</h3>
              <p>{currentStep.description}</p>
            </div>
            <div className="tutorial-video">
              <video
                src={currentStep.videoSrc}
                width="100%"
                height="100%"
                controls
                muted
                autoPlay
                loop
              />
            </div>
            <div className="modal-btn-toolkit">
              {currentStep.id > 1 && (
                <button className="modal-toolkit-skip" onClick={handleBack}>
                  Back
                </button>
              )}
              <button className="modal-toolkit-next" onClick={handleNext}>
                {currentStep.id === steps.length
                  ? "Create your first video"
                  : "Next"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="Polaris-Backdrop" />
    </div>
  );
}

export default ToolKitModal;

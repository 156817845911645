import React, { useState, useEffect } from "react";
import CardTab from "./customise_tabs/CardTab";
import PopupTab from "./customise_tabs/PopupTab";
import StoryCircleTab from "./customise_tabs/StoryCircleTab";
import { authAxios } from "../config/axios";
import toast, { Toaster } from "react-hot-toast";

function Customise() {
  const [tab, setTab] = useState(1); //1=card,2 => popup, 3 => story circle
  const [isLoading, setIsLoading] = useState(true);
  const [allSettings, setAllSettings] = useState(null);
  useEffect(() => {
    fetchSettings();
  }, []);
  const fetchSettings = async () => {
    try {
      let response = await authAxios("get", "/apisv1/authadmin/customise");
      if (response.status === 200) {
        setAllSettings(response.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const showToast = (message) => {
    toast(message, {
      duration: 2000,
      position: "top-center",
      type: "success",
    });
  };
  return (
    <>
      {!isLoading && (
        <main
          className="Polaris-Frame__Main"
          id="AppFrameMain"
          data-has-global-ribbon="false"
        >
          <Toaster />
          <div className="Polaris-Frame__Content transitionEffect">
            <div className="Polaris-Page Polaris-Page--fullWidth">
              <div
                className="Polaris-Box"
                style={{
                  "--pc-box-padding-block-end-xs": "var(--p-space-4)",
                  "--pc-box-padding-block-end-md": "var(--p-space-5)",
                  "--pc-box-padding-block-start-xs": "var(--p-space-4)",
                  "--pc-box-padding-block-start-md": "var(--p-space-5)",
                  "--pc-box-padding-inline-start-xs": "var(--p-space-4)",
                  "--pc-box-padding-inline-start-sm": "var(--p-space-0)",
                  "--pc-box-padding-inline-end-xs": "var(--p-space-4)",
                  "--pc-box-padding-inline-end-sm": "var(--p-space-0)",
                  position: "relative",
                  // marginBottom: "30px",
                }}
              >
                <div className="Polaris-Page-Header--isSingleRow Polaris-Page-Header--mediumTitle">
                  <div
                    className="Polaris-Page-Header__Row"
                    style={{
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div className="tab-list" data-buttongroup-segmented="true">
                      <button
                        onClick={() => setTab(1)}
                        className="tab-list-button"
                        type="button"
                        aria-selected={`${tab === 1 ? true : false}`}
                      >
                        Card
                      </button>

                      <button
                        onClick={() => setTab(2)}
                        className="tab-list-button"
                        type="button"
                        aria-selected={`${tab === 2 ? true : false}`}
                      >
                        Popup
                      </button>

                      <button
                        onClick={() => setTab(3)}
                        className="tab-list-button"
                        type="button"
                        aria-selected={`${tab === 3 ? true : false}`}
                      >
                        Story Circle
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                {tab === 1 && (
                  <CardTab
                    allSettings={allSettings}
                    setAllSettings={setAllSettings}
                    showToast={showToast}
                  />
                )}
                {tab === 2 && (
                  <PopupTab
                    allSettings={allSettings}
                    setAllSettings={setAllSettings}
                    showToast={showToast}
                  />
                )}
                {tab === 3 && (
                  <StoryCircleTab
                    allSettings={allSettings}
                    setAllSettings={setAllSettings}
                    showToast={showToast}
                  />
                )}
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
}

export default Customise;

export const Upload = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="98"
        viewBox="0 0 100 98"
        fill="none"
      >
        <g clip-path="url(#clip0_416_25671)">
          <path
            d="M30.9084 91.4875L31.0155 89.0801C32.1316 88.4835 33.3765 88.1684 34.6421 88.1621C32.9003 89.5861 33.1179 92.3312 31.937 94.2462C31.5642 94.8407 31.0623 95.3436 30.4686 95.7177C29.8749 96.0918 29.2047 96.3275 28.5075 96.4074L27.0499 97.2998C26.8499 96.1848 26.894 95.0397 27.1792 93.9434C27.4643 92.8471 27.9838 91.8256 28.7017 90.9494C29.1094 90.4618 29.5768 90.0275 30.0929 89.6567C30.442 90.5772 30.9084 91.4875 30.9084 91.4875Z"
            fill="#F2F2F2"
          />
          <path
            d="M91.1579 56.4099C95.0877 53.4168 96.9646 48.3871 95.7896 43.589C95.7604 43.4698 95.7296 43.3509 95.6971 43.2322C94.9181 40.3841 92.9908 37.6773 90.1819 36.7673C87.8233 36.0031 85.2489 36.5907 82.7884 36.2869C77.9582 35.6904 74.2135 31.7822 71.5786 27.6903C68.9437 23.5984 66.9187 19.0181 63.4175 15.6376C57.6463 10.0654 48.2321 8.86568 41.0766 12.4928C33.9212 16.1199 29.3633 24.1669 29.5577 32.1867C29.7521 40.2066 34.5131 47.8741 41.4273 51.9422C43.9673 53.4367 46.9051 54.4954 49.8287 54.125C52.3655 53.8035 54.6705 52.4404 57.1954 52.0357C61.1651 51.3995 65.1095 53.1943 68.543 55.2858C71.9765 57.3774 75.293 59.8564 79.1947 60.8261C82.9832 61.7676 87.6768 59.0614 91.1579 56.4099Z"
            fill="#F2F2F2"
          />
          <path
            d="M60.0639 20.5957H20.9146C20.0982 20.5967 19.3154 20.9214 18.7381 21.4987C18.1607 22.0761 17.836 22.8588 17.835 23.6753V43.1599C17.836 43.9763 18.1607 44.7591 18.7381 45.3364C19.3154 45.9138 20.0982 46.2385 20.9146 46.2395H60.0639C60.8805 46.2388 61.6634 45.9141 62.2408 45.3367C62.8182 44.7593 63.1428 43.9764 63.1435 43.1599V23.6753C63.1428 22.8588 62.8182 22.0759 62.2408 21.4985C61.6634 20.9211 60.8805 20.5964 60.0639 20.5957Z"
            fill="white"
          />
          <path
            d="M60.0641 46.3906H20.9153C20.0587 46.3897 19.2374 46.0489 18.6317 45.4432C18.026 44.8375 17.6852 44.0162 17.6843 43.1596V23.6744C17.6852 22.8178 18.026 21.9965 18.6317 21.3908C19.2374 20.7851 20.0587 20.4443 20.9153 20.4434H60.0641C60.9207 20.4443 61.742 20.7851 62.3477 21.3908C62.9534 21.9965 63.2942 22.8178 63.2951 23.6744V43.1596C63.2942 44.0162 62.9534 44.8375 62.3477 45.4432C61.742 46.0489 60.9207 46.3897 60.0641 46.3906ZM20.9153 20.7462C20.139 20.7471 19.3947 21.0559 18.8457 21.6048C18.2968 22.1538 17.988 22.8981 17.9871 23.6744V43.1596C17.988 43.9359 18.2968 44.6802 18.8457 45.2292C19.3947 45.7781 20.139 46.0869 20.9153 46.0878H60.0641C60.8404 46.0869 61.5847 45.7781 62.1337 45.2292C62.6826 44.6802 62.9914 43.9359 62.9923 43.1596V23.6744C62.9914 22.8981 62.6826 22.1538 62.1337 21.6048C61.5847 21.0559 60.8404 20.7471 60.0641 20.7462H20.9153Z"
            fill="#CCCCCC"
          />
          <path
            d="M61.8649 51.7775H24.348C24.0237 51.7775 23.7127 51.6486 23.4834 51.4193C23.2541 51.19 23.1253 50.879 23.1253 50.5547C23.1253 50.2305 23.2541 49.9195 23.4834 49.6902C23.7127 49.4609 24.0237 49.332 24.348 49.332H61.8649C62.1892 49.332 62.5002 49.4609 62.7295 49.6902C62.9588 49.9195 63.0876 50.2305 63.0876 50.5547C63.0876 50.879 62.9588 51.19 62.7295 51.4193C62.5002 51.6486 62.1892 51.7775 61.8649 51.7775Z"
            fill="#E6E6E6"
          />
          <path
            d="M36.3752 40.1173C36.1529 40.1166 35.9345 40.0597 35.7401 39.9519C35.5323 39.8397 35.3589 39.6731 35.2386 39.4698C35.1184 39.2666 35.0558 39.0343 35.0577 38.7982V27.9238C35.0577 27.688 35.121 27.4565 35.241 27.2536C35.3611 27.0506 35.5334 26.8837 35.7401 26.7701C35.9467 26.6566 36.1801 26.6006 36.4158 26.6081C36.6514 26.6156 36.8808 26.6862 37.0798 26.8126L47.3953 32.2498C47.5823 32.3687 47.7364 32.5328 47.8431 32.7271C47.9498 32.9213 48.0058 33.1393 48.0058 33.361C48.0058 33.5826 47.9498 33.8006 47.8431 33.9949C47.7364 34.1891 47.5823 34.3532 47.3953 34.4721L37.0798 39.9093C36.8696 40.0444 36.6251 40.1166 36.3752 40.1173Z"
            fill="#F2F2F2"
          />
          <path
            d="M96.9536 34.6951H80.5938C80.2526 34.6947 79.9255 34.559 79.6842 34.3177C79.4429 34.0764 79.3072 33.7493 79.3068 33.4081V25.2655C79.3072 24.9243 79.4429 24.5971 79.6842 24.3559C79.9255 24.1146 80.2526 23.9789 80.5938 23.9785H96.9536C97.2948 23.9789 97.622 24.1146 97.8632 24.3559C98.1045 24.5971 98.2402 24.9243 98.2406 25.2655V33.4081C98.2402 33.7493 98.1045 34.0764 97.8632 34.3177C97.622 34.559 97.2948 34.6947 96.9536 34.6951Z"
            fill="white"
          />
          <path
            d="M96.9537 34.8475H80.5938C80.2125 34.8471 79.8469 34.6954 79.5772 34.4257C79.3076 34.1561 79.1559 33.7905 79.1555 33.4091V25.2665C79.1559 24.8851 79.3076 24.5195 79.5772 24.2499C79.8469 23.9802 80.2125 23.8286 80.5938 23.8281H96.9537C97.335 23.8286 97.7006 23.9802 97.9703 24.2499C98.2399 24.5195 98.3916 24.8851 98.392 25.2665V33.4091C98.3916 33.7905 98.2399 34.1561 97.9703 34.4257C97.7006 34.6954 97.335 34.8471 96.9537 34.8475ZM80.5938 24.1309C80.2928 24.1313 80.0041 24.251 79.7912 24.4639C79.5783 24.6768 79.4586 24.9654 79.4583 25.2665V33.4091C79.4586 33.7102 79.5783 33.9988 79.7912 34.2117C80.0041 34.4246 80.2928 34.5443 80.5938 34.5447H96.9537C97.2547 34.5443 97.5434 34.4246 97.7563 34.2117C97.9692 33.9988 98.0889 33.7102 98.0892 33.4091V25.2665C98.0889 24.9654 97.9692 24.6768 97.7563 24.4639C97.5434 24.251 97.2547 24.1313 96.9537 24.1309H80.5938Z"
            fill="#CCCCCC"
          />
          <path
            d="M94.266 38.6798H83.2453C82.9212 38.6794 82.6106 38.5505 82.3816 38.3212C82.1526 38.0919 82.0239 37.7811 82.0239 37.4571C82.0239 37.133 82.1526 36.8222 82.3816 36.593C82.6106 36.3637 82.9212 36.2347 83.2453 36.2344H94.266C94.5903 36.2344 94.9013 36.3632 95.1306 36.5925C95.3599 36.8218 95.4887 37.1328 95.4887 37.4571C95.4887 37.7814 95.3599 38.0924 95.1306 38.3217C94.9013 38.551 94.5903 38.6798 94.266 38.6798Z"
            fill="#E6E6E6"
          />
          <path
            d="M87.0145 32.2355C86.9216 32.2352 86.8304 32.2114 86.7491 32.1663C86.6623 32.1195 86.5898 32.0498 86.5396 31.9649C86.4893 31.88 86.4632 31.7829 86.4639 31.6842V27.1399C86.4639 27.0414 86.4904 26.9447 86.5406 26.8599C86.5907 26.7751 86.6628 26.7053 86.7491 26.6578C86.8355 26.6104 86.933 26.587 87.0315 26.5901C87.13 26.5932 87.2258 26.6228 87.309 26.6756L91.6197 28.9478C91.6979 28.9974 91.7622 29.066 91.8068 29.1472C91.8514 29.2284 91.8748 29.3195 91.8748 29.4121C91.8748 29.5047 91.8514 29.5958 91.8068 29.677C91.7622 29.7581 91.6979 29.8267 91.6197 29.8764L87.309 32.1486C87.2211 32.205 87.119 32.2352 87.0145 32.2355Z"
            fill="#883DCF"
          />
          <path
            d="M86.5066 69.7244H70.1467C69.8055 69.724 69.4784 69.5883 69.2371 69.347C68.9959 69.1057 68.8601 68.7786 68.8597 68.4374V60.2948C68.8601 59.9536 68.9958 59.6264 69.2371 59.3852C69.4784 59.1439 69.8055 59.0082 70.1467 59.0078H86.5066C86.8478 59.0082 87.1749 59.1439 87.4161 59.3852C87.6574 59.6264 87.7931 59.9536 87.7935 60.2948V68.4374C87.7931 68.7786 87.6574 69.1057 87.4161 69.347C87.1749 69.5883 86.8478 69.724 86.5066 69.7244Z"
            fill="white"
          />
          <path
            d="M86.5066 69.8768H70.1467C69.7654 69.8763 69.3998 69.7247 69.1301 69.455C68.8605 69.1854 68.7088 68.8198 68.7084 68.4384V60.2958C68.7088 59.9144 68.8605 59.5488 69.1301 59.2792C69.3998 59.0095 69.7654 58.8579 70.1467 58.8574H86.5066C86.8879 58.8579 87.2535 59.0095 87.5232 59.2792C87.7928 59.5488 87.9445 59.9144 87.945 60.2958V68.4384C87.9445 68.8198 87.7928 69.1854 87.5232 69.455C87.2535 69.7247 86.8879 69.8763 86.5066 69.8768ZM70.1467 59.1602C69.8457 59.1606 69.557 59.2803 69.3441 59.4932C69.1313 59.7061 69.0115 59.9947 69.0112 60.2958V68.4384C69.0115 68.7395 69.1313 69.0281 69.3441 69.241C69.557 69.4539 69.8457 69.5736 70.1467 69.574H86.5066C86.8077 69.5736 87.0963 69.4539 87.3092 69.241C87.5221 69.0281 87.6418 68.7395 87.6421 68.4384V60.2958C87.6418 59.9947 87.5221 59.7061 87.3092 59.4932C87.0963 59.2803 86.8077 59.1606 86.5066 59.1602H70.1467Z"
            fill="#CCCCCC"
          />
          <path
            d="M83.8189 73.7306H72.7982C72.4742 73.7302 72.1635 73.6012 71.9345 73.372C71.7055 73.1427 71.5768 72.8319 71.5768 72.5079C71.5768 72.1838 71.7055 71.873 71.9345 71.6438C72.1635 71.4145 72.4742 71.2855 72.7982 71.2852H83.8189C84.1432 71.2852 84.4542 71.414 84.6835 71.6433C84.9128 71.8726 85.0416 72.1836 85.0416 72.5079C85.0416 72.8322 84.9128 73.1432 84.6835 73.3725C84.4542 73.6018 84.1432 73.7306 83.8189 73.7306Z"
            fill="#E6E6E6"
          />
          <path
            d="M76.5277 67.5128C76.4348 67.5125 76.3435 67.4888 76.2623 67.4437C76.1755 67.3968 76.103 67.3272 76.0527 67.2423C76.0025 67.1573 75.9764 67.0603 75.9771 66.9616V62.4173C75.9771 62.3187 76.0036 62.222 76.0538 62.1372C76.1039 62.0524 76.1759 61.9826 76.2623 61.9352C76.3487 61.8877 76.4462 61.8643 76.5447 61.8675C76.6431 61.8706 76.739 61.9001 76.8222 61.9529L81.1329 64.2251C81.2111 64.2748 81.2754 64.3434 81.32 64.4245C81.3646 64.5057 81.388 64.5968 81.388 64.6894C81.388 64.782 81.3646 64.8732 81.32 64.9543C81.2754 65.0355 81.2111 65.1041 81.1329 65.1538L76.8222 67.4259C76.7343 67.4824 76.6321 67.5125 76.5277 67.5128Z"
            fill="#883DCF"
          />
          <path
            d="M30.9538 41.5537L22.5803 41.6099C22.5803 41.6099 17.8114 40.0725 15.6487 40.3937L14.8218 43.6422L20.7622 45.6104L31.1008 44.1103C31.3328 44.2275 31.5916 44.2815 31.8512 44.2669C32.1107 44.2523 32.3618 44.1696 32.5792 44.027C32.7966 43.8845 32.9727 43.6872 33.0896 43.4551C33.2065 43.2229 33.2602 42.964 33.2453 42.7045C33.2304 42.445 33.1474 42.1939 33.0046 41.9767C32.8618 41.7594 32.6643 41.5836 32.432 41.467C32.1997 41.3503 31.9408 41.2969 31.6813 41.3121C31.4218 41.3273 31.1708 41.4107 30.9538 41.5537Z"
            fill="#A0616A"
          />
          <path
            d="M20.6913 45.9252L10.757 43.068L9.85253 39.6981C9.61692 39.2485 9.51176 38.7419 9.54893 38.2357C9.58611 37.7294 9.76414 37.2437 10.0629 36.8333C10.911 35.6846 11.5739 36.8256 12.7775 37.5937C14.8875 37.7429 20.154 39.5449 22.2919 41.6314L22.1234 42.914L20.6913 45.9252Z"
            fill="#3F3D56"
          />
          <path
            d="M29.3348 43.1739C29.1126 43.1733 28.8941 43.1164 28.6998 43.0085C28.4919 42.8964 28.3185 42.7297 28.1983 42.5265C28.078 42.3232 28.0155 42.091 28.0173 41.8548V30.9804C28.0173 30.7446 28.0806 30.5132 28.2007 30.3102C28.3207 30.1073 28.4931 29.9403 28.6997 29.8268C28.9064 29.7132 29.1397 29.6572 29.3754 29.6647C29.6111 29.6722 29.8404 29.7429 30.0394 29.8693L40.3549 35.3065C40.542 35.4253 40.696 35.5895 40.8027 35.7837C40.9095 35.978 40.9654 36.196 40.9654 36.4176C40.9654 36.6392 40.9095 36.8573 40.8027 37.0515C40.696 37.2457 40.542 37.4099 40.3549 37.5287L30.0394 42.9659C29.8292 43.101 29.5847 43.1732 29.3348 43.1739Z"
            fill="#883DCF"
          />
          <path
            d="M22.4049 95.5503L24.5184 95.5502L25.5237 87.3984L22.4046 87.3986L22.4049 95.5503Z"
            fill="#A0616A"
          />
          <path
            d="M21.8658 94.8605L22.4606 94.8605L24.7831 93.916L26.0279 94.8603H26.028C26.7315 94.8604 27.4061 95.1398 27.9035 95.6373C28.4009 96.1347 28.6804 96.8093 28.6804 97.5127V97.5989L21.8659 97.5992L21.8658 94.8605Z"
            fill="#2F2E41"
          />
          <path
            d="M7.35504 93.561L9.35694 94.2387L12.9238 86.8402L9.96938 85.8398L7.35504 93.561Z"
            fill="#A0616A"
          />
          <path
            d="M7.06578 92.7344L7.62922 92.9251L10.1319 92.7755L11.008 94.0692L11.0082 94.0692C11.6744 94.2949 12.2238 94.7759 12.5354 95.4066C12.847 96.0373 12.8953 96.7659 12.6697 97.4322L12.6421 97.5138L6.1875 95.3284L7.06578 92.7344Z"
            fill="#2F2E41"
          />
          <path
            d="M9.80478 60.4379C9.80478 60.4379 9.55799 63.3993 10.1749 66.2372C10.7919 69.0752 12.1196 78.4958 12.1196 78.4958C9.43036 82.9159 7.8718 87.2655 7.57739 91.6682L11.3626 91.971L16.7824 79.1114L17.3315 66.2372L21.28 74.9979L21.077 75.6747C21.0289 75.8347 21.0348 76.0061 21.0937 76.1625C21.1526 76.3189 21.2612 76.4516 21.4029 76.5403C21.4029 76.5403 21.0332 78.9463 21.28 78.9463C21.5268 78.9463 22.1437 79.3165 22.1437 79.3165C20.6055 84.1052 20.399 88.6911 21.8096 93.0309L25.1406 92.8795L27.3261 74.0108L25.722 63.7695L25.5986 61.7952L25.0434 61.8181L24.7606 60.9932L24.2413 59.5742L9.80478 60.4379Z"
            fill="#2F2E41"
          />
          <path
            d="M9.59148 61.0604L9.56949 61.0296C8.39501 59.3853 10.1614 55.434 10.4279 54.8612L9.30572 54.4971L8.32451 46.1939L7.6069 43.3255C7.33651 42.2412 7.35955 41.1044 7.67367 40.0319C7.98778 38.9594 8.58164 37.9898 9.3943 37.2226L11.0531 35.6586L13.069 32.6758H16.3199L17.4345 34.7812C18.5152 35.031 19.474 35.652 20.144 36.536C20.8139 37.42 21.1524 38.511 21.1007 39.619L20.8481 45.1409L21.837 52.0163L21.8377 53.2605L23.5546 56.0756L23.9286 57.8209C24.0834 58.0251 25.1051 59.3874 25.1051 59.7598C25.1051 59.8157 25.0638 59.8697 24.9752 59.9297C24.279 60.4011 20.4308 61.3623 18.7276 60.6807C16.9134 59.9553 9.70163 61.0435 9.62897 61.0546L9.59148 61.0604Z"
            fill="#3F3D56"
          />
          <path
            d="M42.7982 97.5454C42.7983 97.569 42.7937 97.5924 42.7847 97.6142C42.7758 97.636 42.7626 97.6559 42.746 97.6726C42.7293 97.6893 42.7096 97.7026 42.6878 97.7117C42.666 97.7208 42.6427 97.7255 42.6191 97.7256H0.180175C0.132389 97.7256 0.0865607 97.7066 0.0527715 97.6728C0.0189823 97.639 0 97.5932 0 97.5454C0 97.4976 0.0189823 97.4518 0.0527715 97.418C0.0865607 97.3842 0.132389 97.3652 0.180175 97.3652H42.618C42.6657 97.3651 42.7114 97.3839 42.7452 97.4175C42.779 97.4511 42.798 97.4967 42.7982 97.5443V97.5454Z"
            fill="#CCCCCC"
          />
          <path
            d="M15.1144 31.7124C17.4922 31.7124 19.4198 29.7848 19.4198 27.407C19.4198 25.0292 17.4922 23.1016 15.1144 23.1016C12.7366 23.1016 10.809 25.0292 10.809 27.407C10.809 29.7848 12.7366 31.7124 15.1144 31.7124Z"
            fill="#A0616A"
          />
          <path
            d="M31.0968 30.7124L24.4542 35.8108C24.4542 35.8108 19.7239 37.463 18.1933 39.0244L19.4944 42.1136L25.4188 40.0978L32.757 32.6623C33.0128 32.6157 33.2518 32.5025 33.4499 32.3342C33.648 32.1659 33.7983 31.9484 33.8856 31.7036C33.973 31.4587 33.9943 31.1952 33.9474 30.9395C33.9005 30.6838 33.7871 30.445 33.6185 30.247C33.45 30.0491 33.2323 29.8991 32.9874 29.812C32.7424 29.725 32.4789 29.704 32.2232 29.7511C31.9676 29.7983 31.7289 29.912 31.5312 30.0808C31.3334 30.2495 31.1836 30.4674 31.0968 30.7124Z"
            fill="#A0616A"
          />
          <path
            d="M25.5522 40.3923L16.3244 43.0943L13.1517 41.9684C12.6925 41.7521 12.3029 41.4117 12.027 40.9856C11.7511 40.5595 11.5999 40.0647 11.5904 39.5572C11.5735 38.1295 12.7906 38.6391 14.2139 38.5252C15.9863 37.3707 21.2731 35.6292 24.237 36.0027L24.8768 37.127L25.5522 40.3923Z"
            fill="#3F3D56"
          />
          <path
            d="M18.0667 22.4368C17.7581 22.4276 17.864 21.846 17.6154 21.6605C17.6142 21.6661 17.613 21.6717 17.6117 21.6773C17.5791 21.8029 17.507 21.9148 17.4061 21.9964C17.3051 22.078 17.1806 22.125 17.051 22.1305C16.9213 22.1361 16.7932 22.0998 16.6857 22.0271C16.5782 21.9544 16.4969 21.8491 16.4537 21.7267C16.4125 21.5851 16.3378 21.4555 16.2361 21.3487C16.1343 21.242 16.0084 21.1612 15.8689 21.1133C15.8535 21.6524 15.4457 22.1203 14.9592 22.3531C14.4727 22.586 13.918 22.6247 13.3788 22.6327C12.8395 22.6406 12.2925 22.6226 11.7695 22.7546C11.2466 22.8865 10.7379 23.1968 10.5145 23.6877C10.2911 24.1785 10.4505 24.8544 10.9391 25.0828C10.5606 25.0848 10.2394 25.3971 10.1086 25.7523C10.0013 26.1165 9.97332 26.4996 10.0265 26.8755C10.1329 28.2678 10.3922 29.7666 11.406 30.7268C12.1786 31.4585 13.2845 31.7533 14.3486 31.7491C15.4128 31.7448 15.2257 31.647 16.2546 31.3754C16.2546 31.3754 17.2328 28.5711 16.9865 28.2952C16.7401 28.0193 16.5355 27.6663 16.5801 27.2992C16.6247 26.9321 17.0193 26.6032 17.3617 26.7429C17.3041 26.5958 17.2607 26.5677 17.2336 26.5752C17.2508 26.5334 17.2659 26.4907 17.2788 26.4473C17.3432 26.2261 17.4593 26.0233 17.6174 25.8557C17.7754 25.6881 17.971 25.5604 18.1881 25.4831C18.4073 25.4119 18.643 25.4096 18.8636 25.4763C19.0843 25.5431 19.2791 25.6758 19.4221 25.8565C20.5488 24.7999 19.8498 22.4897 18.0667 22.4368Z"
            fill="#2F2E41"
          />
          <path
            d="M90.9452 5.22089H22.1621C22.0906 5.21937 22.0225 5.18988 21.9724 5.13875C21.9224 5.08761 21.8943 5.01891 21.8943 4.94736C21.8943 4.87581 21.9224 4.8071 21.9724 4.75597C22.0225 4.70484 22.0906 4.67535 22.1621 4.67383H90.9452C91.0167 4.67535 91.0848 4.70484 91.1349 4.75597C91.1849 4.8071 91.213 4.87581 91.213 4.94736C91.213 5.01891 91.1849 5.08761 91.1349 5.13875C91.0848 5.18988 91.0167 5.21937 90.9452 5.22089Z"
            fill="#CACACA"
          />
          <path
            d="M26.8443 3.27749C27.7296 3.27749 28.4473 2.5438 28.4473 1.63874C28.4473 0.733691 27.7296 0 26.8443 0C25.959 0 25.2413 0.733691 25.2413 1.63874C25.2413 2.5438 25.959 3.27749 26.8443 3.27749Z"
            fill="#3F3D56"
          />
          <path
            d="M32.382 3.27749C33.2673 3.27749 33.985 2.5438 33.985 1.63874C33.985 0.733691 33.2673 0 32.382 0C31.4967 0 30.779 0.733691 30.779 1.63874C30.779 2.5438 31.4967 3.27749 32.382 3.27749Z"
            fill="#3F3D56"
          />
          <path
            d="M37.9197 3.27749C38.805 3.27749 39.5227 2.5438 39.5227 1.63874C39.5227 0.733691 38.805 0 37.9197 0C37.0343 0 36.3167 0.733691 36.3167 1.63874C36.3167 2.5438 37.0343 3.27749 37.9197 3.27749Z"
            fill="#3F3D56"
          />
          <path
            d="M87.611 0.40625H83.6773C83.5994 0.407793 83.5251 0.439853 83.4705 0.495543C83.4159 0.551233 83.3853 0.626111 83.3853 0.704101C83.3853 0.782091 83.4159 0.856972 83.4705 0.912662C83.5251 0.968352 83.5994 1.00041 83.6773 1.00195H87.611C87.689 1.00041 87.7633 0.968352 87.8179 0.912662C87.8725 0.856972 87.9031 0.782091 87.9031 0.704101C87.9031 0.626111 87.8725 0.551233 87.8179 0.495543C87.7633 0.439853 87.689 0.407793 87.611 0.40625Z"
            fill="#3F3D56"
          />
          <path
            d="M87.611 1.52344H83.6773C83.5994 1.52498 83.5251 1.55704 83.4705 1.61273C83.4159 1.66842 83.3853 1.7433 83.3853 1.82129C83.3853 1.89928 83.4159 1.97416 83.4705 2.02985C83.5251 2.08554 83.5994 2.1176 83.6773 2.11914H87.611C87.689 2.1176 87.7633 2.08554 87.8179 2.02985C87.8725 1.97416 87.9031 1.89928 87.9031 1.82129C87.9031 1.7433 87.8725 1.66842 87.8179 1.61273C87.7633 1.55704 87.689 1.52498 87.611 1.52344Z"
            fill="#3F3D56"
          />
          <path
            d="M87.611 2.64062H83.6773C83.5994 2.64217 83.5251 2.67423 83.4705 2.72992C83.4159 2.78561 83.3853 2.86049 83.3853 2.93848C83.3853 3.01647 83.4159 3.09135 83.4705 3.14704C83.5251 3.20272 83.5994 3.23478 83.6773 3.23633H87.611C87.689 3.23478 87.7633 3.20272 87.8179 3.14704C87.8725 3.09135 87.9031 3.01647 87.9031 2.93848C87.9031 2.86049 87.8725 2.78561 87.8179 2.72992C87.7633 2.67423 87.689 2.64217 87.611 2.64062Z"
            fill="#3F3D56"
          />
          <path
            d="M54.3665 20.5957V24.7079C54.3665 24.9123 54.0092 25.0834 53.5277 25.1303C53.5236 25.1301 53.5195 25.1306 53.5156 25.1319C53.4353 25.1379 53.3505 25.1425 53.2642 25.1425C52.9988 25.1473 52.7346 25.1042 52.4845 25.0153C52.4618 25.0062 52.4391 24.9956 52.4163 24.9865C52.3952 24.9744 52.3724 24.9638 52.3512 24.9517C52.2316 24.8805 52.162 24.7972 52.162 24.7079V20.5957H54.3665Z"
            fill="#CCCCCC"
          />
          <path
            d="M87.8311 71.186C88.8657 71.186 89.7043 70.3473 89.7043 69.3127C89.7043 68.2781 88.8657 67.4395 87.8311 67.4395C86.7965 67.4395 85.9578 68.2781 85.9578 69.3127C85.9578 70.3473 86.7965 71.186 87.8311 71.186Z"
            fill="#3F3D56"
          />
          <path
            d="M87.6908 70.0607C87.6868 70.0607 87.6828 70.0606 87.6788 70.0604C87.6435 70.0587 87.609 70.0494 87.5777 70.0331C87.5463 70.0168 87.5189 69.9939 87.4972 69.966L87.1842 69.5636C87.1644 69.5382 87.1498 69.5091 87.1413 69.4781C87.1327 69.447 87.1304 69.4146 87.1344 69.3827C87.1384 69.3508 87.1486 69.3199 87.1645 69.2919C87.1804 69.2639 87.2017 69.2394 87.2271 69.2196L87.2384 69.2109C87.2638 69.1911 87.2929 69.1765 87.3239 69.1679C87.3549 69.1594 87.3874 69.157 87.4193 69.161C87.4513 69.165 87.4821 69.1753 87.5101 69.1912C87.5381 69.2071 87.5627 69.2284 87.5824 69.2538C87.5982 69.2741 87.6182 69.2908 87.6411 69.3026C87.6639 69.3145 87.689 69.3213 87.7147 69.3225C87.7405 69.3238 87.7661 69.3195 87.79 69.3099C87.8139 69.3003 87.8354 69.2857 87.8531 69.267L88.4888 68.596C88.5335 68.5488 88.5951 68.5214 88.6601 68.5196C88.725 68.5179 88.788 68.5419 88.8353 68.5866L88.8457 68.5965C88.8691 68.6186 88.8879 68.6451 88.901 68.6745C88.9141 68.7039 88.9213 68.7356 88.9222 68.7678C88.923 68.8 88.9176 68.832 88.906 68.8621C88.8945 68.8921 88.8772 68.9196 88.8551 68.943L87.8687 69.9842C87.8458 70.0084 87.8182 70.0276 87.7876 70.0408C87.757 70.0539 87.7241 70.0607 87.6908 70.0607Z"
            fill="white"
          />
          <path
            d="M98.1267 36.3618C99.1613 36.3618 100 35.5231 100 34.4885C100 33.4539 99.1613 32.6152 98.1267 32.6152C97.0922 32.6152 96.2535 33.4539 96.2535 34.4885C96.2535 35.5231 97.0922 36.3618 98.1267 36.3618Z"
            fill="#3F3D56"
          />
          <path
            d="M97.9865 35.2365C97.9825 35.2365 97.9785 35.2364 97.9745 35.2362C97.9393 35.2345 97.9048 35.2252 97.8734 35.2089C97.8421 35.1926 97.8146 35.1697 97.7929 35.1418L97.4799 34.7394C97.4601 34.714 97.4455 34.6849 97.437 34.6539C97.4285 34.6228 97.4261 34.5904 97.4301 34.5585C97.4341 34.5265 97.4443 34.4957 97.4602 34.4677C97.4762 34.4397 97.4974 34.4152 97.5228 34.3954L97.5341 34.3866C97.5595 34.3668 97.5886 34.3523 97.6196 34.3437C97.6507 34.3352 97.6831 34.3328 97.715 34.3368C97.747 34.3408 97.7778 34.3511 97.8058 34.367C97.8338 34.3829 97.8584 34.4042 97.8781 34.4296C97.8939 34.4499 97.914 34.4666 97.9368 34.4784C97.9596 34.4903 97.9848 34.4971 98.0105 34.4983C98.0362 34.4996 98.0618 34.4953 98.0857 34.4857C98.1096 34.4761 98.1311 34.4615 98.1489 34.4428L98.7845 33.7718C98.8292 33.7246 98.8908 33.6972 98.9558 33.6954C99.0207 33.6936 99.0838 33.7177 99.131 33.7623L99.1414 33.7723C99.1648 33.7944 99.1836 33.8209 99.1967 33.8503C99.2098 33.8797 99.217 33.9114 99.2179 33.9436C99.2188 33.9758 99.2133 34.0078 99.2018 34.0379C99.1902 34.0679 99.1729 34.0954 99.1508 34.1188L98.1644 35.1599C98.1415 35.1841 98.1139 35.2034 98.0833 35.2166C98.0528 35.2297 98.0198 35.2365 97.9865 35.2365Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_416_25671">
            <rect width="100" height="97.7253" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export const Pause = () => {
  return (
    <>
      <svg
        width="25px"
        height="25px"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          {" "}
          <path
            d="M2 6C2 4.11438 2 3.17157 2.58579 2.58579C3.17157 2 4.11438 2 6 2C7.88562 2 8.82843 2 9.41421 2.58579C10 3.17157 10 4.11438 10 6V18C10 19.8856 10 20.8284 9.41421 21.4142C8.82843 22 7.88562 22 6 22C4.11438 22 3.17157 22 2.58579 21.4142C2 20.8284 2 19.8856 2 18V6Z"
            fill="#7441f6"
          ></path>{" "}
          <path
            d="M14 6C14 4.11438 14 3.17157 14.5858 2.58579C15.1716 2 16.1144 2 18 2C19.8856 2 20.8284 2 21.4142 2.58579C22 3.17157 22 4.11438 22 6V18C22 19.8856 22 20.8284 21.4142 21.4142C20.8284 22 19.8856 22 18 22C16.1144 22 15.1716 22 14.5858 21.4142C14 20.8284 14 19.8856 14 18V6Z"
            fill="#7441f6"
          ></path>{" "}
        </g>
      </svg>
    </>
  );
};

export const Play = () => {
  return (
    <svg
      width="25px"
      height="25px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M21.4086 9.35258C23.5305 10.5065 23.5305 13.4935 21.4086 14.6474L8.59662 21.6145C6.53435 22.736 4 21.2763 4 18.9671L4 5.0329C4 2.72368 6.53435 1.26402 8.59661 2.38548L21.4086 9.35258Z"
          fill="#883dcf"
        ></path>{" "}
      </g>
    </svg>
  );
};

export const WarningIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 6C12.5523 6 13 6.44772 13 7V14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14V7C11 6.44772 11.4477 6 12 6Z"
      fill="#EB3D4D"
    />
    <path
      d="M13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z"
      fill="#EB3D4D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
      fill="#EB3D4D"
    />
  </svg>
);

export const NoSavedVideos = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="161"
    height="145"
    viewBox="0 0 161 145"
    fill="none"
  >
    <g clip-path="url(#clip0_416_26624)">
      <path
        d="M99.9107 84.0158H41.3045C39.3171 84.0136 37.4117 83.2231 36.0064 81.8178C34.6011 80.4125 33.8106 78.5071 33.8083 76.5197V7.69145C33.8106 5.70404 34.6011 3.79867 36.0064 2.39336C37.4117 0.988048 39.3171 0.197557 41.3045 0.195312H95.828L107.407 9.42441V76.5197C107.405 78.5071 106.614 80.4125 105.209 81.8178C103.803 83.2231 101.898 84.0136 99.9107 84.0158Z"
        fill="#E6E6E6"
      />
      <path
        d="M41.3045 2.35352C39.8892 2.35511 38.5323 2.91803 37.5316 3.91879C36.5308 4.91954 35.9679 6.2764 35.9663 7.69168V76.5199C35.9679 77.9352 36.5308 79.292 37.5316 80.2928C38.5323 81.2935 39.8892 81.8565 41.3045 81.8581H99.9107C101.326 81.8565 102.683 81.2935 103.684 80.2928C104.684 79.292 105.247 77.9352 105.249 76.5199V10.4642L95.0731 2.35352H41.3045Z"
        fill="white"
      />
      <path
        d="M88.5119 22.3658H61.8294C61.6654 22.3658 61.5031 22.3335 61.3517 22.2707C61.2002 22.208 61.0626 22.1161 60.9467 22.0001C60.8308 21.8842 60.7388 21.7466 60.6761 21.5952C60.6133 21.4437 60.5811 21.2814 60.5811 21.1174C60.5811 20.9535 60.6133 20.7912 60.6761 20.6397C60.7388 20.4883 60.8308 20.3507 60.9467 20.2348C61.0626 20.1188 61.2002 20.0269 61.3517 19.9642C61.5031 19.9014 61.6654 19.8691 61.8294 19.8691H88.5119C88.6759 19.8691 88.8382 19.9014 88.9897 19.9642C89.1411 20.0269 89.2787 20.1188 89.3946 20.2348C89.5106 20.3507 89.6025 20.4883 89.6652 20.6397C89.728 20.7912 89.7603 20.9535 89.7603 21.1174C89.7603 21.2814 89.728 21.4437 89.6652 21.5952C89.6025 21.7466 89.5106 21.8842 89.3946 22.0001C89.2787 22.1161 89.1411 22.208 88.9897 22.2707C88.8382 22.3335 88.6759 22.3658 88.5119 22.3658Z"
        fill="#CCCCCC"
      />
      <path
        d="M93.6612 26.5806H61.8294C61.4983 26.5806 61.1808 26.4491 60.9467 26.215C60.7126 25.9809 60.5811 25.6634 60.5811 25.3323C60.5811 25.0012 60.7126 24.6837 60.9467 24.4496C61.1808 24.2155 61.4983 24.084 61.8294 24.084H93.6612C93.9923 24.084 94.3098 24.2155 94.5439 24.4496C94.778 24.6837 94.9095 25.0012 94.9095 25.3323C94.9095 25.6634 94.778 25.9809 94.5439 26.215C94.3098 26.4491 93.9923 26.5806 93.6612 26.5806Z"
        fill="#CCCCCC"
      />
      <path
        d="M88.5119 41.2466H61.8294C61.4983 41.2466 61.1808 41.1151 60.9467 40.881C60.7126 40.6469 60.5811 40.3294 60.5811 39.9983C60.5811 39.6672 60.7126 39.3497 60.9467 39.1156C61.1808 38.8815 61.4983 38.75 61.8294 38.75H88.5119C88.843 38.75 89.1605 38.8815 89.3946 39.1156C89.6287 39.3497 89.7603 39.6672 89.7603 39.9983C89.7603 40.3294 89.6287 40.6469 89.3946 40.881C89.1605 41.1151 88.843 41.2466 88.5119 41.2466Z"
        fill="#CCCCCC"
      />
      <path
        d="M93.6612 45.4595H61.8294C61.4983 45.4595 61.1808 45.328 60.9467 45.0939C60.7126 44.8598 60.5811 44.5423 60.5811 44.2112C60.5811 43.8801 60.7126 43.5626 60.9467 43.3285C61.1808 43.0944 61.4983 42.9629 61.8294 42.9629H93.6612C93.9923 42.9629 94.3098 43.0944 94.5439 43.3285C94.778 43.5626 94.9095 43.8801 94.9095 44.2112C94.9095 44.5423 94.778 44.8598 94.5439 45.0939C94.3098 45.328 93.9923 45.4595 93.6612 45.4595Z"
        fill="#CCCCCC"
      />
      <path
        d="M88.5119 60.1275H61.8294C61.4983 60.1275 61.1808 59.996 60.9467 59.7619C60.7126 59.5277 60.5811 59.2102 60.5811 58.8792C60.5811 58.5481 60.7126 58.2306 60.9467 57.9965C61.1808 57.7624 61.4983 57.6309 61.8294 57.6309H88.5119C88.843 57.6309 89.1605 57.7624 89.3946 57.9965C89.6287 58.2306 89.7603 58.5481 89.7603 58.8792C89.7603 59.2102 89.6287 59.5277 89.3946 59.7619C89.1605 59.996 88.843 60.1275 88.5119 60.1275Z"
        fill="#CCCCCC"
      />
      <path
        d="M93.6612 64.3404H61.8294C61.4983 64.3404 61.1808 64.2088 60.9467 63.9747C60.7126 63.7406 60.5811 63.4231 60.5811 63.0921C60.5811 62.761 60.7126 62.4435 60.9467 62.2094C61.1808 61.9753 61.4983 61.8438 61.8294 61.8438H93.6612C93.9923 61.8437 94.3098 61.9753 94.5439 62.2094C94.778 62.4435 94.9095 62.761 94.9095 63.0921C94.9095 63.4231 94.778 63.7406 94.5439 63.9747C94.3098 64.2088 93.9923 64.3404 93.6612 64.3404Z"
        fill="#CCCCCC"
      />
      <path
        d="M50.718 27.6367C53.1547 27.6367 55.1301 25.6613 55.1301 23.2246C55.1301 20.7879 53.1547 18.8125 50.718 18.8125C48.2813 18.8125 46.3059 20.7879 46.3059 23.2246C46.3059 25.6613 48.2813 27.6367 50.718 27.6367Z"
        fill="#883DCF"
      />
      <path
        d="M50.2807 24.9083C50.1814 24.9085 50.0848 24.8764 50.0054 24.8168L50.0005 24.8132L48.9636 24.02C48.8667 23.9456 48.8033 23.8357 48.7874 23.7147C48.7714 23.5936 48.8042 23.4711 48.8785 23.3742C48.9529 23.2772 49.0626 23.2138 49.1837 23.1977C49.3048 23.1817 49.4273 23.2143 49.5243 23.2886L50.1959 23.8036L51.783 21.7331C51.8198 21.6851 51.8657 21.6448 51.918 21.6145C51.9704 21.5843 52.0282 21.5646 52.0882 21.5567C52.1482 21.5488 52.2091 21.5527 52.2675 21.5684C52.326 21.584 52.3807 21.611 52.4287 21.6478L52.419 21.6616L52.4291 21.6479C52.526 21.7223 52.5893 21.8321 52.6053 21.9532C52.6213 22.0743 52.5886 22.1968 52.5143 22.2938L50.6476 24.7281C50.6044 24.7842 50.5489 24.8296 50.4853 24.8607C50.4218 24.8919 50.3519 24.908 50.2811 24.9078L50.2807 24.9083Z"
        fill="white"
      />
      <path
        d="M51.1723 46.5175C53.6091 46.5175 55.5844 44.5422 55.5844 42.1054C55.5844 39.6687 53.6091 37.6934 51.1723 37.6934C48.7356 37.6934 46.7603 39.6687 46.7603 42.1054C46.7603 44.5422 48.7356 46.5175 51.1723 46.5175Z"
        fill="#CCCCCC"
      />
      <path
        d="M50.718 65.3984C53.1547 65.3984 55.1301 63.423 55.1301 60.9863C55.1301 58.5496 53.1547 56.5742 50.718 56.5742C48.2813 56.5742 46.3059 58.5496 46.3059 60.9863C46.3059 63.423 48.2813 65.3984 50.718 65.3984Z"
        fill="#CCCCCC"
      />
      <path
        d="M107.181 9.72085H98.1114C97.4492 9.72085 96.8142 9.45781 96.346 8.98961C95.8778 8.5214 95.6147 7.88638 95.6147 7.22423V0.525182C95.6147 0.495853 95.623 0.467115 95.6386 0.442269C95.6542 0.417423 95.6765 0.397472 95.7029 0.384703C95.7293 0.371935 95.7587 0.366867 95.7879 0.37008C95.817 0.373293 95.8447 0.384657 95.8677 0.402869L107.278 9.4425C107.304 9.46261 107.322 9.49013 107.331 9.52124C107.34 9.55235 107.339 9.58551 107.329 9.61611C107.318 9.6467 107.298 9.67323 107.272 9.692C107.245 9.71076 107.214 9.72085 107.181 9.72085Z"
        fill="#E6E6E6"
      />
      <path
        d="M45.783 96.911L11.1086 104.22C10.1066 104.43 9.06214 104.234 8.20444 103.675C7.34674 103.116 6.74583 102.239 6.53354 101.238L0.583463 73.0091C0.373427 72.007 0.569445 70.9626 1.12851 70.1049C1.68758 69.2472 2.56405 68.6463 3.56561 68.434L38.24 61.1252C39.2421 60.9152 40.2865 61.1112 41.1442 61.6703C42.0019 62.2293 42.6028 63.1058 42.8151 64.1074L48.7652 92.3359C48.9752 93.3379 48.7792 94.3824 48.2201 95.2401C47.6611 96.0978 46.7846 96.6987 45.783 96.911Z"
        fill="white"
      />
      <path
        d="M45.783 96.911L11.1086 104.22C10.1066 104.43 9.06214 104.234 8.20444 103.675C7.34674 103.116 6.74583 102.239 6.53354 101.238L0.583463 73.0091C0.373427 72.007 0.569445 70.9626 1.12851 70.1049C1.68758 69.2472 2.56405 68.6463 3.56561 68.434L38.24 61.1252C39.2421 60.9152 40.2865 61.1112 41.1442 61.6703C42.0019 62.2293 42.6028 63.1058 42.8151 64.1074L48.7652 92.3359C48.9752 93.3379 48.7792 94.3824 48.2201 95.2401C47.6611 96.0978 46.7846 96.6987 45.783 96.911ZM3.65932 68.8785C2.77558 69.0658 2.00224 69.5961 1.50894 70.3528C1.01565 71.1096 0.842684 72.0312 1.028 72.9154L6.97808 101.144C7.1654 102.028 7.69562 102.801 8.45241 103.294C9.2092 103.788 10.1308 103.961 11.0149 103.775L45.6893 96.4664C46.5731 96.2791 47.3464 95.7489 47.8397 94.9921C48.333 94.2353 48.506 93.3138 48.3206 92.4296L42.3706 64.2011C42.1833 63.3173 41.653 62.544 40.8962 62.0507C40.1394 61.5574 39.2179 61.3845 38.3337 61.5698L3.65932 68.8785Z"
        fill="#3F3D56"
      />
      <path
        d="M36.0856 70.4336L18.5859 74.1223C18.4328 74.1578 18.2718 74.1339 18.1357 74.0553C17.9995 73.9768 17.8982 73.8494 17.8523 73.6991C17.8314 73.6207 17.8266 73.5388 17.8383 73.4585C17.85 73.3781 17.8779 73.301 17.9204 73.2319C17.9629 73.1627 18.019 73.1029 18.0854 73.0561C18.1517 73.0094 18.2269 72.9766 18.3063 72.9599L36.12 69.2051C36.8155 69.5971 36.5959 70.3261 36.0856 70.4336Z"
        fill="#CCCCCC"
      />
      <path
        d="M36.7326 73.504L19.2329 77.1926C19.0797 77.2281 18.9188 77.2042 18.7826 77.1256C18.6465 77.0471 18.5452 76.9197 18.4993 76.7694C18.4783 76.691 18.4735 76.6091 18.4852 76.5288C18.4969 76.4484 18.5249 76.3713 18.5674 76.3022C18.6099 76.233 18.666 76.1732 18.7323 76.1265C18.7987 76.0797 18.8739 76.0469 18.9533 76.0302L36.7669 72.2754C37.4625 72.6674 37.2429 73.3964 36.7326 73.504Z"
        fill="#CCCCCC"
      />
      <path
        d="M15.8182 79.7377L10.4775 80.8634C10.3031 80.9 10.1213 80.8659 9.97198 80.7686C9.82269 80.6713 9.7181 80.5187 9.68116 80.3443L8.35271 74.0418C8.31613 73.8674 8.35023 73.6856 8.44755 73.5363C8.54486 73.387 8.69744 73.2824 8.87178 73.2455L14.2125 72.1198C14.3869 72.0832 14.5687 72.1174 14.718 72.2147C14.8673 72.312 14.9719 72.4645 15.0089 72.6388L16.3373 78.9414C16.3739 79.1158 16.3398 79.2976 16.2425 79.4469C16.1452 79.5962 15.9926 79.7008 15.8182 79.7377Z"
        fill="#CCCCCC"
      />
      <path
        d="M38.0066 79.7989L11.2988 85.4285C11.1457 85.464 10.9847 85.4401 10.8486 85.3615C10.7124 85.2829 10.6111 85.1556 10.5652 85.0053C10.5442 84.9268 10.5395 84.845 10.5512 84.7646C10.5629 84.6843 10.5908 84.6072 10.6333 84.538C10.6758 84.4689 10.7319 84.4091 10.7983 84.3623C10.8646 84.3156 10.9398 84.2828 11.0192 84.2661L38.0411 78.5703C38.7367 78.9623 38.5168 79.6914 38.0066 79.7989Z"
        fill="#CCCCCC"
      />
      <path
        d="M38.6538 82.8692L11.946 88.4988C11.7929 88.5343 11.632 88.5104 11.4958 88.4318C11.3596 88.3533 11.2583 88.2259 11.2125 88.0756C11.1915 87.9971 11.1867 87.9153 11.1984 87.8349C11.2101 87.7546 11.238 87.6775 11.2805 87.6083C11.323 87.5392 11.3791 87.4794 11.4455 87.4326C11.5118 87.3859 11.587 87.3531 11.6664 87.3364L38.6884 81.6406C39.3839 82.0326 39.1641 82.7617 38.6538 82.8692Z"
        fill="#CCCCCC"
      />
      <path
        d="M39.3005 85.9376L12.5927 91.5671C12.4396 91.6027 12.2787 91.5787 12.1425 91.5002C12.0063 91.4216 11.9051 91.2943 11.8592 91.1439C11.8382 91.0655 11.8334 90.9836 11.8451 90.9033C11.8568 90.823 11.8848 90.7459 11.9272 90.6767C11.9697 90.6075 12.0258 90.5478 12.0922 90.501C12.1586 90.4542 12.2337 90.4215 12.3132 90.4047L39.3351 84.709C40.0306 85.101 39.8108 85.83 39.3005 85.9376Z"
        fill="#CCCCCC"
      />
      <path
        d="M39.9477 89.0079L13.24 94.6374C13.0868 94.673 12.9259 94.649 12.7897 94.5705C12.6535 94.4919 12.5523 94.3646 12.5064 94.2142C12.4854 94.1358 12.4806 94.0539 12.4923 93.9736C12.504 93.8933 12.532 93.8162 12.5745 93.747C12.6169 93.6778 12.6731 93.6181 12.7394 93.5713C12.8058 93.5245 12.881 93.4918 12.9604 93.475L39.9823 87.7793C40.6778 88.1713 40.458 88.9003 39.9477 89.0079Z"
        fill="#CCCCCC"
      />
      <path
        d="M40.5944 92.0763L13.8867 97.7058C13.7335 97.7413 13.5726 97.7174 13.4364 97.6388C13.3003 97.5603 13.199 97.433 13.1531 97.2826C13.1321 97.2042 13.1274 97.1223 13.1391 97.042C13.1508 96.9617 13.1787 96.8845 13.2212 96.8154C13.2637 96.7462 13.3198 96.6864 13.3861 96.6397C13.4525 96.5929 13.5277 96.5602 13.6071 96.5434L40.629 90.8477C41.3246 91.2396 41.1047 91.9687 40.5944 92.0763Z"
        fill="#CCCCCC"
      />
      <path
        d="M45.3091 78.0243C45.2484 78.1344 45.1965 78.2491 45.1539 78.3673L34.2201 81.0805L32.318 79.1128L28.9731 81.5952L32.0019 85.406C32.2465 85.7138 32.5845 85.934 32.965 86.0335C33.3454 86.1329 33.7479 86.1063 34.1119 85.9575L45.9761 81.1095C46.3661 81.3965 46.8342 81.5581 47.3182 81.5726C47.8022 81.5871 48.2791 81.4539 48.6856 81.1907C49.0921 80.9276 49.4088 80.5469 49.5936 80.0993C49.7784 79.6518 49.8226 79.1586 49.7203 78.6853C49.618 78.212 49.374 77.7811 49.0208 77.4499C48.6676 77.1186 48.2219 76.9028 47.743 76.831C47.2641 76.7593 46.7748 76.835 46.34 77.0481C45.9052 77.2613 45.5456 77.6018 45.3091 78.0243Z"
        fill="#A0616A"
      />
      <path
        d="M7.98801 102.169C8.32888 102.063 8.64252 101.885 8.90684 101.645C9.17116 101.405 9.37974 101.111 9.51787 100.782C9.65601 100.453 9.72034 100.098 9.70635 99.7412C9.69235 99.3847 9.60036 99.0356 9.43687 98.7186L16.9612 77.805L11.7358 76.8789L5.98272 97.7275C5.46825 98.0375 5.08536 98.5254 4.9066 99.0988C4.72783 99.6722 4.76562 100.291 5.0128 100.839C5.25998 101.386 5.69937 101.824 6.24772 102.069C6.79607 102.314 7.41528 102.349 7.98801 102.169Z"
        fill="#A0616A"
      />
      <path
        d="M16.0057 81.5175L10.8464 79.5374C10.6996 79.4811 10.5667 79.3938 10.4567 79.2814C10.3467 79.1691 10.2622 79.0344 10.2089 78.8864C10.1557 78.7385 10.135 78.5808 10.1481 78.4241C10.1613 78.2674 10.2081 78.1154 10.2854 77.9785L12.9919 73.1796C13.2845 72.4303 13.8623 71.8276 14.5985 71.5035C15.3348 71.1795 16.1695 71.1606 16.9197 71.4511C17.6699 71.7415 18.2743 72.3175 18.6004 73.0529C18.9265 73.7882 18.9478 74.6229 18.6595 75.3739L17.4657 80.7342C17.4314 80.8876 17.3645 81.0319 17.2695 81.1572C17.1745 81.2825 17.0536 81.3858 16.915 81.4602C16.7765 81.5345 16.6235 81.5781 16.4666 81.588C16.3097 81.5979 16.1525 81.5739 16.0057 81.5175Z"
        fill="#883DCF"
      />
      <path
        d="M27.6508 141.433L30.4358 141.433L31.7605 130.691L27.6504 130.692L27.6508 141.433Z"
        fill="#A0616A"
      />
      <path
        d="M26.9404 140.524L32.4248 140.523H32.4251C32.884 140.523 33.3385 140.614 33.7626 140.789C34.1866 140.965 34.5719 141.223 34.8965 141.547C35.2211 141.872 35.4785 142.257 35.6541 142.681C35.8298 143.105 35.9202 143.56 35.9202 144.019V144.132L26.9406 144.132L26.9404 140.524Z"
        fill="#2F2E41"
      />
      <path
        d="M11.977 141.433L14.7619 141.433L16.0867 130.691L11.9766 130.692L11.977 141.433Z"
        fill="#A0616A"
      />
      <path
        d="M11.2666 140.524L16.751 140.523H16.7512C17.2102 140.523 17.6647 140.614 18.0888 140.789C18.5128 140.965 18.8981 141.223 19.2227 141.547C19.5472 141.872 19.8047 142.257 19.9803 142.681C20.156 143.105 20.2464 143.56 20.2464 144.019V144.132L11.2668 144.132L11.2666 140.524Z"
        fill="#2F2E41"
      />
      <path
        d="M14.8965 137.11H11.3723C11.234 137.11 11.0971 137.082 10.9699 137.028C10.8428 136.973 10.728 136.894 10.6325 136.794C10.5371 136.693 10.4629 136.575 10.4146 136.445C10.3662 136.316 10.3447 136.178 10.3512 136.04L12.0252 100.074L12.1307 100.071L29.1491 99.627L32.4793 135.614C32.4921 135.749 32.4779 135.885 32.4376 136.014C32.3974 136.143 32.3318 136.264 32.2447 136.367C32.1576 136.471 32.0507 136.557 31.9303 136.619C31.8098 136.681 31.6783 136.718 31.5432 136.729L27.5847 137.046C27.336 137.066 27.0887 136.994 26.8893 136.844C26.6898 136.694 26.552 136.476 26.5018 136.232L21.4385 110.614C21.4246 110.536 21.3834 110.466 21.3221 110.416C21.2609 110.366 21.1838 110.34 21.1048 110.342H21.1034C21.0241 110.34 20.9468 110.367 20.8857 110.417C20.8245 110.468 20.7836 110.539 20.7703 110.617L15.8995 136.286C15.8534 136.518 15.7282 136.727 15.5454 136.877C15.3625 137.028 15.1333 137.11 14.8965 137.11Z"
        fill="#2F2E41"
      />
      <path
        d="M21.3521 67.9963C24.4334 67.9963 26.9313 65.4984 26.9313 62.4171C26.9313 59.3358 24.4334 56.8379 21.3521 56.8379C18.2708 56.8379 15.7729 59.3358 15.7729 62.4171C15.7729 65.4984 18.2708 67.9963 21.3521 67.9963Z"
        fill="#A0616A"
      />
      <path
        d="M19.4815 104.289C16.8223 104.289 14.091 103.59 11.8268 101.523C11.7063 101.414 11.6133 101.278 11.5553 101.126C11.4973 100.974 11.476 100.81 11.493 100.648C11.7354 98.471 12.911 87.2279 12.2489 79.7175C12.1197 78.3147 12.3183 76.9011 12.829 75.5881C13.3398 74.2752 14.1486 73.099 15.1918 72.1522C16.2168 71.2076 17.4472 70.5141 18.786 70.1264C20.1249 69.7387 21.5355 69.6675 22.9065 69.9183C22.976 69.9308 23.0455 69.9438 23.1151 69.9572C27.5818 70.8216 30.6852 74.9555 30.3341 79.5729C29.8216 86.3104 29.3623 95.4237 30.0836 100.578C30.1135 100.788 30.0766 101.003 29.9783 101.192C29.8799 101.381 29.7249 101.534 29.535 101.631C27.8939 102.46 23.782 104.289 19.4815 104.289Z"
        fill="#883DCF"
      />
      <path
        d="M26.7617 81.918C26.6308 81.8309 26.5202 81.7166 26.4375 81.5829C26.3548 81.4491 26.302 81.2991 26.2825 81.1431L25.6028 75.6937C25.387 74.9187 25.4874 74.0898 25.8818 73.3886C26.2763 72.6875 26.9326 72.1714 27.707 71.9535C28.4814 71.7355 29.3105 71.8336 30.0128 72.2261C30.715 72.6185 31.2329 73.2734 31.453 74.0472L33.6919 79.0812C33.7558 79.2249 33.788 79.3807 33.7863 79.5379C33.7845 79.6951 33.7489 79.8501 33.6819 79.9924C33.6148 80.1346 33.5179 80.2607 33.3978 80.3621C33.2776 80.4635 33.137 80.5378 32.9855 80.5799L27.6616 82.0615C27.5101 82.1037 27.3513 82.1127 27.196 82.088C27.0407 82.0632 26.8926 82.0052 26.7617 81.918Z"
        fill="#883DCF"
      />
      <path
        d="M19.4046 67.916C18.8765 67.9258 18.3517 67.8309 17.8606 67.6366C17.6444 67.5534 17.4201 67.4849 17.2044 67.4016C15.2993 66.6655 14.0444 64.6366 13.9994 62.5948C13.9545 60.553 15.0119 58.5713 16.582 57.2652C18.1521 55.9592 20.1858 55.2887 22.2222 55.1336C24.4158 54.9666 26.8858 55.5229 28.1115 57.3496C28.4378 57.8359 28.666 58.4259 28.4727 59.013C28.4148 59.1902 28.3113 59.349 28.1728 59.4737C27.6254 59.9607 27.0796 59.5945 26.5216 59.5621C25.7546 59.5177 25.0657 60.1385 24.8181 60.8658C24.5705 61.5931 24.6795 62.3938 24.8794 63.1356C25.0317 63.582 25.1277 64.0456 25.1652 64.5158C25.1814 64.7515 25.137 64.9875 25.0364 65.2013C24.9358 65.4151 24.7823 65.5997 24.5903 65.7375C24.111 66.0272 23.4816 65.8596 23.0021 65.5703C22.5225 65.2811 22.1081 64.8789 21.5901 64.6661C21.0721 64.4533 20.3762 64.5005 20.0746 64.9724C19.9788 65.1441 19.914 65.3314 19.8831 65.5256C19.6128 66.7571 19.6749 66.6845 19.4046 67.916Z"
        fill="#2F2E41"
      />
      <path
        d="M44.0384 144.439H0.878767C0.818522 144.439 0.760744 144.415 0.718144 144.372C0.675544 144.33 0.651611 144.272 0.651611 144.212C0.651611 144.151 0.675544 144.094 0.718144 144.051C0.760744 144.008 0.818522 143.984 0.878767 143.984H44.0384C44.0986 143.984 44.1564 144.008 44.199 144.051C44.2416 144.094 44.2655 144.151 44.2655 144.212C44.2655 144.272 44.2416 144.33 44.199 144.372C44.1564 144.415 44.0986 144.439 44.0384 144.439Z"
        fill="#CCCCCC"
      />
      <path
        d="M154.514 97.5608L123.608 114.897C122.715 115.397 121.659 115.522 120.674 115.244C119.688 114.967 118.853 114.31 118.351 113.418L104.238 88.2571C103.738 87.3636 103.613 86.3083 103.89 85.3227C104.167 84.3371 104.824 83.5018 105.717 82.9999L136.623 65.664C137.516 65.1641 138.572 65.0391 139.557 65.3163C140.543 65.5936 141.378 66.2504 141.88 67.1428L155.993 92.3037C156.493 93.1972 156.618 94.2525 156.341 95.238C156.064 96.2236 155.407 97.059 154.514 97.5608Z"
        fill="white"
      />
      <path
        d="M154.514 97.5608L123.608 114.897C122.715 115.397 121.659 115.522 120.674 115.244C119.688 114.967 118.853 114.31 118.351 113.418L104.238 88.2571C103.738 87.3636 103.613 86.3083 103.89 85.3227C104.167 84.3371 104.824 83.5018 105.717 82.9999L136.623 65.664C137.516 65.1641 138.572 65.0391 139.557 65.3163C140.543 65.5936 141.378 66.2504 141.88 67.1428L155.993 92.3037C156.493 93.1972 156.618 94.2525 156.341 95.238C156.064 96.2236 155.407 97.059 154.514 97.5608ZM105.939 83.3961C105.151 83.839 104.572 84.5761 104.327 85.4457C104.083 86.3153 104.193 87.2464 104.634 88.0348L118.747 113.196C119.19 113.983 119.927 114.563 120.797 114.807C121.666 115.052 122.597 114.942 123.386 114.501L154.292 97.1646C155.079 96.7218 155.659 95.9847 155.904 95.1151C156.148 94.2455 156.038 93.3143 155.597 92.5259L141.484 67.365C141.041 66.5777 140.304 65.9981 139.434 65.7535C138.565 65.5088 137.633 65.6192 136.845 66.0602L105.939 83.3961Z"
        fill="#3F3D56"
      />
      <path
        d="M137.348 75.1905L121.75 83.9397C121.615 84.0194 121.454 84.0447 121.3 84.0104C121.147 83.9761 121.012 83.8848 120.924 83.7551C120.88 83.6865 120.851 83.6098 120.838 83.5296C120.825 83.4495 120.829 83.3676 120.849 83.2889C120.869 83.2102 120.905 83.1363 120.954 83.0719C121.003 83.0074 121.065 82.9537 121.136 82.914L137.014 74.0078C137.795 74.174 137.803 74.9354 137.348 75.1905Z"
        fill="#CCCCCC"
      />
      <path
        d="M138.883 77.9268L123.285 86.6761C123.149 86.7557 122.989 86.781 122.835 86.7467C122.682 86.7124 122.547 86.6212 122.458 86.4914C122.415 86.4228 122.386 86.3461 122.373 86.266C122.36 86.1858 122.364 86.1039 122.384 86.0252C122.404 85.9465 122.439 85.8726 122.489 85.8082C122.538 85.7437 122.6 85.69 122.671 85.6503L138.548 76.7441C139.329 76.9104 139.338 77.6717 138.883 77.9268Z"
        fill="#CCCCCC"
      />
      <path
        d="M120.787 90.1257L116.026 92.7958C115.871 92.8829 115.687 92.9046 115.516 92.8564C115.344 92.8081 115.199 92.6938 115.111 92.5385L111.96 86.9208C111.873 86.7653 111.851 86.5816 111.9 86.4101C111.948 86.2385 112.062 86.0931 112.218 86.0058L116.978 83.3356C117.133 83.2486 117.317 83.2269 117.489 83.2751C117.66 83.3234 117.806 83.4377 117.893 83.593L121.044 89.2106C121.131 89.3661 121.153 89.5498 121.105 89.7214C121.056 89.8929 120.942 90.0383 120.787 90.1257Z"
        fill="#CCCCCC"
      />
      <path
        d="M141.98 83.5539L118.174 96.9068C118.039 96.9865 117.878 97.0117 117.725 96.9775C117.571 96.9432 117.436 96.8519 117.348 96.7221C117.304 96.6536 117.275 96.5769 117.262 96.4967C117.25 96.4166 117.253 96.3347 117.273 96.2559C117.293 96.1772 117.329 96.1034 117.378 96.039C117.427 95.9745 117.489 95.9208 117.56 95.8811L141.645 82.3711C142.426 82.5373 142.434 83.2988 141.98 83.5539Z"
        fill="#CCCCCC"
      />
      <path
        d="M143.515 86.2902L119.709 99.6431C119.574 99.7228 119.413 99.7481 119.26 99.7138C119.106 99.6795 118.972 99.5882 118.883 99.4585C118.839 99.3899 118.81 99.3132 118.798 99.2331C118.785 99.1529 118.788 99.071 118.808 98.9923C118.828 98.9136 118.864 98.8397 118.913 98.7753C118.962 98.7108 119.024 98.6571 119.095 98.6174L143.181 85.1074C143.962 85.2737 143.97 86.0351 143.515 86.2902Z"
        fill="#CCCCCC"
      />
      <path
        d="M145.049 89.0266L121.244 102.379C121.108 102.459 120.947 102.484 120.794 102.45C120.64 102.416 120.506 102.325 120.417 102.195C120.374 102.126 120.345 102.05 120.332 101.969C120.319 101.889 120.323 101.807 120.342 101.729C120.362 101.65 120.398 101.576 120.447 101.512C120.497 101.447 120.559 101.393 120.629 101.354L144.715 87.8438C145.496 88.01 145.504 88.7714 145.049 89.0266Z"
        fill="#CCCCCC"
      />
      <path
        d="M146.584 91.7629L122.778 105.116C122.643 105.195 122.482 105.221 122.329 105.186C122.175 105.152 122.041 105.061 121.952 104.931C121.909 104.863 121.879 104.786 121.867 104.706C121.854 104.626 121.857 104.544 121.877 104.465C121.897 104.386 121.933 104.312 121.982 104.248C122.032 104.183 122.094 104.13 122.164 104.09L146.25 90.5801C147.031 90.7463 147.039 91.5078 146.584 91.7629Z"
        fill="#CCCCCC"
      />
      <path
        d="M148.118 94.4973L124.313 107.85C124.177 107.93 124.017 107.955 123.863 107.921C123.71 107.887 123.575 107.795 123.486 107.666C123.443 107.597 123.414 107.52 123.401 107.44C123.388 107.36 123.392 107.278 123.412 107.199C123.432 107.121 123.467 107.047 123.517 106.982C123.566 106.918 123.628 106.864 123.699 106.824L147.784 93.3145C148.565 93.4807 148.573 94.2421 148.118 94.4973Z"
        fill="#CCCCCC"
      />
      <path
        d="M143.965 104.748C144.095 104.462 144.16 104.151 144.156 103.837C144.153 103.523 144.081 103.214 143.945 102.931C143.809 102.648 143.612 102.398 143.369 102.199C143.126 102 142.843 101.857 142.538 101.78L141.891 94.1758L138.778 96.632L139.87 103.476C139.774 103.996 139.871 104.533 140.145 104.985C140.418 105.438 140.848 105.774 141.353 105.931C141.857 106.088 142.402 106.054 142.884 105.836C143.365 105.617 143.75 105.23 143.965 104.748Z"
        fill="#FFB8B8"
      />
      <path
        d="M141.95 100.982L139.364 101.291C139.233 101.307 139.1 101.295 138.973 101.257C138.846 101.218 138.729 101.154 138.628 101.069C138.527 100.983 138.445 100.878 138.387 100.759C138.329 100.64 138.296 100.51 138.29 100.378L137.913 91.6548L133.012 78.2649C132.815 77.8495 132.726 77.3915 132.751 76.9326C132.777 76.4738 132.916 76.0285 133.158 75.6373C133.278 75.4483 133.415 75.2708 133.568 75.1073C133.87 74.7825 134.246 74.5354 134.663 74.3871C135.081 74.2387 135.529 74.1936 135.967 74.2554C136.42 74.3156 136.851 74.4828 137.226 74.7431C137.6 75.0035 137.908 75.3494 138.122 75.7523L141.318 82.4504C142.655 85.2519 143.286 88.3381 143.157 91.4396L142.796 100.069C142.787 100.295 142.698 100.51 142.546 100.677C142.393 100.843 142.187 100.951 141.963 100.981L141.95 100.982Z"
        fill="#883DCF"
      />
      <path
        d="M151.133 138.685L148.735 139.737L143.536 130.991L147.074 129.438L151.133 138.685Z"
        fill="#FFB8B8"
      />
      <path
        d="M152.765 140.739L145.034 144.133L144.992 144.035C144.818 143.64 144.724 143.214 144.715 142.783C144.706 142.351 144.782 141.922 144.939 141.52C145.096 141.118 145.33 140.751 145.629 140.44C145.927 140.128 146.284 139.879 146.679 139.705L151.401 137.633L152.765 140.739Z"
        fill="#2F2E41"
      />
      <path
        d="M118.947 141.595H116.329L115.084 131.496L118.948 131.496L118.947 141.595Z"
        fill="#FFB8B8"
      />
      <path
        d="M119.615 144.133L111.173 144.133V144.026C111.173 143.595 111.258 143.167 111.423 142.769C111.588 142.37 111.83 142.008 112.135 141.703C112.441 141.397 112.803 141.155 113.202 140.99C113.6 140.825 114.027 140.74 114.459 140.74L119.615 140.74L119.615 144.133Z"
        fill="#2F2E41"
      />
      <path
        d="M144.438 138.141L129.853 105.594C129.827 105.533 129.782 105.482 129.725 105.447C129.668 105.412 129.602 105.396 129.536 105.401C129.47 105.406 129.407 105.431 129.355 105.473C129.304 105.515 129.267 105.572 129.25 105.637L120.363 137.074C120.305 137.294 120.169 137.486 119.982 137.615C119.794 137.744 119.567 137.802 119.34 137.778L114.487 137.293C114.237 137.266 114.007 137.144 113.846 136.951C113.685 136.758 113.605 136.509 113.624 136.259C115.168 117.059 117.601 98.9375 124.891 87.0749L124.93 87.0117L135.957 89.174L150.679 136.053C150.751 136.294 150.727 136.555 150.61 136.778C150.493 137.002 150.293 137.171 150.054 137.249L145.614 138.676C145.387 138.75 145.14 138.736 144.922 138.637C144.705 138.538 144.532 138.361 144.438 138.141Z"
        fill="#2F2E41"
      />
      <path
        d="M130.26 70.4024C133.157 70.4024 135.505 68.0541 135.505 65.1573C135.505 62.2604 133.157 59.9121 130.26 59.9121C127.363 59.9121 125.015 62.2604 125.015 65.1573C125.015 68.0541 127.363 70.4024 130.26 70.4024Z"
        fill="#FFB8B8"
      />
      <path
        d="M124.214 87.984L124.147 87.9702L124.131 87.9039C123.424 84.9201 123.407 81.6638 124.081 78.2259C124.423 76.4687 125.373 74.8884 126.766 73.7634C128.158 72.6383 129.903 72.0407 131.692 72.0757C133.634 72.1151 135.488 72.8951 136.874 74.256C138.259 75.617 139.073 77.4562 139.147 79.3971C139.372 85.3103 136.956 90.4369 136.931 90.488L136.895 90.5631L124.214 87.984Z"
        fill="#883DCF"
      />
      <path
        d="M110.153 97.9632C110.463 97.9173 110.76 97.8039 111.022 97.6309C111.284 97.4579 111.505 97.2296 111.669 96.962C111.833 96.6944 111.937 96.3941 111.972 96.0821C112.008 95.7702 111.975 95.4543 111.875 95.1566L117.916 90.4941L114.165 89.207L109.003 93.832C108.514 94.0327 108.116 94.4058 107.884 94.8806C107.651 95.3554 107.601 95.8989 107.743 96.4081C107.885 96.9173 108.208 97.3569 108.652 97.6435C109.097 97.9301 109.63 98.0439 110.153 97.9632Z"
        fill="#FFB8B8"
      />
      <path
        d="M113.453 94.4461C113.256 94.5624 113.024 94.6045 112.799 94.5648C112.574 94.5251 112.371 94.4062 112.226 94.2297L110.566 92.2231C110.482 92.1211 110.419 92.0028 110.383 91.8757C110.346 91.7485 110.336 91.6151 110.354 91.484C110.371 91.3528 110.416 91.2267 110.484 91.1135C110.553 91.0002 110.644 90.9024 110.752 90.826L117.88 85.7845L126.482 74.412C126.725 74.0218 127.061 73.6985 127.461 73.4714C127.86 73.2442 128.31 73.1205 128.77 73.1114C128.994 73.11 129.217 73.1295 129.437 73.1696C129.874 73.2472 130.285 73.4292 130.636 73.7001C130.987 73.9709 131.267 74.3226 131.453 74.7252C131.647 75.1379 131.74 75.5911 131.724 76.047C131.709 76.5029 131.584 76.9484 131.361 77.3466L124.992 87.661L124.979 87.6836L113.453 94.4461Z"
        fill="#883DCF"
      />
      <path
        d="M131.198 64.8335C130.811 64.1976 129.958 64.0179 129.217 64.0905C128.477 64.163 127.756 64.4233 127.012 64.4176C124.946 64.4015 123.351 61.883 124.22 60.0081C124.932 58.4704 126.712 57.7952 128.321 57.2653C131.696 56.1542 135.573 55.0866 138.636 56.8881C139.612 57.4792 140.412 58.3202 140.955 59.3241C141.497 60.328 141.762 61.4586 141.721 62.5989C141.652 64.0167 141.109 65.4731 141.568 66.8163C142.098 68.3658 143.778 69.1972 145.365 69.5994C148.148 70.3043 151.082 70.1908 153.882 70.8231C156.682 71.4554 159.552 73.1344 160.307 75.9041C160.802 77.7233 160.285 79.6817 159.428 81.3614C158.554 83.0752 157.339 84.5924 155.858 85.8203C154.378 87.0481 152.661 87.9607 150.815 88.5022C148.969 89.0437 147.032 89.2026 145.123 88.9692C143.213 88.7359 141.371 88.1152 139.71 87.1451C136.325 85.1684 133.881 81.8276 132.424 78.1884C131.598 76.0655 131.016 73.8555 130.69 71.601C130.364 69.4905 130.641 67.3578 131.232 65.3056C131.26 65.229 131.271 65.1473 131.265 65.0659C131.259 64.9845 131.236 64.9053 131.198 64.8335Z"
        fill="#2F2E41"
      />
      <path
        d="M150.12 144.439H106.961C106.9 144.439 106.843 144.415 106.8 144.372C106.757 144.33 106.733 144.272 106.733 144.212C106.733 144.151 106.757 144.094 106.8 144.051C106.843 144.008 106.9 143.984 106.961 143.984H150.12C150.18 143.984 150.238 144.008 150.281 144.051C150.323 144.094 150.347 144.151 150.347 144.212C150.347 144.272 150.323 144.33 150.281 144.372C150.238 144.415 150.18 144.439 150.12 144.439Z"
        fill="#CCCCCC"
      />
    </g>
    <defs>
      <clipPath id="clip0_416_26624">
        <rect
          width="160"
          height="144.244"
          fill="white"
          transform="translate(0.5 0.195312)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const NoDraftVideos = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="161"
    height="159"
    viewBox="0 0 161 159"
    fill="none"
  >
    <g clip-path="url(#clip0_416_26829)">
      <path
        d="M160.5 158.271C160.5 158.472 160.338 158.634 160.137 158.634H0.862895C0.661635 158.634 0.5 158.472 0.5 158.271C0.5 158.07 0.661635 157.908 0.862895 157.908H160.137C160.338 157.908 160.5 158.07 160.5 158.271Z"
        fill="#3F3D56"
      />
      <path
        d="M111.058 140.602L112.888 148.225L107.399 149.75L106.179 141.516L111.058 140.602Z"
        fill="#FFB6B6"
      />
      <path
        d="M119.902 115.9L114.108 120.78L110.449 126.574L115.633 129.928L124.477 119.865L119.902 115.9Z"
        fill="#FFB6B6"
      />
      <path
        d="M118.072 65.582L122.951 82.0495C122.951 82.0495 144.603 100.957 142.469 104.921C140.334 108.885 126.001 119.864 126.001 119.864C126.001 119.864 127.065 123.374 123.941 121.924C120.817 120.474 117.462 116.509 117.462 116.509C117.462 116.509 117.462 113.46 118.987 114.07C120.512 114.68 127.526 106.446 127.526 106.446L112.278 92.4179V139.324C112.278 139.324 115.938 142.524 112.278 142.782C108.619 143.04 105.264 143.65 105.264 143.65C105.264 143.65 100.995 142.125 102.52 140.601L97.9453 115.594L94.5908 85.0991L97.0304 69.2415L118.072 65.582Z"
        fill="#2F2E41"
      />
      <path
        d="M108.314 147.31C108.314 147.31 108.619 146.09 109.838 146.09H113.564C113.564 146.09 114.718 151.274 114.413 151.274C114.108 151.274 119.292 157.983 113.803 158.288C108.314 158.593 106.789 157.983 106.789 157.983L106.179 155.576C106.179 155.576 105.264 154.019 105.569 152.799C105.874 151.579 108.314 147.31 108.314 147.31Z"
        fill="#2F2E41"
      />
      <path
        d="M115.938 127.488C115.938 127.488 119.44 127.433 118.604 128.375C117.767 129.318 116.243 130.843 116.243 130.843C116.243 130.843 112.615 138.161 110.922 136.789C109.229 135.417 106.484 120.169 107.399 118.339C108.314 116.51 109.534 116.51 109.534 116.51L115.16 119.893C115.16 119.893 111.668 128.403 115.938 127.488Z"
        fill="#2F2E41"
      />
      <path
        d="M107.399 39.6619L108.619 32.0381L100.385 28.6836L99.165 40.2718L107.399 39.6619Z"
        fill="#FFB6B6"
      />
      <path
        opacity="0.1"
        d="M107.399 39.6619L108.619 32.0381L100.385 28.6836L99.165 40.2718L107.399 39.6619Z"
        fill="black"
      />
      <path
        d="M98.708 38.5939L108.467 36.1543L118.835 39.5088C118.835 39.5088 123.409 52.3168 121.884 56.8911C120.36 61.4654 120.36 70.4003 120.36 70.4003C120.36 70.4003 120.207 66.4972 111.364 69.2418C102.52 71.9863 96.1159 71.6814 96.1159 71.6814L95.8109 60.7031L91.98 50.9516C90.3426 46.7836 92.1075 42.0513 96.0744 39.9734L98.708 38.5939Z"
        fill="#E6E6E6"
      />
      <path
        d="M143.504 74.5197C144.881 76.1694 145.146 78.2171 144.096 79.0932C143.046 79.9694 141.078 79.3422 139.701 77.6917C139.142 77.04 138.747 76.2638 138.549 75.4281L132.803 68.3648L135.602 66.0996L141.482 72.981C142.269 73.3251 142.962 73.8527 143.504 74.5197Z"
        fill="#FFB6B6"
      />
      <path
        d="M118.391 39.6074L116.392 47.529L126.18 63.1971L136.172 73.2395L139.411 70.5527L129.333 58.014L121.695 41.8584L118.391 39.6074Z"
        fill="#E6E6E6"
      />
      <path
        d="M141.693 31.9613C148.663 31.4462 153.786 23.8801 153.134 15.0621C152.482 6.24398 146.304 -0.486885 139.333 0.0282331C132.363 0.543351 127.24 8.10939 127.892 16.9275C128.543 25.7455 134.722 32.4764 141.693 31.9613Z"
        fill="#883DCF"
      />
      <path
        d="M141.428 31.9609H140.818V92.7803H141.428V31.9609Z"
        fill="#2F2E41"
      />
      <path
        d="M141.58 30.6777L141.653 32.8328L142.627 34.8279L138.563 34.6207L140.271 32.9027L140.078 30.9445L141.58 30.6777Z"
        fill="#883DCF"
      />
      <path
        d="M105.318 34.4902C109.956 34.4902 113.715 30.731 113.715 26.0937C113.715 21.4565 109.956 17.6973 105.318 17.6973C100.681 17.6973 96.9219 21.4565 96.9219 26.0937C96.9219 30.731 100.681 34.4902 105.318 34.4902Z"
        fill="#FFB6B6"
      />
      <path
        d="M104.648 14.8045C104.891 14.9463 105.217 14.7318 105.295 14.4614C105.372 14.1911 105.282 13.9041 105.192 13.6376L104.739 12.2957C104.417 11.3441 104.076 10.3592 103.381 9.63486C102.33 8.5416 100.661 8.26348 99.1585 8.46497C97.2289 8.72372 95.3251 9.76858 94.4279 11.4964C93.5307 13.2242 93.9127 15.6351 95.5339 16.7131C93.2233 19.3613 92.4179 22.3127 92.5451 25.825C92.6722 29.3372 96.4997 32.5696 98.9957 35.0439C99.5531 34.706 100.06 33.1225 99.7534 32.5472C99.4468 31.972 99.886 31.3055 99.5064 30.7756C99.1268 30.2458 98.8092 31.0895 99.193 30.5626C99.4352 30.2301 98.4899 29.4653 98.8499 29.2663C100.591 28.3039 101.17 26.1336 102.264 24.4716C103.583 22.467 105.84 21.1095 108.229 20.8843C109.545 20.7602 110.935 20.9849 112.014 21.7489C113.093 22.513 113.791 23.8783 113.541 25.1763C114.189 24.5185 114.511 23.5545 114.39 22.6393C114.268 21.7241 113.705 20.8779 112.908 20.4121C113.393 18.8092 112.977 16.9654 111.852 15.7251C110.727 14.4847 106.164 14.696 104.521 15.0224"
        fill="#2F2E41"
      />
      <path
        d="M104.393 20.9336C102.218 21.1684 100.647 23.0528 99.3208 24.793C98.5564 25.796 97.7556 26.9036 97.7748 28.1646C97.7942 29.4394 98.6466 30.5329 99.054 31.7411C99.7198 33.7159 99.0709 36.0655 97.4858 37.4186C99.052 37.7158 100.745 36.5415 101.015 34.9705C101.33 33.1416 99.9439 31.3765 100.108 29.528C100.253 27.8994 101.536 26.6461 102.627 25.4284C103.718 24.2108 104.743 22.595 104.241 21.039"
        fill="#2F2E41"
      />
      <path
        d="M96.3852 71.5293H33.6658C26.5534 71.5293 20.7876 77.2951 20.7876 84.4075V145.225C20.7876 152.338 26.5533 158.103 33.6657 158.103H96.3852C103.498 158.103 109.263 152.338 109.263 145.225V84.4075C109.263 77.2951 103.498 71.5293 96.3852 71.5293Z"
        fill="white"
      />
      <path
        d="M96.3855 158.408H33.666C26.3969 158.408 20.4829 152.495 20.4829 145.225V84.4077C20.4829 77.1386 26.3969 71.2246 33.666 71.2246H96.3855C103.655 71.2246 109.569 77.1386 109.569 84.4077V145.225C109.569 152.495 103.655 158.408 96.3855 158.408ZM33.666 71.8345C26.7331 71.8345 21.0928 77.4748 21.0928 84.4077V145.225C21.0928 152.158 26.7331 157.799 33.666 157.799H96.3855C103.318 157.799 108.959 152.158 108.959 145.225V84.4077C108.959 77.4748 103.318 71.8345 96.3855 71.8345H33.666Z"
        fill="#3F3D56"
      />
      <path
        d="M89.7673 77.5122C90.5705 77.5122 91.2217 76.8611 91.2217 76.0578C91.2217 75.2546 90.5705 74.6035 89.7673 74.6035C88.9641 74.6035 88.313 75.2546 88.313 76.0578C88.313 76.8611 88.9641 77.5122 89.7673 77.5122Z"
        fill="#3F3D56"
      />
      <path
        d="M93.7668 77.5122C94.57 77.5122 95.2212 76.8611 95.2212 76.0578C95.2212 75.2546 94.57 74.6035 93.7668 74.6035C92.9636 74.6035 92.3125 75.2546 92.3125 76.0578C92.3125 76.8611 92.9636 77.5122 93.7668 77.5122Z"
        fill="#3F3D56"
      />
      <path
        d="M97.7663 77.5122C98.5696 77.5122 99.2207 76.8611 99.2207 76.0578C99.2207 75.2546 98.5696 74.6035 97.7663 74.6035C96.9631 74.6035 96.312 75.2546 96.312 76.0578C96.312 76.8611 96.9631 77.5122 97.7663 77.5122Z"
        fill="#3F3D56"
      />
      <path
        d="M31.6099 136.812C31.1581 136.812 30.7905 137.18 30.7905 137.632C30.7905 137.852 30.8756 138.056 31.03 138.206C31.1858 138.366 31.3899 138.451 31.6099 138.451H97.2049C97.6568 138.451 98.0243 138.084 98.0243 137.632C98.0243 137.412 97.9392 137.208 97.7848 137.057C97.629 136.898 97.425 136.812 97.2049 136.812H31.6099Z"
        fill="#E6E6E6"
      />
      <path
        d="M66.6841 136.539V138.724H31.6101C31.3097 138.724 31.0365 138.604 30.8399 138.402C30.6377 138.205 30.5176 137.932 30.5176 137.632C30.5176 137.031 31.0092 136.539 31.6101 136.539L66.6841 136.539Z"
        fill="#883DCF"
      />
      <path
        d="M95.8392 132.442H84.914C83.7092 132.442 82.729 131.462 82.729 130.257C82.729 129.052 83.7092 128.072 84.914 128.072H95.8392C97.044 128.072 98.0242 129.052 98.0242 130.257C98.0242 131.462 97.044 132.442 95.8392 132.442Z"
        fill="#E6E6E6"
      />
      <path
        d="M54.7951 134.003L47.1578 108.342L46.5732 108.516L54.1837 134.087"
        fill="#2F2E41"
      />
      <path
        d="M55.0591 75.6019C62.0296 75.0868 67.152 67.5207 66.5004 58.7027C65.8487 49.8846 59.6698 43.1537 52.6993 43.6689C45.7288 44.184 40.6064 51.75 41.2581 60.5681C41.9097 69.3862 48.0887 76.117 55.0591 75.6019Z"
        fill="#883DCF"
      />
      <path
        d="M54.7945 75.6016H54.1846V136.421H54.7945V75.6016Z"
        fill="#2F2E41"
      />
      <path
        d="M47.5009 107.598L48.1856 109.642L49.6881 111.277L45.7344 112.237L46.8815 110.103L46.1381 108.281L47.5009 107.598Z"
        fill="#883DCF"
      />
      <path
        d="M54.9459 74.3184L55.0191 76.4735L55.993 78.4685L51.9297 78.2613L53.6377 76.5433L53.4446 74.5851L54.9459 74.3184Z"
        fill="#883DCF"
      />
      <path
        d="M47.7065 108.133C54.2346 105.636 56.9657 96.9164 53.8065 88.6579C50.6473 80.3994 42.7942 75.7291 36.266 78.2263C29.7379 80.7236 27.0068 89.4429 30.166 97.7013C33.3252 105.96 41.1784 110.63 47.7065 108.133Z"
        fill="#883DCF"
      />
      <path
        d="M103.12 68.4069C105.177 69.0279 106.524 70.5928 106.129 71.9019C105.733 73.211 103.745 73.7684 101.687 73.1468C100.862 72.9098 100.105 72.4786 99.4807 71.8892L90.7979 69.1477L91.8907 65.7168L100.585 68.2328C101.432 68.0876 102.301 68.1473 103.12 68.4069Z"
        fill="#FFB6B6"
      />
      <path
        d="M88.0345 45.9141L97.1831 39.2051L96.8781 52.0131L88.3394 62.9915L97.488 66.6509L97.1831 71.8759C97.1831 71.8759 85.5949 69.0905 83.7651 68.7856C81.9354 68.4806 80.4106 64.2113 80.4106 64.2113L88.0345 45.9141Z"
        fill="#E6E6E6"
      />
    </g>
    <defs>
      <clipPath id="clip0_416_26829">
        <rect
          width="160"
          height="158.633"
          fill="white"
          transform="translate(0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const NoAnalytics = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="200"
    height="140"
    viewBox="0 0 200 140"
    fill="none"
  >
    <g clip-path="url(#clip0_418_28240)">
      <path
        d="M176.62 136.454L173.274 136.454L171.682 123.548L176.62 123.549L176.62 136.454Z"
        fill="#FFB8B8"
      />
      <path
        d="M177.473 139.698L166.685 139.697V139.561C166.685 138.447 167.127 137.379 167.915 136.592C168.702 135.804 169.77 135.362 170.884 135.362H170.884L177.474 135.362L177.473 139.698Z"
        fill="#2F2E41"
      />
      <path
        d="M191.387 128.228L189.216 130.774L178.365 123.607L181.57 119.851L191.387 128.228Z"
        fill="#FFB8B8"
      />
      <path
        d="M194.409 129.684L187.405 137.891L187.302 137.803C186.454 137.08 185.929 136.05 185.841 134.94C185.753 133.83 186.11 132.73 186.833 131.883L186.833 131.882L191.11 126.87L194.409 129.684Z"
        fill="#2F2E41"
      />
      <path
        d="M152.862 72.9849L151.82 82.5905L193.924 81.99L175.562 52.0714L160.57 51.5329L152.862 72.9849Z"
        fill="#2F2E41"
      />
      <path
        opacity="0.28"
        d="M152.862 72.9849L151.82 82.5905L193.924 81.99L175.562 52.0714L160.57 51.5329L152.862 72.9849Z"
        fill="black"
      />
      <path
        d="M161.141 74.634L159.655 83.4231V91.2792L171.639 95.1911C171.639 95.1911 170.236 125.706 172.107 127.649L172.061 132.341L177.289 132.579L177.332 129.277L179.548 104.596L180.854 86.4755L178.737 72.737L161.141 74.634Z"
        fill="#2F2E41"
      />
      <path
        d="M170.426 82.1997L159.655 91.2792C159.655 91.2792 159.025 107.661 160.626 109.582C162.001 111.232 179.884 125.464 179.884 125.464C179.884 125.464 183.062 128.379 184.843 128.426C186.625 128.472 188.696 124.736 188.696 124.736L184.571 121.602C184.571 121.602 173.884 111.627 170.711 107.661C169.431 106.06 169.431 105.9 169.431 105.9L169.868 99.2421L174.27 86.8866L170.426 82.1997Z"
        fill="#2F2E41"
      />
      <path
        d="M158.99 38.7089L154.346 38.2797L150.134 38.9817L141.214 38.9845L140.454 45.4494L148.594 47.271L150.578 46.2988L158.847 49.4492L158.99 38.7089Z"
        fill="#2F2E41"
      />
      <path
        d="M164.537 38.6886C164.537 38.6886 174.393 37.7651 176.634 39.5261C178.876 41.2871 183.678 76.1868 183.678 76.1868L164.537 77.2671L161.105 74.9061L162.08 57.4827L164.537 38.6886Z"
        fill="#CCCCCC"
      />
      <path
        d="M167.545 38.4712L156.673 38.7098L146.537 84.5114L154.542 86.2726L167.545 38.4712Z"
        fill="#2F2E41"
      />
      <path
        d="M175.275 38.0841L183.839 40.7645L183.352 59.5829L193.925 82.0818L181.496 83.391L171.672 59.0965L175.275 38.0841Z"
        fill="#2F2E41"
      />
      <path
        d="M189.396 84.3671C189.697 84.1028 189.935 83.7759 190.095 83.4094C190.256 83.0429 190.334 82.6457 190.324 82.2458C190.314 81.8459 190.216 81.4531 190.038 81.0949C189.86 80.7368 189.606 80.4221 189.293 80.1729L191.016 74.1626L187.619 72.2247L185.305 80.7467C184.921 81.3001 184.754 81.9751 184.834 82.6437C184.915 83.3123 185.238 83.9281 185.742 84.3743C186.247 84.8206 186.897 85.0663 187.571 85.065C188.244 85.0637 188.894 84.8153 189.396 84.3671Z"
        fill="#FFB8B8"
      />
      <path
        d="M178.875 41.2871L183.943 40.8476C183.943 40.8476 188.492 45.6057 189.527 49.2897C190.562 52.9737 191.683 64.5002 191.683 64.5002L191.215 81.3624L185.292 77.34L184.895 62.8363L178.875 41.2871Z"
        fill="#2F2E41"
      />
      <path
        d="M177.653 32.8117C179.556 29.3307 178.277 24.9662 174.796 23.0633C171.315 21.1604 166.95 22.4397 165.048 25.9207C163.145 29.4018 164.424 33.7663 167.905 35.6692C171.386 37.5721 175.751 36.2928 177.653 32.8117Z"
        fill="#FFB8B8"
      />
      <path
        d="M170.55 26.3674C169.607 26.1496 168.629 26.391 167.674 26.5474C166.72 26.7038 165.668 26.7554 164.86 26.2228C164.264 25.8295 163.897 25.1788 163.553 24.5529C163.409 24.3293 163.314 24.0784 163.272 23.816C163.231 23.3531 163.526 22.9307 163.838 22.587C165.291 20.9904 167.414 20.1165 169.554 19.839C171.319 19.5759 173.123 19.7961 174.773 20.4763C176.398 21.189 177.795 22.4941 178.434 24.1494C178.728 24.9966 178.912 25.8782 178.98 26.7724C179.077 27.4303 179.089 28.0979 179.017 28.7589C178.931 29.249 178.802 29.7305 178.632 30.1981L177.574 33.3935C177.498 33.6954 177.356 33.9767 177.158 34.2172C177.056 34.3349 176.919 34.4165 176.767 34.4498C176.615 34.4831 176.456 34.4663 176.315 34.4018C176.399 34.0548 176.412 33.6941 176.353 33.3418C176.32 33.1663 176.235 33.0047 176.109 32.8778C175.983 32.751 175.822 32.6647 175.647 32.6301C175.257 32.5887 174.924 32.8911 174.554 33.0213C174.384 33.0715 174.207 33.0878 174.031 33.0694C173.855 33.051 173.685 32.9981 173.53 32.9139C173.375 32.8296 173.238 32.7156 173.126 32.5784C173.015 32.4412 172.932 32.2834 172.882 32.114C172.872 32.0799 172.863 32.0454 172.856 32.0105C172.797 31.6452 172.895 31.2541 172.757 30.9111C172.595 30.5091 172.164 30.2985 171.841 30.0097C171.546 29.7375 171.335 29.3868 171.233 28.9988C171.13 28.6108 171.141 28.2016 171.263 27.8194C171.349 27.5535 171.594 27.4326 171.545 27.1271C171.481 26.7263 170.89 26.4461 170.55 26.3674Z"
        fill="#2F2E41"
      />
      <path d="M90.6883 56.7319H0V0H90.6883V56.7319Z" fill="white" />
      <path
        d="M90.6883 56.7319H0V0H90.6883V56.7319ZM0.414103 56.3178H90.2742V0.414103H0.414103V56.3178Z"
        fill="#3F3D56"
      />
      <path
        d="M79.6108 135.308H27.8481V78.5759H79.6108V135.308Z"
        fill="white"
      />
      <path
        d="M79.6108 135.308H27.8481V78.5759H79.6108V135.308ZM28.2622 134.894H79.1967V78.99H28.2622V134.894Z"
        fill="#3F3D56"
      />
      <path
        d="M72.5713 129.95H61.8047V130.386H72.5713V129.95Z"
        fill="#3F3D56"
      />
      <path
        d="M72.5713 131.259H61.8047V131.696H72.5713V131.259Z"
        fill="#3F3D56"
      />
      <path
        d="M72.5713 132.569H61.8047V133.005H72.5713V132.569Z"
        fill="#3F3D56"
      />
      <path
        d="M53.7295 88.7213V93.2764C56.4322 93.2764 59.0743 94.0779 61.3216 95.5795C63.5688 97.081 65.3203 99.2153 66.3546 101.712C67.3889 104.209 67.6595 106.957 67.1323 109.608C66.605 112.259 65.3035 114.694 63.3924 116.605L66.6133 119.826C69.1615 117.277 70.8968 114.031 71.5999 110.496C72.3029 106.962 71.9421 103.298 70.563 99.9691C69.1839 96.6397 66.8486 93.7941 63.8522 91.792C60.8559 89.7899 57.3332 88.7213 53.7295 88.7213Z"
        fill="#E6E6E6"
      />
      <path
        d="M66.6137 119.826L63.3927 116.605C61.0564 118.941 57.9523 120.352 54.6555 120.576C51.3586 120.799 48.0925 119.82 45.4621 117.82L42.7051 121.448C46.2128 124.114 50.5678 125.419 54.9636 125.12C59.3594 124.822 63.4983 122.941 66.6137 119.826Z"
        fill="#575A89"
      />
      <path
        d="M40.0644 106.942C40.0644 105.147 40.4179 103.37 41.1046 101.712C41.7914 100.054 42.7979 98.5479 44.0669 97.2789C45.3358 96.01 46.8423 95.0034 48.5002 94.3166C50.1582 93.6299 51.9352 93.2764 53.7298 93.2764V88.7213C49.9155 88.7213 46.1974 89.9183 43.0996 92.1435C40.0018 94.3688 37.6804 97.5102 36.4627 101.125C35.245 104.74 35.1924 108.645 36.3123 112.291C37.4323 115.937 39.6682 119.14 42.705 121.448L45.462 117.82C43.7833 116.547 42.4224 114.902 41.4859 113.015C40.5494 111.127 40.0629 109.049 40.0644 106.942Z"
        fill="#883DCF"
      />
      <path d="M156.22 100.109H104.457V43.3771H156.22V100.109Z" fill="white" />
      <path
        d="M156.22 100.109H104.457V43.3771H156.22V100.109ZM104.871 99.6951H155.806V43.7912H104.871V99.6951Z"
        fill="#3F3D56"
      />
      <path
        d="M121.228 78.0582H111.911V92.9659H121.228V78.0582Z"
        fill="#575A89"
      />
      <path
        d="M134.686 67.9127H125.369V92.9659H134.686V67.9127Z"
        fill="#E6E6E6"
      />
      <path
        d="M148.145 56.1108H138.828V92.9659H148.145V56.1108Z"
        fill="#883DCF"
      />
      <path
        d="M151.25 93.3801H109.426V50.1063H109.84V92.9659H151.25V93.3801Z"
        fill="#3F3D56"
      />
      <path
        d="M141.167 44.8876C142.742 44.8876 144.019 43.6108 144.019 42.0358C144.019 40.4609 142.742 39.1841 141.167 39.1841C139.592 39.1841 138.315 40.4609 138.315 42.0358C138.315 43.6108 139.592 44.8876 141.167 44.8876Z"
        fill="#FFB8B8"
      />
      <path
        d="M199.762 139.973H155.323C155.26 139.973 155.199 139.948 155.155 139.903C155.11 139.859 155.085 139.798 155.085 139.735C155.085 139.672 155.11 139.612 155.155 139.567C155.199 139.523 155.26 139.498 155.323 139.498H199.762C199.825 139.498 199.886 139.523 199.93 139.567C199.975 139.612 200 139.672 200 139.735C200 139.798 199.975 139.859 199.93 139.903C199.886 139.948 199.825 139.973 199.762 139.973Z"
        fill="#CCCCCC"
      />
      <path
        d="M78.094 44.8671H12.5946C12.5403 44.8671 12.4881 44.8455 12.4497 44.8071C12.4112 44.7686 12.3897 44.7165 12.3896 44.6621V13.2683C12.3896 13.2139 12.4112 13.1618 12.4497 13.1233C12.4881 13.0849 12.5403 13.0633 12.5946 13.0633C12.649 13.0633 12.7011 13.0849 12.7396 13.1233C12.778 13.1618 12.7996 13.2139 12.7996 13.2683V44.4572H78.094C78.121 44.4571 78.1477 44.4623 78.1727 44.4726C78.1976 44.4828 78.2203 44.4979 78.2394 44.5169C78.2585 44.536 78.2737 44.5586 78.284 44.5835C78.2944 44.6084 78.2997 44.6352 78.2997 44.6621C78.2997 44.6891 78.2944 44.7158 78.284 44.7407C78.2737 44.7657 78.2585 44.7883 78.2394 44.8073C78.2203 44.8264 78.1976 44.8415 78.1727 44.8517C78.1477 44.862 78.121 44.8672 78.094 44.8671Z"
        fill="#3F3D56"
      />
      <path
        d="M26.5889 42.6123H20.6314C20.4701 42.6121 20.3155 42.5479 20.2014 42.4339C20.0873 42.3198 20.0231 42.1652 20.0229 42.0038V33.7915C20.0231 33.6302 20.0873 33.4755 20.2014 33.3615C20.3155 33.2474 20.4701 33.1832 20.6314 33.183H26.5889C26.7502 33.1832 26.9048 33.2474 27.0189 33.3615C27.133 33.4755 27.1972 33.6302 27.1974 33.7915V42.0038C27.1972 42.1651 27.133 42.3198 27.0189 42.4339C26.9049 42.5479 26.7502 42.6121 26.5889 42.6123Z"
        fill="#883DCF"
      />
      <path
        d="M37.4531 42.6123H31.4957C31.3344 42.6121 31.1797 42.5479 31.0656 42.4339C30.9516 42.3198 30.8874 42.1651 30.8872 42.0038V26.0021C30.8874 25.8408 30.9516 25.6861 31.0656 25.5721C31.1797 25.458 31.3344 25.3939 31.4957 25.3937H37.4531C37.6144 25.3939 37.7691 25.458 37.8832 25.5721C37.9972 25.6861 38.0614 25.8408 38.0616 26.0021V42.0038C38.0614 42.1651 37.9972 42.3198 37.8832 42.4339C37.7691 42.5479 37.6144 42.6121 37.4531 42.6123Z"
        fill="#883DCF"
      />
      <path
        d="M48.3169 42.6123H42.3595C42.1982 42.6121 42.0435 42.5479 41.9294 42.4339C41.8153 42.3198 41.7512 42.1652 41.751 42.0038V33.7915C41.7512 33.6302 41.8153 33.4755 41.9294 33.3615C42.0435 33.2474 42.1982 33.1832 42.3595 33.183H48.3169C48.4782 33.1832 48.6329 33.2474 48.747 33.3615C48.861 33.4755 48.9252 33.6302 48.9254 33.7915V42.0038C48.9252 42.1652 48.861 42.3198 48.747 42.4339C48.6329 42.5479 48.4782 42.6121 48.3169 42.6123Z"
        fill="#883DCF"
      />
      <path
        d="M59.1812 42.6123H53.2237C53.0661 42.6157 52.9135 42.5565 52.7995 42.4476C52.6854 42.3387 52.6192 42.1891 52.6152 42.0314V22.8999C52.6192 22.7422 52.6854 22.5926 52.7995 22.4837C52.9135 22.3748 53.0661 22.3156 53.2237 22.319H59.1812C59.3388 22.3156 59.4914 22.3748 59.6054 22.4837C59.7195 22.5926 59.7857 22.7422 59.7896 22.8999V42.0314C59.7857 42.1891 59.7195 42.3387 59.6054 42.4476C59.4914 42.5565 59.3388 42.6157 59.1812 42.6123Z"
        fill="#883DCF"
      />
      <path
        d="M70.0454 42.6123H64.088C63.9267 42.6121 63.772 42.548 63.6579 42.4339C63.5439 42.3198 63.4797 42.1652 63.4795 42.0039V17.3929C63.4797 17.2315 63.5439 17.0769 63.6579 16.9628C63.772 16.8488 63.9267 16.7846 64.088 16.7844H70.0454C70.2067 16.7846 70.3614 16.8488 70.4755 16.9628C70.5895 17.0769 70.6537 17.2315 70.6539 17.3929V42.0039C70.6537 42.1652 70.5895 42.3198 70.4755 42.4339C70.3614 42.548 70.2067 42.6121 70.0454 42.6123Z"
        fill="#883DCF"
      />
      <path
        d="M23.6103 30.7233C24.2895 30.7233 24.8402 30.1726 24.8402 29.4934C24.8402 28.8141 24.2895 28.2635 23.6103 28.2635C22.931 28.2635 22.3804 28.8141 22.3804 29.4934C22.3804 30.1726 22.931 30.7233 23.6103 30.7233Z"
        fill="#3F3D56"
      />
      <path
        d="M34.474 22.7289C35.1533 22.7289 35.7039 22.1783 35.7039 21.499C35.7039 20.8197 35.1533 20.2691 34.474 20.2691C33.7948 20.2691 33.2441 20.8197 33.2441 21.499C33.2441 22.1783 33.7948 22.7289 34.474 22.7289Z"
        fill="#3F3D56"
      />
      <path
        d="M45.3383 30.7233C46.0176 30.7233 46.5682 30.1726 46.5682 29.4934C46.5682 28.8141 46.0176 28.2635 45.3383 28.2635C44.659 28.2635 44.1084 28.8141 44.1084 29.4934C44.1084 30.1726 44.659 30.7233 45.3383 30.7233Z"
        fill="#3F3D56"
      />
      <path
        d="M56.2026 18.8343C56.8818 18.8343 57.4325 18.2836 57.4325 17.6043C57.4325 16.9251 56.8818 16.3745 56.2026 16.3745C55.5233 16.3745 54.9727 16.9251 54.9727 17.6043C54.9727 18.2836 55.5233 18.8343 56.2026 18.8343Z"
        fill="#3F3D56"
      />
      <path
        d="M67.0663 14.3246C67.7456 14.3246 68.2962 13.774 68.2962 13.0947C68.2962 12.4155 67.7456 11.8648 67.0663 11.8648C66.3871 11.8648 65.8364 12.4155 65.8364 13.0947C65.8364 13.774 66.3871 14.3246 67.0663 14.3246Z"
        fill="#3F3D56"
      />
      <path
        d="M45.3642 29.7689L34.4744 21.6122L23.733 29.6574L23.4873 29.3292L34.4744 21.0998L45.3124 29.2178L56.0827 17.4318L56.126 17.4142L66.99 13.0367L67.1432 13.4172L56.3223 17.7771L45.3642 29.7689Z"
        fill="#3F3D56"
      />
    </g>
    <defs>
      <clipPath id="clip0_418_28240">
        <rect width="200" height="139.973" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const Step1 = () => (
  <svg
    width="134"
    height="80"
    viewBox="0 0 134 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_841_19824)">
      <path
        d="M133.436 62.4288C133.439 62.9646 133.229 63.4796 132.852 63.8605C132.475 64.2413 131.962 64.4568 131.427 64.4596H43.0044C42.7391 64.4582 42.4766 64.4046 42.2321 64.3018C41.9875 64.199 41.7656 64.0491 41.5789 63.8605C41.3923 63.6719 41.2447 63.4484 41.1444 63.2028C41.0442 62.9572 40.9933 62.6942 40.9947 62.4289V2.03079C40.9919 1.49499 41.202 0.980026 41.5789 0.59918C41.9558 0.218335 42.4685 0.00280197 43.0043 0H131.426C131.692 0.00138 131.954 0.0550016 132.199 0.157802C132.443 0.260603 132.665 0.41057 132.852 0.59914C133.039 0.787711 133.186 1.01119 133.286 1.25683C133.387 1.50246 133.438 1.76543 133.436 2.03073V62.4288Z"
        fill="#E6E6E6"
      />
      <path
        d="M132.393 61.4398C132.393 61.9606 132.187 62.46 131.818 62.8283C131.45 63.1965 130.951 63.4034 130.43 63.4034H44.0622C43.5415 63.4034 43.042 63.1965 42.6738 62.8283C42.3055 62.46 42.0986 61.9606 42.0986 61.4398V3.04748C42.0986 2.5267 42.3055 2.02724 42.6738 1.65899C43.042 1.29074 43.5415 1.08386 44.0622 1.08386H130.434C130.954 1.08491 131.452 1.29225 131.82 1.66039C132.187 2.02852 132.393 2.52738 132.393 3.04748V61.4398Z"
        fill="white"
      />
      <path
        d="M46.7144 3.61902H127.869C128.178 3.61902 128.475 3.74182 128.694 3.96039C128.912 4.17897 129.035 4.47543 129.035 4.78455V59.4761C129.035 59.7852 128.912 60.0817 128.694 60.3003C128.475 60.5189 128.178 60.6417 127.869 60.6417H46.7144C46.4052 60.6417 46.1088 60.5189 45.8902 60.3003C45.6716 60.0817 45.5488 59.7852 45.5488 59.4761V4.78455C45.5488 4.47543 45.6716 4.17897 45.8902 3.96039C46.1088 3.74182 46.4052 3.61902 46.7144 3.61902Z"
        fill="#E6E6E6"
      />
      <path
        d="M47.7417 4.93079H126.754C127.063 4.93079 127.36 5.05358 127.578 5.27216C127.797 5.49074 127.919 5.7872 127.919 6.09631V57.8695C127.919 58.1786 127.797 58.475 127.578 58.6936C127.36 58.9122 127.063 59.035 126.754 59.035H47.7417C47.4326 59.035 47.1361 58.9122 46.9175 58.6936C46.699 58.475 46.5762 58.1786 46.5762 57.8695V6.09631C46.5762 5.7872 46.699 5.49074 46.9175 5.27216C47.1361 5.05358 47.4326 4.93079 47.7417 4.93079Z"
        fill="white"
      />
      <path
        d="M103.53 40.8966C103.219 40.8964 102.911 40.8309 102.627 40.7043C102.561 40.675 102.498 40.6391 102.439 40.5974L96.6967 36.5554C96.4055 36.3504 96.1678 36.0784 96.0038 35.7623C95.8397 35.4462 95.754 35.0953 95.7539 34.7392V29.2267C95.754 28.8705 95.8397 28.5196 96.0037 28.2036C96.1678 27.8875 96.4055 27.6155 96.6967 27.4105L102.439 23.3685C102.498 23.3267 102.561 23.2909 102.627 23.2615C102.965 23.1112 103.336 23.0477 103.705 23.0769C104.074 23.1061 104.43 23.227 104.74 23.4287C105.051 23.6304 105.306 23.9064 105.482 24.2318C105.659 24.5571 105.751 24.9214 105.751 25.2916V38.6743C105.751 39.2635 105.517 39.8286 105.101 40.2452C104.684 40.6619 104.119 40.8959 103.53 40.8959L103.53 40.8966Z"
        fill="#883DCF"
      />
      <path
        d="M90.6784 41.9804H74.6629C71.3955 41.9777 68.7475 39.8656 68.7441 37.2593V26.7065C68.7475 24.1003 71.3955 21.9882 74.6629 21.9855H90.7202C93.9644 21.9885 96.5934 24.0855 96.5972 26.6732V37.2593C96.5938 39.8656 93.9459 41.9777 90.6784 41.9804Z"
        fill="#883DCF"
      />
      <path
        d="M119.208 55.7058C121.838 55.7058 123.971 53.5732 123.971 50.9426C123.971 48.312 121.838 46.1794 119.208 46.1794C116.577 46.1794 114.444 48.312 114.444 50.9426C114.444 53.5732 116.577 55.7058 119.208 55.7058Z"
        fill="#E6E6E6"
      />
      <path
        d="M121.681 50.8572L117.885 48.6652C117.87 48.6565 117.853 48.652 117.835 48.652C117.818 48.652 117.801 48.6565 117.786 48.6652C117.771 48.6738 117.759 48.6863 117.75 48.7013C117.741 48.7163 117.737 48.7333 117.737 48.7506V53.1347C117.737 53.152 117.741 53.169 117.75 53.184C117.759 53.199 117.771 53.2114 117.786 53.2201C117.801 53.2287 117.818 53.2333 117.835 53.2333C117.853 53.2333 117.87 53.2287 117.885 53.2201L121.681 51.028C121.696 51.0194 121.709 51.0069 121.718 50.9919C121.726 50.9769 121.731 50.9599 121.731 50.9426C121.731 50.9253 121.726 50.9083 121.718 50.8933C121.709 50.8783 121.696 50.8659 121.681 50.8572L117.885 48.6652C117.87 48.6565 117.853 48.652 117.835 48.652C117.818 48.652 117.801 48.6565 117.786 48.6652C117.771 48.6738 117.759 48.6863 117.75 48.7013C117.741 48.7163 117.737 48.7333 117.737 48.7506V53.1347C117.737 53.152 117.741 53.169 117.75 53.184C117.759 53.199 117.771 53.2114 117.786 53.2201C117.801 53.2287 117.818 53.2333 117.835 53.2333C117.853 53.2333 117.87 53.2287 117.885 53.2201L121.681 51.028C121.696 51.0194 121.709 51.0069 121.718 50.9919C121.726 50.9769 121.731 50.9599 121.731 50.9426C121.731 50.9253 121.726 50.9083 121.718 50.8933C121.709 50.8783 121.696 50.8659 121.681 50.8572Z"
        fill="white"
      />
      <path
        d="M113.023 69.6723C113.026 70.208 112.816 70.723 112.439 71.1039C112.062 71.4847 111.549 71.7002 111.013 71.703H22.5913C22.326 71.7017 22.0636 71.648 21.819 71.5452C21.5744 71.4424 21.3525 71.2925 21.1658 71.1039C20.9792 70.9153 20.8316 70.6918 20.7313 70.4462C20.6311 70.2006 20.5802 69.9376 20.5816 69.6723V9.27419C20.5788 8.7384 20.7889 8.22343 21.1658 7.84259C21.5427 7.46174 22.0554 7.24621 22.5912 7.24341H111.013C111.279 7.24479 111.541 7.29841 111.786 7.40121C112.03 7.50401 112.252 7.65398 112.439 7.84255C112.625 8.03112 112.773 8.2546 112.873 8.50024C112.974 8.74587 113.024 9.00884 113.023 9.27414V69.6723Z"
        fill="#E6E6E6"
      />
      <path
        d="M111.98 68.6832C111.98 69.204 111.773 69.7034 111.405 70.0717C111.037 70.4399 110.537 70.6468 110.016 70.6468H23.6487C23.1279 70.6468 22.6284 70.4399 22.2602 70.0717C21.8919 69.7034 21.6851 69.204 21.6851 68.6832V10.2909C21.6851 9.7701 21.8919 9.27065 22.2602 8.9024C22.6284 8.53415 23.1279 8.32727 23.6487 8.32727H110.02C110.54 8.32832 111.039 8.53566 111.406 8.9038C111.774 9.27193 111.98 9.77079 111.98 10.2909V68.6832Z"
        fill="white"
      />
      <path
        d="M26.3013 10.8624H107.456C107.765 10.8624 108.062 10.9852 108.28 11.2038C108.499 11.4224 108.622 11.7188 108.622 12.028V66.7195C108.622 67.0286 108.499 67.3251 108.28 67.5437C108.062 67.7623 107.765 67.8851 107.456 67.8851H26.3013C25.9922 67.8851 25.6957 67.7623 25.4771 67.5437C25.2585 67.3251 25.1357 67.0286 25.1357 66.7195V12.028C25.1357 11.7188 25.2585 11.4224 25.4771 11.2038C25.6957 10.9852 25.9922 10.8624 26.3013 10.8624Z"
        fill="#E6E6E6"
      />
      <path
        d="M27.3286 12.1742H106.341C106.65 12.1742 106.946 12.297 107.165 12.5156C107.384 12.7341 107.506 13.0306 107.506 13.3397V65.1129C107.506 65.422 107.384 65.7184 107.165 65.937C106.946 66.1556 106.65 66.2784 106.341 66.2784H27.3286C27.0195 66.2784 26.723 66.1556 26.5045 65.937C26.2859 65.7184 26.1631 65.422 26.1631 65.1129V13.3397C26.1631 13.0306 26.2859 12.7341 26.5045 12.5156C26.723 12.297 27.0195 12.1742 27.3286 12.1742Z"
        fill="white"
      />
      <path
        d="M83.1166 48.14C82.8056 48.1398 82.4981 48.0743 82.2141 47.9477C82.148 47.9184 82.0849 47.8826 82.0259 47.8408L76.2836 43.7988C75.9924 43.5938 75.7548 43.3218 75.5907 43.0057C75.4266 42.6896 75.3409 42.3387 75.3408 41.9826V36.4701C75.3409 36.114 75.4266 35.7631 75.5907 35.447C75.7547 35.1309 75.9924 34.8589 76.2836 34.6539L82.0259 30.6119C82.0849 30.5701 82.148 30.5343 82.2141 30.5049C82.5523 30.3546 82.9228 30.2911 83.2918 30.3203C83.6608 30.3495 84.0167 30.4704 84.3271 30.6721C84.6375 30.8738 84.8926 31.1498 85.0692 31.4752C85.2458 31.8005 85.3383 32.1648 85.3383 32.535V45.9177C85.3383 46.5069 85.1042 47.072 84.6876 47.4886C84.2709 47.9053 83.7058 48.1393 83.1166 48.1393L83.1166 48.14Z"
        fill="#883DCF"
      />
      <path
        d="M70.2653 49.2238H54.2498C50.9824 49.2211 48.3344 47.109 48.3311 44.5028V33.9499C48.3344 31.3437 50.9824 29.2316 54.2498 29.2289H70.3071C73.5513 29.2319 76.1803 31.3289 76.1841 33.9166V44.5028C76.1808 47.109 73.5328 49.2211 70.2653 49.2238Z"
        fill="#883DCF"
      />
      <path
        d="M98.7944 62.9492C101.425 62.9492 103.558 60.8166 103.558 58.186C103.558 55.5554 101.425 53.4229 98.7944 53.4229C96.1638 53.4229 94.0312 55.5554 94.0312 58.186C94.0312 60.8166 96.1638 62.9492 98.7944 62.9492Z"
        fill="#E6E6E6"
      />
      <path
        d="M101.268 58.1007L97.4716 55.9086C97.4566 55.8999 97.4396 55.8954 97.4223 55.8954C97.405 55.8954 97.388 55.8999 97.373 55.9086C97.358 55.9173 97.3456 55.9297 97.3369 55.9447C97.3283 55.9597 97.3237 55.9767 97.3237 55.994V60.3781C97.3237 60.3954 97.3283 60.4124 97.3369 60.4274C97.3456 60.4424 97.358 60.4548 97.373 60.4635C97.388 60.4721 97.405 60.4767 97.4223 60.4767C97.4396 60.4767 97.4566 60.4721 97.4716 60.4635L101.268 58.2714C101.283 58.2628 101.296 58.2503 101.304 58.2353C101.313 58.2203 101.318 58.2033 101.318 58.186C101.318 58.1687 101.313 58.1517 101.304 58.1367C101.296 58.1218 101.283 58.1093 101.268 58.1007L97.4716 55.9086C97.4566 55.8999 97.4396 55.8954 97.4223 55.8954C97.405 55.8954 97.388 55.8999 97.373 55.9086C97.358 55.9173 97.3456 55.9297 97.3369 55.9447C97.3283 55.9597 97.3237 55.9767 97.3237 55.994V60.3781C97.3237 60.3954 97.3283 60.4124 97.3369 60.4274C97.3456 60.4424 97.358 60.4548 97.373 60.4635C97.388 60.4721 97.405 60.4767 97.4223 60.4767C97.4396 60.4767 97.4566 60.4721 97.4716 60.4635L101.268 58.2714C101.283 58.2628 101.296 58.2503 101.304 58.2353C101.313 58.2203 101.318 58.2033 101.318 58.186C101.318 58.1687 101.313 58.1517 101.304 58.1367C101.296 58.1218 101.283 58.1093 101.268 58.1007Z"
        fill="white"
      />
      <path
        d="M93.005 77.9692C93.0078 78.505 92.7977 79.02 92.4208 79.4009C92.0439 79.7817 91.5312 79.9972 90.9954 80H2.57322C2.30792 79.9986 2.04549 79.945 1.80091 79.8422C1.55633 79.7394 1.3344 79.5895 1.14778 79.4009C0.961162 79.2123 0.813512 78.9888 0.713261 78.7432C0.613011 78.4976 0.562124 78.2346 0.563504 77.9693V17.5712C0.560703 17.0354 0.770859 16.5204 1.14774 16.1396C1.52463 15.7587 2.03736 15.5432 2.57316 15.5404H90.9953C91.2606 15.5418 91.523 15.5954 91.7676 15.6982C92.0122 15.801 92.2341 15.951 92.4208 16.1395C92.6074 16.3281 92.755 16.5516 92.8553 16.7972C92.9555 17.0429 93.0064 17.3058 93.005 17.5711V77.9692Z"
        fill="#E6E6E6"
      />
      <path
        d="M91.9618 76.9802C91.9618 77.501 91.755 78.0004 91.3867 78.3687C91.0185 78.7369 90.519 78.9438 89.9982 78.9438H3.63061C3.10983 78.9438 2.61037 78.7369 2.24212 78.3687C1.87387 78.0004 1.66699 77.501 1.66699 76.9802V18.5879C1.66699 18.0671 1.87387 17.5676 2.24212 17.1994C2.61037 16.8311 3.10983 16.6243 3.63061 16.6243H90.0022C90.5223 16.6253 91.0207 16.8327 91.3881 17.2008C91.7555 17.5689 91.9618 18.0678 91.9618 18.5879V76.9802Z"
        fill="white"
      />
      <path
        d="M6.2832 19.1594H87.4382C87.7473 19.1594 88.0438 19.2822 88.2624 19.5008C88.4809 19.7194 88.6037 20.0158 88.6037 20.325V75.0165C88.6037 75.3256 88.4809 75.6221 88.2624 75.8407C88.0438 76.0593 87.7473 76.1821 87.4382 76.1821H6.2832C5.97409 76.1821 5.67763 76.0593 5.45905 75.8407C5.24047 75.6221 5.11768 75.3256 5.11768 75.0165V20.325C5.11768 20.0158 5.24047 19.7194 5.45905 19.5008C5.67763 19.2822 5.97409 19.1594 6.2832 19.1594Z"
        fill="#E6E6E6"
      />
      <path
        d="M7.31055 20.4712H86.3228C86.4759 20.4712 86.6274 20.5013 86.7688 20.5599C86.9102 20.6185 87.0387 20.7043 87.147 20.8126C87.2552 20.9208 87.341 21.0493 87.3996 21.1907C87.4582 21.3321 87.4883 21.4837 87.4883 21.6367V73.4099C87.4883 73.5629 87.4582 73.7145 87.3996 73.8559C87.341 73.9973 87.2552 74.1258 87.147 74.234C87.0387 74.3423 86.9102 74.4281 86.7688 74.4867C86.6274 74.5452 86.4759 74.5754 86.3228 74.5754H7.31055C7.00143 74.5754 6.70497 74.4526 6.48639 74.234C6.26782 74.0154 6.14502 73.719 6.14502 73.4099V21.6367C6.14502 21.3276 6.26782 21.0311 6.48639 20.8126C6.70497 20.594 7.00143 20.4712 7.31055 20.4712Z"
        fill="white"
      />
      <path
        d="M63.0985 56.4369C62.7876 56.4367 62.4801 56.3711 62.196 56.2446C62.1299 56.2152 62.0669 56.1794 62.0079 56.1377L56.2656 52.0956C55.9744 51.8906 55.7367 51.6186 55.5726 51.3026C55.4085 50.9865 55.3228 50.6356 55.3228 50.2794V44.767C55.3228 44.4108 55.4085 44.0599 55.5726 43.7438C55.7367 43.4278 55.9744 43.1558 56.2656 42.9508L62.0079 38.9087C62.0669 38.867 62.1299 38.8312 62.196 38.8018C62.5342 38.6514 62.9047 38.588 63.2737 38.6171C63.6428 38.6463 63.9986 38.7673 64.309 38.969C64.6194 39.1707 64.8745 39.4467 65.0511 39.772C65.2277 40.0974 65.3202 40.4617 65.3202 40.8319V54.2145C65.3202 54.8038 65.0861 55.3689 64.6695 55.7855C64.2528 56.2021 63.6878 56.4362 63.0985 56.4362L63.0985 56.4369Z"
        fill="url(#paint0_linear_841_19824)"
      />
      <path
        d="M50.2473 57.5206H34.2318C30.9643 57.518 28.3163 55.4059 28.313 52.7996V42.2468C28.3163 39.6405 30.9643 37.5284 34.2318 37.5258H50.2891C53.5332 37.5288 56.1622 39.6258 56.166 42.2134V52.7996C56.1627 55.4059 53.5147 57.518 50.2473 57.5206Z"
        fill="url(#paint1_linear_841_19824)"
      />
      <path
        d="M78.7763 71.2462C81.407 71.2462 83.5395 69.1136 83.5395 66.483C83.5395 63.8524 81.407 61.7198 78.7763 61.7198C76.1457 61.7198 74.0132 63.8524 74.0132 66.483C74.0132 69.1136 76.1457 71.2462 78.7763 71.2462Z"
        fill="#883DCF"
      />
      <path
        d="M81.2503 66.3977L77.4536 64.2056C77.4386 64.1969 77.4216 64.1924 77.4043 64.1924C77.3869 64.1924 77.3699 64.1969 77.355 64.2056C77.34 64.2143 77.3275 64.2267 77.3189 64.2417C77.3102 64.2567 77.3057 64.2737 77.3057 64.291V68.6751C77.3057 68.6924 77.3102 68.7094 77.3189 68.7244C77.3275 68.7394 77.34 68.7518 77.355 68.7605C77.3699 68.7691 77.3869 68.7737 77.4043 68.7737C77.4216 68.7737 77.4386 68.7691 77.4536 68.7605L81.2503 66.5684C81.2653 66.5598 81.2777 66.5473 81.2864 66.5323C81.295 66.5173 81.2996 66.5003 81.2996 66.483C81.2996 66.4657 81.295 66.4487 81.2864 66.4337C81.2777 66.4188 81.2653 66.4063 81.2503 66.3977L77.4536 64.2056C77.4386 64.1969 77.4216 64.1924 77.4043 64.1924C77.3869 64.1924 77.3699 64.1969 77.355 64.2056C77.34 64.2143 77.3275 64.2267 77.3189 64.2417C77.3102 64.2567 77.3057 64.2737 77.3057 64.291V68.6751C77.3057 68.6924 77.3102 68.7094 77.3189 68.7244C77.3275 68.7394 77.34 68.7518 77.355 68.7605C77.3699 68.7691 77.3869 68.7737 77.4043 68.7737C77.4216 68.7737 77.4386 68.7691 77.4536 68.7605L81.2503 66.5684C81.2653 66.5598 81.2777 66.5473 81.2864 66.5323C81.295 66.5173 81.2996 66.5003 81.2996 66.483C81.2996 66.4657 81.295 66.4487 81.2864 66.4337C81.2777 66.4188 81.2653 66.4063 81.2503 66.3977Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_841_19824"
        x1="55.3228"
        y1="38.6102"
        x2="66.403"
        y2="39.3108"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#883DCF" />
        <stop offset="1" stop-color="#B74AB5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_841_19824"
        x1="28.313"
        y1="37.5258"
        x2="58.5602"
        y2="42.276"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#883DCF" />
        <stop offset="1" stop-color="#B74AB5" />
      </linearGradient>
      <clipPath id="clip0_841_19824">
        <rect
          width="132.873"
          height="80"
          fill="white"
          transform="translate(0.563477)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const Step2 = () => (
  <svg
    width="124"
    height="80"
    viewBox="0 0 124 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_841_19885)">
      <path
        d="M104.396 74.4465C104.965 74.6466 105.576 74.6968 106.171 74.5921C106.765 74.4873 107.323 74.2313 107.789 73.8486C108.978 72.8509 109.351 71.2073 109.654 69.6852L110.551 65.1833L108.673 66.4764C107.323 67.4063 105.942 68.366 105.007 69.7129C104.072 71.0597 103.664 72.8984 104.415 74.3557"
        fill="#E6E6E6"
      />
      <path
        d="M104.556 79.8968C104.366 78.5123 104.17 77.1102 104.304 75.7109C104.422 74.4682 104.801 73.2545 105.573 72.2592C105.983 71.7319 106.481 71.2802 107.046 70.9242C107.194 70.8315 107.329 71.0648 107.183 71.1573C106.205 71.7751 105.449 72.6873 105.023 73.7625C104.553 74.9581 104.478 76.2616 104.559 77.5316C104.608 78.2997 104.712 79.0625 104.817 79.8249C104.825 79.8594 104.82 79.8958 104.803 79.9267C104.785 79.9575 104.756 79.9805 104.723 79.991C104.688 80.0003 104.651 79.9956 104.62 79.9779C104.589 79.9603 104.566 79.9311 104.556 79.8966L104.556 79.8968Z"
        fill="#F2F2F2"
      />
      <path
        d="M119.725 79.8414C119.725 79.8414 117.753 74.9872 123.669 71.3466L119.725 79.8414Z"
        fill="#E6E6E6"
      />
      <path
        d="M117.3 79.7531C117.3 79.7531 116.698 76.2933 112.042 76.3229L117.3 79.7531Z"
        fill="#E6E6E6"
      />
      <path d="M8.3252 37.2723H51.6792V6.6908H8.3252V37.2723Z" fill="white" />
      <path
        d="M52.6071 38.2001H7.39746V5.76294H52.6071V38.2001ZM8.32529 37.2722H51.6793V6.69077H8.32529V37.2722Z"
        fill="#E6E6E6"
      />
      <path
        d="M4.48809 34.4619H4.16846V3.38184H47.5237V3.70395H4.48809V34.4619Z"
        fill="url(#paint0_linear_841_19885)"
      />
      <path
        d="M0.651334 31.0801H0.331543V0H43.6868V0.322111H0.651334V31.0801Z"
        fill="url(#paint1_linear_841_19885)"
      />
      <path
        d="M30.0025 31.3216C35.1232 31.3216 39.2745 27.1399 39.2745 21.9815C39.2745 16.8231 35.1232 12.6414 30.0025 12.6414C24.8817 12.6414 20.7305 16.8231 20.7305 21.9815C20.7305 27.1399 24.8817 31.3216 30.0025 31.3216Z"
        fill="white"
      />
      <path
        d="M30.0023 31.4763C24.8044 31.4763 20.5757 27.217 20.5757 21.9816C20.5757 16.7462 24.8044 12.4868 30.0023 12.4868C35.2002 12.4868 39.4289 16.7462 39.4289 21.9816C39.4289 27.217 35.2002 31.4763 30.0023 31.4763ZM30.0023 12.7961C24.975 12.7961 20.885 16.9166 20.885 21.9816C20.885 27.0465 24.975 31.167 30.0023 31.167C35.0296 31.167 39.1197 27.0465 39.1197 21.9816C39.1197 16.9166 35.0296 12.7961 30.0023 12.7961Z"
        fill="url(#paint2_linear_841_19885)"
      />
      <path
        d="M27.4399 17.3475V26.7764L35.1332 21.9975L27.4399 17.3475Z"
        fill="url(#paint3_linear_841_19885)"
      />
      <path
        d="M80.0099 78.0324H78.28L77.457 71.3595H80.0103L80.0099 78.0324Z"
        fill="#FFB7B7"
      />
      <path
        d="M80.4514 79.7095L74.873 79.7093V79.6387C74.8731 79.0629 75.1019 78.5107 75.509 78.1035C75.9162 77.6963 76.4685 77.4676 77.0443 77.4675L80.4515 77.4677L80.4514 79.7095Z"
        fill="#2F2E41"
      />
      <path
        d="M94.8736 75.5036L93.4231 76.4466L89.0957 71.3007L91.2365 69.9089L94.8736 75.5036Z"
        fill="#FFB7B7"
      />
      <path
        d="M96.1575 76.6691L91.4806 79.7096L91.4422 79.6505C91.1283 79.1677 91.0191 78.58 91.1385 78.0167C91.258 77.4533 91.5963 76.9605 92.079 76.6466L94.9356 74.7896L96.1575 76.6691Z"
        fill="#2F2E41"
      />
      <path
        d="M84.431 15.0466C85.3113 15.0466 86.025 14.333 86.025 13.4526C86.025 12.5722 85.3113 11.8585 84.431 11.8585C83.5506 11.8585 82.8369 12.5722 82.8369 13.4526C82.8369 14.333 83.5506 15.0466 84.431 15.0466Z"
        fill="#2F2E41"
      />
      <path
        d="M82.9262 14.0323C82.76 13.8122 82.6537 13.5526 82.6178 13.2791C82.582 13.0056 82.6177 12.7274 82.7215 12.4718C82.8253 12.2162 82.9937 11.9919 83.2101 11.8209C83.4266 11.6498 83.6837 11.5379 83.9564 11.496C83.7351 11.4899 83.515 11.53 83.31 11.6136C83.1051 11.6973 82.9199 11.8228 82.7661 11.982C82.6123 12.1412 82.4934 12.3308 82.417 12.5385C82.3405 12.7462 82.3081 12.9676 82.322 13.1885C82.3358 13.4095 82.3955 13.6251 82.4972 13.8217C82.599 14.0182 82.7406 14.1915 82.913 14.3303C83.0854 14.4691 83.2848 14.5705 83.4986 14.628C83.7123 14.6855 83.9357 14.6979 84.1545 14.6642C83.9154 14.6581 83.6808 14.598 83.4681 14.4886C83.2555 14.3792 83.0702 14.2233 82.9262 14.0323Z"
        fill="#2F2E41"
      />
      <path
        d="M82.7792 23.7756C85.3653 23.7756 87.4618 21.6792 87.4618 19.0931C87.4618 16.507 85.3653 14.4105 82.7792 14.4105C80.1931 14.4105 78.0967 16.507 78.0967 19.0931C78.0967 21.6792 80.1931 23.7756 82.7792 23.7756Z"
        fill="#2F2E41"
      />
      <path
        d="M78.4163 17.3873C78.923 16.2839 79.8387 15.4205 80.97 14.9794C82.1012 14.5383 83.3596 14.554 84.4796 15.0231C84.4324 14.9995 84.3854 14.9754 84.337 14.9532C83.2084 14.435 81.9202 14.3864 80.7557 14.8181C79.5912 15.2497 78.6459 16.1263 78.1278 17.2549C77.6096 18.3835 77.561 19.6717 77.9926 20.8362C78.4243 22.0007 79.3008 22.946 80.4295 23.4641C80.4778 23.4864 80.5267 23.5063 80.5754 23.5267C79.4896 22.9832 78.6575 22.039 78.2546 20.8935C77.8518 19.7481 77.9098 18.4909 78.4163 17.3873Z"
        fill="#2F2E41"
      />
      <path
        d="M88.4608 43.6648L91.8166 61.0734L96.2211 74.077L89.09 75.5453L82.1686 49.5375L81.9587 74.4967L72.9399 74.7064L75.0374 59.8148L77.5839 43.0027L88.4608 43.6648Z"
        fill="#2F2E41"
      />
      <path
        d="M82.7941 23.7434C84.5316 23.7434 85.9401 22.3348 85.9401 20.5973C85.9401 18.8597 84.5316 17.4512 82.7941 17.4512C81.0565 17.4512 79.6479 18.8597 79.6479 20.5973C79.6479 22.3348 81.0565 23.7434 82.7941 23.7434Z"
        fill="#FFB8B8"
      />
      <path
        d="M85.7341 25.2965L80.91 25.4174L77.5542 27.0953L77.9737 36.7433L77.1604 44.1316C77.1604 44.1316 76.8564 45.2895 78.9538 46.3382C79.625 46.6738 80.4895 46.8161 81.3548 46.8544C83.127 46.9119 84.8656 46.3621 86.2825 45.2962C86.7224 44.9739 87.2097 44.7584 87.6217 44.9232C88.6704 45.3427 88.8802 44.0843 88.8802 44.0843L87.6217 38.0018L89.5094 32.2179L87.6028 26.009L85.7341 25.2965Z"
        fill="url(#paint4_linear_841_19885)"
      />
      <path
        d="M79.8896 17.3077V20.8106H80.701L81.7334 19.7045L81.5951 20.8106H85.1809L84.9598 19.7045L85.4022 20.8106H85.9737V17.3077H79.8896Z"
        fill="#2F2E41"
      />
      <path
        d="M86.6036 19.7248C86.5945 19.6299 86.5505 19.5418 86.48 19.4777C86.4095 19.4135 86.3177 19.3779 86.2224 19.3777H85.8374V21.9154H86.2224C86.3177 21.9152 86.4095 21.8796 86.48 21.8154C86.5505 21.7512 86.5945 21.6632 86.6036 21.5683C86.7955 21.5166 86.965 21.4032 87.0859 21.2455C87.2068 21.0878 87.2724 20.8947 87.2725 20.6959V20.5971C87.2724 20.3984 87.2068 20.2053 87.0859 20.0476C86.965 19.8899 86.7955 19.7765 86.6036 19.7248Z"
        fill="url(#paint5_linear_841_19885)"
      />
      <path
        d="M79.6455 21.6004H79.2199C79.1011 21.6004 78.9835 21.577 78.8737 21.5316C78.764 21.4861 78.6643 21.4195 78.5803 21.3355C78.4963 21.2515 78.4297 21.1518 78.3843 21.0421C78.3388 20.9324 78.3154 20.8148 78.3154 20.696V20.5972C78.3154 20.4784 78.3388 20.3608 78.3843 20.2511C78.4297 20.1413 78.4963 20.0416 78.5803 19.9576C78.6643 19.8737 78.764 19.807 78.8737 19.7616C78.9835 19.7161 79.1011 19.6927 79.2199 19.6927H79.6455V21.6004Z"
        fill="url(#paint6_linear_841_19885)"
      />
      <path
        d="M79.7505 21.9154H79.3655C79.2634 21.9154 79.1654 21.8748 79.0932 21.8026C79.021 21.7304 78.9805 21.6325 78.9805 21.5304V19.7627C78.9805 19.6606 79.021 19.5627 79.0932 19.4905C79.1654 19.4183 79.2634 19.3777 79.3655 19.3777H79.7505V21.9154Z"
        fill="url(#paint7_linear_841_19885)"
      />
      <path
        d="M86.3975 19.8255H86.1153C86.1153 17.8323 84.6384 16.2107 82.8231 16.2107C81.0076 16.2107 79.5308 17.8323 79.5308 19.8255H79.2485C79.2485 17.6767 80.8521 15.9285 82.8231 15.9285C84.794 15.9285 86.3975 17.6767 86.3975 19.8255Z"
        fill="url(#paint8_linear_841_19885)"
      />
      <path
        d="M74.9002 49.2312C75.07 49.1386 75.2174 49.0098 75.332 48.854C75.4467 48.6981 75.5257 48.5191 75.5637 48.3294C75.6016 48.1397 75.5975 47.944 75.5517 47.7561C75.5058 47.5681 75.4193 47.3926 75.2982 47.2417L76.7515 44.59L75.3555 43.3138L73.3558 47.0873C73.1158 47.3075 72.9649 47.6081 72.9317 47.9321C72.8985 48.2561 72.9854 48.581 73.1757 48.8453C73.3661 49.1095 73.6468 49.2948 73.9646 49.3659C74.2824 49.4371 74.6153 49.3891 74.9002 49.2312Z"
        fill="#FFB7B7"
      />
      <path
        d="M78.6033 27.0954L78.0387 26.8187C78.0387 26.8187 76.7156 27.0954 76.2961 27.9343C75.8766 28.7733 74.8279 37.3726 74.8279 37.3726L73.989 43.0356C73.989 43.0356 73.3598 43.0356 73.3598 43.6648C73.3598 44.294 72.5208 45.972 73.15 45.972C73.7792 45.972 76.2961 45.972 76.7156 45.5525C77.1351 45.133 77.9741 43.4551 77.7643 43.2453C77.5546 43.0356 77.1351 43.0356 77.1351 43.0356L77.7252 37.3582L78.6033 27.0954Z"
        fill="url(#paint9_linear_841_19885)"
      />
      <path
        d="M89.8754 47.2601C89.7345 47.1274 89.6245 46.9655 89.553 46.7858C89.4816 46.606 89.4504 46.4128 89.4617 46.2197C89.4729 46.0265 89.5264 45.8383 89.6184 45.668C89.7103 45.4978 89.8384 45.3499 89.9937 45.2345L89.2588 42.3014L90.9323 41.42L91.912 45.5768C92.0885 45.8505 92.1584 46.1795 92.1085 46.5013C92.0586 46.8232 91.8924 47.1155 91.6414 47.323C91.3903 47.5305 91.0719 47.6387 90.7464 47.6271C90.4209 47.6155 90.111 47.4849 89.8754 47.2601Z"
        fill="#FFB7B7"
      />
      <path
        d="M87.7706 35.5025L88.3607 41.1799C88.3607 41.1799 87.9413 41.1799 87.7315 41.3897C87.5218 41.5994 88.3607 43.2773 88.7802 43.6968C89.1997 44.1163 91.7166 44.1163 92.3458 44.1163C92.975 44.1163 92.1361 42.4384 92.1361 41.8091C92.1361 41.1799 91.5068 41.1799 91.5068 41.1799L90.6679 35.5169C90.6679 35.5169 89.6192 27.8454 89.1997 27.0065C88.7802 26.1675 87.4572 25.8909 87.4572 25.8909L86.8926 26.1675L87.7706 35.5025Z"
        fill="url(#paint10_linear_841_19885)"
      />
      <path
        d="M76.2677 47.3222L76.2406 47.3394L75.7701 46.5977C75.7343 46.5413 75.6878 46.4926 75.6332 46.4542C75.5786 46.4158 75.517 46.3885 75.4518 46.3739C75.3867 46.3594 75.3193 46.3578 75.2535 46.3693C75.1878 46.3807 75.1249 46.405 75.0685 46.4408L73.4973 47.4377C73.3834 47.5099 73.3029 47.6244 73.2735 47.756C73.2441 47.8875 73.2681 48.0254 73.3403 48.1393L75.9217 52.208C75.9575 52.2644 76.004 52.3131 76.0586 52.3515C76.1132 52.3899 76.1749 52.4172 76.24 52.4318C76.3051 52.4463 76.3725 52.4479 76.4383 52.4365C76.5041 52.425 76.5669 52.4007 76.6233 52.3649L78.1946 51.368C78.3084 51.2958 78.3889 51.1813 78.4183 51.0497C78.4477 50.9182 78.4237 50.7803 78.3515 50.6665L76.5756 47.8673L76.6026 47.8501L76.2677 47.3222Z"
        fill="url(#paint11_linear_841_19885)"
      />
      <path
        d="M75.6804 46.6587L78.258 50.7214C78.3119 50.8064 78.3299 50.9094 78.3079 51.0077C78.2859 51.1059 78.2258 51.1914 78.1408 51.2454L76.562 52.247C76.4769 52.301 76.374 52.3189 76.2757 52.297C76.1775 52.275 76.092 52.2149 76.038 52.1299L73.4604 48.0671C73.4065 47.9821 73.3885 47.8791 73.4105 47.7809C73.4325 47.6826 73.4926 47.5971 73.5776 47.5432L73.7692 47.4216C73.7744 47.4507 73.7868 47.4781 73.8051 47.5013C73.8234 47.5245 73.8471 47.5429 73.8742 47.5548C73.9013 47.5667 73.9309 47.5717 73.9603 47.5695C73.9898 47.5672 74.0183 47.5578 74.0433 47.542L74.9435 46.9709C74.9684 46.955 74.9891 46.9333 75.0037 46.9075C75.0183 46.8818 75.0263 46.8529 75.0271 46.8233C75.0279 46.7938 75.0214 46.7645 75.0082 46.738C74.995 46.7116 74.9755 46.6888 74.9514 46.6716L75.1564 46.5415C75.2415 46.4876 75.3444 46.4696 75.4427 46.4916C75.541 46.5136 75.6264 46.5737 75.6804 46.6587Z"
        fill="url(#paint12_linear_841_19885)"
      />
      <path
        d="M122.384 79.9999H63.4666C63.4256 79.9999 63.3863 79.9837 63.3573 79.9547C63.3283 79.9257 63.312 79.8863 63.312 79.8453C63.312 79.8043 63.3283 79.765 63.3573 79.736C63.3863 79.707 63.4256 79.6907 63.4666 79.6907H122.384C122.425 79.6907 122.464 79.707 122.493 79.736C122.522 79.765 122.538 79.8043 122.538 79.8453C122.538 79.8863 122.522 79.9257 122.493 79.9547C122.464 79.9837 122.425 79.9999 122.384 79.9999Z"
        fill="#CBCBCB"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_841_19885"
        x1="4.16846"
        y1="3.38184"
        x2="51.2471"
        y2="10.7857"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#883DCF" />
        <stop offset="1" stop-color="#B74AB5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_841_19885"
        x1="0.331543"
        y1="0"
        x2="47.4102"
        y2="7.40386"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#883DCF" />
        <stop offset="1" stop-color="#B74AB5" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_841_19885"
        x1="20.5757"
        y1="12.4868"
        x2="41.2949"
        y2="14.8059"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#883DCF" />
        <stop offset="1" stop-color="#B74AB5" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_841_19885"
        x1="27.4399"
        y1="17.3475"
        x2="35.9287"
        y2="18.1284"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#883DCF" />
        <stop offset="1" stop-color="#B74AB5" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_841_19885"
        x1="77.145"
        y1="25.2965"
        x2="90.8461"
        y2="26.1823"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#883DCF" />
        <stop offset="1" stop-color="#B74AB5" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_841_19885"
        x1="85.8374"
        y1="19.3777"
        x2="87.4279"
        y2="19.4791"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#883DCF" />
        <stop offset="1" stop-color="#B74AB5" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_841_19885"
        x1="78.3154"
        y1="19.6927"
        x2="79.7864"
        y2="19.8084"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#883DCF" />
        <stop offset="1" stop-color="#B74AB5" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_841_19885"
        x1="78.9805"
        y1="19.3777"
        x2="79.8363"
        y2="19.407"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#883DCF" />
        <stop offset="1" stop-color="#B74AB5" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_841_19885"
        x1="79.2485"
        y1="15.9285"
        x2="86.8772"
        y2="17.5062"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#883DCF" />
        <stop offset="1" stop-color="#B74AB5" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_841_19885"
        x1="72.916"
        y1="26.8187"
        x2="79.2373"
        y2="27.0303"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#883DCF" />
        <stop offset="1" stop-color="#B74AB5" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_841_19885"
        x1="86.8926"
        y1="25.8909"
        x2="93.2132"
        y2="26.1132"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#883DCF" />
        <stop offset="1" stop-color="#B74AB5" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_841_19885"
        x1="73.2612"
        y1="46.3617"
        x2="78.961"
        y2="46.9078"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#883DCF" />
        <stop offset="1" stop-color="#B74AB5" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_841_19885"
        x1="73.4014"
        y1="46.4824"
        x2="78.8221"
        y2="46.9983"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#883DCF" />
        <stop offset="1" stop-color="#B74AB5" />
      </linearGradient>
      <clipPath id="clip0_841_19885">
        <rect
          width="123.337"
          height="80"
          fill="white"
          transform="translate(0.331543)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const Step3 = () => (
  <svg
    width="114"
    height="80"
    viewBox="0 0 114 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_841_19951)">
      <path
        d="M92.837 52.9563C92.9642 52.7911 93.0536 52.6001 93.099 52.3966C93.1444 52.1931 93.1447 51.9821 93.0997 51.7786C93.0548 51.575 92.9657 51.3837 92.8389 51.2183C92.712 51.0528 92.5504 50.9172 92.3655 50.8209L92.6473 47.5741L90.7157 46.9216L90.3821 51.5123C90.241 51.8337 90.2228 52.1957 90.3308 52.5297C90.4388 52.8636 90.6655 53.1463 90.968 53.3243C91.2705 53.5023 91.6278 53.5632 91.9721 53.4954C92.3165 53.4276 92.624 53.2358 92.8365 52.9565L92.837 52.9563Z"
        fill="#FFB8B8"
      />
      <path
        d="M102.474 78.1995H100.736L99.9092 71.4923H102.475L102.474 78.1995Z"
        fill="#FFB8B8"
      />
      <path
        d="M99.4941 77.7025H102.847V79.8138H97.3828C97.3828 79.2539 97.6053 78.7169 98.0012 78.3209C98.3972 77.925 98.9342 77.7025 99.4941 77.7025Z"
        fill="#2F2E41"
      />
      <path
        d="M98.5277 73.7491L96.8395 74.164L94.4355 67.8487L96.9276 67.2362L98.5277 73.7491Z"
        fill="#FFB8B8"
      />
      <path
        d="M95.5389 73.9179L98.7949 73.1164L99.2995 75.1665L93.9934 76.4726C93.8595 75.9289 93.9472 75.3543 94.237 74.8752C94.5269 74.3961 94.9951 74.0517 95.5389 73.9179Z"
        fill="#2F2E41"
      />
      <path
        d="M100.44 76.0608C100.281 76.0613 100.128 76.0027 100.01 75.8965C99.8917 75.7903 99.8173 75.644 99.8011 75.4861L97.8008 56.8473L96.0598 62.5471L98.5256 71.4494C98.5681 71.602 98.5526 71.7649 98.4823 71.9068C98.412 72.0487 98.2918 72.1597 98.1447 72.2184L96.1842 73.0026C96.1035 73.0348 96.0171 73.0504 95.9302 73.0484C95.8433 73.0463 95.7577 73.0267 95.6786 72.9906C95.5995 72.9546 95.5285 72.9029 95.4699 72.8386C95.4114 72.7744 95.3664 72.699 95.3378 72.6169L92.3159 63.9464C91.9851 62.9997 91.8387 61.9984 91.8847 60.9966L92.3065 51.6395L103.416 52.1099L103.242 75.2605C103.24 75.4217 103.178 75.5763 103.068 75.694C102.958 75.8118 102.808 75.8842 102.647 75.8972L100.49 76.0598C100.473 76.0605 100.456 76.0608 100.44 76.0608Z"
        fill="#2F2E41"
      />
      <path
        d="M98.5998 30.9314C100.663 30.9314 102.335 29.2589 102.335 27.1958C102.335 25.1328 100.663 23.4603 98.5998 23.4603C96.5367 23.4603 94.8643 25.1328 94.8643 27.1958C94.8643 29.2589 96.5367 30.9314 98.5998 30.9314Z"
        fill="#FFB8B8"
      />
      <path
        d="M101.347 52.7187C100.363 52.7187 99.1417 52.6382 97.8768 52.555C96.149 52.4413 94.3625 52.3237 93.0289 52.401C92.8488 52.4189 92.6671 52.3903 92.5012 52.318C92.3353 52.2458 92.1906 52.1322 92.081 51.9882C90.7063 50.2279 92.8263 43.3634 92.9175 43.0722L94.6428 35.3964C95.0231 33.7082 96.2354 32.4629 97.8083 32.1465C99.2806 31.8509 100.705 32.4402 101.618 33.7245C101.666 33.7926 101.715 33.8625 101.763 33.9342C104.737 38.3383 103.055 49.2802 102.942 49.9858C103.099 50.1739 103.949 51.2378 103.715 51.9768C103.62 52.2775 103.362 52.4823 102.948 52.5852C102.421 52.6896 101.884 52.7342 101.347 52.7182L101.347 52.7187Z"
        fill="url(#paint0_linear_841_19951)"
      />
      <path
        d="M93.089 52.722C92.7505 52.722 92.2941 52.5971 91.6788 52.3418C91.4143 52.2317 90.6094 51.8969 91.5866 43.3575C92.0666 39.1628 92.7542 34.9973 92.761 34.9558L92.7676 34.9156L92.8047 34.8989C92.8223 34.8908 94.5784 34.086 94.8838 33.0516C94.8939 33.0158 94.9058 32.9811 94.9194 32.9474C95.0034 32.7175 95.173 32.5289 95.3927 32.421C95.6124 32.3131 95.8654 32.2942 96.0987 32.3683C96.3452 32.4272 96.56 32.5776 96.6996 32.7891C96.8392 33.0006 96.8931 33.2573 96.8503 33.5071C96.744 34.1818 96.5395 34.8372 96.2433 35.4527C95.417 37.1576 94.8572 41.6775 94.8517 41.7229C94.8201 42.1089 94.0702 51.2548 93.9258 51.9488C93.8753 52.1912 93.7825 52.4878 93.5101 52.6317C93.3794 52.6961 93.2349 52.7275 93.0893 52.7229L93.089 52.722Z"
        fill="#2F2E41"
      />
      <path
        d="M97.1696 52.3715C97.1013 52.3715 97.035 52.3488 96.9811 52.3069C95.8821 51.4717 97.6607 41.3485 98.715 37.8558C98.9262 37.1561 99.1142 36.5574 99.2741 36.0754C99.5653 35.2105 99.7685 34.3185 99.8808 33.4129C99.9389 32.9222 100.065 32.6421 100.267 32.5561C100.407 32.4964 100.571 32.537 100.756 32.6768C101.731 32.6287 102.678 33.0266 103.555 33.4647L104.344 33.8587L105.231 49.5467C105.205 50.5479 103.232 51.2547 102.371 51.2999C100.989 51.3724 98.5066 51.5975 97.4131 52.2898C97.3414 52.3397 97.257 52.368 97.1698 52.3714L97.1696 52.3715Z"
        fill="#2F2E41"
      />
      <path
        d="M92.2334 49.428C92.2203 49.428 92.2072 49.428 92.194 49.4268L90.4042 49.3167C90.3151 49.3112 90.2281 49.2873 90.1487 49.2464C90.0694 49.2054 89.9994 49.1485 89.9433 49.079C89.8872 49.0096 89.8461 48.9293 89.8227 48.8431C89.7994 48.7569 89.7942 48.6669 89.8075 48.5786L90.6174 43.213L92.142 35.6844C92.1795 35.499 92.2556 35.3235 92.3653 35.1694C92.4751 35.0152 92.616 34.8858 92.7789 34.7896C92.9418 34.6934 93.1231 34.6325 93.3111 34.6109C93.4991 34.5893 93.6895 34.6074 93.87 34.6641C94.1786 34.7625 94.4419 34.9681 94.6122 35.2436C94.7826 35.5191 94.8488 35.8465 94.799 36.1666L93.5877 43.7103L92.8706 48.8733C92.8486 49.0269 92.7721 49.1674 92.6551 49.2692C92.5381 49.371 92.3885 49.4274 92.2334 49.428Z"
        fill="#2F2E41"
      />
      <path
        d="M98.3479 43.555C98.4571 43.3774 98.6039 43.2259 98.7779 43.1111C98.952 42.9963 99.1491 42.9211 99.3554 42.8908C99.5616 42.8604 99.7721 42.8757 99.9718 42.9355C100.172 42.9952 100.356 43.0981 100.511 43.2367L103.446 41.8192L104.742 43.3924L100.57 45.336C100.32 45.5821 99.988 45.7279 99.6374 45.7457C99.2868 45.7634 98.9419 45.652 98.668 45.4325C98.3941 45.213 98.2103 44.9006 98.1513 44.5546C98.0923 44.2086 98.1622 43.8529 98.3479 43.555Z"
        fill="#FFB8B8"
      />
      <path
        d="M102.424 45.0772C102.28 45.0772 102.14 45.0286 102.026 44.9392C101.913 44.8498 101.832 44.7248 101.799 44.5843L101.373 42.8124C101.34 42.6792 101.351 42.539 101.404 42.4125C101.458 42.2861 101.55 42.18 101.668 42.1099L104.238 40.5714L102.924 35.7601C102.81 35.3481 102.863 34.908 103.069 34.5339C103.276 34.1599 103.621 33.8816 104.03 33.7587C104.44 33.6359 104.881 33.6781 105.26 33.8765C105.638 34.0748 105.924 34.4135 106.056 34.82L107.507 39.2497C107.685 39.7897 107.687 40.3724 107.511 40.9132C107.335 41.454 106.992 41.9248 106.53 42.257L102.801 44.9551C102.691 45.0345 102.56 45.0772 102.424 45.0772Z"
        fill="#2F2E41"
      />
      <path
        d="M74.6525 0H2.68385C2.01128 0.000804999 1.36648 0.268342 0.890896 0.743926C0.415313 1.21951 0.147778 1.86431 0.146973 2.53688V10.2814C0.147778 10.9539 0.415313 11.5987 0.890896 12.0743C1.36648 12.5499 2.01128 12.8174 2.68385 12.8183H74.6525C75.3251 12.8174 75.9699 12.5499 76.4455 12.0743C76.9211 11.5987 77.1886 10.9539 77.1894 10.2814V2.53688C77.1886 1.86431 76.9211 1.21951 76.4455 0.743926C75.9699 0.268342 75.3251 0.000804999 74.6525 0ZM76.9223 10.2814C76.9214 10.8831 76.682 11.4599 76.2565 11.8854C75.8311 12.3109 75.2542 12.5503 74.6525 12.5512H2.68385C2.08213 12.5503 1.50531 12.3109 1.07983 11.8854C0.654353 11.4599 0.414929 10.8831 0.414044 10.2814V2.53688C0.414929 1.93516 0.654353 1.35834 1.07983 0.932861C1.50531 0.507381 2.08213 0.267957 2.68385 0.267072H74.6525C75.2542 0.267957 75.8311 0.507381 76.2565 0.932861C76.682 1.35834 76.9214 1.93516 76.9223 2.53688V10.2814Z"
        fill="#3F3D56"
      />
      <path
        d="M74.6525 31.1107H2.68385C2.01128 31.1099 1.36648 30.8424 0.890896 30.3668C0.415313 29.8912 0.147778 29.2464 0.146973 28.5738V20.8295C0.147778 20.1569 0.415313 19.5121 0.890896 19.0365C1.36648 18.5609 2.01128 18.2934 2.68385 18.2926H74.6525C75.3251 18.2934 75.9699 18.5609 76.4455 19.0365C76.9211 19.5121 77.1886 20.1569 77.1894 20.8295V28.5738C77.1886 29.2464 76.9211 29.8912 76.4455 30.3668C75.9699 30.8424 75.3251 31.1099 74.6525 31.1107ZM2.68385 18.5597C2.08207 18.5604 1.50514 18.7997 1.07961 19.2252C0.654089 19.6508 0.414729 20.2277 0.414044 20.8295V28.5738C0.414608 29.1757 0.653914 29.7527 1.07945 30.1784C1.50499 30.604 2.08199 30.8434 2.68385 30.8441H74.6525C75.2543 30.8434 75.8313 30.6041 76.2569 30.1786C76.6824 29.753 76.9218 29.1761 76.9225 28.5743V20.8295C76.9218 20.2277 76.6824 19.6508 76.2569 19.2252C75.8314 18.7997 75.2545 18.5604 74.6527 18.5597H2.68385Z"
        fill="#CCCCCC"
      />
      <path
        d="M74.6525 49.4033H2.68385C2.01128 49.4025 1.36648 49.135 0.890896 48.6594C0.415313 48.1838 0.147778 47.539 0.146973 46.8664V39.1221C0.147778 38.4495 0.415313 37.8047 0.890896 37.3291C1.36648 36.8535 2.01128 36.586 2.68385 36.5852H74.6525C75.3251 36.586 75.9699 36.8535 76.4455 37.3291C76.9211 37.8047 77.1886 38.4495 77.1894 39.1221V46.8664C77.1886 47.539 76.9211 48.1838 76.4455 48.6594C75.9699 49.135 75.3251 49.4025 74.6525 49.4033ZM2.68385 36.8517C2.08207 36.8524 1.50514 37.0917 1.07961 37.5172C0.654089 37.9428 0.414729 38.5197 0.414044 39.1215V46.8658C0.414688 47.4676 0.654032 48.0446 1.07956 48.4702C1.50509 48.8957 2.08205 49.1351 2.68385 49.1358H74.6525C75.2543 49.1351 75.8313 48.8958 76.2569 48.4703C76.6824 48.0447 76.9218 47.4678 76.9225 46.866V39.1221C76.922 38.5202 76.6826 37.9431 76.2571 37.5174C75.8315 37.0917 75.2544 36.8523 74.6525 36.8517H2.68385Z"
        fill="#CCCCCC"
      />
      <path
        d="M12.4082 10.8247C11.5349 10.8247 10.6812 10.5657 9.95506 10.0805C9.22894 9.59535 8.66299 8.90574 8.32879 8.09891C7.99459 7.29208 7.90715 6.40427 8.07752 5.54774C8.24789 4.69122 8.66843 3.90445 9.28595 3.28693C9.90347 2.66941 10.6902 2.24887 11.5468 2.0785C12.4033 1.90812 13.2911 1.99557 14.0979 2.32976C14.9048 2.66396 15.5944 3.22991 16.0796 3.95604C16.5647 4.68217 16.8237 5.53586 16.8237 6.40917C16.8237 7.58023 16.3585 8.70334 15.5304 9.53141C14.7024 10.3595 13.5793 10.8247 12.4082 10.8247Z"
        fill="url(#paint1_linear_841_19951)"
      />
      <path
        d="M68.1334 10.0141H21.0003C20.717 10.0141 20.4453 9.90157 20.245 9.70124C20.0447 9.50091 19.9321 9.22921 19.9321 8.9459C19.9321 8.66259 20.0447 8.39089 20.245 8.19056C20.4453 7.99023 20.717 7.87769 21.0003 7.87769H68.1334C68.4167 7.87769 68.6884 7.99023 68.8888 8.19056C69.0891 8.39089 69.2016 8.66259 69.2016 8.9459C69.2016 9.22921 69.0891 9.50091 68.8888 9.70124C68.6884 9.90157 68.4167 10.0141 68.1334 10.0141Z"
        fill="#CCCCCC"
      />
      <path
        d="M52.7776 5.47432H20.9994C20.7161 5.47432 20.4444 5.36177 20.244 5.16144C20.0437 4.96111 19.9312 4.68941 19.9312 4.4061C19.9312 4.1228 20.0437 3.85109 20.244 3.65076C20.4444 3.45043 20.7161 3.33789 20.9994 3.33789H52.7776C53.0609 3.33789 53.3326 3.45043 53.533 3.65076C53.7333 3.85109 53.8458 4.1228 53.8458 4.4061C53.8458 4.68941 53.7333 4.96111 53.533 5.16144C53.3326 5.36177 53.0609 5.47432 52.7776 5.47432Z"
        fill="#CCCCCC"
      />
      <path
        d="M12.4082 29.1172C11.5349 29.1172 10.6812 28.8582 9.95506 28.373C9.22894 27.8878 8.66299 27.1982 8.32879 26.3914C7.99459 25.5846 7.90715 24.6968 8.07752 23.8402C8.24789 22.9837 8.66843 22.1969 9.28595 21.5794C9.90347 20.9619 10.6902 20.5414 11.5468 20.371C12.4033 20.2006 13.2911 20.288 14.0979 20.6222C14.9048 20.9564 15.5944 21.5224 16.0796 22.2485C16.5647 22.9746 16.8237 23.8283 16.8237 24.7016C16.8237 25.8727 16.3585 26.9958 15.5304 27.8239C14.7024 28.652 13.5793 29.1172 12.4082 29.1172Z"
        fill="#E6E6E6"
      />
      <path
        d="M68.1334 28.3067H21.0003C20.717 28.3067 20.4453 28.1942 20.245 27.9938C20.0447 27.7935 19.9321 27.5218 19.9321 27.2385C19.9321 26.9552 20.0447 26.6835 20.245 26.4832C20.4453 26.2828 20.717 26.1703 21.0003 26.1703H68.1334C68.4167 26.1703 68.6884 26.2828 68.8888 26.4832C69.0891 26.6835 69.2016 26.9552 69.2016 27.2385C69.2016 27.5218 69.0891 27.7935 68.8888 27.9938C68.6884 28.1942 68.4167 28.3067 68.1334 28.3067Z"
        fill="#E6E6E6"
      />
      <path
        d="M52.7776 23.767H20.9994C20.7161 23.767 20.4444 23.6545 20.244 23.4542C20.0437 23.2538 19.9312 22.9821 19.9312 22.6988C19.9312 22.4155 20.0437 22.1438 20.244 21.9435C20.4444 21.7432 20.7161 21.6306 20.9994 21.6306H52.7776C53.0609 21.6306 53.3326 21.7432 53.533 21.9435C53.7333 22.1438 53.8458 22.4155 53.8458 22.6988C53.8458 22.9821 53.7333 23.2538 53.533 23.4542C53.3326 23.6545 53.0609 23.767 52.7776 23.767Z"
        fill="#E6E6E6"
      />
      <path
        d="M12.4082 47.4098C11.5349 47.4098 10.6812 47.1508 9.95506 46.6656C9.22894 46.1804 8.66299 45.4908 8.32879 44.684C7.99459 43.8772 7.90715 42.9894 8.07752 42.1328C8.24789 41.2763 8.66843 40.4895 9.28595 39.872C9.90347 39.2545 10.6902 38.834 11.5468 38.6636C12.4033 38.4932 13.2911 38.5806 14.0979 38.9148C14.9048 39.249 15.5944 39.815 16.0796 40.5411C16.5647 41.2673 16.8237 42.1209 16.8237 42.9943C16.8237 44.1653 16.3585 45.2884 15.5304 46.1165C14.7024 46.9446 13.5793 47.4098 12.4082 47.4098Z"
        fill="#E6E6E6"
      />
      <path
        d="M68.1334 46.5994H21.0003C20.717 46.5994 20.4453 46.4869 20.245 46.2866C20.0447 46.0862 19.9321 45.8145 19.9321 45.5312C19.9321 45.2479 20.0447 44.9762 20.245 44.7759C20.4453 44.5756 20.717 44.463 21.0003 44.463H68.1334C68.4167 44.463 68.6884 44.5756 68.8888 44.7759C69.0891 44.9762 69.2016 45.2479 69.2016 45.5312C69.2016 45.8145 69.0891 46.0862 68.8888 46.2866C68.6884 46.4869 68.4167 46.5994 68.1334 46.5994Z"
        fill="#E6E6E6"
      />
      <path
        d="M52.7776 42.0596H20.9994C20.7161 42.0596 20.4444 41.9471 20.244 41.7468C20.0437 41.5464 19.9312 41.2747 19.9312 40.9914C19.9312 40.7081 20.0437 40.4364 20.244 40.2361C20.4444 40.0358 20.7161 39.9232 20.9994 39.9232H52.7776C53.0609 39.9232 53.3326 40.0358 53.533 40.2361C53.7333 40.4364 53.8458 40.7081 53.8458 40.9914C53.8458 41.2747 53.7333 41.5464 53.533 41.7468C53.3326 41.9471 53.0609 42.0596 52.7776 42.0596Z"
        fill="#E6E6E6"
      />
      <path
        d="M12.1831 7.96232C12.0734 7.96257 11.9667 7.92712 11.8789 7.86133L11.8734 7.85722L10.729 6.98179C10.676 6.94115 10.6315 6.89047 10.598 6.83265C10.5646 6.77482 10.5429 6.71098 10.5342 6.64476C10.5254 6.57854 10.5298 6.51125 10.5471 6.44673C10.5643 6.38221 10.5941 6.32172 10.6347 6.26871C10.6754 6.2157 10.7261 6.17121 10.7839 6.13779C10.8417 6.10437 10.9056 6.08266 10.9718 6.07391C11.038 6.06516 11.1053 6.06954 11.1698 6.0868C11.2343 6.10405 11.2948 6.13385 11.3478 6.17449L12.0891 6.743L13.8408 4.45768C13.9228 4.35071 14.044 4.2807 14.1776 4.26305C14.3113 4.24539 14.4464 4.28154 14.5534 4.36354L14.5427 4.37874L14.5538 4.36354C14.6606 4.4457 14.7305 4.56689 14.7481 4.70053C14.7657 4.83416 14.7297 4.96933 14.6477 5.07639L12.5874 7.76323C12.5396 7.82502 12.4783 7.875 12.4082 7.90929C12.338 7.94357 12.2609 7.96125 12.1828 7.96095L12.1831 7.96232Z"
        fill="white"
      />
      <path
        d="M113.701 80H83.5871C83.5468 80 83.5081 79.984 83.4796 79.9554C83.4511 79.9269 83.4351 79.8882 83.4351 79.8479C83.4351 79.8076 83.4511 79.7689 83.4796 79.7403C83.5081 79.7118 83.5468 79.6958 83.5871 79.6958H113.701C113.742 79.6958 113.78 79.7118 113.809 79.7403C113.837 79.7689 113.853 79.8076 113.853 79.8479C113.853 79.8882 113.837 79.9269 113.809 79.9554C113.78 79.984 113.742 80 113.701 80Z"
        fill="#CCCCCC"
      />
      <path
        d="M99.973 28.1916C99.973 28.1916 99.5184 25.6781 98.484 25.9784C97.4496 26.2787 94.7875 26.5859 94.7826 25.4022C94.7777 24.2185 97.2884 23.1722 98.7687 23.3138C100.249 23.4554 102.766 23.8887 102.628 26.1087C102.517 27.507 102.084 28.8607 101.365 30.0646L99.973 28.1916Z"
        fill="#2F2E41"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_841_19951"
        x1="91.6187"
        y1="32.0715"
        x2="105.065"
        y2="32.9625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#883DCF" />
        <stop offset="1" stop-color="#B74AB5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_841_19951"
        x1="7.99268"
        y1="1.99365"
        x2="17.696"
        y2="3.08759"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#883DCF" />
        <stop offset="1" stop-color="#B74AB5" />
      </linearGradient>
      <clipPath id="clip0_841_19951">
        <rect
          width="113.706"
          height="80"
          fill="white"
          transform="translate(0.146973)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const Step4 = () => (
  <svg
    width="86"
    height="80"
    viewBox="0 0 86 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_841_20018)">
      <path
        d="M75.5407 35.0345C75.5314 34.7044 75.3936 34.391 75.1567 34.1609C74.9198 33.9309 74.6025 33.8024 74.2723 33.8027C74.2052 33.8038 74.1382 33.8105 74.0722 33.8227L71.855 29.9962L69.5176 30.4381L73.1216 35.3515C73.1932 35.6214 73.3522 35.8599 73.5736 36.0299C73.7951 36.1999 74.0666 36.2918 74.3458 36.2913C74.5046 36.2895 74.6616 36.2564 74.8076 36.1939C74.9537 36.1314 75.086 36.0408 75.1971 35.9271C75.3081 35.8135 75.3957 35.6791 75.4548 35.5317C75.5139 35.3842 75.5434 35.2265 75.5416 35.0677C75.5414 35.0566 75.5411 35.0455 75.5407 35.0345Z"
        fill="#A0616A"
      />
      <path
        d="M68.0116 77.5087L66.1592 77.5084L65.2778 70.3632L68.012 70.3633L68.0116 77.5087Z"
        fill="#A0616A"
      />
      <path
        d="M68.077 79.4411L62.3804 79.4409V79.3688C62.3804 78.7808 62.614 78.2169 63.0298 77.801C63.4456 77.3852 64.0096 77.1516 64.5976 77.1516H64.5978L65.6383 76.3622L67.5798 77.1517L68.0771 77.1517L68.077 79.4411Z"
        fill="#2F2E41"
      />
      <path
        d="M69.9634 66.8652L68.7151 68.2338L62.8418 64.07L64.6843 62.0499L69.9634 66.8652Z"
        fill="#A0616A"
      />
      <path
        d="M71.4354 68.1192L67.5966 72.328L67.5434 72.2795C67.1089 71.8832 66.8497 71.3306 66.8227 70.7432C66.7956 70.1557 67.003 69.5816 67.3993 69.1471L67.3994 69.147L67.5173 67.8463L69.4089 66.9438L69.744 66.5764L71.4354 68.1192Z"
        fill="#2F2E41"
      />
      <path
        d="M56.1018 30.0516L52.6313 36.3707L52.8981 40.9063L51.4307 55.5802L65.1708 67.0526L66.905 64.518L59.1678 54.6464L59.2606 53.9465C59.1239 53.741 59.0664 53.4929 59.0988 53.2482C59.1313 53.0036 59.2514 52.779 59.4369 52.6163C60.1016 51.9785 59.5367 51.8632 59.5367 51.8632L60.6352 43.5743L68.5058 42.0008L65.2044 29.5438L56.1018 30.0516Z"
        fill="#2F2E41"
      />
      <path
        d="M60.7686 39.0388L64.9039 73.856L68.5057 74.2184V62.05C68.5057 62.05 67.8387 61.3879 68.5057 61.0854C69.1727 60.7829 68.5057 59.6484 68.5057 59.6484V42.0009L65.1707 36.771L60.7686 39.0388Z"
        fill="#2F2E41"
      />
      <path
        d="M53.4318 9.95764L50.2302 11.1582L48.3626 15.427C48.3626 15.427 44.0938 17.5614 45.6946 20.4962C47.2954 23.431 53.7369 29.8725 53.7369 29.8725C52.9488 31.4097 53.2184 32.5923 54.499 33.4359C56.1708 36.1176 60.865 34.8038 66.1047 32.7689C67.0744 30.4044 65.5733 26.7424 64.0977 23.0938L64.5039 18.2284C64.5039 18.2284 59.9022 11.5322 59.168 11.5584C58.4337 11.5847 55.5662 11.2916 55.5662 11.2916L53.4318 9.95764Z"
        fill="#3F3D56"
      />
      <path
        d="M57.9673 12.2392C58.2426 11.9784 58.5723 11.7819 58.9327 11.6637C59.2931 11.5456 59.6751 11.5088 60.0514 11.5559C60.4277 11.6031 60.7889 11.7331 61.1089 11.9365C61.429 12.14 61.7 12.4118 61.9024 12.7325L68.881 23.7877C69.5508 24.1049 69.8874 24.8202 70.0874 25.6989C70.5848 25.5301 70.9616 25.8582 71.0763 27.2653L73.7084 31.435L71.3072 34.103L67.9863 29.7009C67.2143 29.4968 67.5733 28.3902 67.0391 28.4452C66.5048 28.5003 66.2466 27.3947 66.2466 27.3947C65.5727 27.3871 65.3748 27.071 65.571 26.4993L58.7677 16.7769L57.9673 12.2392Z"
        fill="#3F3D56"
      />
      <path
        d="M28.2925 11.9903C28.3944 12.3044 28.6148 12.5664 28.9069 12.7205C29.1989 12.8745 29.5396 12.9086 29.8564 12.8153C29.9205 12.7953 29.9828 12.7701 30.0427 12.7398L33.2472 15.7876L35.3658 14.7057L30.5247 11.0052C30.38 10.7665 30.1603 10.5823 29.9 10.4815C29.6396 10.3807 29.3532 10.3689 29.0855 10.448C28.9335 10.4945 28.7922 10.5704 28.6697 10.6715C28.5471 10.7725 28.4456 10.8968 28.371 11.037C28.2965 11.1773 28.2503 11.3309 28.2351 11.489C28.2198 11.6472 28.2359 11.8068 28.2824 11.9587C28.2856 11.9693 28.289 11.9798 28.2925 11.9903Z"
        fill="#A0616A"
      />
      <path
        d="M48.0958 16.6277L43.6936 17.9617L40.5908 17.4686L39.8948 16.9713C39.5951 16.161 38.9912 15.78 38.1864 15.6823C38.1864 15.6823 37.5355 15.9054 37.5464 15.1994C37.5573 14.4933 33.2885 11.9587 33.2885 11.9587L31.4209 15.2937L33.8221 18.2285C34.6744 18.2548 35.1997 18.1424 34.7559 19.1623C34.937 20.1445 34.8117 19.9165 35.5433 19.9345L39.2915 23.6979L47.162 24.8985L52.1608 23.1377L48.0958 16.6277Z"
        fill="#3F3D56"
      />
      <path
        d="M49.1908 9.8013C51.4538 9.8013 53.2884 7.96677 53.2884 5.70375C53.2884 3.44074 51.4538 1.6062 49.1908 1.6062C46.9278 1.6062 45.0933 3.44074 45.0933 5.70375C45.0933 7.96677 46.9278 9.8013 49.1908 9.8013Z"
        fill="#A0616A"
      />
      <path
        d="M51.8201 5.62458C51.8166 5.5789 51.8119 5.53322 51.8061 5.48755C51.8003 5.5337 51.8051 5.58056 51.8201 5.62458Z"
        fill="#2F2E41"
      />
      <path
        d="M43.4886 2.89112C43.4166 2.39951 43.6904 1.84227 44.1691 1.70592C44.4674 1.62077 44.7857 1.70238 45.0951 1.70777C45.963 1.72244 47.5057 0.276403 48.3435 0.0548655C49.1829 -0.166044 50.2691 0.295641 50.3371 1.1608C52.4834 0.521757 54.1223 1.96758 54.2224 4.24775C54.3006 6.031 52.7841 8.46149 51.1848 9.25472C50.989 8.21702 51.7143 6.66159 51.5189 5.62497C51.485 5.33939 51.3929 5.0638 51.2482 4.81526C51.088 4.5775 50.7942 4.4057 50.5164 4.47597C50.4031 4.50954 50.3065 4.58435 50.2456 4.68564C50.1847 4.78694 50.164 4.90736 50.1876 5.02316C50.025 4.74712 49.7962 4.51596 49.5218 4.35059C49.2474 4.18522 48.9361 4.09085 48.6161 4.07602C48.296 4.06119 47.9774 4.12637 47.6889 4.26565C47.4004 4.40493 47.1511 4.61393 46.9637 4.87375L47.1901 3.94156C46.6156 4.57316 45.8727 5.02766 45.0488 5.25166C45.002 5.19292 44.9666 5.12584 44.9447 5.05396C44.9209 4.98031 44.9165 4.90174 44.932 4.82589C44.9474 4.75004 44.9823 4.67948 45.0331 4.62108C44.9221 4.70692 44.8296 4.81436 44.7612 4.93691C44.6928 5.05947 44.65 5.19461 44.6352 5.33418C44.1394 5.41991 43.6293 5.31962 43.2028 5.05254C42.7914 4.77242 43.5605 3.38273 43.4886 2.89112Z"
        fill="#2F2E41"
      />
      <path
        d="M80.9267 79.8633H76.4996L76.4806 79.8288C76.424 79.7261 76.3694 79.6174 76.3183 79.5056C75.8623 78.5293 75.6695 77.4128 75.4995 76.4276L75.3714 75.6851C75.3559 75.5951 75.3676 75.5026 75.405 75.4194C75.4423 75.3361 75.5037 75.2659 75.5812 75.2178C75.6587 75.1696 75.7488 75.1456 75.84 75.149C75.9312 75.1523 76.0194 75.1828 76.0931 75.2365C76.7659 75.7261 77.439 76.2152 78.1123 76.7038C78.3672 76.8893 78.6584 77.104 78.9372 77.336C78.9641 77.2054 78.9923 77.0743 79.0204 76.9451C79.0398 76.8558 79.0854 76.7744 79.1513 76.7113C79.2173 76.6481 79.3006 76.606 79.3906 76.5905C79.4805 76.5749 79.5731 76.5866 79.6564 76.6239C79.7398 76.6613 79.81 76.7227 79.8583 76.8002L80.3762 77.6324C80.754 78.2401 81.0875 78.839 81.0195 79.4849C81.0198 79.4927 81.0193 79.5006 81.0178 79.5083C81.0042 79.6144 80.979 79.7187 80.9426 79.8193L80.9267 79.8633Z"
        fill="#F0F0F0"
      />
      <path
        d="M85.3701 79.959L43.3016 80C43.2596 79.9998 43.2194 79.983 43.1898 79.9532C43.1602 79.9235 43.1436 79.8832 43.1436 79.8412C43.1436 79.7992 43.1602 79.7589 43.1898 79.7292C43.2194 79.6994 43.2596 79.6826 43.3016 79.6824L85.3701 79.6414C85.412 79.6416 85.4522 79.6584 85.4819 79.6882C85.5115 79.7179 85.5281 79.7582 85.5281 79.8002C85.5281 79.8422 85.5115 79.8825 85.4819 79.9122C85.4522 79.942 85.412 79.9588 85.3701 79.959Z"
        fill="#CACACA"
      />
      <path
        d="M37.2904 63.0506H3.54035C2.7269 63.0497 1.94703 62.7261 1.37183 62.1509C0.796629 61.5757 0.473082 60.7959 0.472168 59.9824V14.6267C0.473093 13.8132 0.796644 13.0333 1.37184 12.4581C1.94704 11.8829 2.7269 11.5594 3.54035 11.5585H37.2904C38.1038 11.5594 38.8837 11.8829 39.4589 12.4581C40.0341 13.0333 40.3576 13.8132 40.3586 14.6267V59.9824C40.3576 60.7959 40.0341 61.5757 39.4589 62.1509C38.8837 62.7261 38.1038 63.0497 37.2904 63.0506Z"
        fill="white"
      />
      <path
        d="M37.2904 63.0506H3.54035C2.7269 63.0497 1.94703 62.7261 1.37183 62.1509C0.796629 61.5757 0.473082 60.7959 0.472168 59.9824V14.6267C0.473093 13.8132 0.796644 13.0333 1.37184 12.4581C1.94704 11.8829 2.7269 11.5594 3.54035 11.5585H37.2904C38.1038 11.5594 38.8837 11.8829 39.4589 12.4581C40.0341 13.0333 40.3576 13.8132 40.3586 14.6267V59.9824C40.3576 60.7959 40.0341 61.5757 39.4589 62.1509C38.8837 62.7261 38.1038 63.0497 37.2904 63.0506ZM3.54035 11.8253C2.79763 11.8261 2.08557 12.1215 1.56039 12.6467C1.03521 13.1719 0.739799 13.8839 0.738967 14.6267V59.9824C0.73981 60.7251 1.03523 61.4372 1.56041 61.9624C2.08558 62.4875 2.79764 62.783 3.54035 62.7838H37.2904C38.0331 62.783 38.7451 62.4875 39.2703 61.9624C39.7955 61.4372 40.0909 60.7251 40.0918 59.9824V14.6267C40.0909 13.8839 39.7955 13.1719 39.2703 12.6467C38.7452 12.1215 38.0331 11.8261 37.2904 11.8253H3.54035Z"
        fill="#E6E6E6"
      />
      <path
        d="M8.54288 26.533C9.20595 26.533 9.74347 25.9955 9.74347 25.3324C9.74347 24.6694 9.20595 24.1318 8.54288 24.1318C7.87981 24.1318 7.34229 24.6694 7.34229 25.3324C7.34229 25.9955 7.87981 26.533 8.54288 26.533Z"
        fill="#CCCCCC"
      />
      <path
        d="M33.2217 25.4659H12.6456C12.6102 25.4659 12.5763 25.4518 12.5513 25.4268C12.5263 25.4018 12.5122 25.3679 12.5122 25.3325C12.5122 25.2971 12.5263 25.2632 12.5513 25.2382C12.5763 25.2132 12.6102 25.1991 12.6456 25.1991H33.2217C33.2571 25.1991 33.291 25.2132 33.316 25.2382C33.341 25.2632 33.3551 25.2971 33.3551 25.3325C33.3551 25.3679 33.341 25.4018 33.316 25.4268C33.291 25.4518 33.2571 25.4659 33.2217 25.4659Z"
        fill="#CCCCCC"
      />
      <path
        d="M8.60929 48.8107C9.27235 48.8107 9.80988 48.2732 9.80988 47.6101C9.80988 46.9471 9.27235 46.4095 8.60929 46.4095C7.94622 46.4095 7.40869 46.9471 7.40869 47.6101C7.40869 48.2732 7.94622 48.8107 8.60929 48.8107Z"
        fill="#CCCCCC"
      />
      <path
        d="M8.55118 36.0843C9.21425 36.0843 9.75177 35.5468 9.75177 34.8837C9.75177 34.2206 9.21425 33.6831 8.55118 33.6831C7.88811 33.6831 7.35059 34.2206 7.35059 34.8837C7.35059 35.5468 7.88811 36.0843 8.55118 36.0843Z"
        fill="#CCCCCC"
      />
      <path
        d="M32.955 28.7474H27.619C27.4775 28.7474 27.3418 28.6912 27.2417 28.5911C27.1417 28.491 27.0854 28.3553 27.0854 28.2138C27.0854 28.0723 27.1417 27.9365 27.2417 27.8365C27.3418 27.7364 27.4775 27.6802 27.619 27.6802H32.955C33.0965 27.6802 33.2323 27.7364 33.3323 27.8365C33.4324 27.9365 33.4886 28.0723 33.4886 28.2138C33.4886 28.3553 33.4324 28.491 33.3323 28.5911C33.2323 28.6912 33.0965 28.7474 32.955 28.7474Z"
        fill="url(#paint0_linear_841_20018)"
      />
      <path
        d="M32.955 41.9539H27.619C27.4775 41.9539 27.3418 41.8977 27.2417 41.7976C27.1417 41.6976 27.0854 41.5618 27.0854 41.4203C27.0854 41.2788 27.1417 41.1431 27.2417 41.043C27.3418 40.9429 27.4775 40.8867 27.619 40.8867H32.955C33.0965 40.8867 33.2323 40.9429 33.3323 41.043C33.4324 41.1431 33.4886 41.2788 33.4886 41.4203C33.4886 41.5618 33.4324 41.6976 33.3323 41.7976C33.2323 41.8977 33.0965 41.9539 32.955 41.9539Z"
        fill="url(#paint1_linear_841_20018)"
      />
      <path
        d="M29.1124 18.7959H13.7857C13.4124 18.7959 13.0543 18.6476 12.7903 18.3836C12.5263 18.1196 12.3779 17.7615 12.3779 17.3881C12.3779 17.0148 12.5263 16.6567 12.7903 16.3927C13.0543 16.1287 13.4124 15.9803 13.7857 15.9803H29.1124C29.4858 15.9803 29.8439 16.1287 30.1079 16.3927C30.3719 16.6567 30.5202 17.0148 30.5202 17.3881C30.5202 17.7615 30.3719 18.1196 30.1079 18.3836C29.8439 18.6476 29.4858 18.7959 29.1124 18.7959Z"
        fill="url(#paint2_linear_841_20018)"
      />
      <path
        d="M32.955 58.6288H27.619C27.4775 58.6288 27.3418 58.5726 27.2417 58.4726C27.1417 58.3725 27.0854 58.2368 27.0854 58.0952C27.0854 57.9537 27.1417 57.818 27.2417 57.7179C27.3418 57.6179 27.4775 57.5616 27.619 57.5616H32.955C33.0965 57.5616 33.2323 57.6179 33.3323 57.7179C33.4324 57.818 33.4886 57.9537 33.4886 58.0952C33.4886 58.2368 33.4324 58.3725 33.3323 58.4726C33.2323 58.5726 33.0965 58.6288 32.955 58.6288Z"
        fill="url(#paint3_linear_841_20018)"
      />
      <path
        d="M31.9156 55.3473H13.9641C13.8508 55.346 13.7381 55.3317 13.628 55.3047C13.2996 55.2258 13.0074 55.0384 12.7987 54.7728C12.59 54.5072 12.477 54.1789 12.478 53.8412V47.7395C12.4787 47.3403 12.6376 46.9578 12.9199 46.6757C13.2023 46.3936 13.585 46.235 13.9841 46.2347H31.9156C32.1574 46.2341 32.3956 46.2924 32.6097 46.4046C32.6949 46.4488 32.7756 46.5014 32.8503 46.5617C33.029 46.7015 33.1733 46.8803 33.2724 47.0844C33.3714 47.2886 33.4225 47.5126 33.4217 47.7395V53.8412C33.4213 54.2405 33.2625 54.6233 32.9801 54.9057C32.6978 55.188 32.3149 55.3468 31.9156 55.3473ZM13.9841 46.5015C13.6557 46.5017 13.3408 46.6322 13.1085 46.8643C12.8762 47.0963 12.7454 47.4111 12.7448 47.7395V53.8412C12.744 54.1191 12.837 54.3893 13.0088 54.6078C13.1805 54.8264 13.421 54.9806 13.6913 55.0455C13.7811 55.0676 13.8732 55.0793 13.9658 55.0805H31.9156C32.2442 55.0801 32.5592 54.9494 32.7915 54.7171C33.0238 54.4847 33.1545 54.1697 33.1549 53.8412V47.7395C33.1555 47.5528 33.1134 47.3684 33.0318 47.2005C32.9502 47.0326 32.8314 46.8855 32.6843 46.7705C32.6229 46.721 32.5567 46.6777 32.4867 46.6414C32.3106 46.549 32.1145 46.501 31.9156 46.5015H13.9841Z"
        fill="#E6E6E6"
      />
      <path
        d="M30.7124 55.2138C31.5275 53.2329 32.3861 51.2714 33.2883 49.3296V52.6219C32.6731 53.5578 31.9613 54.4266 31.1646 55.2138H30.7124Z"
        fill="#E6E6E6"
      />
      <path
        d="M32.7668 46.6657C31.154 49.5591 29.4665 52.4085 27.7043 55.2139H27.3975C29.189 52.3645 30.9059 49.4675 32.548 46.5229C32.6255 46.5631 32.6988 46.6109 32.7668 46.6657Z"
        fill="#E6E6E6"
      />
      <path
        d="M20.9316 49.8246C19.8776 51.688 18.7146 53.4876 17.4485 55.2139H15.6196C16.8176 53.7545 18.0164 52.2951 19.2161 50.8357C19.5602 50.4155 19.9338 49.9753 20.45 49.8046L20.9316 49.8246Z"
        fill="#E6E6E6"
      />
      <path
        d="M18.5009 49.5258C18.3102 49.7246 18.1274 49.9313 17.9553 50.1461C17.5765 50.6023 17.203 51.0626 16.8281 51.5215C16.5947 51.8069 16.3612 52.0924 16.1304 52.3779C16.0572 52.3308 15.9876 52.2782 15.9223 52.2205C15.5537 51.9005 15.3251 51.4489 15.2855 50.9625C15.2458 50.476 15.3983 49.9934 15.7102 49.6179C16.0221 49.2425 16.4687 49.0042 16.9541 48.9541C17.4396 48.904 17.9255 49.046 18.3075 49.3497C18.3764 49.4034 18.441 49.4623 18.5009 49.5258Z"
        fill="url(#paint4_linear_841_20018)"
      />
      <path
        d="M28.817 50.8223L25.3112 49.5404L23.5704 48.904C23.0034 48.6973 22.4378 48.4612 21.8522 48.3118C21.3429 48.1662 20.8021 48.1732 20.2968 48.3318C19.8461 48.4985 19.4337 48.7544 19.0842 49.0841C18.9294 49.2269 18.7796 49.3741 18.6346 49.5257C18.4439 49.7245 18.2611 49.9312 18.089 50.146C17.7102 50.6022 17.3366 51.0624 16.9618 51.5213C16.7283 51.8068 16.4949 52.0923 16.2641 52.3778C15.4931 53.3209 14.7265 54.2663 13.9643 55.2138C13.8618 55.2126 13.7597 55.1997 13.6602 55.1751C14.4552 54.188 15.2538 53.2031 16.056 52.2204C16.1481 52.1056 16.2428 51.9896 16.3361 51.8748C16.727 51.3959 17.1183 50.9175 17.5101 50.4395C17.8129 50.07 18.113 49.6978 18.4412 49.3496C18.4705 49.3176 18.4999 49.2869 18.5306 49.2549C19.1962 48.5665 20.0273 47.9582 21.0225 47.9422C21.6081 47.9329 22.1737 48.1183 22.718 48.3144C23.3036 48.5265 23.8879 48.7426 24.4735 48.9574L28.0032 50.2487L28.8863 50.5715C29.0424 50.6276 28.9757 50.881 28.817 50.8223Z"
        fill="#E6E6E6"
      />
      <path
        d="M17.4187 38.6445H13.2582C13.0359 38.6443 12.8226 38.5558 12.6654 38.3986C12.5081 38.2413 12.4197 38.0281 12.4194 37.8057V33.9136C12.4197 33.6913 12.5081 33.478 12.6654 33.3208C12.8226 33.1635 13.0359 33.0751 13.2582 33.0748H17.4187C17.6411 33.0751 17.8543 33.1635 18.0116 33.3208C18.1689 33.478 18.2573 33.6913 18.2576 33.9136V37.8057C18.2573 38.0281 18.1689 38.2413 18.0116 38.3986C17.8543 38.5558 17.6411 38.6443 17.4187 38.6445Z"
        fill="#E6E6E6"
      />
      <path
        d="M24.8401 38.6445H20.6796C20.4572 38.6443 20.244 38.5558 20.0868 38.3986C19.9295 38.2413 19.8411 38.0281 19.8408 37.8057V33.9136C19.8411 33.6913 19.9295 33.478 20.0868 33.3208C20.244 33.1635 20.4572 33.0751 20.6796 33.0748H24.8401C25.0625 33.0751 25.2757 33.1635 25.433 33.3208C25.5902 33.478 25.6787 33.6913 25.6789 33.9136V37.8057C25.6787 38.0281 25.5902 38.2413 25.433 38.3986C25.2757 38.5558 25.0625 38.6443 24.8401 38.6445Z"
        fill="#E6E6E6"
      />
      <path
        d="M32.261 38.6445H28.1005C27.8781 38.6443 27.6649 38.5558 27.5077 38.3986C27.3504 38.2413 27.262 38.0281 27.2617 37.8057V33.9136C27.262 33.6913 27.3504 33.478 27.5077 33.3208C27.6649 33.1635 27.8781 33.0751 28.1005 33.0748H32.261C32.4834 33.0751 32.6966 33.1635 32.8539 33.3208C33.0111 33.478 33.0996 33.6913 33.0998 33.9136V37.8057C33.0996 38.0281 33.0111 38.2413 32.8539 38.3986C32.6966 38.5558 32.4834 38.6443 32.261 38.6445Z"
        fill="#E6E6E6"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_841_20018"
        x1="27.0854"
        y1="27.6802"
        x2="31.9738"
        y2="30.9868"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#883DCF" />
        <stop offset="1" stop-color="#B74AB5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_841_20018"
        x1="27.0854"
        y1="40.8867"
        x2="31.9738"
        y2="44.1934"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#883DCF" />
        <stop offset="1" stop-color="#B74AB5" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_841_20018"
        x1="12.3779"
        y1="15.9803"
        x2="25.5922"
        y2="25.5797"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#883DCF" />
        <stop offset="1" stop-color="#B74AB5" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_841_20018"
        x1="27.0854"
        y1="57.5616"
        x2="31.9738"
        y2="60.8683"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#883DCF" />
        <stop offset="1" stop-color="#B74AB5" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_841_20018"
        x1="15.2793"
        y1="48.9442"
        x2="18.8245"
        y2="49.3192"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#883DCF" />
        <stop offset="1" stop-color="#B74AB5" />
      </linearGradient>
      <clipPath id="clip0_841_20018">
        <rect
          width="85.0558"
          height="80"
          fill="white"
          transform="translate(0.472168)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const Step5 = () => (
  <svg
    width="110"
    height="80"
    viewBox="0 0 110 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_842_20079)">
      <path
        d="M77.1496 75.9807H0.65332V76.2331H77.1496V75.9807Z"
        fill="#883DCF"
      />
      <path
        d="M24.372 13.0382H23.6711L20.6921 23.8442C20.6921 23.8442 20.6337 31.0288 21.3346 34.5334C21.3346 34.5334 22.5029 33.0147 23.3206 33.0147C24.1384 33.0147 24.8977 25.3629 24.8977 25.3629C24.8977 25.3629 28.1103 14.4401 24.372 13.0382Z"
        fill="#E6E6E6"
      />
      <path
        d="M39.7927 33.7157C39.7927 33.7157 37.9236 38.9727 39.6759 39.3231C41.4282 39.6736 42.3628 35.2344 41.8955 34.6503C41.4282 34.0662 39.7927 33.7157 39.7927 33.7157Z"
        fill="#FEB8B8"
      />
      <path
        d="M21.1445 32.6296C21.1445 32.6296 21.67 38.1841 23.4064 37.7619C25.1428 37.3397 24.1155 32.921 23.4453 32.5888C22.7751 32.2566 21.1445 32.6296 21.1445 32.6296Z"
        fill="#FEB8B8"
      />
      <path
        d="M25.6572 65.1408C25.6572 65.1408 26.475 69.1127 25.0731 70.1641C23.6713 71.2155 25.5404 73.4351 25.5404 73.4351L29.9796 68.7622C29.9796 68.7622 28.1105 65.8417 28.2273 64.0894L25.6572 65.1408Z"
        fill="#FEB8B8"
      />
      <path
        d="M32.6661 68.7623L32.1989 71.9165L32.082 74.136L34.6521 73.9024L35.0026 69.3464L32.6661 68.7623Z"
        fill="#FEB8B8"
      />
      <path
        d="M22.7754 33.1863C22.7754 33.1863 21.3347 33.7157 21.4516 35.2343C21.5684 36.753 21.9188 41.5427 21.9188 41.5427C21.9188 41.5427 20.8675 51.8231 21.9188 55.3277C22.9702 58.8324 24.9562 65.8417 24.9562 65.8417C24.9562 65.8417 27.9936 66.4258 28.6945 64.2062C28.6945 64.2062 28.5777 56.6127 27.2927 54.9772C26.0076 53.3417 26.9422 52.8745 27.059 52.6408C27.1758 52.4072 27.5263 52.0567 27.5263 51.7062C27.5263 51.3558 27.6431 50.0707 27.6431 49.6034C27.6431 49.1361 27.5263 49.253 27.7599 48.552C27.9936 47.8511 28.2272 47.6175 28.2272 47.5006C28.2272 47.3838 30.2132 41.1923 30.2132 41.1923L32.7833 55.5613C32.7833 55.5613 32.1992 69.6968 32.4328 69.6968C32.6665 69.6968 35.2365 70.6314 36.1711 69.6968C36.1711 69.6968 38.5076 57.5473 38.0403 56.8464C37.573 56.1455 37.4562 56.0286 37.573 55.3277C37.6898 54.6268 37.9234 54.2763 37.6898 53.9258C37.4562 53.5754 37.1057 52.4072 37.4562 52.0567C37.8066 51.7062 37.9234 50.0707 37.9234 50.0707C37.9234 50.0707 39.9094 38.6222 38.6244 36.9867C37.3393 35.3512 22.7754 33.1863 22.7754 33.1863Z"
        fill="#2F2E41"
      />
      <path
        d="M26.7083 70.2809C26.7083 70.2809 25.3064 68.5286 24.8392 69.3464C24.3719 70.1641 23.671 71.4492 23.671 71.4492C23.671 71.4492 18.414 76.0052 21.3345 76.122C24.2551 76.2389 26.1242 74.1361 26.1242 74.1361C26.1242 74.1361 26.5915 71.7996 27.9934 71.7996L28.227 72.3837L30.7971 70.7482C30.7971 70.7482 30.4351 67.6464 29.3895 67.7371L26.7083 70.2809Z"
        fill="#2F2E41"
      />
      <path
        d="M31.6147 73.7856C31.6147 73.7856 34.6521 72.6173 35.0026 73.7856C35.353 74.9538 35.3531 75.5379 35.3531 75.5379C35.3531 75.5379 37.9231 79.7435 35.3531 79.9771C32.783 80.2108 31.6147 78.5753 31.6147 78.5753C31.6147 78.5753 31.9652 76.5893 31.6147 76.3557C31.2643 76.122 31.6147 73.7856 31.6147 73.7856Z"
        fill="#2F2E41"
      />
      <path
        d="M33.7177 9.29997C35.9758 9.29997 37.8064 7.46936 37.8064 5.2112C37.8064 2.95304 35.9758 1.12244 33.7177 1.12244C31.4595 1.12244 29.6289 2.95304 29.6289 5.2112C29.6289 7.46936 31.4595 9.29997 33.7177 9.29997Z"
        fill="#FEB8B8"
      />
      <path
        d="M30.6805 6.14575L29.8628 10.7018L35.5871 12.1037C35.5871 12.1037 34.2436 8.77425 35.7039 7.31397C36.0733 6.94455 30.6805 6.14575 30.6805 6.14575Z"
        fill="#FEB8B8"
      />
      <path
        d="M30.2111 8.76074C30.0732 8.78672 29.9454 8.8511 29.8424 8.94647C29.7394 9.04184 29.6655 9.16434 29.6291 9.29988C29.5271 9.63552 29.3691 9.9515 29.1618 10.2345C29.1618 10.2345 23.6711 12.8045 23.6711 13.155C23.6711 13.5055 25.8908 20.9821 25.8908 20.9821C25.8908 20.9821 26.0076 25.6549 25.073 26.7063C24.1384 27.7577 23.2039 31.6129 23.4375 31.9633C23.6712 32.3138 21.802 33.1315 22.5029 33.7156C23.2039 34.2998 33.0169 39.9072 38.858 37.3371C38.858 37.3371 38.9748 36.2857 38.858 35.5848C38.7412 34.8839 39.5589 32.5474 39.3253 31.7297C39.0916 30.9119 39.3253 22.5007 39.3253 22.5007L40.5292 12.5409C40.5522 12.3517 40.5078 12.1605 40.4038 12.0008C40.2999 11.8411 40.1429 11.7231 39.9606 11.6676C38.6141 11.2584 35.4917 9.58298 35.0613 9.9424C34.504 10.4078 31.0268 10.0908 30.2111 8.76074Z"
        fill="#E6E6E6"
      />
      <path
        d="M39.4419 11.6363L39.4494 11.6333C39.6164 11.5663 39.8011 11.5568 39.9741 11.6064C40.1472 11.656 40.2988 11.7617 40.405 11.9071C41.1289 12.8977 42.6524 15.1241 42.8297 16.5428C43.0634 18.412 43.7643 23.4353 43.6475 24.1362C43.5307 24.8372 43.4138 24.954 43.6475 25.3045C43.8811 25.6549 43.7643 25.5381 43.6475 25.8886C43.5307 26.239 42.1288 35.9353 42.1288 35.9353C42.1288 35.9353 41.1942 33.8325 39.4419 34.5334C39.4419 34.5334 39.6755 32.6642 39.4419 32.0801C39.2083 31.496 38.9746 31.1456 39.2083 30.6783C39.4419 30.211 39.2083 25.6549 39.0914 25.1876C38.9746 24.7204 39.0914 22.2671 39.0914 22.2671L38.04 17.4774L39.4419 11.6363Z"
        fill="#E6E6E6"
      />
      <path
        d="M32.9541 5.90726L33.0922 4.94451C33.1244 4.7201 33.2557 4.42506 33.474 4.48609C33.6597 4.53799 34.0643 4.69695 34.132 4.64897C34.3857 4.46927 34.7695 4.78348 35.0363 4.62385C35.148 4.55698 35.221 4.41369 35.3508 4.40413C35.438 4.40969 35.5201 4.44689 35.5817 4.50874C35.7549 4.65932 35.9589 4.77016 36.1795 4.83346C36.2902 4.86359 36.4069 4.86358 36.5175 4.83342C36.6282 4.80326 36.7287 4.74406 36.8088 4.66194C36.8371 4.6182 36.8752 4.58167 36.9201 4.55527C36.9728 4.54008 37.029 4.54267 37.0801 4.56263L37.7616 4.74819C37.8619 4.38476 37.8824 4.00396 37.822 3.63184C37.7615 3.25972 37.6214 2.90503 37.4112 2.59204C37.1348 2.21674 36.7996 1.88848 36.4186 1.61988C35.9965 1.29878 35.5609 0.996772 35.1118 0.71384C34.6203 0.40334 34.0991 0.110441 33.5243 0.0231572C32.9495 -0.0641181 32.306 0.0920277 31.9445 0.547279C31.7744 0.424212 31.6466 0.251551 31.5786 0.0529768C31.5381 0.0898571 31.5061 0.135185 31.485 0.185761C31.4639 0.236337 31.4542 0.290927 31.4565 0.345681C31.4588 0.400434 31.4731 0.454015 31.4983 0.50264C31.5236 0.551265 31.5593 0.593748 31.6027 0.627093L31.0264 0.259012C30.8643 0.429145 31.1809 0.737653 31.0428 0.927764C31.0011 0.974782 30.9475 1.00977 30.8877 1.02908C30.5747 1.15107 30.2878 1.33161 30.0425 1.56103C29.7112 1.89323 29.5313 2.34416 29.3769 2.78718C29.0334 3.77256 28.7838 4.90261 29.2718 5.82499C29.3271 5.92956 30.0574 7.65552 30.1547 7.72283C30.4836 7.95034 30.2695 7.39974 30.5051 7.72283C30.6899 7.97619 30.6553 7.00649 30.93 7.21934C31.0377 7.29639 31.1654 7.34056 31.2977 7.34651C31.4299 7.35245 31.5611 7.31992 31.6752 7.25285C31.7891 7.17388 31.7524 6.73537 31.691 6.33267C31.6792 6.25597 31.6865 6.17755 31.7121 6.1043C31.7377 6.03104 31.7809 5.96519 31.8378 5.9125C31.8948 5.85982 31.9638 5.82192 32.0389 5.80211C32.1139 5.78231 32.1926 5.78121 32.2682 5.79892C32.6102 5.87937 32.9482 5.94813 32.9541 5.90726Z"
        fill="#2F2E41"
      />
      <path
        opacity="0.1"
        d="M38.5837 15.2124L39.6172 26.8816C39.6172 26.8816 37.4334 17.0946 38.5837 15.2124Z"
        fill="black"
      />
      <path
        d="M19.386 55.6106C17.7137 57.9745 17.2719 71.4287 17.1735 75.7413C17.171 75.8385 17.1684 75.9306 17.1672 76.0177H12.934C12.8823 75.9256 12.8343 75.8347 12.7889 75.7413C10.2798 70.7888 11.6719 64.8505 11.7451 64.5502C11.5545 65.4942 12.4166 68.2305 13.3909 70.8809C13.9854 62.7314 19.0679 56.022 19.386 55.6106Z"
        fill="#E6E6E6"
      />
      <path
        d="M94.7021 21.623C96.2821 21.623 97.5629 20.3421 97.5629 18.7622C97.5629 17.1822 96.2821 15.9014 94.7021 15.9014C93.1221 15.9014 91.8413 17.1822 91.8413 18.7622C91.8413 20.3421 93.1221 21.623 94.7021 21.623Z"
        fill="url(#paint0_linear_842_20079)"
      />
      <path
        d="M95.2741 23.0533H82.4006C81.2625 23.0533 80.171 22.6012 79.3662 21.7965C78.5615 20.9917 78.1094 19.9002 78.1094 18.7621C78.1094 17.624 78.5615 16.5326 79.3662 15.7278C80.171 14.9231 81.2625 14.4709 82.4006 14.4709H95.2741C96.4122 14.4709 97.5037 14.9231 98.3085 15.7278C99.1132 16.5326 99.5653 17.624 99.5653 18.7621C99.5653 19.9002 99.1132 20.9917 98.3085 21.7965C97.5037 22.6012 96.4122 23.0533 95.2741 23.0533ZM82.4006 15.0431C81.4142 15.0431 80.4683 15.4349 79.7708 16.1324C79.0734 16.8298 78.6815 17.7758 78.6815 18.7621C78.6815 19.7485 79.0734 20.6944 79.7708 21.3919C80.4683 22.0893 81.4142 22.4812 82.4006 22.4812H95.2741C96.2605 22.4812 97.2064 22.0893 97.9039 21.3919C98.6013 20.6944 98.9932 19.7485 98.9932 18.7621C98.9932 17.7758 98.6013 16.8298 97.9039 16.1324C97.2064 15.4349 96.2605 15.0431 95.2741 15.0431H82.4006Z"
        fill="#3F3D56"
      />
      <path
        d="M108.69 33.6985H68.9338C68.7665 33.6984 68.6061 33.6659 68.4878 33.6082C68.3695 33.5504 68.3029 33.4721 68.3027 33.3904V2.57997C68.3029 2.49828 68.3695 2.41997 68.4878 2.3622C68.6061 2.30444 68.7665 2.27195 68.9338 2.27185H108.69C108.858 2.27195 109.018 2.30444 109.136 2.3622C109.255 2.41997 109.321 2.49828 109.321 2.57997V33.3904C109.321 33.4721 109.255 33.5504 109.136 33.6082C109.018 33.6659 108.858 33.6984 108.69 33.6985ZM68.9338 2.3951C68.8334 2.39515 68.7372 2.41465 68.6662 2.44931C68.5952 2.48396 68.5553 2.53096 68.5552 2.57997V33.3904C68.5553 33.4394 68.5952 33.4864 68.6662 33.5211C68.7372 33.5557 68.8334 33.5752 68.9338 33.5753H108.69C108.791 33.5752 108.887 33.5557 108.958 33.5211C109.029 33.4864 109.069 33.4394 109.069 33.3904V2.57997C109.069 2.53096 109.029 2.48396 108.958 2.44931C108.887 2.41465 108.791 2.39515 108.69 2.3951H68.9338Z"
        fill="#883DCF"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_842_20079"
        x1="91.8413"
        y1="15.9014"
        x2="98.128"
        y2="16.6101"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#883DCF" />
        <stop offset="1" stop-color="#B74AB5" />
      </linearGradient>
      <clipPath id="clip0_842_20079">
        <rect
          width="108.694"
          height="80"
          fill="white"
          transform="translate(0.65332)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const CrossSVG = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 1L1 13M1 1L13 13"
      stroke="#1D1F2C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

import React from "react";

function InputColorSelector({ label, events, error }) {
  return (
    <>
      <p className="Polaris-Text--bodyLg" style={{ marginTop: "10px" }}>
        {label}
      </p>
      <div className="color-selector-container">
        <input
          type="color"
          className="style1"
          name={events.name}
          onChange={(e) => events.onChange(e)}
          value={events.value}
        />
        <input
          autoComplete="off"
          className="input-text"
          name={events.name}
          type="text"
          onChange={(e) => events.onChange(e)}
          value={events.value}
        />
      </div>

      {error && <div className="VD-error">{error}</div>}
    </>
  );
}

export default InputColorSelector;

import React, { useEffect, useState, useContext } from "react";
import config from "../config/config";
import axios from "axios";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { authAxios } from "../config/axios";
import NavbarWithTitle from "../component/NavbarWithTitle";

const authContext = React.createContext(null);

function AuthProvider(props) {
  const location = useLocation();
  const currentRoute = location.pathname;

  let navigate = useNavigate();
  const [isAuth, setIsAuth] = useState(false);
  const [token, setToken] = useState(null);
  const [shopname, setShopName] = useState("");
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if (currentRoute === "/privacy-policy") {
      return;
    }
    const searchParams = new URLSearchParams(document.location.search);
    let charge_id = searchParams.get("charge_id");
    if (charge_id === null) {
      fetchShopData();
    } else {
      BillingApproved(charge_id);
    }
  }, []);

  const fetchShopData = async () => {
    try {
      let token = localStorage.getItem(config.LOCAL_STORAGE.SECURITY_TOKEN);
      let response = await axios.post(
        `${process.env.REACT_APP_NODE_BASE_URL}/apisv1/shop/get_details`,
        { authtoken: token }
      );
      if (response.status === 200) {
        let data = response.data.data;
        if (data.charge_approved == "true") {
          setIsAuth(true);
          setShopName(data.shop_name);
          if (currentRoute == "/") {
            navigate(`/${data.shop_name}/dashboard`);
          }
        } else {
          navigate(`/${data.shop_name}/billing`);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* billing check */
  const BillingApproved = async (charge_id) => {
    try {
      let response = await authAxios(
        "post",
        `/apisv1/billing/charge/?charge_id=${charge_id}`
      );
      if (response.status === 200) {
        setIsAuth(true);
        setShopName(response.data.data.store_name);
        navigate(`/${response.data.data.store_name}/dashboard`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  let value = { isAuth, token, shopname, loader, setLoader };
  return (
    <authContext.Provider value={value}>{props.children}</authContext.Provider>
  );
}

function useAuth() {
  return useContext(authContext);
}

function ProtectedRoutes(props) {
  let auth = useAuth();
  // let isAuth = auth.isAuth;
  // let RedirectToHome = () => {
  //   let location = window.location.href;
  //   if(location.includes('master')){
  //     <Navigate to="/master" />
  //   }else if(location.includes('staff') || location.includes('admin')){
  //     <Navigate to="/loginstaff" />
  //   }
  // }
  return (
    <>
      {auth.isAuth === true ? (
        <>
          <NavbarWithTitle>{props.children}</NavbarWithTitle>
        </>
      ) : (
        <div>NOT AUTHORIZED</div>
      )}
    </>
  );
}
export { useAuth, AuthProvider, ProtectedRoutes };

import React from "react";

function InputRange({ label, events }) {
  return (
    <>
      <p
        className="Polaris-Text--bodyLg"
        style={{ marginTop: "10px", marginBottom: "7px" }}
      >
        {label}
      </p>
      <div>
        <input
          type="range"
          min={events.min}
          max={events.max}
          id={events.name}
          step={events.step}
          aria-valuemin={0}
          aria-valuemax={100}
          aria-invalid="false"
          value={events.value}
          onChange={(e) => events.onChange(e)}
          name={events.name}
          className="range-input"
        />
      </div>
      <div
        className="Polaris-RangeSlider-SingleThumb Polaris-RangeSlider"
        style={{
          "--pcRangeSliderMin": 0,
          "--pcRangeSliderMax": 100,
          "--pcRangeSliderCurrent": 32,
          "--pcRangeSliderProgress": "32%",
          "--pcRangeSliderOutputFactor": "0.18",
        }}
      >
        {/* <div className="Polaris-RangeSlider-SingleThumb__InputWrapper">
          <input
            type="range"
            className="Polaris-RangeSlider-SingleThumb__Input"
            id={events.name}
            min={events.min}
            max={events.max}
            step={events.step}
            aria-valuemin={0}
            aria-valuemax={100}
            aria-invalid="false"
            value={events.value}
            onChange={(e) => events.onChange(e)}
            name={events.name}
          />
          <output
            htmlFor={events.name}
            className="Polaris-RangeSlider-SingleThumb__Output"
          >
            <div className="Polaris-RangeSlider-SingleThumb__OutputBubble">
              <span className="Polaris-Text--root Polaris-Text--headingXs Polaris-Text--block Polaris-Text--center">
                {events.value}
              </span>
            </div>
          </output>
        </div> */}
      </div>
    </>
  );
}

export default InputRange;

import React, { useState, useEffect } from "react";
import Welcome from "../component/dashboard/welcome";
import SectionTitle from "../component/dashboard/sectionTitle";
import Engagement from "../component/dashboard/engagement";
import TopPlaylist from "../component/dashboard/topPlaylist";
import TopVideos from "../component/dashboard/topVideos";
import Analytics from "../component/dashboard/analytics";
import DateRangeFilter from "../component/DateRangePicker";
import Orders from "../component/dashboard/orders";
import moment from "moment";
import calendar from "../assets/img/fi-sr-calendar.svg";
import { useNavigate, useParams } from "react-router-dom";
import { authAxios } from "../config/axios";
import ToolKitModal from "../component/dashboard/ToolKitModal";
import { calc } from "antd/es/theme/internal";
function Dashboard() {
  const defaultStartDate = new Date().getTime() - 7 * 24 * 60 * 60 * 1000;
  const [startDate, setStartDate] = useState(new Date(defaultStartDate));
  const [endDate, setEndDate] = useState(new Date());
  const [showDate, setShowDate] = useState(false);
  const [showToolKit, setShowToolKit] = useState(false);
  let { shop } = useParams();
  let navigate = useNavigate();
  // const [isPickerOpen, setIsPickerOpen] = useState(false);

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const onChange = (ranges) => {
    if (
      moment(ranges.startDate).format("MM-DD-YYYY") !==
      moment(ranges.endDate).format("MM-DD-YYYY")
    ) {
      setIsPickerOpen(false);
    } else if (ranges.startDate === "" && ranges.endDate === "") {
      setIsPickerOpen(false);
    }
    setShowDate(true);
  };

  // const handleDateRangeChange = (selection) => {
  //   console.log(selection, "Selection");
  //   setStartDate(selection.startDate);
  //   setEndDate(selection.endDate);
  //   setIsPickerOpen(false);
  // };

  const handleTogglePicker = () => {
    setIsPickerOpen(!isPickerOpen);
  };

  const buttonLabel = "Select Date";

  const setDefaultDates = () => {
    const today = new Date();
    const lastSevenDays = new Date();
    lastSevenDays.setTime(today.getTime() - 7 * 24 * 60 * 60 * 1000); // Subtract 7 days in milliseconds

    setStartDate(lastSevenDays);
    setEndDate(today);
  };

  const getToolKitStatus = async () => {
    try {
      let response = await authAxios("get", `/apisv1/authadmin/get-toolkit`);
      console.log("response>>>", response);
      if (response.data.data) setShowToolKit(response.data.data.showToolkit);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    setDefaultDates();
    getToolKitStatus();
  }, []);

  return (
    <main
      className="Polaris-Frame__Main"
      id="AppFrameMain"
      data-has-global-ribbon="false"
    >
      <div
        className="Polaris-Frame__Content transitionEffect"
        style={{ minHeight: "calc(100vh - 56px)", overflowY: "auto" }}
      >
        <div
          className="Polaris-Page Polaris-Page--fullWidth"
          style={{ paddingTop: "16px" }}
        >
          <p className="Polaris-Header-Title" style={{ fontSize: "28px" }}>
            Welcome to Vidzy!
          </p>
          <p className="Polaris-Header-Desc" style={{ fontSize: "18px" }}>
            Transform videos into your personal virtual marketplace!
          </p>
          <Welcome></Welcome>

          <div
            className="section-padding transitionEffect"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2 className="Polaris-Page-subTitle" style={{ margin: 0 }}>
              Analytics
            </h2>
            <div
              className="dashboard-btn Polaris-Button"
              style={{ display: "flex", alignItems: "center", gap: "6px" }}
              onClick={() => {
                navigate(`/${shop}/analytics`);
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.33333 2.5C3.79357 2.5 4.16667 2.8731 4.16667 3.33333V15.8333H16.6667C17.1269 15.8333 17.5 16.2064 17.5 16.6667C17.5 17.1269 17.1269 17.5 16.6667 17.5H3.33333C2.8731 17.5 2.5 17.1269 2.5 16.6667V3.33333C2.5 2.8731 2.8731 2.5 3.33333 2.5Z"
                  fill="#883DCF"
                />
                <path
                  d="M16.5267 7.1289C16.782 6.74596 16.6785 6.22857 16.2956 5.97328C15.9126 5.71798 15.3952 5.82146 15.1399 6.2044L12.2953 10.4714L9.66665 8.49999C9.28393 8.21294 8.73865 8.30635 8.47328 8.7044L5.13994 13.7044C4.88465 14.0873 4.98813 14.6047 5.37107 14.86C5.75401 15.1153 6.2714 15.0118 6.5267 14.6289L9.37139 10.3619L12 12.3333C12.3827 12.6204 12.928 12.527 13.1934 12.1289L16.5267 7.1289Z"
                  fill="#883DCF"
                />
              </svg>

              <span>View Analytics</span>
            </div>
          </div>
          <Engagement
            startDate={moment(startDate).format("YYYY-MM-DD")}
            endDate={moment(endDate).format("YYYY-MM-DD")}
          ></Engagement>
        </div>
      </div>
      {showToolKit && (
        <ToolKitModal
          isModalOpen={showToolKit}
          setIsModalOpen={setShowToolKit}
        />
      )}
    </main>
  );
}

export default Dashboard;

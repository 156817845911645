import React, { useEffect, useMemo, useState } from "react";
import { authAxios } from "../config/axios";
import Table from "./videoTable";
import Loader from "./Loader";
import Select from "react-select";
const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "#F9F9FC",
    borderRadius: "8px",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? data.color
          : isFocused
            ? "#F4ECFB"
            : undefined,

      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "#F4ECFB"
          : undefined,
      },
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#F4ECFB",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "#883DCF",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "18px",
    letterSpacing: "0.06px",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#883DCF",
    ":hover": {
      backgroundColor: data.color,
      color: "#883DCF",
      cursor: "pointer",
    },
  }),
};
function PlaylistSelector({
  playlist_data,
  handleClose,
  playlist_id,
  successPopUp,
}) {
  const [title, setTitle] = useState(playlist_data.title);
  const [videos, setVideos] = useState([]);
  const [filteredVideos, setFilteredVideos] = useState(videos);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [videoIds, setSelectedVideoIds] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchVideos();
  }, []);

  const fetchVideos = async () => {
    try {
      setLoading(true);
      let response = await authAxios("get", "/apisv1/authadmin/getvideos");
      if (response.status === 200) {
        const filteredData = response.data.data.filter(
          (item) => item.steps_completed === 5
        );
        const options = filteredData.map((item) => ({
          value: item._id,
          label: item.video_title,
        }));
        const selectedVideoIds = playlist_data.selected_videos;
        console.log(
          options.filter((option) => selectedVideoIds.includes(option.value))
        );
        const filteredOptions = options
          .filter((option) => selectedVideoIds.includes(option.value))
          .map((option) => ({
            label: option.label,
            value: option.value,
          }));
        setVideos(response.data.data);
        setOptions(options);
        setSelectedVideos(filteredOptions);
        setFilteredVideos(response.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSave = async () => {
    try {
      if (title !== "") {
        if (playlist_id === null) {
          // create new playlist
          let response = await authAxios(
            "post",
            "/apisv1/authadmin/createplaylist",
            { name: title, playlist: videoIds }
          );
          if (response.status === 200) {
            handleClose();
            successPopUp(true);
          }
        } else {
          let response = await authAxios(
            "post",
            "/apisv1/authadmin/updateplaylist",
            { playlist_id: playlist_id, playlist: videoIds }
          );
          if (response.status === 200) {
            handleClose();
            successPopUp(true);
          }
        }
      } else {
        setError("Enter playlist name");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSearchChange = ({ target: { value } }) => {
    const res = videos.filter((video) => video.video_title.includes(value));
    setFilteredVideos(res);
  };

  const tableData = useMemo(
    () =>
      filteredVideos.map(({ _id, video_title, card_image }) => ({
        id: _id,
        title: video_title,
        // video: (
        //   <div>
        //     {card_image ? (
        //       <img
        //         className="card-preview"
        //         src={card_image}
        //         alt="captured frame"
        //       />
        //     ) : (
        //       <div>No thumbnail found</div>
        //     )}
        //   </div>
        // ),
      })),
    [filteredVideos]
  );

  const handleSelect = (e) => {
    const valueArray = e.map((item) => item.value);
    setSelectedVideos(e);
    setSelectedVideoIds(valueArray);
  };

  return (
    <div data-portal-id="modal-Polarisportal1">
      <div>
        <div
          className="Polaris-Modal-Dialog__Container"
          data-polaris-layer="true"
          data-polaris-overlay="true"
        >
          <div>
            <div
              role="dialog"
              aria-modal="true"
              aria-label="Polarismodal-header1"
              aria-labelledby="Polarismodal-header1"
              tabIndex={-1}
              className="Polaris-Modal-Dialog"
            >
              <div
                className="Polaris-Modal-Dialog__Modal"
                style={{ maxWidth: "540px" }}
              >
                <div
                  className="Polaris-Modal__Body Polaris-Scrollable Polaris-Scrollable--vertical Polaris-Scrollable--horizontal"
                  data-polaris-scrollable="true"
                >
                  <div className="Polaris-Modal-Section">
                    <section
                      className="Polaris-Box"
                      style={{
                        "--pc-box-padding-block-end-xs": "var(--p-space-5)",
                        "--pc-box-padding-block-start-xs": "var(--p-space-5)",
                        "--pc-box-padding-inline-start-xs": "var(--p-space-5)",
                        "--pc-box-padding-inline-end-xs": "var(--p-space-5)",
                        "padding-bottom": "var(--p-space-5)",
                      }}
                    >
                      <h2
                        className="Polaris-Text--root Polaris-Text--headingLg Polaris-Text--break"
                        id="Polarismodal-header1"
                      >
                        {playlist_id !== null
                          ? "Edit Playlist"
                          : "Create Playlist"}
                      </h2>
                      <div className="" style={{ marginBottom: "5px" }}>
                        <div className="Polaris-Label">
                          <label
                            id="PolarisTextField1Label"
                            htmlFor="PolarisTextField1"
                            className="border-header"
                            style={{ color: "#777980" }}
                          >
                            Playlist Name
                          </label>
                        </div>
                        <div className="Polaris-Connected">
                          <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                            <div
                              className="Polaris-TextField Polaris-TextField--hasValue"
                              style={{ marginTop: "12px" }}
                            >
                              <input
                                onChange={(e) => setTitle(e.target.value)}
                                id="PolarisTextField1"
                                autoComplete="off"
                                className="Polaris-TextField__Input"
                                type="text"
                                aria-labelledby="PolarisTextField1Label"
                                aria-invalid="false"
                                defaultValue="Jaded Pixel"
                                value={title}
                                placeholder="Type Name"
                                style={{ fontSize: "14px" }}
                              />
                              <div className="Polaris-TextField__Backdrop"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {error !== "" && (
                        <div style={{ color: "red", fontSize: "14px" }}>
                          {error}
                        </div>
                      )}
                      <section className="">
                        <div className="">
                          <div
                            className="Polaris-Connected"
                            style={{ marginTop: 25 }}
                          >
                            <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                              <div>
                                <div className="Polaris-Label">
                                  <label
                                    id="PolarisTextField1Label"
                                    htmlFor="PolarisTextField1"
                                    className="border-header"
                                    style={{
                                      color: "#777980",
                                    }}
                                  >
                                    Select Videos
                                  </label>
                                </div>
                              </div>
                              <div className="search-bar">
                                <div
                                  style={{
                                    zIndex: 10000000,
                                    marginTop: "12px",
                                  }}
                                >
                                  <Select
                                    onChange={handleSelect}
                                    isClearable={true}
                                    menuPosition="fixed"
                                    options={options}
                                    isSearchable={true}
                                    value={selectedVideos}
                                    placeholder={
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "8px",
                                          fontSize: "14px",
                                        }}
                                      >
                                        <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
                                            stroke="#858D9D"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M14.0001 14.0001L11.1001 11.1001"
                                            stroke="#858D9D"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>

                                        <span>Search & Select</span>
                                      </div>
                                    }
                                    name="playlist"
                                    isMulti
                                    styles={{
                                      dropdownIndicator: (provided) => ({
                                        ...provided,
                                        display: "none",
                                      }),
                                      indicatorSeparator: (provided) => ({
                                        ...provided,
                                        display: "none",
                                      }),
                                      ...colourStyles,
                                    }}
                                  />
                                </div>
                              </div>

                              {/* <div className="Polaris-TextField">
                                <div
                                  className="Polaris-TextField__Prefix"
                                  id="PolarisComboboxTextField1-Prefix"
                                >
                                    <span className="Polaris-Text--root Polaris-Text--visuallyHidden"></span>
                                    <svg className="Polaris-Icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g id="search">
                                        <path id="Vector" d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z" stroke="#858D9D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path id="Vector_2" d="M14 14.0001L11.1 11.1001" stroke="#858D9D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                      </g>
                                    </svg>

                                </div>
                                <input
                                  onChange={onSearchChange}
                                  id="PolarisComboboxTextField1"
                                  role="combobox"
                                  placeholder="Search & Select"
                                  autoComplete="off"
                                  className="Polaris-TextField__Input"
                                />
                                <div className="Polaris-TextField__Backdrop"></div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                        {/* <div className="Tab-media-all-product">
                          {loading ? (
                            <Loader />
                          ) : (
                            <Table
                              selectedData={selectedVideos}
                              selectable
                              tableData={tableData}
                              headings={[
                                // { title: "" },
                                { title: "Video Title" },
                              ]}
                              handleSelect={handleSelect}
                              video={false}
                            />
                          )}
                        </div> */}
                      </section>
                      <div className="polaris-inline">
                        <button
                          onClick={() => handleClose()}
                          className="Polaris-Button Polaris-Button--unfilled"
                          aria-label="Close"
                          type="button"
                        >
                          <span className="Polaris-Button__Content ">
                            <span className="Polaris-Button__Text_Neutral">
                              Cancel
                            </span>
                          </span>
                        </button>
                        <button
                          onClick={() => handleSave()}
                          className="Polaris-Button Polaris-Button--primary"
                          type="button"
                        >
                          <span className="Polaris-Button__Content">
                            <span className="Polaris-Button__Text">Save</span>
                          </span>
                        </button>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Polaris-Backdrop" />
    </div>
  );
}

export default PlaylistSelector;

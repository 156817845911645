import React, { useState, useEffect } from "react";
import { authAxios } from "../config/axios";
import Plan from "../component/settings/plan";
import { useLocation, useParams } from "react-router-dom";
import Billing from "../component/settings/billing";
import { SettingMock } from "../assets/mocks/settings.mock";

function Settings() {
  let { shop } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(SettingMock);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const chargeId = queryParams.get("charge_id");

    if (chargeId) {
      // Call the API function with the chargeId
      activatePlan(chargeId);
    }
  }, [location.search]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      let response = await authAxios("get", `/apisv1/authadmin/settings`);

      if (response.data.data) setData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching data:", error);
      setLoading(false);
    }
  };

  const activatePlan = async (chargeId) => {
    try {
      await authAxios("post", `/apisv1/billing/charge?charge_id=${chargeId}`);

      setLoading(false);
    } catch (error) {
      console.log("Error fetching data:", error);
      setLoading(false);
    }
  };

  const selectPlan = async (plan) => {
    try {
      let response = await authAxios("post", `/apisv1/billing/chargeapprove`, {
        plan: plan.toLowerCase(),
        return_url: `${window.location.origin}/${shop}/settings`,
      });
      if (response.data.data) {
        window.location.href = response.data.data.confirmation_url;
      }
    } catch (error) {
      console.log("Error fetching data:", error);
      setLoading(false);
    }
  };

  return (
    <main
      className="Polaris-Frame__Main"
      id="AppFrameMain"
      data-has-global-ribbon="false"
    >
      <div className="Polaris-Frame__Content transitionEffect">
        <div className="Polaris-Page Polaris-Page--fullWidth">
          <Billing data={data} />

          {/* Plan */}
          <div className="pricing">
            <Plan
              name="STARTER"
              price="$29/month"
              view="2,500 video view/month"
              features={[
                "Additional 9$ for every extra 1000 views",
                "Unlimited videos",
                "Analytics",
              ]}
              selectPlan={selectPlan}
              userData={data.user}
            />
            <Plan
              name="GROWTH"
              price="$99/month"
              view="10,000 video view/month"
              features={[
                "Additional 9$ for every extra 1000 views",
                "Unlimited videos",
                "Analytics",
                "Dedicated support",
              ]}
              selectPlan={selectPlan}
              className="popular"
              userData={data.user}
            />
            <Plan
              name="SCALE"
              price="$399/month"
              view="50,000 video view/month"
              features={[
                "Additional 9$ for every extra 1000 views",
                "Unlimited videos",
                "Analytics",
                "Dedicated support",
              ]}
              selectPlan={selectPlan}
              userData={data.user}
            />
          </div>
          {/* END Plan */}
        </div>
        {/* End Right Side Video Section */}
      </div>
    </main>
  );
}

export default Settings;

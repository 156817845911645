import axios from "axios";
import config from "./config";
/* node api urls  */
// export let node_base_url = "https://91ce-103-240-76-193.in.ngrok.io";
// export let node_base_url = "http://localhost:5000";

export let node_base_url = process.env.REACT_APP_NODE_BASE_URL;

async function authAxios(method, url, data = null) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios({
        baseURL: node_base_url,
        method: method,
        url: url,
        data: data ? data : null,
        headers: {
          "security-token": localStorage.getItem(
            config.LOCAL_STORAGE.SECURITY_TOKEN,
          ),
        },
      });
      resolve(response);
    } catch (error) {
      if (error.response.status === 401) {
        // localStorage.removeItem(config.LOCAL_STORAGE.SECURITY_TOKEN)
        // let current_location = window.location.href;
        // if (current_location.includes("master")) {
        //   window.location.replace(`/master`);
        // }else if(current_location.includes("staff") || current_location.includes("admin")){
        //   window.location.replace(`/loginstaff`);
        // }else{
        //   window.location.replace(`/login/${window.mall_id}`);
        // }
        reject(error);
      } else if (error.response.status === 404) {
        // console.log(error);
        // window.location.replace(`/404`);
      } else {
        reject(error);
      }
    }
  });
}

async function axiosHandler(method, url, data = null) {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios({
        baseURL: node_base_url,
        method: method,
        url: url,
        data: data ? data : null,
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

async function videoStatusCheck(id, isPopup) {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await authAxios(
        "get",
        `/apisv1/authadmin/checkvideostatus?video=${id}&isPopup=${isPopup}`,
      );
      if (res.status == 200) {
        let state = res.data.data.video_state;
        let new_video_src = res.data.data.new_video_src;
        if (state === false) {
          setTimeout(() => {
            videoStatusCheck(id, isPopup);
          }, 2000);
        } else {
          resolve({ state: state, new_video_src: new_video_src });
        }
      }
    } catch (error) {
      reject(error);
    }
  });
}

export { authAxios, axiosHandler, videoStatusCheck };
/* axios related common function */

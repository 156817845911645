import React, { useState } from "react";

import PopupDesktop from "./PopupDesktop";
import PopupMobile from "./PopupMobile";

function PopupTab({ allSettings, setAllSettings, showToast }) {
  const [isMobileView, setIsMobileView] = useState(false);

  return (
    <>
      {!isMobileView ? (
        <PopupDesktop
          allSettings={allSettings}
          setAllSettings={setAllSettings}
          setIsMobileView={setIsMobileView}
          showToast={showToast}
        />
      ) : (
        <PopupMobile
          allSettings={allSettings}
          setAllSettings={setAllSettings}
          setIsMobileView={setIsMobileView}
          showToast={showToast}
        />
      )}
    </>
  );
}

export default PopupTab;

import React, { useEffect, useState } from "react";
import { authAxios } from "../config/axios";
import { useLocation, useParams } from "react-router-dom";

function Billing() {
  let { shop } = useParams();
  const [loading, setLoading] = useState("");
  const handleClick = async (plan) => {
    try {
      setLoading(plan);
      let res = await authAxios("post", "/apisv1/billing/chargeapprove", {
        plan: plan,
        return_url: `${window.location.origin}/${shop}/settings`,
      });
      if (res.status === 200) {
        window.location.href = res.data.data.confirmation_url;
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading("");
    }
  };

  return (
    <div className="Polaris-Page Polaris-Page--fullWidth">
      <div
        style={{
          "--pc-box-padding-block-end-xs": "var(--p-space-4)",
          "--pc-box-padding-block-end-md": "var(--p-space-5)",
          "--pc-box-padding-block-start-xs": "var(--p-space-4)",
          "--pc-box-padding-block-start-md": "var(--p-space-5)",
          "--pc-box-padding-inline-start-xs": "var(--p-space-4)",
          "--pc-box-padding-inline-start-sm": "var(--p-space-0)",
          "--pc-box-padding-inline-end-xs": "var(--p-space-4)",
          "--pc-box-padding-inline-end-sm": "var(--p-space-0)",
          position: "relative",
        }}
      >
        <div className="Polaris-Page-Header--isSingleRow Polaris-Page-Header--mediumTitle">
          <div className="Polaris-Page-Header__Row">
            <div className="Polaris-Page-Header__TitleWrapper">
              <h1 className="billing-header">Vidzy Select Pricing Plan</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="Polaris-Page--divider">
        <div className="pricing">
          <div className="plan">
            <div className="price">$29/month</div>
            <h2>STARTER</h2>
            <div className="pric-view">2,500 video view/month</div>
            <div className="features">
              <div className="li">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z"
                    fill="#883DCF"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.9497 6.7935C13.3403 6.40297 13.3403 5.76981 12.9497 5.37928C12.5592 4.98876 11.9261 4.98876 11.5355 5.37928L6.58579 10.329L4.46447 8.20771C4.07394 7.81719 3.44078 7.81719 3.05025 8.20771C2.65973 8.59824 2.65973 9.2314 3.05025 9.62192L5.87868 12.4504C6.26921 12.8409 6.90237 12.8409 7.29289 12.4504L12.9497 6.7935Z"
                    fill="white"
                  />
                </svg>
                Additional 9$ for every extra 1000 views
              </div>
              <div className="li">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z"
                    fill="#883DCF"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.9497 6.7935C13.3403 6.40297 13.3403 5.76981 12.9497 5.37928C12.5592 4.98876 11.9261 4.98876 11.5355 5.37928L6.58579 10.329L4.46447 8.20771C4.07394 7.81719 3.44078 7.81719 3.05025 8.20771C2.65973 8.59824 2.65973 9.2314 3.05025 9.62192L5.87868 12.4504C6.26921 12.8409 6.90237 12.8409 7.29289 12.4504L12.9497 6.7935Z"
                    fill="white"
                  />
                </svg>
                Unlimited videos
              </div>
              <div className="li">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z"
                    fill="#883DCF"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.9497 6.7935C13.3403 6.40297 13.3403 5.76981 12.9497 5.37928C12.5592 4.98876 11.9261 4.98876 11.5355 5.37928L6.58579 10.329L4.46447 8.20771C4.07394 7.81719 3.44078 7.81719 3.05025 8.20771C2.65973 8.59824 2.65973 9.2314 3.05025 9.62192L5.87868 12.4504C6.26921 12.8409 6.90237 12.8409 7.29289 12.4504L12.9497 6.7935Z"
                    fill="white"
                  />
                </svg>
                Analytics
              </div>
            </div>
            <button
              onClick={() => handleClick("starter")}
              className="billing-btn"
            >
              {loading === "starter" && <div class="spinner"></div>}Select Plan
            </button>
          </div>
          <div className="plan popular">
            <div className="recommended">
              <span>Recommended</span>
            </div>
            <div className="price popular-price">$99/month</div>
            <h2 className="popular-title">GROWTH</h2>
            <div className="pric-view popular-pric-view">
              10,000 video view/month
            </div>
            <div className="features">
              <div className="li popular-li">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.9497 6.7935C13.3403 6.40297 13.3403 5.76981 12.9497 5.37928C12.5592 4.98876 11.9261 4.98876 11.5355 5.37928L6.58579 10.329L4.46447 8.20771C4.07394 7.81719 3.44078 7.81719 3.05025 8.20771C2.65973 8.59824 2.65973 9.2314 3.05025 9.62192L5.87868 12.4504C6.26921 12.8409 6.90237 12.8409 7.29289 12.4504L12.9497 6.7935Z"
                    fill="#883DCF"
                  />
                </svg>
                Additional 9$ for every extra 1000 views
              </div>
              <div className="li popular-li">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.9497 6.7935C13.3403 6.40297 13.3403 5.76981 12.9497 5.37928C12.5592 4.98876 11.9261 4.98876 11.5355 5.37928L6.58579 10.329L4.46447 8.20771C4.07394 7.81719 3.44078 7.81719 3.05025 8.20771C2.65973 8.59824 2.65973 9.2314 3.05025 9.62192L5.87868 12.4504C6.26921 12.8409 6.90237 12.8409 7.29289 12.4504L12.9497 6.7935Z"
                    fill="#883DCF"
                  />
                </svg>
                Unlimited videos
              </div>
              <div className="li popular-li">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.9497 6.7935C13.3403 6.40297 13.3403 5.76981 12.9497 5.37928C12.5592 4.98876 11.9261 4.98876 11.5355 5.37928L6.58579 10.329L4.46447 8.20771C4.07394 7.81719 3.44078 7.81719 3.05025 8.20771C2.65973 8.59824 2.65973 9.2314 3.05025 9.62192L5.87868 12.4504C6.26921 12.8409 6.90237 12.8409 7.29289 12.4504L12.9497 6.7935Z"
                    fill="#883DCF"
                  />
                </svg>{" "}
                Analytics
              </div>
              <div className="li popular-li">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.9497 6.7935C13.3403 6.40297 13.3403 5.76981 12.9497 5.37928C12.5592 4.98876 11.9261 4.98876 11.5355 5.37928L6.58579 10.329L4.46447 8.20771C4.07394 7.81719 3.44078 7.81719 3.05025 8.20771C2.65973 8.59824 2.65973 9.2314 3.05025 9.62192L5.87868 12.4504C6.26921 12.8409 6.90237 12.8409 7.29289 12.4504L12.9497 6.7935Z"
                    fill="#883DCF"
                  />
                </svg>
                Dedicated support
              </div>
            </div>
            <button
              onClick={() => handleClick("growth")}
              className="popular-btn billing-popular-btn"
            >
              {loading === "growth" && <div class="spinner"></div>}
              Select Plan
            </button>
          </div>
          <div className="plan">
            <div className="price">$399/month</div>
            <h2>SCALE</h2>
            <div className="pric-view">50,000 video view/month</div>
            <div className="features">
              <div className="li">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z"
                    fill="#883DCF"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.9497 6.7935C13.3403 6.40297 13.3403 5.76981 12.9497 5.37928C12.5592 4.98876 11.9261 4.98876 11.5355 5.37928L6.58579 10.329L4.46447 8.20771C4.07394 7.81719 3.44078 7.81719 3.05025 8.20771C2.65973 8.59824 2.65973 9.2314 3.05025 9.62192L5.87868 12.4504C6.26921 12.8409 6.90237 12.8409 7.29289 12.4504L12.9497 6.7935Z"
                    fill="white"
                  />
                </svg>
                Additional 9$ for every extra 1000 views
              </div>
              <div className="li">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z"
                    fill="#883DCF"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.9497 6.7935C13.3403 6.40297 13.3403 5.76981 12.9497 5.37928C12.5592 4.98876 11.9261 4.98876 11.5355 5.37928L6.58579 10.329L4.46447 8.20771C4.07394 7.81719 3.44078 7.81719 3.05025 8.20771C2.65973 8.59824 2.65973 9.2314 3.05025 9.62192L5.87868 12.4504C6.26921 12.8409 6.90237 12.8409 7.29289 12.4504L12.9497 6.7935Z"
                    fill="white"
                  />
                </svg>{" "}
                Unlimited videos
              </div>
              <div className="li">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z"
                    fill="#883DCF"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.9497 6.7935C13.3403 6.40297 13.3403 5.76981 12.9497 5.37928C12.5592 4.98876 11.9261 4.98876 11.5355 5.37928L6.58579 10.329L4.46447 8.20771C4.07394 7.81719 3.44078 7.81719 3.05025 8.20771C2.65973 8.59824 2.65973 9.2314 3.05025 9.62192L5.87868 12.4504C6.26921 12.8409 6.90237 12.8409 7.29289 12.4504L12.9497 6.7935Z"
                    fill="white"
                  />
                </svg>{" "}
                Analytics
              </div>
              <div className="li">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z"
                    fill="#883DCF"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.9497 6.7935C13.3403 6.40297 13.3403 5.76981 12.9497 5.37928C12.5592 4.98876 11.9261 4.98876 11.5355 5.37928L6.58579 10.329L4.46447 8.20771C4.07394 7.81719 3.44078 7.81719 3.05025 8.20771C2.65973 8.59824 2.65973 9.2314 3.05025 9.62192L5.87868 12.4504C6.26921 12.8409 6.90237 12.8409 7.29289 12.4504L12.9497 6.7935Z"
                    fill="white"
                  />
                </svg>
                Dedicated support
              </div>
            </div>
            <button
              onClick={() => handleClick("scale")}
              className="billing-btn"
            >
              {loading === "scale" && <div class="spinner"></div>}Select Plan
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Billing;

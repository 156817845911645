import React from "react";
import Chart from "./commonChart";

const ImpressionChart = (props) => {
  const data = props.data;

  const config = {
    chart: {
      type: "spline",
    },
    plotOptions: {
      spline: {
        marker: {
          radius: 4,
          lineColor: "#883DCF",
          lineWidth: 1,
        },
      },
    },
    // title: {
    //   text: "Impression and Clicks",
    // },
    xAxis: {
      categories: data.map((entry) => entry.date),
      type: "datetime",
      labels: {
        formatter: function () {
          const date = new Date(this.value);
          const day = date.getDate();
          const month = date.toLocaleString("default", { month: "short" });
          return `${day} ${month}`;
        },
      },
    },
    yAxis: {
      // title: {
      //   text: "Impression & Add to cart",
      // },
    },
    series: [
      {
        name: "Impression",
        data: data.map((entry) => entry.impression),
      },
      {
        marker: {
          symbol: "diamond",
        },
        name: "Add to cart",
        data: data.map((entry) => entry.click),
      },
    ],
  };

  return <Chart data={data} config={config} className="impression-chart"/>;
};

export default ImpressionChart;

import React, { useEffect, useMemo, useState } from "react";
import { authAxios } from "../config/axios";
import PlaylistSelector from "../component/PlaylistSelector";
import CommonDelete from "../component/CommonDelete";
import Loader from "../component/Loader";
import PlaylistTable from "../component/playlistTable";
import plusIcon from "../assets/img/x.svg";
import CommonSuccess from "../component/CommonSuccess";
function Playlist() {
  const [playlist, setPlaylist] = useState([]);
  const [playlistName, setPlaylistName] = useState("");
  const [isShowPlaylistSelector, setIsShowPlaylistSelector] = useState(false);
  const [playListInfo, setPlayListInfo] = useState({
    selected_videos: [],
    title: "",
  });
  const [isEdit, setIsEdit] = useState(null);
  const [isShowDeletePopup, setIsShowDeletePopup] = useState(false);
  const [isSuccessPopUp, setIsSuccessPopUp] = useState(false);
  const [isDeleteSuccessPopup, setIsDeleteSuccessPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchPlaylist();
  }, []);

  const fetchPlaylist = async () => {
    // fetch collection api
    try {
      setIsLoading(true);
      let response = await authAxios("get", "/apisv1/authadmin/getplaylist");
      if (response.status === 200) {
        setPlaylist(response.data.data);
        console.log(playlist);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const managePlaylist = async (id, flag, ary = null, title) => {
    if (flag === "edit") {
      setIsEdit(id);
      setPlayListInfo({ selected_videos: ary, title: title });
      setIsShowPlaylistSelector(true);
      setPlaylistName(title);
    } else {
      setIsEdit(id);
      setIsShowDeletePopup(true);
      setPlaylistName(title);
    }
  };

  const handleDelete = async () => {
    let response = await authAxios(
      "delete",
      `/apisv1/authadmin/playlist?playlist=${isEdit}`
    );
    if (response.status === 200) {
      fetchPlaylist();
      setIsShowDeletePopup(false);
      setIsDeleteSuccessPopup(true);
    }
  };

  const handleCreate = () => {
    setIsEdit(null);

    setPlayListInfo({ selected_videos: [], title: "" });
    setIsShowPlaylistSelector(true);
    fetchPlaylist();
  };

  const handleClose = () => {
    setIsEdit(null);
    setPlayListInfo({ selected_videos: [], title: "" });
    setIsShowPlaylistSelector(false);
    fetchPlaylist();
    setIsSuccessPopUp(false);
  };
  const videoTableData = useMemo(
    () =>
      playlist.map(({ _id, playlist_videos, playlist_name }) => {
        return {
          id: _id,
          title: <div style={{ width: "100%" }}>{playlist_name}</div>,
          numOfVideo: playlist_videos.length,
          action: (
            <div className="Polaris-ButtonGroup">
              <div className="Polaris-ButtonGroup__Item">
                <button
                  onClick={() =>
                    managePlaylist(_id, "edit", playlist_videos, playlist_name)
                  }
                  className="Polaris-Button"
                  type="button"
                >
                  <span className="Polaris-Button__Content">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.333 1.28422C10.5081 1.10912 10.716 0.970229 10.9447 0.875468C11.1735 0.780706 11.4187 0.731934 11.6663 0.731934C11.914 0.731934 12.1592 0.780706 12.3879 0.875468C12.6167 0.970229 12.8246 1.10912 12.9997 1.28422C13.1748 1.45931 13.3137 1.66718 13.4084 1.89596C13.5032 2.12473 13.552 2.36993 13.552 2.61755C13.552 2.86517 13.5032 3.11037 13.4084 3.33915C13.3137 3.56792 13.1748 3.77579 12.9997 3.95089L3.99967 12.9509L0.333008 13.9509L1.33301 10.2842L10.333 1.28422Z"
                        fill="#A3A9B6"
                      />
                    </svg>
                  </span>
                </button>
              </div>
              <div className="Polaris-ButtonGroup__Item">
                <button
                  onClick={() =>
                    managePlaylist(_id, "del", playlist_videos, playlist_name)
                  }
                  className="Polaris-Button"
                  type="button"
                >
                  <span className="Polaris-Button__Content">
                    <svg
                      width="14"
                      height="17"
                      viewBox="0 0 14 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="Group">
                        <path
                          id="Vector"
                          d="M12.9997 2.95084H10.933C10.614 1.3999 9.24973 0.28618 7.66632 0.28418H6.33298C4.74957 0.28618 3.38526 1.3999 3.06632 2.95084H0.999664C0.631477 2.95084 0.333008 3.2493 0.333008 3.61749C0.333008 3.98568 0.631477 4.28418 0.999664 4.28418H1.66632V12.9508C1.66854 14.7909 3.15963 16.282 4.99966 16.2842H8.99966C10.8397 16.282 12.3308 14.7909 12.333 12.9508V4.28418H12.9997C13.3679 4.28418 13.6663 3.98571 13.6663 3.61752C13.6663 3.24934 13.3679 2.95084 12.9997 2.95084ZM6.33301 11.6175C6.33301 11.9857 6.03454 12.2842 5.66635 12.2842C5.29813 12.2842 4.99966 11.9857 4.99966 11.6175V7.61752C4.99966 7.24934 5.29813 6.95087 5.66632 6.95087C6.03451 6.95087 6.33298 7.24934 6.33298 7.61752V11.6175H6.33301ZM8.99966 11.6175C8.99966 11.9857 8.7012 12.2842 8.33301 12.2842C7.96482 12.2842 7.66635 11.9857 7.66635 11.6175V7.61752C7.66635 7.24934 7.96482 6.95087 8.33301 6.95087C8.7012 6.95087 8.99966 7.24934 8.99966 7.61752V11.6175ZM4.44701 2.95084C4.73057 2.15237 5.4857 1.61852 6.33301 1.61749H7.66635C8.51367 1.61852 9.26879 2.15237 9.55235 2.95084H4.44701Z"
                          fill="#A3A9B6"
                        />
                      </g>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          ),
        };
      }),
    [playlist]
  );
  return (
    <main
      className="Polaris-Frame__Main"
      id="AppFrameMain"
      data-has-global-ribbon="false"
    >
      <div
        className="Polaris-Frame__Content transitionEffect"
        style={{ minHeight: "calc(100vh - 56px)", overflowY: "auto" }}
      >
        <div className="Polaris-Page Polaris-Page--fullWidth">
          <div className="Polaris-Box" style={{ padding: "24px" }}></div>
          {videoTableData.length ? (
            <div className="Polaris-Page--divider" style={{ padding: "0px" }}>
              {/* FIle upload */}
              <div style={{ textAlign: "end", marginBottom: "20px" }}>
                <button
                  className="Polaris-Button Polaris-Button--primary "
                  type="button"
                  onClick={handleCreate}
                >
                  <span className="Polaris-Button__Content">
                    <img
                      src={plusIcon}
                      alt="plus"
                      style={{
                        marginRight: "4px",
                        height: "20px",
                        width: "20px",
                      }}
                    />
                    <span className="Polaris-Button__Text">
                      Create Playlist
                    </span>
                  </span>
                </button>
              </div>

              {/* FIle upload */}
              {/* <PlaylistTable /> */}

              <div className="Video-list" style={{ height: "max-content" }}>
                {isLoading ? (
                  <Loader />
                ) : (
                  <PlaylistTable
                    headings={[
                      { title: "Playlist" },
                      { title: "Numbers of Video" },
                      { title: "Actions" },
                    ]}
                    tableData={videoTableData}
                  />
                )}
              </div>
            </div>
          ) : (
            <div className="add-container">
              <div className="border-header" style={{ marginBottom: "12px" }}>
                Playlist
              </div>
              <div className="dotted-section" onClick={handleCreate}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100"
                  height="65"
                  viewBox="0 0 100 65"
                  fill="none"
                >
                  <g clip-path="url(#clip0_369_18796)">
                    <path
                      d="M84.3735 60.36C84.8352 60.5223 85.3309 60.5629 85.8128 60.478C86.2948 60.3931 86.7467 60.1856 87.1251 59.8753C88.0889 59.0664 88.3915 57.7337 88.637 56.4996L89.3642 52.8496L87.8417 53.898C86.7468 54.6519 85.6273 55.43 84.8691 56.5221C84.1109 57.6141 83.7801 59.1048 84.3892 60.2864"
                      fill="#E6E6E6"
                    />
                    <path
                      d="M84.5036 64.7792C84.3495 63.6567 84.191 62.5199 84.2991 61.3854C84.3952 60.3778 84.7025 59.3937 85.3284 58.5868C85.6605 58.1593 86.0648 57.793 86.5228 57.5044C86.6423 57.4292 86.7521 57.6184 86.6333 57.6934C85.8407 58.1942 85.2277 58.9339 84.8826 59.8056C84.5015 60.775 84.4403 61.8318 84.5065 62.8616C84.5462 63.4843 84.6304 64.1028 84.7152 64.7209C84.7221 64.7489 84.718 64.7784 84.7038 64.8034C84.6895 64.8285 84.6663 64.8471 84.6388 64.8556C84.6108 64.8631 84.5809 64.8593 84.5557 64.845C84.5305 64.8307 84.512 64.807 84.5041 64.7791L84.5036 64.7792Z"
                      fill="#F2F2F2"
                    />
                    <path
                      d="M96.8022 64.7342C96.8022 64.7342 95.2033 60.7985 100 57.8467L96.8022 64.7342Z"
                      fill="#E6E6E6"
                    />
                    <path
                      d="M94.8358 64.6626C94.8358 64.6626 94.3481 61.8574 90.5729 61.8815L94.8358 64.6626Z"
                      fill="#E6E6E6"
                    />
                    <path
                      d="M6.4812 30.2198H41.6319V5.4248H6.4812V30.2198Z"
                      fill="white"
                    />
                    <path
                      d="M42.3842 30.9721H5.72894V4.67261H42.3842V30.9721ZM6.48121 30.2199H41.632V5.42488H6.48121V30.2199Z"
                      fill="#E6E6E6"
                    />
                    <path
                      d="M3.36994 27.9412H3.11078V2.74194H38.2625V3.00311H3.36994V27.9412Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M0.259282 25.1992H0V0H35.1518V0.261163H0.259282V25.1992Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M24.0565 25.3952C28.2084 25.3952 31.5741 22.0047 31.5741 17.8224C31.5741 13.64 28.2084 10.2495 24.0565 10.2495C19.9047 10.2495 16.5389 13.64 16.5389 17.8224C16.5389 22.0047 19.9047 25.3952 24.0565 25.3952Z"
                      fill="white"
                    />
                    <path
                      d="M24.0566 25.5204C19.8422 25.5204 16.4136 22.067 16.4136 17.8222C16.4136 13.5774 19.8422 10.124 24.0566 10.124C28.2709 10.124 31.6995 13.5774 31.6995 17.8222C31.6995 22.067 28.2709 25.5204 24.0566 25.5204ZM24.0566 10.3748C19.9805 10.3748 16.6643 13.7157 16.6643 17.8222C16.6643 21.9288 19.9805 25.2697 24.0566 25.2697C28.1326 25.2697 31.4488 21.9288 31.4488 17.8222C31.4488 13.7157 28.1326 10.3748 24.0566 10.3748Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M21.9789 14.0652V21.71L28.2164 17.8353L21.9789 14.0652Z"
                      fill="#883DCF"
                    />
                    <path
                      d="M64.6022 63.2675H63.1996L62.5323 57.8572H64.6025L64.6022 63.2675Z"
                      fill="#FFB7B7"
                    />
                    <path
                      d="M64.96 64.6273L60.4371 64.6272V64.57C60.4372 64.1031 60.6226 63.6553 60.9528 63.3252C61.2829 62.9951 61.7307 62.8096 62.1975 62.8096L64.9601 62.8097L64.96 64.6273Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M76.6534 61.2172L75.4773 61.9818L71.9688 57.8096L73.7045 56.6812L76.6534 61.2172Z"
                      fill="#FFB7B7"
                    />
                    <path
                      d="M77.6944 62.1621L73.9024 64.6273L73.8713 64.5793C73.6168 64.1879 73.5282 63.7114 73.6251 63.2547C73.7219 62.7979 73.9962 62.3984 74.3876 62.1439L76.7037 60.6382L77.6944 62.1621Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M68.1864 12.1996C68.9001 12.1996 69.4788 11.621 69.4788 10.9072C69.4788 10.1934 68.9001 9.61475 68.1864 9.61475C67.4726 9.61475 66.8939 10.1934 66.8939 10.9072C66.8939 11.621 67.4726 12.1996 68.1864 12.1996Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M66.9664 11.3772C66.8316 11.1987 66.7455 10.9883 66.7164 10.7665C66.6873 10.5448 66.7163 10.3192 66.8004 10.112C66.8846 9.90476 67.0211 9.72291 67.1966 9.58423C67.3721 9.44555 67.5806 9.35478 67.8017 9.3208C67.6223 9.31587 67.4438 9.34836 67.2776 9.41621C67.1115 9.48406 66.9613 9.58578 66.8366 9.71488C66.712 9.84399 66.6155 9.99765 66.5535 10.1661C66.4915 10.3345 66.4653 10.514 66.4765 10.6931C66.4877 10.8722 66.5361 11.0471 66.6186 11.2064C66.7011 11.3658 66.8159 11.5063 66.9557 11.6188C67.0955 11.7314 67.2572 11.8136 67.4305 11.8602C67.6038 11.9068 67.7849 11.9168 67.9623 11.8896C67.7685 11.8846 67.5783 11.8359 67.4058 11.7472C67.2334 11.6585 67.0832 11.532 66.9664 11.3772Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M66.8474 19.2769C68.9442 19.2769 70.644 17.5772 70.644 15.4804C70.644 13.3836 68.9442 11.6838 66.8474 11.6838C64.7507 11.6838 63.0509 13.3836 63.0509 15.4804C63.0509 17.5772 64.7507 19.2769 66.8474 19.2769Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M63.3099 14.0974C63.7207 13.2028 64.4631 12.5028 65.3804 12.1451C66.2976 11.7875 67.3179 11.8002 68.2259 12.1805C68.1876 12.1614 68.1495 12.1419 68.1103 12.1239C67.1953 11.7037 66.1508 11.6643 65.2066 12.0143C64.2625 12.3643 63.4961 13.075 63.0759 13.99C62.6558 14.9051 62.6164 15.9496 62.9664 16.8937C63.3164 17.8378 64.0271 18.6043 64.9421 19.0244C64.9813 19.0424 65.0209 19.0586 65.0604 19.0751C64.1801 18.6344 63.5054 17.8689 63.1788 16.9402C62.8522 16.0115 62.8992 14.9922 63.3099 14.0974Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M71.454 35.4028L74.1748 49.5174L77.746 60.0606L71.9642 61.251L66.3524 40.1643L66.1822 60.4008L58.8699 60.5709L60.5706 48.4969L62.6352 34.866L71.454 35.4028Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M66.8593 19.2508C68.2681 19.2508 69.4102 18.1088 69.4102 16.7C69.4102 15.2912 68.2681 14.1492 66.8593 14.1492C65.4506 14.1492 64.3085 15.2912 64.3085 16.7C64.3085 18.1088 65.4506 19.2508 66.8593 19.2508Z"
                      fill="#FFB8B8"
                    />
                    <path
                      d="M69.2432 20.51L65.332 20.608L62.6111 21.9684L62.9512 29.7909L62.2918 35.7812C62.2918 35.7812 62.0453 36.72 63.7459 37.5703C64.2901 37.8424 64.991 37.9578 65.6926 37.9888C67.1294 38.0354 68.5391 37.5897 69.6879 36.7255C70.0446 36.4641 70.4397 36.2894 70.7737 36.423C71.624 36.7631 71.7941 35.7428 71.7941 35.7428L70.7737 30.8113L72.3042 26.1218L70.7584 21.0877L69.2432 20.51Z"
                      fill="#883DCF"
                    />
                    <path
                      d="M64.5046 14.0327V16.8728H65.1625L65.9995 15.9759L65.8874 16.8728H68.7947L68.6154 15.9759L68.9741 16.8728H69.4375V14.0327H64.5046Z"
                      fill="#2F2E41"
                    />
                    <path
                      d="M69.9481 15.9926C69.9407 15.9157 69.905 15.8443 69.8479 15.7923C69.7907 15.7402 69.7163 15.7113 69.639 15.7112H69.3268V17.7687H69.639C69.7163 17.7686 69.7907 17.7397 69.8479 17.6876C69.905 17.6356 69.9407 17.5642 69.9481 17.4873C70.1036 17.4454 70.2411 17.3534 70.3391 17.2256C70.4371 17.0977 70.4903 16.9411 70.4904 16.78V16.6999C70.4903 16.5388 70.4371 16.3822 70.3391 16.2543C70.2411 16.1265 70.1036 16.0345 69.9481 15.9926Z"
                      fill="#883DCF"
                    />
                    <path
                      d="M64.3068 17.5132H63.9616C63.8653 17.5132 63.77 17.4943 63.681 17.4574C63.592 17.4206 63.5112 17.3666 63.4431 17.2985C63.375 17.2304 63.321 17.1495 63.2841 17.0606C63.2473 16.9716 63.2283 16.8763 63.2283 16.78V16.6998C63.2283 16.6035 63.2473 16.5082 63.2841 16.4192C63.321 16.3303 63.375 16.2494 63.4431 16.1813C63.5112 16.1132 63.592 16.0592 63.681 16.0224C63.77 15.9855 63.8653 15.9666 63.9616 15.9666H64.3068V17.5132Z"
                      fill="#883DCF"
                    />
                    <path
                      d="M64.3919 17.7687H64.0798C63.997 17.7687 63.9176 17.7358 63.859 17.6773C63.8005 17.6187 63.7676 17.5393 63.7676 17.4565V16.0234C63.7676 15.9406 63.8005 15.8612 63.859 15.8026C63.9176 15.7441 63.997 15.7112 64.0798 15.7112H64.3919V17.7687Z"
                      fill="#883DCF"
                    />
                    <path
                      d="M69.781 16.0742H69.5522C69.5522 14.4581 68.3547 13.1434 66.8829 13.1434C65.4109 13.1434 64.2135 14.4581 64.2135 16.0742H63.9847C63.9847 14.332 65.2848 12.9146 66.8829 12.9146C68.4809 12.9146 69.781 14.332 69.781 16.0742Z"
                      fill="#883DCF"
                    />
                    <path
                      d="M60.459 39.9159C60.5966 39.8408 60.7161 39.7363 60.8091 39.61C60.902 39.4837 60.9662 39.3385 60.9969 39.1847C61.0277 39.0309 61.0244 38.8722 60.9872 38.7199C60.95 38.5675 60.8799 38.4251 60.7817 38.3028L61.96 36.1529L60.8281 35.1182L59.2068 38.1777C59.0122 38.3562 58.8899 38.5999 58.863 38.8626C58.8361 39.1253 58.9065 39.3887 59.0608 39.603C59.2151 39.8172 59.4427 39.9674 59.7004 40.0251C59.9581 40.0828 60.228 40.0439 60.459 39.9159Z"
                      fill="#FFB7B7"
                    />
                    <path
                      d="M63.4614 21.9684L63.0036 21.7441C63.0036 21.7441 61.9309 21.9684 61.5908 22.6486C61.2507 23.3289 60.4004 30.3011 60.4004 30.3011L59.7202 34.8925C59.7202 34.8925 59.2101 34.8925 59.2101 35.4027C59.2101 35.9129 58.5298 37.2733 59.04 37.2733C59.5502 37.2733 61.5908 37.2733 61.9309 36.9332C62.271 36.5931 62.9513 35.2326 62.7812 35.0626C62.6111 34.8925 62.271 34.8925 62.271 34.8925L62.7495 30.2894L63.4614 21.9684Z"
                      fill="#883DCF"
                    />
                    <path
                      d="M72.6008 38.3178C72.4866 38.2102 72.3974 38.079 72.3395 37.9332C72.2815 37.7875 72.2562 37.6308 72.2654 37.4742C72.2745 37.3176 72.3179 37.165 72.3924 37.027C72.467 36.889 72.5708 36.769 72.6968 36.6755L72.1009 34.2974L73.4578 33.5828L74.2521 36.953C74.3951 37.1749 74.4518 37.4416 74.4114 37.7026C74.3709 37.9635 74.2362 38.2006 74.0326 38.3688C73.8291 38.537 73.5709 38.6248 73.307 38.6154C73.0431 38.6059 72.7918 38.5001 72.6008 38.3178Z"
                      fill="#FFB7B7"
                    />
                    <path
                      d="M70.8942 28.7849L71.3726 33.3881C71.3726 33.3881 71.0325 33.3881 70.8625 33.5581C70.6924 33.7282 71.3726 35.0886 71.7127 35.4287C72.0528 35.7688 74.0935 35.7688 74.6036 35.7688C75.1138 35.7688 74.4336 34.4084 74.4336 33.8982C74.4336 33.3881 73.9234 33.3881 73.9234 33.3881L73.2432 28.7966C73.2432 28.7966 72.3929 22.5767 72.0528 21.8964C71.7127 21.2162 70.64 20.9919 70.64 20.9919L70.1823 21.2162L70.8942 28.7849Z"
                      fill="#883DCF"
                    />
                    <path
                      d="M61.5678 38.3681L61.5459 38.382L61.1644 37.7807C61.1354 37.735 61.0977 37.6955 61.0534 37.6643C61.0091 37.6332 60.9592 37.6111 60.9063 37.5993C60.8535 37.5875 60.7989 37.5862 60.7456 37.5955C60.6922 37.6048 60.6413 37.6245 60.5956 37.6535L59.3216 38.4618C59.2293 38.5203 59.164 38.6131 59.1402 38.7198C59.1163 38.8265 59.1358 38.9383 59.1944 39.0306L61.2873 42.3295C61.3163 42.3752 61.354 42.4147 61.3983 42.4458C61.4426 42.477 61.4926 42.4991 61.5454 42.5109C61.5982 42.5227 61.6528 42.524 61.7062 42.5147C61.7595 42.5054 61.8104 42.4857 61.8561 42.4567L63.1301 41.6484C63.2224 41.5898 63.2877 41.497 63.3115 41.3903C63.3354 41.2837 63.3159 41.1719 63.2573 41.0796L61.8175 38.8101L61.8394 38.7961L61.5678 38.3681Z"
                      fill="#3F3D56"
                    />
                    <path
                      d="M61.0919 37.8302L63.1817 41.1242C63.2255 41.1931 63.24 41.2766 63.2222 41.3563C63.2044 41.4359 63.1557 41.5052 63.0867 41.549L61.8066 42.3611C61.7377 42.4049 61.6542 42.4194 61.5746 42.4016C61.4949 42.3838 61.4256 42.335 61.3818 42.2661L59.292 38.9721C59.2482 38.9032 59.2337 38.8197 59.2515 38.74C59.2693 38.6603 59.3181 38.591 59.387 38.5473L59.5423 38.4488C59.5465 38.4724 59.5565 38.4945 59.5714 38.5134C59.5862 38.5322 59.6055 38.5471 59.6274 38.5567C59.6494 38.5663 59.6734 38.5704 59.6973 38.5686C59.7212 38.5668 59.7443 38.5592 59.7645 38.5463L60.4944 38.0833C60.5146 38.0704 60.5314 38.0528 60.5432 38.0319C60.555 38.0111 60.5615 37.9876 60.5622 37.9637C60.5628 37.9397 60.5575 37.9159 60.5468 37.8945C60.5361 37.873 60.5203 37.8545 60.5008 37.8406L60.6671 37.7351C60.736 37.6914 60.8195 37.6769 60.8992 37.6947C60.9788 37.7125 61.0481 37.7612 61.0919 37.8302Z"
                      fill="#883DCF"
                    />
                    <path
                      d="M98.9581 64.8628H51.189C51.1558 64.8628 51.1239 64.8496 51.1004 64.8261C51.0769 64.8026 51.0637 64.7707 51.0637 64.7374C51.0637 64.7042 51.0769 64.6723 51.1004 64.6488C51.1239 64.6253 51.1558 64.6121 51.189 64.6121H98.9581C98.9913 64.6121 99.0232 64.6253 99.0467 64.6488C99.0703 64.6723 99.0835 64.7042 99.0835 64.7374C99.0835 64.7707 99.0703 64.8026 99.0467 64.8261C99.0232 64.8496 98.9913 64.8628 98.9581 64.8628Z"
                      fill="#CBCBCB"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_369_18796">
                      <rect width="100" height="64.8628" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p className="border-header">
                  Create and organize your videos to create a unique playlist.
                </p>
                <p className="cancel-btn" style={{ width: "fit-content" }}>
                  Create Playlist
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      {isShowPlaylistSelector && (
        <PlaylistSelector
          playlist_data={playListInfo}
          playlist_id={isEdit}
          handleClose={() => handleClose()}
          successPopUp={() => setIsSuccessPopUp(true)}
        />
      )}
      {isShowDeletePopup && (
        <CommonDelete
          desc={`Are you sure to delete playlist "${playlistName}" ?`}
          setIsShowDeletePopup={setIsShowDeletePopup}
          handleDelete={() => handleDelete()}
        />
      )}

      {isSuccessPopUp && (
        <CommonSuccess
          desc={`Playlist created successfully`}
          setIsShowDeletePopup={setIsShowDeletePopup}
          headlText={"Create Playlist"}
          handleDelete={() => setIsSuccessPopUp(false)}
        />
      )}

      {isDeleteSuccessPopup && (
        <CommonSuccess
          desc={`"${playlistName}" deleted successfully`}
          setIsShowDeletePopup={setIsDeleteSuccessPopup} // Update state variable accordingly
          headlText="Delete Playlist"
          handleDelete={() => setIsDeleteSuccessPopup(false)}
        />
      )}
    </main>
  );
}

export default Playlist;

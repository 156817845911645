import React from "react";

function CommonSuccess({
  desc,
  setIsShowDeletePopup,
  handleDelete,
  headlText,
}) {
  return (
    <div>
      <div
        className="Polaris-Modal-Dialog__Container"
        data-polaris-layer="true"
        data-polaris-overlay="true"
      >
        <div>
          <div
            role="dialog"
            aria-modal="true"
            aria-label="Polarismodal-header1"
            aria-labelledby="Polarismodal-header1"
            tabIndex={-1}
            className="Polaris-Modal-Dialog"
          >
            <div className="Polaris-Modal-Dialog__Modal">
              <div
                className="Polaris-Box"
                style={{
                  "--pc-box-padding-block-end-xs": "var(--p-space-4)",
                  "--pc-box-padding-block-start-xs": "var(--p-space-4)",
                  "--pc-box-padding-inline-start-xs": "var(--p-space-5)",
                  " --pc-box-padding-inline-end-xs": "var(--p-space-5)",
                }}
              >
                <div
                  className="Polaris-Columns"
                  style={{
                    "--pc-columns-grid-template-columns-xs": "1fr auto",
                    "--pc-columns-gap-xs": "var(--p-space-4)",
                  }}
                >
                  <div
                    className="Polaris-Inline"
                    style={{
                      "--pc-inline-block-align": "center",
                      "--pc-inline-wrap": "wrap",
                      "--pc-inline-gap-xs": "var(--p-space-4)",
                    }}
                  >
                    <h2
                      className="Polaris-Text--root Polaris-Text--headingLg Polaris-Text--break"
                      id="Polarismodal-header1"
                      style={{ marginBottom: "0px", fontSize: "18px" }}
                    >
                      {headlText}
                    </h2>
                  </div>
                </div>
              </div>
              <div
                className="Polaris-Modal__Body Polaris-Scrollable Polaris-Scrollable--vertical Polaris-Scrollable--horizontal"
                data-polaris-scrollable="true"
              >
                <div className="Polaris-Modal-Section">
                  <section
                    className="Polaris-Box"
                    style={{
                      "--pc-box-padding-block-end-xs": "var(--p-space-5)",
                      "--pc-box-padding-block-start-xs": "var(--p-space-5)",
                      // "--pc-box-padding-inline-start-xs": "var(--p-space-5)",
                      // "--pc-box-padding-inline-end-xs": "var(--p-space-5)",
                    }}
                  >
                    <div className="Polaris-LegacyStack Polaris-LegacyStack--vertical">
                      <div
                        className="Polaris-LegacyStack__Item"
                        style={{ padding: "0" }}
                      >
                        <span
                          style={{
                            display: "flex",
                            marginInline: "auto",
                            width: "fit-content",
                            marginBottom: "12px",
                          }}
                        >
                          <svg
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="12.5" cy="12" r="12" fill="#883DCF" />
                            <path
                              d="M16.5 9L11 14.5L8.5 12"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        <div className="Polaris-TextContainer">
                          <p
                            style={{ fontSize: "14px" }}
                            className="border-header"
                          >
                            {desc}
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div
                className="Polaris-Inline"
                style={{
                  "--pc-inline-block-align": "center",
                  "--pc-inline-wrap": "wrap",
                  "--pc-inline-gap-xs": "var(--p-space-4)",
                }}
              >
                <div
                  className="Polaris-Box"
                  style={{
                    width: "fit-content",
                    "padding-bottom": "24px",
                    marginInline: "auto",
                  }}
                >
                  <div
                    className="Polaris-Inline"
                    style={{
                      "--pc-inline-align": "space-between",
                      "--pc-inline-block-align": "center",
                      "--pc-inline-wrap": "wrap",
                      "--pc-inline-gap-xs": "var(--p-space-4)",
                    }}
                  >
                    <div className="" style={{}} />
                    <div
                      className="Polaris-Inline"
                      style={{
                        "--pc-inline-wrap": "wrap",
                        " --pc-inline-gap-xs": "var(--p-space-2)",
                      }}
                    >
                      <button
                        onClick={() => handleDelete()}
                        className="success-btn"
                        type="button"
                        style={{
                          padding: "10px 14px 10px 14px",
                          margin: "0 0 0 0",
                        }}
                      >
                        <span style={{ display: "flex", alignItems: "center" }}>
                          Done
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Polaris-Backdrop" />
    </div>
  );
}

export default CommonSuccess;
